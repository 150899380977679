import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../../constants/constants";

export default makeStyles((theme) => ({

    tittleMobile: {
        color: COLORS.white,
        fontSize: "x-large",
        fontWeight: "bold",
        justifyContent: "center",
        marginTop: "10px"
    },

    select: {
        color: COLORS.gray,
    },

}));
