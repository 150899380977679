import {Button, Dropdown} from "react-bootstrap";
import React from "react";
import useStyles from "./styles";
import {BsArrowLeftCircle, BsArrowRightCircle} from "react-icons/bs";

type InputProps = {
  rowsPerPage: number,
  currentPage: number,
  totalPages: number,
  previousPage: string | null,
  nextPage: string | null,
  handleRowsPerPageClicked: (rows: number) => void,
  handlePrevPageClicked: () => void,
  handleNextPageClicked: () => void,
};

export const AdminPagination: React.FC<InputProps> = React.forwardRef((props, ref) => {

  let classes = useStyles();

  return (
    <div className={["row", classes.dropDown].join(" ")}>
      <div
        className={"col-9 align-self-center text-end"}
        // style={{width: "135px", padding: 0, alignSelf: "center"}}
      >
        <Dropdown>
          <label className={classes.dropdownLabel}>
            Rows per page
          </label>
          <Dropdown.Toggle split className={classes.dropdownToggle}
                           id="dropdown-split-basic">{props.rowsPerPage === 0 ? "All" : props.rowsPerPage} </Dropdown.Toggle>
          <Dropdown.Menu className={classes.dropdownMenu}>
            <Dropdown.Item onClick={() => props.handleRowsPerPageClicked(50)}
                           className={classes.dropdownItem}>50</Dropdown.Item>
            <Dropdown.Item onClick={() => props.handleRowsPerPageClicked(100)}
                           className={classes.dropdownItem}>100</Dropdown.Item>
            <Dropdown.Item onClick={() => props.handleRowsPerPageClicked(5000)}
                           className={classes.dropdownItem}>All</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {props.rowsPerPage !== 0 &&
        <>
          <div className={"col-1 justify-content-end align-self-center text-center pe-0"} style={{fontSize: "12px"}}>
            Page {props.currentPage} of {props.totalPages}
          </div>
          <div className={"col-2 d-flex justify-content-center ps-0"}>
            <Button
              className={classes.btnNextPrev}
              onClick={props.handlePrevPageClicked}
              disabled={props.previousPage === null}
            ><BsArrowLeftCircle size={20}/></Button>
            <Button
              className={classes.btnNextPrev}
              onClick={props.handleNextPageClicked}
              disabled={props.nextPage === null}
            ><BsArrowRightCircle size={20}/></Button>
          </div>
        </>}
    </div>
  )
});

export default AdminPagination;
