import React, {useState} from 'react';
import HeaderWebAdmin from "../HeaderWeb/HeaderWebAdmin";
import {Redirect, useHistory} from "react-router";
import {Link} from 'react-router-dom';
import {useStores} from "../../models/root-store/root-store-context";
import {ROUTES, ROUTES_ADMIN} from "../../constants/constants";
import useStyle from "./styles"
import {Menu, MenuItem, ProSidebar, SubMenu} from "react-pro-sidebar";
import {useClearStore, useWindowSize} from "../../helpers";
import {FaTools, FaUsers} from "react-icons/fa";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {BiMessageError} from "react-icons/bi";
import {BsFileEarmarkCheck, BsHouse} from "react-icons/bs";
import {FiUserCheck} from "react-icons/fi";
import {HiOutlineChatAlt2} from "react-icons/hi";
import {MdOutlineLogout, MdOutlineRequestQuote} from "react-icons/md";

import "../../assets/css/base.scss"
import {RiUserStarLine} from "react-icons/ri";
import {Button, Modal, ModalBody, ModalHeader} from "react-bootstrap";

type PageProps = {
  login_required?: boolean
  title?: string
};

export const PageWebAdminDark: React.FC<PageProps> = React.forwardRef((props, ref) => {
  let classes = useStyle();

  const rootStore = useStores()
  const {loginStore} = rootStore
  const clearStore = useClearStore()
  const isMobile = useWindowSize()
  const history = useHistory()
  const [showSignOutMessage, setShowSignOutMessage] = useState<boolean>(false)

  if (props.login_required && !loginStore.isLoggedIn) {
    return <Redirect to={{pathname: ROUTES.LOGIN}}/>
  }
  if (loginStore.group !== 1) {//controlls if they are admin
    return <Redirect to={{pathname: ROUTES.HOME}}/>
  }

  const signoutClicked = () => {
    setShowSignOutMessage(true)

  }

  const signOut = () => {
    clearStore();
    history.push(ROUTES.LOGIN)
  }

  const closeSignOutMessage = () => {
    setShowSignOutMessage(false)
  }

  return (
    <div className={'d-flex flex-column min-vh-100 '} style={{backgroundColor: '#353939'}}>
      <HeaderWebAdmin/>
      <div className={'d-flex'}>
        <ProSidebar
          className={'flex-grow-1'}
          collapsed={isMobile}>
          <Menu iconShape="square">
            <MenuItem icon={<FaUsers size={"25px"}/>}
                      active={window.location.pathname === ROUTES_ADMIN.USER_LIST}>
              Users
              <Link to={ROUTES_ADMIN.USER_LIST}/>
            </MenuItem>
            <MenuItem icon={<FiUserCheck size={"25px"}/>}
                      active={window.location.pathname === ROUTES_ADMIN.BACKGROUND_CHECKS}
                      style={{whiteSpace: "pre-wrap"}}>
              Background check results
              <Link to={ROUTES_ADMIN.BACKGROUND_CHECKS}/>
            </MenuItem>
            <MenuItem icon={<AiOutlineCheckCircle size={"25px"}/>}
                      active={window.location.pathname === ROUTES_ADMIN.DENIED_USERS}>
              Denied Users
              <Link to={ROUTES_ADMIN.DENIED_USERS}/>
            </MenuItem>
            <SubMenu
              title="Approved accounts"
              icon={<BiMessageError size={"25px"}/>}
              defaultOpen={
                window.location.pathname === ROUTES_ADMIN.HOUSNERS ||
                window.location.pathname === ROUTES_ADMIN.PROPERTY_REQUESTS ||
                window.location.pathname === ROUTES_ADMIN.PROPERTIES_UNDER_CONTRACT ||
                window.location.pathname === ROUTES_ADMIN.MAINTENANCE_REQUESTS
            }
            >
              <MenuItem icon={<RiUserStarLine/>}
                        active={window.location.pathname === ROUTES_ADMIN.HOUSNERS}
              >
                Housners
                <Link to={ROUTES_ADMIN.HOUSNERS}/>
              </MenuItem>
              <MenuItem icon={<MdOutlineRequestQuote/>}
                        active={window.location.pathname === ROUTES_ADMIN.PROPERTY_REQUESTS}>
                Property requests
                <Link to={ROUTES_ADMIN.PROPERTY_REQUESTS}/>
              </MenuItem>
              <MenuItem icon={<BsFileEarmarkCheck/>}
                        active={window.location.pathname === ROUTES_ADMIN.PROPERTIES_UNDER_CONTRACT}>
                Contracts
                <Link to={ROUTES_ADMIN.PROPERTIES_UNDER_CONTRACT}/>
              </MenuItem>
              <MenuItem icon={<FaTools/>}
                        active={window.location.pathname === ROUTES_ADMIN.MAINTENANCE_REQUESTS}>
                Maintenance requests
                <Link to={ROUTES_ADMIN.MAINTENANCE_REQUESTS}/>
              </MenuItem>
            </SubMenu>
            <MenuItem icon={<BsHouse size={"25px"}/>}
                      active={window.location.pathname === ROUTES_ADMIN.PROPERTY_MANAGEMENT}>
              Property management
              <Link to={ROUTES_ADMIN.PROPERTY_MANAGEMENT}/>
            </MenuItem>
            <MenuItem icon={<HiOutlineChatAlt2 size={"25px"}/>}
                      active={window.location.pathname === ROUTES_ADMIN.FEEDBACKS}>
              Feedbacks
              <Link to={ROUTES_ADMIN.FEEDBACKS}/>
            </MenuItem>
            <MenuItem icon={<MdOutlineLogout size={"25px"}/>} onClick={signoutClicked}>
              Log out
            </MenuItem>
          </Menu>
        </ProSidebar>
        <div className={classes.adminContainer}>
          {props.children}
        </div>
      </div>
      <Modal className={"rounded"} centered show={showSignOutMessage} size={'sm'}>
        <ModalBody className={classes.modalBody}>
          <div style={{fontWeight: "bold", fontSize: "x-large"}} className={'mb-4'}>Are you sure?</div>
          <div>
            <Button
              className={classes.btnOption +  ' me-5'}
              onClick={signOut}
            >
              Yes
            </Button>
            <Button
              className={classes.btnOption}
              onClick={closeSignOutMessage}
            >
              No
            </Button>
          </div>

        </ModalBody>
      </Modal>
    </div>
  )
});

export default PageWebAdminDark;
