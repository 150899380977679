import React from 'react';
import {observer} from "mobx-react-lite";
import './HeaderWeb.css';
import {Container, Nav, Navbar} from "react-bootstrap";
import {COLORS, ROUTES, space} from "../../constants/constants";
import {useHistory} from "react-router";
// import {useClearStore} from "../../helpers";
import {useStores} from "../../models/root-store/root-store-context";
import useStyles from "./styles"
import {RiHome3Line} from "react-icons/ri";
import {FiSettings} from "react-icons/fi";
import {BsChatDots} from "react-icons/bs";
import {useWindowSize} from "../../helpers";

type HeaderWebProps = {
    titulo?: string
    boton_derecha?: any
};

export const HeaderUser: React.FC<HeaderWebProps> = observer(() => {
    let classes = useStyles();
    const history = useHistory()
    const isMobile = useWindowSize()
    const rootStore = useStores()
    const {loginStore} = rootStore



    const getFullName = () => {
        let fullName = ""
        if (loginStore.isLoggedIn) {
            fullName += loginStore.first_name !== null && loginStore.first_name !== "" ? loginStore.first_name + " " : loginStore.username
            fullName += loginStore.second_name !== null && loginStore.second_name !== "" ? loginStore.second_name + " " : ""
            fullName += loginStore.last_name !== null && loginStore.last_name !== "" ? loginStore.last_name + " " : ""
            fullName += loginStore.second_last_name !== null && loginStore.second_last_name !== "" ? loginStore.second_last_name + " " : ""
            return fullName
        }
        return fullName
    }

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" style={{backgroundColor: COLORS.gray}}>
            <Container>
                {loginStore.profile_image &&
                    <Navbar.Brand onClick={() => history.push(ROUTES.ACCOUNT_INFO)}
                                  className={["responsive-navbar-brand", classes.navBrand].join(" ")}
                                  style={{paddingBottom: 0, paddingTop: 0}}>
                        {loginStore.profile_image &&
                            <img
                                src={loginStore.profile_image}
                                alt="Profile"
                                style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: 30,
                                    objectFit: "contain",
                                    backgroundColor: "black"
                                }}
                            />
                        }
                        {!loginStore.profile_image &&
                            <div
                                style={{
                                    backgroundColor: COLORS.black,
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: 30,
                                }}
                            />
                        }
                    </Navbar.Brand>}
                <Navbar.Text onClick={() => history.push(ROUTES.ACCOUNT_INFO)}
                             className={["responsive-navbar-text", classes.navBrand].join(" ")}>
                            {getFullName()}
                </Navbar.Text>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className={'ms-auto'}>
                        {loginStore.group === 2 &&
                            <React.Fragment>
                                {!isMobile &&
                                    <Nav.Link onClick={() => history.push(ROUTES.MY_PROPERTY)}
                                              className={classes.navLink} style={{color: COLORS.white}}>
                                        <div>
                                            <RiHome3Line className={classes.iconLink}/>
                                        </div>
                                        <div>My Property</div>
                                    </Nav.Link>

                                }
                                {isMobile &&
                                    <Nav.Link onClick={() => history.push(ROUTES.MY_PROPERTY)}
                                              className={classes.navLink} style={{color: COLORS.white}}>
                                        {/*<div>*/}
                                        <RiHome3Line className={classes.iconLink}/>
                                        My Property
                                        {/*</div>*/}
                                    </Nav.Link>
                                }
                            </React.Fragment>
                        }
                        <React.Fragment>
                            {!isMobile &&
                                <Nav.Link onClick={() => history.push(ROUTES.SETTINGS)}
                                          className={classes.navLink} style={{color: COLORS.white}}>
                                    <div>
                                        <FiSettings className={classes.iconLink}/>
                                    </div>
                                    <div>Settings</div>
                                </Nav.Link>

                            }
                            {isMobile &&
                                <Nav.Link onClick={() => history.push(ROUTES.SETTINGS)}
                                          className={classes.navLink} style={{color: COLORS.white}}>
                                    <FiSettings className={classes.iconLink}/>
                                    {space}Settings
                                </Nav.Link>
                            }
                        </React.Fragment>
                        <React.Fragment>
                            {!isMobile &&
                                <Nav.Link onClick={() => history.push(ROUTES.FEEDBACK)}
                                          className={classes.navLink} style={{color: COLORS.white}}>
                                    <div>
                                        <BsChatDots className={classes.iconLink}/>
                                    </div>
                                    <div>Contact</div>
                                </Nav.Link>

                            }
                            {isMobile &&
                                <Nav.Link onClick={() => history.push(ROUTES.FEEDBACK)}
                                          className={classes.navLink} style={{color: COLORS.white}}>
                                    <BsChatDots className={classes.iconLink}/>
                                    {space}Contact
                                </Nav.Link>
                            }
                        </React.Fragment>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )

});

export default HeaderUser;
