import React from 'react';
import {observer} from "mobx-react-lite";
import './HowItWorks.css';
import {RouteComponentProps} from "react-router";
import PageWeb from "../../../components/PageWeb/PageWeb";

export const HowItWorks: React.FC<RouteComponentProps> = observer(({history}) => {
  return (
    <PageWeb login_required={false}>
      <div className={'content-container row mx-0'}>
        <div className={'col-12 col-md-6'}>
          <div className={'card my-5'}>
            <div className={'card-body'}>
              <p>How It Works</p>
            </div>
          </div>
        </div>
      </div>

    </PageWeb>
  );
});

export default HowItWorks;
