import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {Redirect, RouteComponentProps} from "react-router";
import {Link} from 'react-router-dom';
import {Dropdown, Row} from "react-bootstrap";
import styles from "./Onboard.module.css";
import useStyles from "./styles"
import {COLORS, ROUTES} from "../../../constants/constants"
import PageWebDark from "../../../components/PageWeb/PageWebDark";
import logoLong from "../../../assets/images/logo-big.png";


import {default as _ReactPlayer} from 'react-player';
import {ReactPlayerProps} from "react-player/types/lib";
import {useStores} from "../../../models/root-store/root-store-context";

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;


export const Onboard: React.FC<RouteComponentProps> = observer(({history}) => {
  let classes = useStyles();
  const rootStore = useStores()
  const {loginStore} = rootStore
  const [language, setLanguage] = useState<string>("English");

  const handleLanguageChange = (lang: string) => {
    setLanguage(lang)
  }

  if (loginStore.isLoggedIn) {
    return <Redirect to={{pathname: ROUTES.HOME}}/>
  }

  return (
    <PageWebDark login_required={false}>
      <div className={'d-flex flex-column vh-100'}>
        <Row>
          <div style={{width: 200, marginTop: "25px"}} className={"ms-auto"}>
            <Dropdown>
              <label
                className={classes.dropdownLabel}
              >
                Language
              </label>
              <Dropdown.Toggle split className={classes.dropdownToggle}
                               id="dropdown-split-basic">{language} </Dropdown.Toggle>
              <Dropdown.Menu className={classes.dropdownMenu}>
                <Dropdown.Item onClick={() => handleLanguageChange("English")}
                               className={classes.dropdownItem}>English</Dropdown.Item>
                <Dropdown.Item onClick={() => handleLanguageChange("Spanish")}
                               className={classes.dropdownItem}>Spanish</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

        </Row>
        <div className={'m-auto justify-content-center align-items-center row'}>
          <div className={"col-12"}>
            <Row style={{marginTop: "25px"}}>
              <div className={classes.divLogo}>
                <img
                  src={logoLong}
                  alt="Houshares logo"
                  className={styles.logoLong}
                />
              </div>
            </Row>
            <Row style={{marginTop: "30px", justifyContent: "center", alignItems: "center"}}>
              <div className={styles.playerWrapper}>
                <ReactPlayer className={classes.reactPlayerStyle + ' mx-auto'}
                             url='https://www.youtube.com/watch?v=gLIHhTMN7qw'/>
              </div>
            </Row>

            <Row style={{marginTop: "30px"}}>
              <div className={styles.onboardText}>
                Owning a home with Houseshares is as easy as renting, and as valuable as buying
                {/*Tener vivienda con Houshares es tan fácil como rentar, y tan valioso como comprar*/}
              </div>
            </Row>
            <Row style={{marginTop: "30px"}}>
              <div className={styles.getStartBtn}>
                <Link to="/home"
                      style={{color: COLORS.green, fontWeight: "bold", textDecoration: "none"}}>Get
                  Started</Link>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </PageWebDark>
  );
});

export default Onboard;
