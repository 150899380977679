import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../../constants/constants";

export default makeStyles({
    arrowBack: {
        "&:hover,&:focus": {
            cursor: "pointer"
        }
    },
    logoShort: {
        textAlign: "center",
        marginTop: "25px"
    },
    title: {
        color: COLORS.white,
        fontWeight: "bold",
        marginTop: "20px"
    },
    rowList: {
        color: COLORS.white,
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "x-large",
        marginTop: "25px"
    },

    card: {
        margin: "10%",
        backgroundColor: COLORS.black,
        color: COLORS.white,
        borderBottomColor: COLORS.white,
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
    },

    cardBtn: {
        borderRadius: "50px",
        borderColor: "transparent",
        backgroundColor: COLORS.gray,
        fontSize: "X-large",
        "&:hover, &:focus:": {
            backgroundColor: COLORS.lightGray,
        }
    },

    iconMap: {
        color: COLORS.white,
    },

    itemCarousel: {
        textAlign: "-webkit-center",
    },
    cardImg: {
        maxWidth: "100%",
        maxHeight: "100%",
        height: "480px",
        width: "500px",
        objectFit: "contain",
        cursor: "pointer"
    },
    propertyImage: {
        maxWidth: "100%",
        maxHeight: "100%",
        height: "480px",
        width: "500px",
        objectFit: "contain",
        cursor: "pointer"
    },
    mapLink: {
        color: COLORS.white,
        textDecoration: "underline",
        marginLeft: "10px",
        "&:hover,&:focus": {
            cursor: "pointer",
            color: COLORS.green
        }
    },
    mapLinkLight: {
        color: COLORS.black,
        textDecoration: "underline",
        marginLeft: "10px",
        "&:hover,&:focus": {
            cursor: "pointer",
            color: COLORS.green
        }
    },
    btnPay: {
        backgroundColor: COLORS.white,
        color: COLORS.black,
        borderColor: COLORS.black,
        borderRadius: "35px",
        width: "125px",
        textAlign: "start",
        fontWeight: "bold",
        height: "50px",
        marginRight: "-18px",
        "&:hover,&:focus": {
            cursor: "pointer",
            backgroundColor: COLORS.green
        }
    },
    logoPay: {
        marginLeft: "-18px",
        "&:hover,&:focus": {
            cursor: "pointer",
        }
    },

    profileMyProperty: {
        display: "inline-flex"
    },

    myPropertyImageDiv: {
        textAlign: "center"
    },
    myPropertyImageSize: {
        width: "600px",
        height: "300px",
    },

    titleMaintenance: {
        color: COLORS.white,
        fontWeight: "bold",
        marginTop: "10%",
        textAlign: "center",
        fontSize: "x-large"
    },

    "@media (max-width: 1400px)": {
        profileMyProperty: {
            display: "none"
        },
        myPropertyImageDiv: {
            textAlign: "center",
            width: "100%"
        },
    },

    "@media (max-width: 780px)": {
        myPropertyImageSize: {
            width: "500px",
            height: "480px",
        },
    },

    "@media (max-width: 480px)": {
        cardImg: {
            maxWidth: "100%",
            maxHeight: "100%",
            height: "480px",
            width: "500px",
            objectFit: "contain",
        },
        myPropertyImageSize: {
            width: "250px",
            height: "150px",
        },
    },
});
