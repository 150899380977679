import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import useStyles from "../styles"
import {COLORS, space} from "../../../constants/constants";
import AdminSearch from "../../../components/AdminSearch/AdminSearch";
import {useStores} from "../../../models/root-store/root-store-context";
import AdminPagination from "../../../components/AdminPagination/AdminPagination";
import defaultImage from "../../../assets/images/default_Image.png"
import {BiMap, BiMessageError} from "react-icons/bi";
import {openInNewTab} from "../../../helpers";
import {FaRegEye, FaTools} from "react-icons/fa";
import {CloseButton, Dropdown, Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {AiOutlineCloseCircle} from "react-icons/ai";
import ButtonDark from "../../../components/Buttons/ButtonDark";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";


export const ManteinanceRequestList: React.FC<RouteComponentProps> = observer(({history}) => {

  let classes = useStyles();

  const rootStore = useStores()
  const [Loading, setLoading] = useState(false);
  const [LoadingItem, setLoadingItem] = useState(false);
  const [idMaintenance, setIdMaintenance] = useState<number>(0);
  const [data, setData] = useState<any>();
  const [dataItem, setDataItem] = useState<any>([]);
  const [valuesToSearch, setValuesToSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [previousPage, setPreviousPage] = useState<string | null>("");
  const [nextPage, setNextPage] = useState<string | null>("");
  const [pathLastCalled, setPathLastCalled] = useState<string>("/admin/maintenance-requests/");
  const [offsetLastCalled, setOffsetLastCalled] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");
  const [showDeleteMessage, setShowDeleteMessage] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const setResultListToData = (result: any) => {
    let datos: any = [];
    result.data.results.map((resultItem: any) => {
      datos.push({
        id: resultItem.id,
        fullName: resultItem.full_name,
        housnerId: resultItem.housner,
        profilePicture: resultItem.profile_picture ? resultItem.profile_picture : defaultImage,
        propertyName: resultItem.property_name,
        propertyImage: resultItem.property_picture ? resultItem.property_picture : defaultImage,
        status: resultItem.status,
      })
    })
    setData(datos)

  }

  const setResultToData = (result: any) => {
    let datos = {
      id: result.id,
      fullName: result.full_name,
      housnerId: result.housner,
      profilePicture: result.profile_picture ? result.profile_picture : defaultImage,
      idType: result.id_type,
      idNumber: result.id_number,
      idDateIssue: result.id_date_of_issue,
      idPlaceIssue: result.id_place_of_issue,
      phoneNumber: result.phone_number,
      cellNumber: result.cell_phone_number,
      email: result.email,
      propertyId: result.property,
      propertyName: result.property_name,
      propertyImage: result.property_image ? result.property_image : defaultImage,
      propertyAddress: result.property_address,
      googleMapLink: result.property_google_map_link,
      requestMessage: result.explanation,
      status: result.status,
    }
    setDataItem(datos)
  }

  const search = (rows: number) => {
    setLoading(true)
    rootStore.environment.api.getMaintenancesSearch(valuesToSearch, rows).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setCurrentPage(1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
        setTotalPages(Math.ceil(result.data.count / rows))
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const getMaintenanceList = (rows: number) => {
    setLoading(true)
    rootStore.environment.api.getMaintenanceList(rows).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    getMaintenanceList(rowsPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getMaintenanceItem = (idMaintenance: number) => {
    setLoadingItem(true)
    rootStore.environment.api.getMaintenance(idMaintenance).then((result: any) => {
      setLoadingItem(false)
      if (result.kind === "ok") {
        setResultToData(result.data)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const getMaintenancesPrevNextPage = (path: string, offset: number) => {
    setPathLastCalled(path)
    setOffsetLastCalled(offset)
    setLoading(true)
    rootStore.environment.api.getMaintenanceListPrevNextPage(path).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setCurrentPage(Math.floor(offset / rowsPerPage) + 1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const openShowDetails = (idMaintenance: number) => {
    getMaintenanceItem(idMaintenance)
    setShowDetails(true)
  }

  const finishManteinance = (idMaintenance: number) => {
    rootStore.environment.api.changeStatusMaintenance(idMaintenance).then((result: any) => {
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Maintenance successfully finished")
        setVariantMessage("success")
        getMaintenancesPrevNextPage(pathLastCalled, offsetLastCalled)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const deleteMaintenanceClicked = (idMaintenance: number) => {
    setIdMaintenance(idMaintenance)
    setShowDeleteMessage(true)
  }

  const closeDeleteMessage = () => {
    setShowDeleteMessage(false)
    setIdMaintenance(0)
  }

  const deleteMaintenance = () => {
    rootStore.environment.api.deleteMaintenance(idMaintenance).then((result: any) => {
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Maintenance successfully deleted")
        setVariantMessage("success")
        getMaintenancesPrevNextPage(pathLastCalled, offsetLastCalled)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
    setIdMaintenance(0)
    setShowDeleteMessage(false)
  }

  const handlePrevPageClicked = () => {
    let from = previousPage?.substring(previousPage?.indexOf("offset") + 7);
    let path = previousPage?.substring(previousPage?.indexOf("v1") + 2);
    if (path !== undefined) {
      if (from !== undefined && !isNaN(parseInt(from))) {
        getMaintenancesPrevNextPage(path, parseInt(from))
      } else {
        getMaintenancesPrevNextPage(path, 1)
      }
    }
  }

  const handleNextPageClicked = () => {
    let from = nextPage?.substring(nextPage?.indexOf("offset") + 7);
    let path = nextPage?.substring(nextPage?.indexOf("v1") + 2);

    if (path !== undefined) {
      if (from !== undefined && !isNaN(parseInt(from))) {
        getMaintenancesPrevNextPage(path, parseInt(from))
      } else {
        getMaintenancesPrevNextPage(path, 1)
      }
    }
  }

  const handleRowsPerPageClicked = (rows: number) => {
    setRowsPerPage(rows)
    if (valuesToSearch !== "") {
      search(rows)
    } else {
      getMaintenanceList(rows)
    }
  }

  const closeMessage = () => {
    setShow(false)
  }

  const closeShowDetails = () => {
    setShowDetails(false)
    setDataItem({})
  }

  const ManteinanceItems = (currentItems: any) => {
    return (
      <>
        {currentItems && currentItems.currentItems?.length > 0 &&
          <div style={{marginTop: "35px"}}>
            {currentItems.currentItems.map((item: any, idx: number) => {
              return (
                <div key={idx} className={["row", classes.list].join(" ")}>
                  <div className={"col-1"}>{item.id}</div>
                  <div className={"col-4"}>
                    <img
                      key={idx}
                      src={item.propertyImage}
                      alt="Property"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        height: "80px",
                        width: "130px",
                        objectFit: "contain",
                      }}
                    />
                    {`${space}${item.propertyName}`}
                  </div>
                  <div className={"col-4"}>
                    <img
                      key={idx}
                      src={item.profilePicture}
                      alt="Profile"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        height: "80px",
                        width: "130px",
                        objectFit: "contain",
                      }}
                    />
                    {`${space}${item.fullName}`}
                  </div>
                  <div className={"col-1"}
                       style={{color: item.status == 2 ? COLORS.green : COLORS.orange}}>
                    <Dropdown>
                      <label className={classes.dropdownLabel}>
                        {item.status == 2 ? "Finished" : "Pending"}
                      </label>
                      {item.status !== 2 &&
                        <Dropdown.Toggle split className={classes.dropdownToggle}
                                         id="dropdown-split-basic"/>}
                      <Dropdown.Menu className={classes.dropdownMenu}>
                        <Dropdown.Item onClick={() => finishManteinance(item.id)}
                                       className={classes.dropdownItem}>Finished</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className={"col-1 text-center"}>
                    <ButtonDark startFunction={() => openShowDetails(item.id)} bold={false}>
                      <FaRegEye size={"25px"}/>
                    </ButtonDark>
                  </div>

                  <div className={"col-1"}>
                    <ButtonDark startFunction={() => deleteMaintenanceClicked(item.id)}
                                bold={false}>
                      <AiOutlineCloseCircle size={"25px"}/>
                    </ButtonDark>
                  </div>

                </div>
              );
            })}

          </div>
        }
      </>
    );
  }

  return (
    <PageWebAdminDark>
      <div className={"row"}>
        <div className={"col-9"}
             style={{color: COLORS.white, fontSize: "xx-large"}}>
          <BiMessageError/> Approved accounts
        </div>
        <div className={"col-3"}
             style={{display: "flex", justifyContent: "center"}}>
          <AdminSearch valuesToSearch={valuesToSearch} setValuesToSearch={setValuesToSearch}
                       search={search} rowsPerPage={rowsPerPage}/>
        </div>
      </div>
      <div className={"row"} style={{marginTop: "20px"}}>
        <div className={"col-12"}
             style={{color: COLORS.white, fontSize: "x-large"}}>
          <FaTools/> Maintenance requests
        </div>
      </div>
      {!Loading && data?.length === 0 &&
        <NoDataAdmin/>}
      {Loading &&
        <SpinnerLight/>
      }
      {!Loading && data && data?.length > 0 &&
        <div>
          <div className={["row", classes.listTittles].join(" ")}>
            <div className={"col-1"}>Id</div>
            <div className={"col-4"}>Property</div>
            <div className={"col-4"}>Housner</div>
            <div className={"col-3"}>Status</div>
          </div>
          <ManteinanceItems currentItems={data}/>
          <AdminPagination rowsPerPage={rowsPerPage} handleRowsPerPageClicked={handleRowsPerPageClicked}
                           currentPage={currentPage} handleNextPageClicked={handleNextPageClicked}
                           handlePrevPageClicked={handlePrevPageClicked} nextPage={nextPage}
                           previousPage={previousPage} totalPages={totalPages}/>
        </div>
      }

      <Modal show={showDeleteMessage} size={"lg"}>
        <ModalHeader className={classes.modalHeader}>
          <div style={{fontWeight: "bold", fontSize: "x-large"}}>
            Are you sure you want to remove the maintenance request?
          </div>
        </ModalHeader>
        <ModalBody className={classes.modalBody}>
          <ButtonDark startFunction={deleteMaintenance} bold={true}>Yes</ButtonDark>
          <ButtonDark startFunction={closeDeleteMessage} bold={true}>No</ButtonDark>
        </ModalBody>
      </Modal>

      <Modal show={showDetails} onHide={closeShowDetails}>
        {LoadingItem && <SpinnerLight/>}
        {!LoadingItem && dataItem?.id &&
          <React.Fragment>
            <ModalHeader className={classes.modal}>
              <div className={classes.modalHeaderUserDetail}>
                Housner
              </div>
              <CloseButton variant="white" onClick={closeShowDetails}/>
            </ModalHeader>

            <ModalBody className={classes.modal}>
              <div className={"row my-3"}>
                <div className={"col-4"} style={{marginBottom: "15px", textAlign: "center"}}>
                  <div>
                    <img src={dataItem.profilePicture} alt="Profile"
                         style={{
                           width: "100px",
                           height: "100px",
                           borderRadius: "100px",
                           marginLeft: "auto",
                           marginRight: "auto",
                           marginBottom: "10px",
                           objectFit: 'contain'
                         }}
                    />
                  </div>
                </div>
                <div className={"col-8"} style={{marginBottom: "15px"}}>
                  <div style={{fontWeight: "bold"}}>{dataItem.fullName}</div>
                  <div>{dataItem.idType}{space}{dataItem.idNumber}</div>
                  <div>{dataItem.idDateIssue}{space}{dataItem.idPlaceIssue}</div>
                  <div>Tel:{space}{dataItem.phoneNumber}</div>
                  <div>Cel:{space}{dataItem.cellNumber}</div>
                  <div>{dataItem.email}</div>
                </div>
              </div>
              <div className={"row my-3"}>
                <div style={{fontWeight: "bold"}}>Property</div>
                <div style={{fontWeight: "bold"}}>{dataItem.propertyName}</div>
                <div className={"col-4"} style={{textAlign: "center", marginTop: "20px"}}>
                  <img
                    src={dataItem.propertyImage}
                    alt="Property"
                    style={{
                      width: "110px",
                      height: "90px",
                      marginLeft: "auto",
                      marginRight: "auto",
                        objectFit: 'contain'
                    }}
                  />
                </div>
                <div className={"col-8"} style={{marginTop: "20px"}}>
                  <div style={{overflowWrap: "break-word"}}>
                    {dataItem.googleMapLink != "" && dataItem.googleMapLink != undefined &&
                      <BiMap size={40} className={classes.btnMaps}
                             onClick={() => openInNewTab(dataItem.googleMapLink)}/>}
                    Address:{space}{dataItem.propertyAddress}
                  </div>
                </div>
              </div>
              <div className={"row my-4"}>
                <div>
                  <p style={{fontWeight: "bold"}}>Request:</p>
                </div>
                <div >
                  <p style={{ wordWrap: "break-word"}} className={'text-wrap'}>
                    {dataItem.requestMessage}
                  </p>
                </div>
              </div>
            </ModalBody>
          </React.Fragment>
        }
      </Modal>
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
    </PageWebAdminDark>
  )
});

export default ManteinanceRequestList;
