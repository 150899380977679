import React from "react";
import {COLORS} from "../../constants/constants";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

type InputProps = {
    label: string,
    setValue: (phone: any) => void
    value: string,
    className: string,
    required: boolean
};

export const InputPhone: React.FC<InputProps> = React.forwardRef((props,ref) => {
    return (
        <div className={props.className}>
            <label>{props.label}</label>
            <div style={{marginTop:"11px"}}>
                <PhoneInput
                    country={'co'}
                    value={props.value}
                    onBlur={value => {
                        props.setValue(value.target.value)
                    }}
                    enableSearch={true}
                    inputProps={{
                        required: props.required,
                    }}
                    inputStyle={{
                        backgroundColor: COLORS.gray,
                        color: COLORS.white,
                        borderRadius: "12px",
                        width:"100%",
                        height:"38px"
                    }}
                    dropdownStyle={{color: COLORS.black}}
                    buttonStyle={{
                        backgroundColor: COLORS.gray,
                        color: COLORS.white,
                        borderBottomLeftRadius: "12px",
                        borderTopLeftRadius: "12px"
                    }}
                />
            </div>
        </div>
    )
});

export default InputPhone;