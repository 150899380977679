import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../constants/constants";

export default makeStyles((theme) => ({
    body: {
        minHeight: 'calc(100vh - 90px)',
        height: 'calc(100vh - 90px)',
    },
    inputEditMode: {
        width: "-webkit-fill-available",
        backgroundColor: "transparent",
        borderColor: "transparent",
        borderBottomColor: COLORS.white,
        color: COLORS.white
    },
    list: {
        color: COLORS.white,
        marginTop: "5px",
        paddingLeft: "20px",
        alignItems: "center"
    },
    listTittles: {
        color: COLORS.white,
        fontWeight: "bold",
        marginTop: "35px",
        paddingLeft: "20px",
        alignItems: "center"
    },
    modal: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        border: COLORS.gray,
    },
    modalCenter: {
        display: "flex",
        alignItems: "center",
    },
    modalPropBorderTop: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        border: COLORS.gray,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        width: 450
    },
    modalPropBorderBottom: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        border: COLORS.gray,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        width: 450
    },
    modalBorderTop: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        border: COLORS.gray,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
    },
    modalBorderBottom: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        border: COLORS.gray,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    modalHeaderUserDetail: {
        width: "-webkit-fill-available",
        fontWeight: "bold",
        fontSize: "X-large"
    },
    divModalHeader: {
        width: "-webkit-fill-available",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "X-large"
    },
    labelModal: {
        fontWeight: "bold",
        marginTop: "10px",
        textAlign:"start"
    },
    emailInput:{
      width:"100%"
    },
    modalHeader: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        border: COLORS.gray,
        justifyContent: "center"
    },
    modalBody: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        textAlign: "center",

    },
    inputModal: {
        backgroundColor: COLORS.gray,
        borderColor: COLORS.white,
        border: "solid",
        borderWidth: "thin",
        borderRadius: "12px",
        height: "38px",
        width: "233px",
        marginTop: "10px"
    },
    errorForm: {
        color: COLORS.red,
        marginTop: "5px",
        textAlign: "center"
    },


    inputSearch: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        height: "48px",
        width: "50%",
        borderRadius: "12px 0 0 12px",
        borderRightColor: "transparent",
        borderLeft: "thin solid",
        borderTop: "thin solid",
        borderBottom: "thin solid",
        "&::placeholder": {
            color: COLORS.white,
        },
    },
    searchButton: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        borderRadius: "0  12px 12px 0",
        borderLeftColor: "transparent",
        borderRightColor: COLORS.white,
        borderTopColor: COLORS.white,
        borderBottomColor: COLORS.white,
        height: "48px",
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.lightgreen,
            backgroundColor: COLORS.gray,
            borderColor: COLORS.lightgreen
        },
    },

    dropdownLabel: {
        backgroundColor: COLORS.black,
        borderColor: COLORS.black,
    },

    dropdownToggle: {
        backgroundColor: COLORS.black,
        borderColor: COLORS.black,
        "&:hover, &:focus, &:active": {
            cursor: "pointer",
            color: COLORS.green,
            backgroundColor: "transparent",
            borderColor: "transparent"
        },
    },
    dropdownMenu: {
        backgroundColor: COLORS.black,
        borderColor: COLORS.white
    },
    dropdownItem: {
        color: COLORS.white,
    },

    btnUploadPhotos: {
        marginBottom: "10px",
        fontSize: "larger",
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green,
            backgroundColor: "transparent",
            borderColor: "transparent"
        },
    },
    iconUploadPhotos: {
        color: COLORS.white,
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green,
        },
    },
    btnUser: {
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green
        }
    },
    btnPDF: {
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green
        }
    },
    btnMaps: {
        marginRight:"10px",
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green
        }
    },
    containerPropertyImg:{
        width: "100%",
        height: "auto",
    },
    btnIcon: {
        fontSize:"x-large",
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green
        }
    },
}));
