import React from "react";
import useStyles from "./styles";
import {FieldInputProps, FormikProps} from "formik";

type InputProps = {
    field: FieldInputProps<any>,
    form: FormikProps<any>,
    label: string,
    bold: boolean,
    className: string,
    placeholder: string,
    disabled?: boolean
};

export const InputTextFormModal: React.FC<InputProps> = React.forwardRef((props, ref) => {

    let classes = useStyles();

    return (
        <div className={props.className}>
            <label className={props.bold ? classes.labelBold : classes.label}
                   style={{alignSelf: "center", paddingRight: "20px"}}>{props.label}</label>
            <div>
                <input
                    type={"text"}
                    className={['form-control', classes.inputModal].join(" ")}
                    disabled={props.disabled ? props.disabled : false}
                    {...props.field}
                />
                {props.form.errors[props.field.name] && props.form.touched[props.field.name] ? (
                    <div className={classes.errorModal}>{props.form.errors[props.field.name]}</div>
                ) : null}
            </div>

        </div>
    )
});

export default InputTextFormModal;