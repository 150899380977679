import React, {useEffect, useState} from "react";
import Axios from "axios";
import fileDownload from "js-file-download";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import {COLORS, space} from "../../../constants/constants";
import {DEFAULT_API_CONFIG} from "../../../services/api/api-config"
import {FiUserCheck} from "react-icons/fi";
import {useStores} from "../../../models/root-store/root-store-context";
import useStyles from "../styles"
import {fecha_fmt, outHash, plata_fmt} from "../../../helpers";
import {DetailViewProps} from "../../../constants/types";
import {VscFilePdf} from "react-icons/vsc";
import defaultImage from "../../../assets/images/default_Image.png"
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";
import ToastComponent from "../../../components/Toasts/ToastCustom"
import ButtonDark from "../../../components/Buttons/ButtonDark";
import {BiArrowBack} from "react-icons/bi";

export const UserInfo: React.FC<DetailViewProps> = observer(({history, match}) => {

    let classes = useStyles();
    const rootStore = useStores()

    let idUser = "";
    if (Object.keys(match.params).length > 0) {
      idUser = outHash(match.params.id);
    }

    const [Loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [variantMessage, setVariantMessage] = useState<string>("");

    const setResultToData = (result: any) => {
      let datos = {
        id: result.data.id,
        checkId: result.data.check_id,
        pdfCheckURL: result.data.pdf_check_url,
        firstName: result.data.first_name ? result.data.first_name : " ",
        secondName: result.data.second_name ? result.data.second_name : " ",
        lastName: result.data.last_name ? result.data.last_name : " ",
        secondLastName: result.data.second_last_name ? result.data.second_last_name : " ",
        idType: result.data.id_type ? result.data.id_type : " ",
        idNumber: result.data.id_number ? result.data.id_number : " ",
        idDateIssue: result.data.id_date_of_issue ? result.data.id_date_of_issue : " ",
        idPlaceIssue: result.data.id_place_of_issue ? result.data.id_place_of_issue : " ",
        phoneNumber: result.data.phone_number ? result.data.phone_number : " ",
        cellNumber: result.data.cell_phone_number ? result.data.cell_phone_number : " ",
        email: result.data.email,
        profilePicture: result.data.profile_picture ? result.data.profile_picture : defaultImage,
        dateBirth: result.data.date_of_birth ? result.data.date_of_birth : " ",
        cityBirth: result.data.city_of_birth ? result.data.city_of_birth : " ",
        countryBirth: result.data.country_of_birth ? result.data.country_of_birth : " ",
        studyLevel: result.data.study_level ? result.data.study_level : " ",
        profession: result.data.profession ? result.data.profession : " ",
        residenceAddress: result.data.residence_address ? result.data.residence_address : " ",
        residenceCountry: result.data.residence_country ? result.data.residence_country : " ",
        residenceRegion: result.data.residence_region ? result.data.residence_region : " ",
        residenceCity: result.data.residence_city ? result.data.residence_city : " ",
        monthlyIncome: result.data.monthly_income ? result.data.monthly_income : " ",
        monthlyIncomeDescription: result.data.monthly_income_description ? result.data.monthly_income_description : " ",
        otherMonthlyIncome: result.data.other_monthly_income ? result.data.other_monthly_income : " ",
        otherMonthlyIncomeDescription: result.data.other_monthly_income_description ? result.data.other_monthly_income_description : " ",
        monthlyTotalExpenses: result.data.monthly_total_expenses ? result.data.monthly_total_expenses : " ",
        totalActives: result.data.total_actives ? result.data.total_actives : " ",
        totalPassives: result.data.total_passives ? result.data.total_passives : " ",
      }
      setData(datos)
    }

    const getProfileDataFromUser = () => {
      setLoading(true)
      rootStore.environment.api.getUserProfileById(parseInt(idUser)).then((result: any) => {
        setLoading(false)
        if (result.kind === "ok") {
          setResultToData(result)
        } else {
          if (result.kind === "bad-data") {
            if (result.errors.non_field_errors) {
              setShow(true)
              setMessage(result.errors.non_field_errors[0])
              setVariantMessage("warning")
            } else {
              setShow(true);
              setMessage("Validation errors found")
              setVariantMessage("warning")
            }
          } else {
            setShow(true)
            setMessage("An error occurred, please try again in a few moments")
            setVariantMessage("danger")
          }
        }
      })
    }

    useEffect(() => {
      getProfileDataFromUser()
    }, []);

    const downloadPDF = () => {
      Axios.get(`${DEFAULT_API_CONFIG.url}/admin/background-check-user-profile-pdf/${data.checkId}/`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${rootStore.loginStore.token}`
        }
      }).then(res => {
        fileDownload(res.data, `check-${data.checkId}.pdf`);
      });
    }

    const closeMessage = () => {
      setShow(false)
    }


    return (
      <PageWebAdminDark>
        <div className={"container row"}>
          <div className={"col-6"}
               style={{color: COLORS.white, fontSize: "xx-large"}}>
            <FiUserCheck/> User information
          </div>
        </div>
        {!Loading && Object.keys(data)?.length === 0 &&
          <NoDataAdmin/>}
        {Loading &&
          <SpinnerLight/>
        }
        {!Loading && data && !(Object.keys(data)?.length === 0) &&
          <div className={"container row"} style={{color: COLORS.white}}>
            <div>
              <ButtonDark startFunction={history.goBack} bold={true}
                          className="text-start"><BiArrowBack/>{space}Back</ButtonDark>
            </div>
            <div className={"row my-4"}>
              <div className={"col-3"}
                   style={{marginLeft: "20px", textAlign: "center"}}>
                <div>
                  {data.profilePicture !== "" && data.profilePicture !== null &&
                    <img src={data.profilePicture} alt="Profile image"
                         style={{
                           width: "100px",
                           height: "100px",
                           borderRadius: 50,
                           marginLeft: "auto",
                           marginRight: "auto",
                           marginBottom: "15px",
                           objectFit: 'cover'
                         }}
                    />}
                </div>
                <div style={{
                  fontWeight: "bold",
                  marginTop: "8px"
                }}>{data.firstName}{space}{data.secondName}</div>
                <div style={{
                  fontWeight: "bold",
                  borderBottom: "solid",
                  paddingBottom: "10px",
                }}>{data.lastName}{space}{data.secondLastName}</div>
                <div style={{borderBottom: "solid"}}>
                  <div style={{marginTop: "8px", display: "inline-flex"}}>
                    <p style={{fontWeight: "bold"}}>Studies:</p>{space}{data.studyLevel}
                  </div>
                </div>
                <div style={{borderBottom: "solid"}}>
                  <div style={{marginTop: "8px", display: "inline-flex"}}>
                    <p style={{fontWeight: "bold"}}>Profession:</p>{space}{data.profession}
                  </div>
                </div>
              </div>
              <div className={"col-8"}
                   style={{
                     marginLeft: "20px",
                     justifyContent: "center",
                     borderBottom: "solid",
                     paddingBottom: "10px"
                   }}>
                <div style={{fontWeight: "bold", fontSize: "x-large", marginBottom: "30px"}}>Profile data
                </div>
                <div>
                  <div style={{display: "inline-flex"}}>
                    <p style={{fontWeight: "bold"}}>{data.idType}</p>{space}{data.idNumber}{space}{space}
                    <p style={{fontWeight: "bold"}}>Date of issue: </p>{space}{fecha_fmt(data.idDateIssue, 'DD/MM/YYYY')}{space}{space}
                    <p style={{fontWeight: "bold"}}>Country of
                      issue: </p>{space}{data.idPlaceIssue}
                  </div>
                </div>
                <div>
                  <div style={{display: "inline-flex"}}>
                    <p style={{fontWeight: "bold"}}>Tel:</p>{space}{data.phoneNumber}{space}{space}
                    <p style={{fontWeight: "bold"}}>Cel:</p>{space}{data.cellNumber}
                  </div>
                </div>
                <div>
                  <div style={{display: "inline-flex"}}>
                    <p style={{fontWeight: "bold"}}>Mail:</p>{space}{data.email}
                  </div>
                </div>
                <div>
                  <div style={{display: "inline-flex"}}>
                    <p style={{fontWeight: "bold"}}>Birth date:</p>{space}{fecha_fmt(data.dateBirth, 'DD/MM/YYYY')}
                  </div>
                </div>
                <div>
                  <div style={{display: "inline-flex"}}><p style={{fontWeight: "bold"}}>City and
                    country:</p>{space}{data.cityBirth},{space}{data.countryBirth}
                  </div>
                </div>
                <div>
                  <div style={{display: "inline-flex"}}><p style={{fontWeight: "bold"}}>
                    Address:
                  </p>{space}{data.residenceAddress}
                  </div>
                </div>
                <div>
                  {data.residenceCity},{space}{data.residenceRegion},{space}{data.residenceCountry}
                </div>
              </div>
            </div>
            {data.pdfCheckURL &&
              <div className={"row my-1"}>
                <div className={"col-3"}></div>
                <div className={"col-8"} style={{
                  marginLeft: "38px",
                  justifyContent: "center",
                  borderBottom: "solid",
                  display: "inline-flex",
                  placeContent: "flex-start"
                }}>
                  <div style={{fontWeight: "bold", fontSize: "x-large", marginBottom: "30px"}}>Work data
                  </div>
                  <div style={{
                    fontSize: 50,
                    color: COLORS.white,
                    marginLeft: "20px",
                    marginTop: "-25px"
                  }}>
                    <VscFilePdf className={classes.btnPDF} onClick={downloadPDF}/>
                  </div>
                </div>
              </div>
            }
            <div className={"row my-2"}>
              <div className={"col-3"}></div>
              <div className={"col-8"}
                   style={{marginLeft: "38px", justifyContent: "center"}}>
                <div className={"row"}>
                  <div className={"col-3"}>
                    <div style={{fontWeight: "bold", fontSize: "x-large", marginBottom: "30px"}}>
                      Financial data
                    </div>
                  </div>
                  <div className={"col-6"}>
                    <div>
                      <div style={{display: "inline-flex"}}>
                        <p style={{fontWeight: "bold"}}>Monthly
                          income:</p>{space}{plata_fmt(data.monthlyIncome)}{space}{space}
                      </div>
                    </div>
                    <div>
                      <div style={{display: "inline-flex"}}>
                        <p style={{fontWeight: "bold"}}>Monthly
                          income
                          description:</p>{space}{data.monthlyIncomeDescription}{space}{space}
                      </div>
                    </div>
                    <div>
                      <div style={{display: "inline-flex"}}>
                        <p style={{fontWeight: "bold"}}>Other monthly
                          income:</p>{space}{plata_fmt(data.otherMonthlyIncome)}{space}{space}
                      </div>
                    </div>
                    <div>
                      <div style={{display: "inline-flex"}}>
                        <p style={{fontWeight: "bold"}}>Other monthly
                          income
                          description:</p>{space}{data.otherMonthlyIncomeDescription}{space}{space}
                      </div>
                    </div>
                    <div>
                      <div style={{display: "inline-flex"}}>
                        <p style={{fontWeight: "bold"}}>Monthly
                          total expenses:</p>{space}{plata_fmt(data.monthlyTotalExpenses)}{space}{space}
                      </div>
                    </div>
                    <div>
                      <div style={{display: "inline-flex"}}>
                        <p style={{fontWeight: "bold"}}>Total assets:</p>
                        {space}{plata_fmt(data.totalActives)}{space}{space}
                      </div>
                    </div>
                    <div>
                      <div style={{display: "inline-flex"}}>
                        <p style={{fontWeight: "bold"}}>Total liabilities:</p>
                        {space}{plata_fmt(data.totalPassives)}{space}{space}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
      </PageWebAdminDark>
    )
  })
;

export default UserInfo;
