import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../../constants/constants";

export default makeStyles({

    dropdownItem: {
        color: COLORS.white,
        backgroundColor: COLORS.black,
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green,
            backgroundColor: COLORS.black
        },
    },

    divLogo: {
        textAlign: "center",
        // marginTop: "-80px"
    },

    divLanguage: {},

    reactPlayerStyle: {
        width: "490px !important",
        height: "275px !important",
    },

    dropdownDiv: {
        color: COLORS.white
    },

    dropdownLabel: {
        backgroundColor: COLORS.black,
        borderColor: COLORS.black,
        fontSize: "16px",
        color: COLORS.white
    },

    dropdownToggle: {
        backgroundColor: COLORS.black,
        borderColor: COLORS.black,
        marginBottom: "4px",
        "&:hover, &:focus, &:checked, &:active": {
            cursor: "pointer",
            color: COLORS.green,
            backgroundColor: COLORS.black,
            borderColor: COLORS.black
        },

    },

    dropdownMenu: {
        backgroundColor: COLORS.black,
        borderColor: COLORS.black
    },


    playerWrapper: {
        marginTop: "10%",
        textAlign: "-webkit-center"
    },

    onboardText: {
        color: COLORS.white,
        textAlign: "center",
        marginTop: "10%",
    },

    getStartBtn: {
        color: COLORS.white,
        fontSize: "35px",
        textAlign: "center",
        marginTop: "10%",
    },

    rowLanguage: {},

    "@media (max-width: 768px)": {
        dropdownDiv: {
            width: "50%",
        },
        divLogo: {
            textAlign: "center",
            // marginTop: "-70px"
        },

        divLanguage: {
            width: "200px"
        },

        reactPlayerStyle: {
            width: "490px !important",
            height: "275px !important",
        },

        rowLanguage: {
            width: "30%"
        },
    },

    "@media (max-width: 582px)": {

        reactPlayerStyle: {
            width: "290px !important",
            height: "200px !important",
        },

        rowLanguage: {
            width: "40%"
        },
    },

    "@media (max-width: 339px)": {

        reactPlayerStyle: {
            width: "220px !important",
            height: "140px !important",
        },

        rowLanguage: {
            width: "50%"
        },
    },

})
;
