import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {RouteComponentProps} from "react-router";
import PageWebDarkWithHeader from "../../../components/PageWeb/PageWebDarkWithHeader";
import useStyles from "./styles";
import {useStores} from "../../../models/root-store/root-store-context";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {Field, Form, Formik, FormikProps, FormikState} from "formik";
import * as Yup from "yup";
import ButtonSquare from "../../../components/Buttons/ButtonSquare";
import ButtonSquareForm from "../../../components/Buttons/ButtonSquareForm";
import Logo from "../../../components/Logo/Logo";
import {RiLockPasswordLine} from "react-icons/ri";
import InputForm from "../../../components/Inputs/InputForm";


interface IFriends {
  email: string,
}

const schemaFriends = Yup.object().shape({
  email: Yup.string().email('Valid email required').required('Required'),
})


export const InviteFiends: React.FC<RouteComponentProps> = observer(({history}) => {

  let classes = useStyles();
  const rootStore = useStores()

  const [Loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");

  const sendInvitation = (values: IFriends, setSubmitting: (arg0: boolean) => void,
                          resetForm: {
                            (nextState?: Partial<FormikState<{ email: string; }>> | undefined): void;
                            (arg0: { values: { email: string; }; }): void;
                          }) => {
    setLoading(true)
    rootStore.environment.api.postInvitation(values.email)
      .then((result: any) => {
        setLoading(false)
        setSubmitting(false)
        resetForm({
          values: {
            email: "",
          },
        });
        if (result.kind === "ok") {
          setShow(true)
          setMessage("Invitation successfully sent")
          setVariantMessage("success")
        } else {
          if (result.kind === "bad-data") {
            if (result.errors.non_field_errors) {
              setShow(true)
              setMessage(result.errors.non_field_errors[0])
              setVariantMessage("warning")
            } else {
              setShow(true)
              setMessage('Validation errors found')
              setVariantMessage("warning")
            }
          } else {
            setShow(true)
            setMessage('An error occurred, please try again in a few moments')
            setVariantMessage("danger")
          }
        }
      })
  }

  const closeMessage = () => {
    setShow(false)
  }

  return (
    <PageWebDarkWithHeader login_required={true}>
      <div className={'content-container row mx-0'} style={{padding: "0 10% 0 10%"}}>

        <Logo short={true} light={false}/>

        <div className={classes.title}>Invite friends</div>
        <div style={{textAlign: "center"}}>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={
              (values: IFriends, {setSubmitting, resetForm}) => {
                sendInvitation(values, setSubmitting, resetForm)
              }
            }
            validationSchema={schemaFriends}
          >
            {(props: FormikProps<IFriends>) => {
              const {
                // values,
                // touched,
                // errors,
                // handleBlur,
                // handleChange,
                isSubmitting,
              } = props
              return (
                <Form style={{marginTop: "15px"}}>
                  <div className={'my-2 text-start mb-5'}>
                    <Field name="email" label={"Friend's email"} bold={false}
                           className={"col-12 col-md-4 mx-auto"}
                           placeholder={""}
                           type={"email"}
                           component={InputForm}/>
                  </div>

                  <div style={{textAlign: "center", marginTop: "20px", marginBottom: "20px"}}>
                    <ButtonSquareForm label={"Send invitation"} bold={true}
                                      isSubmitting={isSubmitting}
                                      Loading={Loading}>
                      <RiLockPasswordLine
                        style={{marginLeft: "5px", marginBottom: "5px"}}/>
                    </ButtonSquareForm>
                  </div>
                  <div>
                    <ButtonSquare label={"Back"} startFunction={() => history.goBack()}
                                  bold={true}/>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>

    </PageWebDarkWithHeader>
  );
});
export default InviteFiends;
