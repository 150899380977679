import {GenericResponse} from "../services/api";
import imageCompression from "browser-image-compression";
import {useStores} from "../models/root-store/root-store-context";
import {useEffect, useState} from "react";
import numeral from "numeral";
import moment from "moment";

export const useApiToaster = () => {
    // const {addToast} = useToasts()

    return (
        result: GenericResponse,
        // success_msg = 'The action was completed successfully',
        // accion_infinitivo_para_error_message = "save", autoDismiss = true
    ) => {
        if (result.kind === "ok") {
            // addToast(success_msg, {
            //   appearance: 'success',
            //   autoDismiss: autoDismiss,
            // })
        } else if (result.kind === "bad-data") {
            // @ts-ignore
            if (result.errors !== undefined && result?.errors.non_field_errors) {
                // @ts-ignore
                // addToast(result.errors.non_field_errors[0], {
                //   appearance: 'warning',
                //   autoDismiss: autoDismiss,
                // })
            } else {
                // addToast('Validation errors found', {
                //   appearance: 'warning',
                //   autoDismiss: autoDismiss,
                // })
            }
        } else {
            // addToast(`An error occurred while ${accion_infinitivo_para_error_message}, try again in a few moments`, {
            //   appearance: 'error',
            //   autoDismiss: autoDismiss,
            // })
        }
    }
}


export const useClearStore = () => {
    const rootStore = useStores()
    return () => {
        rootStore.reset()
    }
}

export const validEmail = (email: string) => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return reg.test(email);
}

export const title_pag: any = {}

export const getTitlePag = (match_fn: any) => {
    let match = match_fn()
    if (match !== undefined) {
        let path = match.path
        let title = title_pag[path]
        if (title !== undefined && title !== '')
            return "Houshares | " + title
    }
    return 'Houshares'
}

export const compressImage = async (event: any) => {
    const imageFile = event;
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
    }
    return imageCompression(imageFile, options)
}


export const useWindowSize = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    return width <= 768;
}

export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};

export const inHash = (dato: string) => {
    return btoa(dato + ":" + new Date().getMilliseconds());
};

export const outHash = (dato: string) => {
    return atob(dato).split(":")[0];
};

export const formatDate = (year: number, month: number, date: number | undefined) => {
    let d = new Date(year, month, date);
    let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
    let mo = new Intl.DateTimeFormat('en', {month: 'short'}).format(d);
    let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
    return (`${da} ${mo} ${ye}`);
}

export const sort = (orderBy: string, asc: boolean, data: any) => {
    let sort;
    if (asc) {
        sort = [...data].sort((a, b) => ('' + a[orderBy]).localeCompare(b[orderBy]));
    } else {
        sort = [...data].sort((a, b) => ('' + b[orderBy]).localeCompare(a[orderBy]));
    }
    return sort;
}

export const plata_fmt = (monto: any) => {
    return numeral(Math.trunc(monto)).format('$0,00')
}

export const cantidad_fmt = (monto :any) => {
    return numeral(monto).format('0,0')
}

export const fecha_fmt = (fecha: string, formato_opcional: string) => {
    const mm = moment(fecha);
    return mm.format(formato_opcional ? formato_opcional : 'DD/MM - HH:mm')
}

export const fechahora_fmt = (fecha: string, formato_opcional: string) => {
    return fecha_fmt(fecha, formato_opcional ? formato_opcional : 'DD/MM/YYYY HH:mm')
}
