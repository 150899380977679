import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Modal, ModalBody, Row} from "react-bootstrap";
// @ts-ignore
import RenderSmoothImage from 'render-smooth-image-react';
import 'render-smooth-image-react/build/style.css';
import {COLORS, ROUTES} from "../../../constants/constants";
import {cantidad_fmt, inHash, openInNewTab, outHash, plata_fmt} from "../../../helpers"
import {DetailViewProps} from "../../../constants/types"
import styles from "./PropertySpecific.module.css"
import useStyles from "./styles";
import Logo from "../../../components/Logo/Logo";
import Slider from "react-slick";
import ButtonSquare from "../../../components/Buttons/ButtonSquare";
import PageWebDarkWithHeader from "../../../components/PageWeb/PageWebDarkWithHeader";
import {useStores} from "../../../models/root-store/root-store-context";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {AiOutlineCheckCircle} from "react-icons/ai";

function SampleNextArrow(props: any) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: "block", backgroundColor: COLORS.black}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: "block", background: COLORS.black}}
      onClick={onClick}
    />
  );
}

export const PropertySpecific: React.FC<DetailViewProps> = observer(({history, match}) => {

  // console.log("aver: ",match.params.id);
  let classes = useStyles()
  const idProperty = outHash(match.params.id);
  const rootStore = useStores()
  const {loginStore} = rootStore
  const [data, setData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [ModalDetailImage, setModalDetailImage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [SelectedImage, setSelectedImage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");
  const [showSubmitted, setShowSubmitted] = useState<boolean>(false);

  let settings = {
    arrows: true,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const setResultToData = (result: any) => {
    let datos = ({
      id: result.id,
      name: result.name,
      rentPrice: result.rent_price,
      totalPrice: result.total_price,
      address: result.address,
      floor: result.floor,
      size: result.size,
      rooms: result.rooms,
      bathrooms: result.bathrooms,
      parkingSpaces: result.parking_spaces,
      construction_year: result.construction_year,
      maps: result.google_map_link,
      propertyImageSet: result.propertyimage_set.length !== 0 ? result.propertyimage_set : [],
      city: result.city !== null ? result.city.name : ""
    })
    // console.log("datos: ", datos)
    setData(datos)
  }

  const getProperty = () => {
    rootStore.environment.api.getPropertySpecific(parseInt(idProperty)).then((result: any) => {
      if (result.kind === "ok") {
        // console.log(result);
        // setShow(true)
        // setMessage("result:" + result)
        // setVariantMessage("success")

        setResultToData(result.data)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    getProperty()
  }, []);

  const closeMessage = () => {
    setShow(false)
  }

  const createApplicationHousner = () => {
    rootStore.environment.api.createPropertyRequest(parseInt(idProperty)).then((result: any) => {
      if (result.kind === "ok") {
        setShowSubmitted(true)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const startApplication = () => {
    if (loginStore.group !== 2) { //if they aren't Housners
      history.push(ROUTES.APPLICATION_PROFILE(inHash((idProperty).toString())))
    } else {
      if (loginStore.backgroundStatus === "timed_out") {
        history.push(ROUTES.APPLICATION_PROFILE(inHash((idProperty).toString())))
      } else {
        createApplicationHousner();
      }
    }
  }

  const handleCloseShowSubmitted = () => {
    setShowSubmitted(false)
    history.push(ROUTES.HOME)
  }

  const progImage = (imgItem: any) => {
    return (
      <div style={{ width: 250, height: 220, cursor: "pointer"}}
           className={' mx-auto'}
           onClick={() => openModal(imgItem.image)}
      >
        <RenderSmoothImage src={imgItem.image} alt="Property image" />
      </div>
    )
  }

  const openModal = (imageUrl: string) => {
    setModalDetailImage(true)
    setSelectedImage(imageUrl)
  }

  return (
    <PageWebDarkWithHeader login_required={false}>
      <div className="content-container row mt-3">
        <div style={{cursor: "pointer"}}>
          <Logo short={true} light={false}/>
        </div>
        {data && data.propertyImageSet.length >= 3 &&
          <Row style={{justifyContent: "center", width: "75%", marginTop: "15px"}}>
            <Slider {...settings} className={styles.slickSlider}>
              {data.propertyImageSet.map((imgItem: { image: string | undefined; },
                                          idx: React.Key | null | undefined) => {
                return (
                  <div key={idx} className={styles.itemCarousel}>
                    {progImage(imgItem)}
                  </div>

                );
              })
              }
            </Slider>
          </Row>
        }
        <Row style={{justifyContent: "center", marginTop: "15px"}}>
          {data && data.propertyImageSet.length < 3 &&
            data.propertyImageSet.map((imgItem: { image: string | undefined; },
                                       idx: React.Key | null | undefined) => {
              return (
                <div key={idx} className={"col-lg-4 col-md-6 col-sm-6"}
                     style={{textAlign: "center"}}>
                  {progImage(imgItem)}
                </div>

              );
            })
          }

        </Row>
      </div>
      <div className={"mx-auto col-lg-3 col-md-4 col-sm-12 mt-3"}>
        <ul style={{color: COLORS.white, overflowWrap: "break-word"}}>
          <li>Property name: {data && data.name}</li>
          <li>Rent price: {data && plata_fmt(data.rentPrice)}</li>
          <li>Total price {data && plata_fmt(data.totalPrice)}</li>
          <li>Address: {data && data.address}</li>
          <li>Floor: {data && cantidad_fmt(data.floor)}</li>
          <li>Size: {data && cantidad_fmt(data.size)}</li>
          <li>Number of rooms: {data && cantidad_fmt(data.rooms)}</li>
          <li>Number of bathrooms: {data && cantidad_fmt(data.bathrooms)}</li>
          <li>Number of parking spaces: {data && cantidad_fmt(data.parkingSpaces)}</li>
          <li>Year of construction: {data && data.construction_year}</li>
          <li>City: {data && data.city}</li>
          {data && data.maps &&
            <li>Google map link:
              <a onClick={() => openInNewTab(data.maps)}
                 className={classes.mapLink}>
                Map link
              </a>
            </li>
          }
        </ul>
      </div>
      <div className={"mx-auto col-3 mt-3"} style={{textAlign: "center"}}>
        <ButtonSquare label={"Start application"} bold={true}
                      startFunction={startApplication}/>
      </div>
      <div className={"mx-auto col-3 mt-3"} style={{textAlign: "center"}}>
        <ButtonSquare label={"Back"} bold={true}
                      startFunction={() => history.goBack()}/>
      </div>

      <Modal show={showSubmitted} size="lg">
        <ModalBody style={{backgroundColor: COLORS.white, textAlign: "center"}}>
          <div style={{marginTop: "10px"}}>
            <AiOutlineCheckCircle style={{color: COLORS.green, fontSize: "100px"}}/>
          </div>
          <div style={{
            fontWeight: "bold",
            fontSize: "X-large",
            marginTop: "10px"
          }}>
            You have successfully submitted a request
          </div>
          <div style={{marginTop: "10px"}}>
            <ButtonSquare label={"Back"} bold={true} startFunction={handleCloseShowSubmitted}/>
          </div>
        </ModalBody>
      </Modal>
      <Modal show={ModalDetailImage} fullscreen onHide={() => setModalDetailImage(false)}>
        <Modal.Header closeButton style={{backgroundColor: COLORS.white}}>

        </Modal.Header>
        <Modal.Body style={{backgroundColor: COLORS.white, textAlign: "center"}}>
          <img src={SelectedImage} alt={''} style={{width: '100%', height: '100%', objectFit: "contain"}}/>
        </Modal.Body>
      </Modal>
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
    </PageWebDarkWithHeader>
  );
});

export default PropertySpecific;
