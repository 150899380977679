import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {RouteComponentProps} from "react-router";
import PageWebDarkWithHeader from "../../../components/PageWeb/PageWebDarkWithHeader";
import useStyles from "./styles";
import {useStores} from "../../../models/root-store/root-store-context";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {Field, Form, Formik, FormikProps, FormikState} from "formik";
import * as Yup from "yup";
import ButtonSquare from "../../../components/Buttons/ButtonSquare";
import ButtonSquareForm from "../../../components/Buttons/ButtonSquareForm";
import Logo from "../../../components/Logo/Logo";
import InputPasswordForm from "../../../components/Inputs/InputPasswordForm";
import {RiLockPasswordLine} from "react-icons/ri";


interface IChangePass {
    old_password: string,
    new_password: string,
    new_password_confirm: string,
}

const schemaChangePass = Yup.object().shape({
    old_password: Yup.string().required('Required'),
    new_password: Yup.string().matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{7,20}\S$/,
        'Please valid password: At least 8 characters, one uppercase, one lowercase, one special character (like !@#$%^&*()) and no spaces'
    )
        .required(
            'Please valid password: At least 8 characters, one uppercase, one lowercase, one special character (like !@#$%^&*()) and no spaces'
        ),
    new_password_confirm: Yup.string().required('Required')
        .test(
            'Password-match',
            'Password must match',
            function (value) {
                return this.parent.new_password === value
            }
        ),
})


export const ChangePassword: React.FC<RouteComponentProps> = observer(({history}) => {

    let classes = useStyles();
    const rootStore = useStores()

    const [Loading, setLoading] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [variantMessage, setVariantMessage] = useState<string>("");
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false);

    const changePassword = (values: IChangePass, setSubmitting: (arg0: boolean) => void,
                            resetForm: {
                                (nextState?: Partial<FormikState<{
                                    old_password: string;
                                    new_password: string; new_password_confirm: string;
                                }>> | undefined): void;
                                (arg0: { values: { old_password: string; new_password: string; new_password_confirm: string; }; }): void;
                            }) => {
        setLoading(true)
        rootStore.environment.api.changePassword(values.old_password, values.new_password, values.new_password_confirm)
            .then((result: any) => {
                setLoading(false)
                setSubmitting(false)
                resetForm({
                    values: {
                        old_password: "",
                        new_password: "",
                        new_password_confirm: "",
                    },
                });
                if (result.kind === "ok") {
                    setShow(true)
                    setMessage("Password successfully changed")
                    setVariantMessage("success")
                } else {
                    if (result.kind === "bad-data") {
                        if (result.errors.non_field_errors) {
                            setShow(true)
                            setMessage(result.errors.non_field_errors[0])
                            setVariantMessage("warning")
                        } else {
                            setShow(true)
                            setMessage('Validation errors found')
                            setVariantMessage("warning")
                        }
                    } else {
                        setShow(true)
                        setMessage('An error occurred, please try again in a few moments')
                        setVariantMessage("danger")
                    }
                }
            })
    }

    const closeMessage = () => {
        setShow(false)
    }

    return (
        <PageWebDarkWithHeader login_required={true}>
            <div className={'content-container row mx-0'} style={{padding: "0 10% 0 10%"}}>

                <Logo short={true} light={false}/>

                <div className={classes.title}>Change password</div>
                <div style={{textAlign: "center"}}>
                    <Formik
                        initialValues={{
                            old_password: "",
                            new_password: "",
                            new_password_confirm: "",
                        }}
                        onSubmit={
                            (values: IChangePass, {setSubmitting, resetForm}) => {
                                changePassword(values, setSubmitting, resetForm)
                            }
                        }
                        validationSchema={schemaChangePass}
                    >
                        {(props: FormikProps<IChangePass>) => {
                            const {
                                isSubmitting,
                            } = props
                            return (

                                <Form style={{marginTop: "15px"}}>
                                        <div className={'my-2 text-start offset-sm-3 col-sm-6 offset-lg-2 col-lg-8'}>
                                            <Field name="old_password" label={"Old password"}
                                                   setShowPassword={setShowOldPassword}
                                                   showPassword={showOldPassword} startFunction={changePassword} bold={true}
                                                   onKeyPressFunction={false} component={InputPasswordForm}/>
                                        </div>
                                        <div className={'my-2 text-start offset-sm-3 col-sm-6 offset-lg-2 col-lg-8'}>
                                            <Field name="new_password" label={"New password"}
                                                   setShowPassword={setShowNewPassword}
                                                   showPassword={showNewPassword} startFunction={changePassword} bold={true}
                                                   onKeyPressFunction={false} component={InputPasswordForm}/>
                                        </div>
                                        <div className={'my-2 text-start offset-sm-3 col-sm-6 offset-lg-2 col-lg-8'}>
                                            <Field name="new_password_confirm" label={"Confirm new password"}
                                                   setShowPassword={setShowPasswordConfirm}
                                                   showPassword={showPasswordConfirm} startFunction={changePassword}
                                                   bold={true}
                                                   onKeyPressFunction={true} component={InputPasswordForm}/>
                                        </div>
                                    <div style={{textAlign: "center", marginTop: "20px", marginBottom: "20px"}}>
                                        <ButtonSquareForm label={"Change password"} bold={true}
                                                          isSubmitting={isSubmitting}
                                                          Loading={Loading}><RiLockPasswordLine
                                            style={{marginLeft: "5px", marginBottom: "5px"}}/>
                                        </ButtonSquareForm>
                                    </div>
                                    <div>
                                        <ButtonSquare label={"Back"} startFunction={() => history.goBack()}
                                                      bold={true}/>
                                    </div>
                                </Form>

                            )
                        }}
                    </Formik>
                </div>
            </div>
            <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>

        </PageWebDarkWithHeader>
    );
});
export default ChangePassword;
