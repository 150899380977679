import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {RouteComponentProps} from "react-router";
import PageWebWithHeader from "../../../components/PageWeb/PageWebWithHeader";
import logoACH from "../../../assets/images/logoACH.png";
import {COLORS, ROUTES, space} from "../../../constants/constants";
import {cantidad_fmt, inHash, openInNewTab, plata_fmt} from "../../../helpers";
import {Accordion, Button, Row} from "react-bootstrap";
import useStyles from "./styles";
import {BiMap} from "react-icons/bi"
import {useStores} from "../../../models/root-store/root-store-context";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import ButtonSquare from "../../../components/Buttons/ButtonSquare";
import Logo from "../../../components/Logo/Logo";
import defaultImage from "../../../assets/images/default_Image.png"
import {RiExchangeDollarLine} from "react-icons/ri";
import {SpinnerDark} from "../../../components/Spinner/SpinnerDark";

function SampleNextArrow(props: any) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: "block", backgroundColor: COLORS.black}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const {className, style, onClick} = props;
  return (
    <div
      className={className}
      style={{...style, display: "block", background: COLORS.black}}
      onClick={onClick}
    />
  );
}

export const MyProperty: React.FC<RouteComponentProps> = observer(({history, match}) => {

  let classes = useStyles();
  const rootStore = useStores()
  const {loginStore} = rootStore

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  const todayShow = mm + '/' + dd + '/' + yyyy;
  const [data, setData] = useState<any>(null);
  const [dataPaymentRentList, setDataPaymentRentList] = useState<any>(null);
  const [dataPaymentACTIList, setDataPaymentACTIList] = useState<any>(null);
  const [Loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");

  let datosFake =
    [
      {
        date: "12/03/21",
        amount: "100",
        paymentType: "Rent"
      },
      {
        date: "12/04/21",
        amount: "100",
        paymentType: "Rent"
      },
      {
        date: "12/05/21",
        amount: "1000",
        paymentType: "ACTI"
      },
      {
        date: "12/06/21",
        amount: "100",
        paymentType: "Rent"
      },
      {
        date: "12/07/21",
        amount: "1500",
        paymentType: "ACTI"
      },
      {
        date: "12/03/21",
        amount: "100",
        paymentType: "Rent"
      },
      {
        date: "12/04/21",
        amount: "100",
        paymentType: "Rent"
      },
      {
        date: "12/05/21",
        amount: "1000",
        paymentType: "ACTI"
      },
      {
        date: "12/06/21",
        amount: "100",
        paymentType: "Rent"
      },
      {
        date: "12/07/21",
        amount: "1500",
        paymentType: "ACTI"
      },
      {
        date: "12/04/21",
        amount: "100",
        paymentType: "Rent"
      },
      {
        date: "12/05/21",
        amount: "1000",
        paymentType: "ACTI"
      },
      {
        date: "12/06/21",
        amount: "100",
        paymentType: "Rent"
      },
      {
        date: "12/07/21",
        amount: "1500",
        paymentType: "ACTI"
      },
      {
        date: "12/04/21",
        amount: "100",
        paymentType: "Rent"
      },
      {
        date: "12/05/21",
        amount: "1000",
        paymentType: "ACTI"
      },
      {
        date: "12/06/21",
        amount: "100",
        paymentType: "Rent"
      },
      {
        date: "12/07/21",
        amount: "1500",
        paymentType: "ACTI"
      }
    ]

  let settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const setResultListToData = (result: any) => {
    let datos: any = [];
    result.data.results.map((resultItem: any) => {
      datos.push({
        id: resultItem.id,
        idUser: resultItem.user_id,
        address: resultItem.property_address,
        propertyImage: resultItem.property_image,
        initialPropertyValue: resultItem.property_value,
        currentPropertyValue: resultItem.current_property_value,
        initialRent: resultItem.property_initial_rent,
        currentRent: resultItem.current_rent,
        valueEachACTI: resultItem.value_acti,
        opCost: resultItem.operational_costs,
        totalACTIS: resultItem.total_number_actis,
        googleMapLink: resultItem.google_map_link
      })
      //todo: change this part if is going to have more properties
      if (resultItem.rent_payment_history) {
        setResultToDataPaymentRentList(resultItem.rent_payment_history)
      }
      if (resultItem.acti_purchase_history) {
        setResultToDataPaymentACTIList(resultItem.acti_purchase_history)
      }
    })

    setData(datos)
  }

  const setResultToDataPaymentRentList = (result: any) => {
    let datos: any = [];

    result.map((resultItem: any) => {
      datos.push({
        // id: result.id,
        date: resultItem.date,
        amount: resultItem.amount,
        paymentType: "Rent"
      })
    })
    setDataPaymentRentList(datos)
  }

  const setResultToDataPaymentACTIList = (result: any) => {
    let datos: any = [];

    result.map((resultItem: any) => {
      datos.push({
        // id: result.id,
        date: resultItem.date,
        amount: resultItem.amount,
        paymentType: "ACTIS"
      })
    })
    setDataPaymentACTIList(datos)
  }

  const getProperty = () => {
    setLoading(true)
    rootStore.environment.api.getMyProperty().then((result: any) => {
      console.log('result ===> ', result)
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)

      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    getProperty()
  }, []);

  const buyActis = () => {
    openInNewTab("https://www.google.com")
  }

  const payRent = () => {
    openInNewTab("https://www.google.com")
  }

  const closeMessage = () => {
    setShow(false)
  }

  const PaymentItem = (currentItems: any) => {

    return (
      <React.Fragment>
        {currentItems.currentItems?.length > 0 &&
          <React.Fragment>
            {currentItems.currentItems.map((item: any, idx: number) => {
              return (
                <ul key={idx}>
                  <li>Date: {item.date}</li>
                  <li>Amount: {plata_fmt(item.amount)} $</li>
                  <li>Payment type: {item.paymentType}</li>
                </ul>
              )
            })
            }
          </React.Fragment>
        }
      </React.Fragment>
    );
  }

  return (
    <PageWebWithHeader login_required={true} housner_required={true}>
      <div className={'content-container row mx-0'}>
        <Logo short={true} light={true}/>
        <div className={classes.title}>My Property</div>
        <div>
          <Row style={{marginTop: "15px"}}>
            <div className={["col-2", classes.profileMyProperty].join(" ")}>
              {loginStore.profile_image &&
                <img
                  src={loginStore.profile_image}
                  alt={"Property"}
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50px",
                    objectFit: 'contain'
                  }}
                />
              }
              {!loginStore.profile_image &&
                <div
                  style={{
                    backgroundColor: COLORS.black,
                    width: "80px",
                    height: "80px",
                    borderRadius: "50px",
                    objectFit: 'contain'
                  }}
                />
              }
              <div style={{marginLeft: "20px"}}>
                <div>{loginStore.first_name}{space}{loginStore.second_name}</div>
                <div>{loginStore.last_name}{space}{loginStore.second_last_name}</div>
              </div>
            </div>
            <div className={["col-8", classes.myPropertyImageDiv].join(" ")}>
              <img
                src={data ? data[0].propertyImage : defaultImage}
                alt={"Property"}
                className={classes.myPropertyImageSize}
                style={{objectFit: 'contain'}}
              />
            </div>
            <div className={["col-2", classes.profileMyProperty].join(" ")}>

            </div>
          </Row>

          {Loading &&
            <SpinnerDark/>
          }
          {!Loading && data &&
            <React.Fragment>
              <div style={{textAlign: "center", marginTop: "10px", marginBottom: "40px"}}>
                <BiMap onClick={() => openInNewTab(data[0].googleMapLink)}
                       className={classes.mapLinkLight}/>{space}Address:{space}{data[0].address}
              </div>
              <div style={{textAlign: "center", marginBottom: "10px"}}>
                <ButtonSquare label={"Request manteinance"}
                              startFunction={() => history.push(ROUTES.MAINTENANCE(inHash(data[0].id)))}
                              bold={true}/>
                <div className={"row"} style={{marginTop: "10px"}}>
                  <div className={"col"}>
                    <Button className={classes.btnPay} onClick={payRent}>Pay rent</Button>
                    <img src={logoACH} className={classes.logoPay} onClick={payRent} alt={"logo"}/>
                  </div>
                  <div className={"col"}>
                    <Button className={classes.btnPay} onClick={buyActis}>Buy ACTIS</Button>
                    <img src={logoACH} className={classes.logoPay} onClick={buyActis} alt={"logo"}/>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }

        </div>
        {data &&
          <div style={{
            marginTop: "50px",
            marginLeft: "auto",
            marginRight: "auto",
            width: "550px"
          }}>
            <div style={{
              backgroundColor: COLORS.gray,
              color: COLORS.white,
              padding: "10px",
            }}>
              <div>Property finance table in real time</div>
              <ul>
                <div>
                  <li style={{textAlign: "left"}}>
                    Real estate value
                    <span style={{float: "right"}}>
                                           {plata_fmt(data[0].initialPropertyValue)}
                                        </span>
                  </li>
                </div>
                <div>
                  <li style={{textAlign: "left"}}>
                    Property value {todayShow}:
                    <span style={{float: "right"}}>
                                           {plata_fmt(data[0].currentPropertyValue)}
                                        </span>
                  </li>
                </div>
                <div>
                  <li style={{textAlign: "left"}}>
                    Initial monthly Rent:
                    <span style={{float: "right"}}>
                                           {plata_fmt(data[0].initialRent)}
                                        </span>
                  </li>
                </div>
                <div>
                  <li style={{textAlign: "left"}}>
                    Value for each ACTI (share):
                    <span style={{float: "right"}}>
                                           {plata_fmt(data[0].valueEachACTI)}
                                        </span>
                  </li>
                </div>
                <div>
                  <li style={{textAlign: "left"}}>
                    Monthly Rent Value at {todayShow}:
                    <span style={{float: "right"}}>
                                           {plata_fmt(data[0].currentRent)}
                                        </span>
                  </li>
                </div>
                <div>
                  <li style={{textAlign: "left"}}>
                    Operational Costs:
                    <span style={{float: "right"}}>
                                           {plata_fmt(data[0].opCost)}
                                        </span>
                  </li>
                </div>
                <div>
                  <li style={{textAlign: "left"}}>
                    Total number of ACTI's:
                    <span style={{float: "right"}}>
                                           {cantidad_fmt(data[0].totalACTIS)}
                                        </span>
                  </li>
                </div>
              </ul>
            </div>
            <div style={{
              color: COLORS.white,
              padding: "10px",
              marginTop: "50px"
            }}>
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header style={{borderColor: COLORS.gray, borderStyle: "solid"}}>
                    <RiExchangeDollarLine/>{space}Payment history
                  </Accordion.Header>
                  <Accordion.Body style={{backgroundColor: COLORS.gray}}>
                    <div style={{
                      backgroundColor: COLORS.lightGray,
                      color: COLORS.black,
                      padding: "5px 0px 5px 10px",
                      width: "50%", marginBottom: "10px"
                    }}>
                      List of all payments
                    </div>
                    <div style={{height: "auto", maxHeight: "80vh", display: "flex"}}>
                      <div style={{overflowY: "scroll", flex: 1}}>
                        <PaymentItem currentItems={dataPaymentRentList}/>
                        <PaymentItem currentItems={dataPaymentACTIList}/>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

        }
        <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage}
                        delay={0}/>
      </div>
    </PageWebWithHeader>
  );
});
export default MyProperty;
