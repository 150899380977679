import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import './Reviews.css';
import {RouteComponentProps} from "react-router";
import PageWeb from "../../../components/PageWeb/PageWeb";
import {useStores} from "../../../models/root-store/root-store-context";
import {Review} from "../../../services/api";
import {Spinner} from "react-bootstrap";

export const Reviews: React.FC<RouteComponentProps> = observer(({history}) => {
  const rootStore = useStores()
  const [ReviewList, setReviewList] = useState<Review[]>([])
  const [Loading, setLoading] = useState(false)


  const getReviews= () => {
    setLoading(true)
    rootStore.environment.api.reviewList().then(result => {
      setLoading(false)
      if (result.kind === "ok") {
        setReviewList(result.data)
      }
    }).catch(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getReviews()
  }, [ ])
  return (
    <PageWeb login_required={false}>
      <div className={'content-container row mx-0'}>
        <div className={'col-12 col-md-6'}>
          <div className={'card my-5'}>
            <div className={'card-body'}>
              <p>Reviews</p>
              {Loading && <div className={'w-100 d-flex justify-content-center align-items-center'}><Spinner animation="border" variant="primary"/></div>}
              {!Loading && ReviewList.map(review => {
                return (
                  <p key={'id-r-' + review.id}>{review.author} - {review.comment}</p>
                )
              })}
            </div>
          </div>
        </div>
      </div>

    </PageWeb>
  );
});

export default Reviews;
