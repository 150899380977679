import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import './Faqs.css';
import {RouteComponentProps} from "react-router";
import PageWeb from "../../../components/PageWeb/PageWeb";
import {useStores} from "../../../models/root-store/root-store-context";
import {Faq} from "../../../services/api";
import {Spinner} from "react-bootstrap";

export const Faqs: React.FC<RouteComponentProps> = observer(({history}) => {
  const rootStore = useStores()
  const [FaqList, setFaqList] = useState<Faq[]>([])
  const [Loading, setLoading] = useState(false)


  const getFaqs= () => {
    setLoading(true)
    rootStore.environment.api.faq().then(result => {
      setLoading(false)
      if (result.kind === "ok") {
        setFaqList(result.data)
      }
    }).catch(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getFaqs()
  }, [ ])
  return (
    <PageWeb login_required={false}>
      <div className={'content-container row mx-0'}>
        <div className={'col-12 col-md-6'}>
          <div className={'card my-5'}>
            <div className={'card-body'}>
              <h2>FAQ</h2>
              {Loading && <div className={'w-100 d-flex justify-content-center align-items-center'}><Spinner animation="border" variant="primary"/></div>}
              {!Loading && FaqList.map(faq => {
                return (
                  <div key={'id-f-' + faq.id}>
                    <strong>{faq.question}</strong>
                    <p>{faq.answer}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

    </PageWeb>
  );
});

export default Faqs;
