import {types} from "mobx-state-tree"

export const Insurance = types
  .model("Insurance")
  .props({
    id: types.identifierNumber,
    type_of_insurance: types.number,
    agency_name: types.string,
    agency_contact: types.string,
    agency_phone: types.string,
    name_on_policy: types.string,
    policy_number: types.string,
    expiration_date: types.string,
  })
