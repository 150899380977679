import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../constants/constants";

export default makeStyles({

    labelFormBold: {
        color: COLORS.white,
        float: "left",
        fontWeight: "bold"
    },

    labelForm: {
        color: COLORS.white,
        float: "left",
    },

    labelPasswordBold: {
        color: COLORS.white,
        fontWeight: "bold"
    },

    labelPassword: {
        color: COLORS.white,
    },

    labelBold: {
        color: COLORS.white,
        fontWeight: "bold",
    },

    label: {
        color: COLORS.white,
    },

    controlForm: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        borderRadius: "12px",
        marginTop: "10px",
        "&:placeholder": {
            color: COLORS.white
        },
    },

    inputModal:{
        backgroundColor: COLORS.gray,
        borderColor: COLORS.white,
        borderRadius: "12px",
        height: "38px",
        width: "233px",
        marginTop: "10px",
        color:COLORS.white,
        "&:disabled": {
            color: COLORS.gray
        },
    },
    emailInput:{
      width: "100%"
    },
    textareaModal:{
        backgroundColor: COLORS.gray,
        borderColor: COLORS.white,
        borderRadius: "12px",
        marginTop: "10px",
        color:COLORS.white,
    },

    errorModal:{
        backgroundColor:COLORS.gray,
        color:COLORS.red,
        marginTop:"5px"
    },

    error:{
        backgroundColor:COLORS.black,
        color:COLORS.red,
        marginTop:"5px"
    },

    drop:{
        backgroundColor:"red",
    }
});