import React, {useEffect, useState} from 'react';
import './App.css';
import {RootStore, setupRootStore} from "./models";
import {RootStoreProvider} from "./models/root-store/root-store-context";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ROUTES, ROUTES_ADMIN} from "./constants/constants";
import {AccountInfo, Home, Login, Onboard, PrivacyPolicy, Signup, Splash, TermsConditions} from "./pages";
import {title_pag} from "./helpers";
import PropertyList from "./pages/web/Property/PropertyList";
import PropertySpecific from "./pages/web/Property/PropertySpecific";
import Application from "./pages/web/Application/Application";
import UserList from "./pages/admin/UserList/UserList";
import PropertyManagement from "./pages/admin/PropertyManagement/PropertyManagement";
import MaintenanceRequestList from "./pages/admin/MaintenanceRequestList/MaintenanceRequestList";
import FeedbackList from "./pages/admin/FeedbackList/FeedbackList";
import Settings from "./pages/web/Settings/Settings";
import BackgroundCheckList from "./pages/admin/BackgroundCheckList/BackgroundCheckList";
import PropertyRequestList from "./pages/admin/PropertyRequestList/PropertyRequestList";
import PropertyUnderContractList from "./pages/admin/PropertyUnderContractList/PropertyUnderContractList";
import DeniedUsers from "./pages/admin/DeniedUsers/DeniedUsers";
import HousnerList from "./pages/admin/HousnerList/HousnerList";
import MaintenanceRequest from "./pages/web/Property/MaintenanceRequest";
import MyProperty from "./pages/web/Property/MyProperty";
import Feedback from "./pages/web/Feedback/Feedback";
import UserInfo from "./pages/admin/UserInfo/UserInfo";
import ChangePassword from "./pages/web/ChangePassword/ChangePassword";
import InviteFriends from "./pages/web/InviteFriends/InviteFriends";
import PropertyUnderContractInfo from "./pages/admin/PropertyUnderContractList/PropertyUnderContractInfo";

const App: React.FC = () => {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined)

  useEffect(() => {
    (async () => {
      setupRootStore().then(setRootStore)
      const numeral = require('numeral')
      try {
        numeral.register('locale', 'py_custom', {
          delimiters: {
            thousands: '.',
            decimal: ','
          },
          abbreviations: {
            thousand: 'm',
            million: 'mm',
            billion: 'b',
            trillion: 't'
          },
          ordinal: function (number: number) {
            return number === 1 ? 'peso' : 'pesos'
          },
          currency: {
            symbol: '$'
          }
        })
        numeral.locale('py_custom')
      } catch (e) {
      }
    })()
  }, [])

  if (!rootStore) return null

  const route = (path: string, title: string, componente: any, exact = true) => {
    (title_pag as any)[path as any] = title
    return <Route path={path} component={componente} exact={exact}/>
  }

  return (
    <RootStoreProvider value={rootStore}>
      <Router>
        <Switch>
          {route(ROUTES.ROOT, "", Splash)}
          {route(ROUTES.HOME, "Home", Home)}
          {route(ROUTES.ONBOARD, "On board", Onboard)}
          {route(ROUTES.SIGNUP, "Sign up", Signup)}
          {route(ROUTES.CHANGE_PASSWORD, "Change password", ChangePassword)}
          {route(ROUTES.LOGIN, "Log in", Login)}
          {route(ROUTES.TERMS, "Terms and Conditions", TermsConditions)}
          {route(ROUTES.PRIVACY, "Privacy Policy", PrivacyPolicy)}
          {route(ROUTES.ACCOUNT_INFO, "Account Information", AccountInfo)}
          {route(ROUTES.MY_PROPERTY, "My Property", MyProperty, true)}
          {route(ROUTES.PROPERTY_LIST, "Property List", PropertyList)}
          {route(ROUTES.PROPERTY_SPECIFIC(":id"), "Property Specific", PropertySpecific, true)}
          {route(ROUTES.APPLICATION_PROFILE(":id"), "Application to Property 1", AccountInfo)}
          {route(ROUTES.APPLICATION_PROPERTY(":id"), "Application to Property 2", Application)}
          {route(ROUTES.MAINTENANCE(":id"), "Maintenance Request", MaintenanceRequest)}
          {route(ROUTES.SETTINGS, "Settings", Settings)}
          {route(ROUTES.INVITE_FRIENDS, "Invite Friends", InviteFriends)}
          {route(ROUTES.FEEDBACK, "Feedback", Feedback)}
          {route(ROUTES_ADMIN.USER_LIST, "User List", UserList, true)}
          {route(ROUTES_ADMIN.USER_INFO(":id"), "User Info", UserInfo)}
          {route(ROUTES_ADMIN.BACKGROUND_CHECKS, "Background Checks", BackgroundCheckList)}
          {route(ROUTES_ADMIN.DENIED_USERS, "Denied Users", DeniedUsers)}
          {route(ROUTES_ADMIN.PROPERTY_MANAGEMENT, "Property management", PropertyManagement)}
          {route(ROUTES_ADMIN.HOUSNERS, "Housner List", HousnerList)}
          {route(ROUTES_ADMIN.PROPERTY_REQUESTS, "Property Requests", PropertyRequestList)}
          {route(ROUTES_ADMIN.PROPERTIES_UNDER_CONTRACT, "Properties under Contract", PropertyUnderContractList)}
          {route(ROUTES_ADMIN.PROPERTIES_UNDER_CONTRACT_INFO(":id"), "Properties under Contract Info", PropertyUnderContractInfo)}
          {route(ROUTES_ADMIN.MAINTENANCE_REQUESTS, "Maintenance requests", MaintenanceRequestList)}
          {route(ROUTES_ADMIN.FEEDBACKS, "Feedbacks", FeedbackList)}
        </Switch>
      </Router>
    </RootStoreProvider>
  );
}


export default App;
