import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import useStyles from "../styles"
import {COLORS, ROUTES_ADMIN} from "../../../constants/constants";
import AdminSearch from "../../../components/AdminSearch/AdminSearch";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {useStores} from "../../../models/root-store/root-store-context";
import AdminPagination from "../../../components/AdminPagination/AdminPagination";
import {Dropdown} from "react-bootstrap";
import {inHash} from "../../../helpers";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";


export const DeniedUsers: React.FC<RouteComponentProps> = observer(({history}) => {

    let classes = useStyles();
    const rootStore = useStores()
    const [data, setData] = useState<any>([]);
    const [Loading, setLoading] = useState<boolean>(false);
    const [valuesToSearch, setValuesToSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [previousPage, setPreviousPage] = useState<string | null>("");
    const [nextPage, setNextPage] = useState<string | null>("");
    const [pathLastCalled, setPathLastCalled] = useState<string>("/admin/denied-users/");
    const [offsetLastCalled, setOffsetLastCalled] = useState<number>(0);
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [variantMessage, setVariantMessage] = useState<string>("");


    const setResultListToData = (result: any) => {
        let datos: any = [];
        result.data.results.map((resultItem: any) => {
            datos.push({
                id: resultItem.id,
                userId: resultItem.user_id,
                fullName: resultItem.full_name,
                email: resultItem.email,
                phone: resultItem.phone,
                approvedAdmin: resultItem.approved_admin,
                approvedAdminCode: resultItem.approved_admin_code,
            })
        })
        setData(datos)
    }

    const getDeniedUsers = (rows: number) => {
        rootStore.environment.api.getDeniedUserList(rows).then((result: any) => {
            if (result.kind === "ok") {
                setResultListToData(result)
                setPreviousPage(result.data.previous)
                setNextPage(result.data.next)
            } else {
                if (result.kind === "bad-data") {
                    if (result.errors.non_field_errors) {
                        setShow(true)
                        setMessage(result.errors.non_field_errors[0])
                        setVariantMessage("warning")
                    } else {
                        setShow(true);
                        setMessage("Validation errors found")
                        setVariantMessage("warning")
                    }
                } else {
                    setShow(true)
                    setMessage("An error occurred, please try again in a few moments")
                    setVariantMessage("danger")
                }
            }
        })
    }

    useEffect(() => {
        getDeniedUsers(rowsPerPage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const search = (rows: number) => {
        setLoading(true)
        rootStore.environment.api.getDeniedUsersSearch(valuesToSearch, rows).then((result: any) => {
            setLoading(false)
            if (result.kind === "ok") {
                setResultListToData(result)
                setCurrentPage(1)
                setPreviousPage(result.data.previous)
                setNextPage(result.data.next)
                setTotalPages(Math.ceil(result.data.count / rows))
            } else {
                if (result.kind === "bad-data") {
                    if (result.errors.non_field_errors) {
                        setShow(true)
                        setMessage(result.errors.non_field_errors[0])
                        setVariantMessage("warning")
                    } else {
                        setShow(true)
                        setMessage('Validation errors found')
                        setVariantMessage("warning")
                    }
                } else {
                    setShow(true)
                    setMessage('An error occurred, please try again in a few moments')
                    setVariantMessage("danger")
                }
            }
        })
    }

    const getDeniedUsersPrevNextPage = (path: string, offset: number) => {
        setLoading(true)
        setPathLastCalled(path)
        setOffsetLastCalled(offset)
        rootStore.environment.api.getDeniedUserListPrevNextPage(path).then((result: any) => {
            setLoading(false)
            if (result.kind === "ok") {
                setResultListToData(result)
                setCurrentPage(Math.floor(offset / rowsPerPage) + 1)
                setPreviousPage(result.data.previous)
                setNextPage(result.data.next)
            } else {
                if (result.kind === "bad-data") {
                    if (result.errors.non_field_errors) {
                        setShow(true)
                        setMessage(result.errors.non_field_errors[0])
                        setVariantMessage("warning")
                    } else {
                        setShow(true);
                        setMessage("Validation errors found")
                        setVariantMessage("warning")
                    }
                } else {
                    setShow(true)
                    setMessage("An error occurred, please try again in a few moments")
                    setVariantMessage("danger")
                }
            }
        })
    }

    const handlePrevPageClicked = () => {
        let from = previousPage?.substring(previousPage?.indexOf("offset") + 7);
        let path = previousPage?.substring(previousPage?.indexOf("v1") + 2);
        if (path !== undefined) {
            if (from !== undefined && !isNaN(parseInt(from))) {
                getDeniedUsersPrevNextPage(path, parseInt(from))
            } else {
                getDeniedUsersPrevNextPage(path, 1)
            }
        }
    }

    const handleNextPageClicked = () => {
        let from = nextPage?.substring(nextPage?.indexOf("offset") + 7);
        let path = nextPage?.substring(nextPage?.indexOf("v1") + 2);

        if (path !== undefined) {
            if (from !== undefined && !isNaN(parseInt(from))) {
                getDeniedUsersPrevNextPage(path, parseInt(from))
            } else {
                getDeniedUsersPrevNextPage(path, 1)
            }
        }
    }

    const handleRowsPerPageClicked = (rows: number) => {
        setRowsPerPage(rows)
        if (valuesToSearch !== "") {
            search(rows)
        } else {
            getDeniedUsers(rows)
        }
    }

    const approveUser = (idUser: number) => {
        rootStore.environment.api.approveByAdminUser(idUser).then((result: any) => {
            if (result.kind === "ok") {
                setShow(true)
                setMessage(`User ${idUser} has been successfully approved`)
                setVariantMessage("success")
                getDeniedUsersPrevNextPage(pathLastCalled, offsetLastCalled)
            } else {
                if (result.kind === "bad-data") {
                    if (result.errors.non_field_errors) {
                        setShow(true)
                        setMessage(result.errors.non_field_errors[0])
                        setVariantMessage("warning")
                    } else {
                        setShow(true);
                        setMessage("Validation errors found")
                        setVariantMessage("warning")
                    }
                } else {
                    setShow(true)
                    setMessage("An error occurred, please try again in a few moments")
                    setVariantMessage("danger")
                }
            }
        })

        // const newDatos = datosFake.slice()
        // newDatos[idUser - 1].status = "Approved"
        // setDatosFake(newDatos)
    }

    const closeMessage = () => {
        setShow(false)
    }

    const ToApproveItems = (currentItems: any) => {
        return (
            <>
                {currentItems.currentItems?.length > 0 &&
                    <div style={{marginTop: "35px"}}>
                        {currentItems.currentItems.map((item: any, idx: number) => {
                            return (
                                <div key={idx} className={["row", classes.list].join(" ")}>
                                    <div className={"col-1"}>{item.userId}
                                    </div>
                                    <div
                                        className={["col-3", classes.btnUser].join(" ")}
                                        onClick={() => history.push(ROUTES_ADMIN.USER_INFO(inHash(item.userId)))}>
                                        {item.fullName}</div>
                                    <div className={"col-3"}>{item.email} </div>
                                    <div className={"col-3"}>{item.phone} </div>
                                    <div className={"col-2"}
                                         style={{color: item.approvedAdminCode === "APPROVED" ? COLORS.green : COLORS.orange}}>
                                        {item.approvedAdminCode === "APPROVED" ? item.approvedAdmin : ""}
                                        {item.approvedAdminCode !== "APPROVED" &&
                                            <Dropdown>
                                                <label className={classes.dropdownLabel}>
                                                    {item.approvedAdmin?item.approvedAdmin:"No status"}
                                                </label>
                                                <React.Fragment>
                                                    <Dropdown.Toggle split className={classes.dropdownToggle}
                                                                     id="dropdown-split-basic"/>
                                                    <Dropdown.Menu className={classes.dropdownMenu}>
                                                        {/*<Spinner animation={"border"} variant={"light"}/>*/}
                                                        <Dropdown.Item onClick={() => approveUser(item.id)}
                                                                       className={classes.dropdownItem}>Approve</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </React.Fragment>
                                            </Dropdown>
                                        }
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                }
            </>
        );
    }

    return (
        <PageWebAdminDark>
            <div className={"row"}
            >
                <div className={"col-9"}
                     style={{color: COLORS.white, fontSize: "xx-large"}}>
                    <AiOutlineCheckCircle/> Denied Users
                </div>
                <div className={"col-3"}
                     style={{display: "flex", justifyContent: "center"}}>
                    <AdminSearch valuesToSearch={valuesToSearch} setValuesToSearch={setValuesToSearch}
                                 search={search} rowsPerPage={rowsPerPage}/>
                </div>
            </div>
            {!Loading && data?.length === 0 &&
                <NoDataAdmin/>}
            {Loading &&
                <SpinnerLight/>
            }
            {!Loading && data && data?.length > 0 &&
                <div>
                    <div className={["row", classes.listTittles].join(" ")}>
                        <div className={"col-1"}>User ID</div>
                        <div className={"col-3"}>Name</div>
                        <div className={"col-3"}>Email</div>
                        <div className={"col-3"}>Phone</div>
                        <div className={"col-2"}>User status</div>
                    </div>
                    <ToApproveItems currentItems={data}/>
                    <AdminPagination rowsPerPage={rowsPerPage} handleRowsPerPageClicked={handleRowsPerPageClicked}
                                     currentPage={currentPage} handleNextPageClicked={handleNextPageClicked}
                                     handlePrevPageClicked={handlePrevPageClicked} nextPage={nextPage}
                                     previousPage={previousPage} totalPages={totalPages}/>
                </div>
            }
            <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
        </PageWebAdminDark>
    )
});

export default DeniedUsers;
