import React from 'react';
import {Redirect} from "react-router";
import {ROUTES} from "../../constants/constants";
import {useStores} from "../../models/root-store/root-store-context";

type PageProps = {
  login_required?: boolean
  title?: string
};

export const PageWebDark: React.FC<PageProps> = React.forwardRef((props,ref) => {
  const rootStore = useStores()
  const {loginStore} = rootStore


  if (props.login_required && !loginStore.isLoggedIn) {
    return <Redirect to={{ pathname: ROUTES.LOGIN }} />
  }
  return (
    <div className={'d-flex flex-column'} style={{backgroundColor: '#353939'}}>
      <div className={'container'}>
        {props.children}
      </div>
    </div>
  )
});

export default PageWebDark;
