import React from "react";
import useStyles from "./styles";
import {FieldInputProps, FormikProps} from "formik";
import TextareaAutosize from 'react-textarea-autosize';

type InputProps = {
    field: FieldInputProps<any>,
    form: FormikProps<any>,
    label: string,
    bold: boolean,
    className: string,
    placeholder: string,
    modal?:boolean
};

export const InputTextAreaFormModal: React.FC<InputProps> = React.forwardRef((props,ref) => {

    let classes = useStyles();

    return (
        <div className={props.className}>
            <label className={props.bold ? classes.labelBold : classes.label}
                   style={{alignSelf: "center", paddingRight: "20px"}}>{props.label}</label>
            <div>
                <TextareaAutosize
                    rows={4}
                    maxLength={500}
                    placeholder={`${props.placeholder}, 500 character limit`}
                    className={['form-control', classes.textareaModal].join(" ")}
                    {...props.field}
                />
                {props.form.errors[props.field.name] && props.form.touched[props.field.name] ? (
                    <div className={props.modal?classes.errorModal:classes.error}>{props.form.errors[props.field.name]}</div>
                ) : null}
            </div>

        </div>
    )
});

export default InputTextAreaFormModal;