import {Button} from "react-bootstrap";
import React from "react";
import useStyles from "./styles";
import {BsSearch} from "react-icons/bs";

type InputProps = {
    valuesToSearch: string,
    setValuesToSearch: (value:string)=>void,
    search: (rows:number)=>void,
    rowsPerPage: number,
};

export const AdminPagination: React.FC<InputProps> = React.forwardRef((props,ref) => {

    let classes = useStyles();

    return (
       <React.Fragment>
            <input
                type="text"
                value={props.valuesToSearch}
                onChange={event => {
                    props.setValuesToSearch(event.target.value)
                }}
                onKeyPress={evt => {
                    if (evt.key === 'Enter') {
                        props.search(props.rowsPerPage)
                    }
                }}
                placeholder=" Search..."
                className={classes.inputSearch}
            />
            <Button className={classes.searchButton}
                    onClick={()=>props.search(props.rowsPerPage)}
            >
                <BsSearch style={{fontSize: "larger"}}/>
            </Button>
        </React.Fragment>
    )
});

export default AdminPagination;
