import React from 'react';
import {observer} from "mobx-react-lite";
import {RouteComponentProps} from "react-router";
import PageWeb from "../../../components/PageWeb/PageWeb";
import Logo from "../../../components/Logo/Logo"
import {IoIosArrowDropleft} from "react-icons/io"
import {COLORS} from "../../../constants/constants";
import useStyles from "./styles";

export const PrivacyPolicy: React.FC<RouteComponentProps> = observer(({history}) => {
  let classes = useStyles();

  return (
    <PageWeb login_required={false}>
      <div className={'content-container row mx-0 white-body'} style={{padding: "0 10% 0 10%"}}>
        <div>
          <IoIosArrowDropleft color={COLORS.gray} fontSize={"xx-large"} onClick={history.goBack}
                              className={classes.arrowBack}/>
        </div>
        <Logo short={false} light={true}/>
        <div className={classes.title}>Privacy Policy</div>
        <div className={"col-lg-8 col-md-10 col-sm-12 term-conditions"}>
          <p className={classes.text}>
            Política de Privacidad De Datos Personales Houshares
          </p>
          <p className={classes.text}>
            Fecha de Entrada en Vigor: Junio 2021
          </p>
          <p className={classes.text}>
            Con fundamento en lo dispuesto en la Constitución Política de Colombia y particularmente la Ley 1581 de 2012
            y el Decreto Reglamentario 1377 de 2013, les damos a conocer la presente Política de Privacidad de
            Información bajo la cual Houshares, respetando su privacidad lleva a cabo la recolección, almacenamiento,
            uso, circulación, supresión y, en general, el tratamiento de los datos de los titulares de la información,
            sean estos clientes actuales o potenciales, especialistas, proveedores, subcontratistas, entre otros. Así
            mismo, esta Política contiene las condiciones de seguridad y confidencialidad con la que será procesado
            dicha información y los procedimientos establecidos para que los titulares de los datos puedan ejercer los
            derechos a conocer, actualizar, rectificar y suprimir información y revocar la autorización, las finalidades
            para las que Houshares recauda dichos datos personales, y las partes con las que podríamos compartirlos.
          </p>
          <p className={classes.text}>
            Houshares., recolecta los datos de los titulares de la información necesarios para ejecutar las obligaciones
            derivadas de su relación Contractual y extracontractual.
          </p>
          <p className={classes.text}>
            1. Datos Personales proporcionados por el usuario
          </p>
          <p className={classes.text}>
            Para los objetos de esta Política de Privacidad, el término "datos personales" significará toda información
            relacionada con una persona identificada o identificable, a quien le solicitaremos proporcionar ciertos
            datos personales, copia de su documento y de su licencia de conducción al momento en que se registre en la
            aplicación.
          </p>
          <p className={classes.text}>
            2. Información Recaudada Automáticamente
          </p>
          <p className={classes.text}>
            Cuando usted utiliza los Servicios Houshares, puede que obtengamos cierta información a través de medios
            automatizados, tales como el número de ID único del dispositivo móvil utilizado, información de red,
            información sobre las acciones tomadas en la aplicación, la fechas y hora de tales acciones, así como otros
            datos analíticos móviles. Esta información es empleada por Houshares para mejorar sus Servicios. Podremos
            recolectar la dirección IP y/o las coordenadas de geolocalización/GPS asignadas a un dispositivo por el
            tercero proveedor de servicio de acceso a Internet en el dispositivo, a fin de nosotros poder determinar en
            qué país se localiza el dispositivo y para fines de detección/prevención de fraudes.
          </p>
          <p className={classes.text}>
            Houshares. también puede recolectar información de los titulares de la información a través de terceros
            tales como socios comerciales, entidades financieras, proveedores, para las finalidades expresadas en la
            presente Política en cumplimiento de las disposiciones legales vigentes sobre protección de datos.
          </p>
          <p className={classes.text}>
            3. Utilización de los Datos Personales
          </p>
          <p className={classes.text}>
            Los datos personales de los titulares de la información serán recolectados, almacenados, usados, circulados,
            procesados, según su vínculo contractual con Houshares para:
          </p>
          <ul>
            <li>Brindarle los servicios de Houshares y sus funcionalidades;</li>

            <li>Crear y administrar su cuenta personal en Houshares, proporcionarle nuestros servicios y responder a sus
              inquietudes;
            </li>

            <li>Invitarlo a los eventos y/o lanzamientos de las diferentes marcas de Houshares.</li>

            <li>Informarle sobre las novedades de las marcas propias o aliadas a Houshares.</li>

            <li>Mantenerlo informado sobre promociones y/o campañas comerciales.</li>

            <li>Generar mecanismos de comunicación eficientes y mantener contacto permanente con el usuario.</li>

            <li>Realizar encuestas de satisfacción.</li>

            <li>Contactar al usuario en caso de campañas establecidas por Houshares o sus proveedores.</li>

            <li>Brindarle información al usuario relevante para el uso de la plataforma Houshares.</li>

            <li>Validar su información personal de pago para sus transacciones de pago utilizando los Servicios de
              Houshares;
            </li>

            <li>Operar, evaluar y mejorar la plataforma de Houshares haciendo desarrollo de nuevos productos y
              servicios; administrar nuestras comunicaciones; determinar la efectividad de nuestra publicidad; analizar
              nuestros productos, servicios y sitios web; facilitar la funcionalidad de nuestros sitios web; y realizar
              actividades contables, de auditoría, facturación, conciliación y cobranza;
            </li>

            <li>Realizar análisis de datos, incluyendo la anonimización de información personal;</li>

            <li>Cumplir con los requisitos legales aplicables y estándares de la industria, así como también nuestras
              políticas;
            </li>

            <li>Llevar a cabo auditorías, investigaciones y análisis a fin de mantener, proteger y mejorar nuestros
              servicios.
            </li>

            <li>Habilitar la acumulación de puntos de lealtad/fidelización</li>

            <li>Con el fin de enviarle nuestras comunicaciones de marketing/mercadeo, utilizamos sus datos personales,
              tales como su nombre, dirección de correo electrónico y preferencias. Usted puede, en cualquier momento y
              de forma gratuita, optar por no recibir más comunicaciones de marketing/mercadeo de Houshares, haciendo
              clic en el enlace para darse de baja que encontrará en los correos electrónicos que usted reciba de
              nosotros.
            </li>

          </ul>
          <p className={classes.text}>
            4. Datos Personales que Compartimos
          </p>
          <p className={classes.text}>
            Los datos personales de los titulares de la información serán circulados o compartidos asi:
          </p>
          <ul>
            <li>Nosotros no vendemos ni divulgamos los datos personales que obtengamos sobre usted, salvo según lo
              descrito por esta Política de Privacidad o según le sea informado al momento de recolectar los datos.
            </li>

            <li>Compartimos información para brindar acceso a los Servicios de Houshares, y las funcionalidades que
              usted solicite. Podemos compartir los datos personales que recabemos de usted con nuestras filiales,
              proveedores, instituciones financieras que emitan tarjetas de pago o comercios que procesen transacciones
              con tarjetas de pago. Compartimos datos personales para llevar a cabo y/o para facilitar transacciones con
              tarjeta de pago (incluyendo la detección y prevención de fraude con tarjetas).
            </li>

            <li>De igual modo, Houshares podrá compartir datos personales con proveedores que presten servicios actuando
              por cuenta nuestra, o con proveedores que nos presten servicios en relación con los Servicios de
              Houshares, y que brindan servicios actuando en nuestra representación. Estos proveedores de servicios no
              contarán con nuestra autorización para utilizar o divulgar los datos, salvo que sea necesario para prestar
              ciertos servicios actuando en representación nuestra o para cumplir con determinados requisitos legales, y
              exigimos a dichos proveedores, a través de un contrato, salvaguardar adecuadamente la privacidad y
              seguridad de los datos personales que procesen/traten por cuenta nuestra.
            </li>

            <li>De igual modo, Houshares también puede revelar datos que tenemos sobre usted: (i) si ello es requerido
              por la ley o por un proceso legal; o (ii) cuando lo consideremos necesario o conveniente hacerlo para
              evitar algún daño físico o una pérdida financiera o en caso de que se suscite alguna investigación por
              actividad fraudulenta o ilícita, sospechosa o real.
            </li>

            <li>Nos reservamos el derecho a transmitir datos personales que tengamos sobre usted en caso de que
              Houshares venda o transfiera la totalidad o una parte de su negocio o activos. En caso de que dicha venta
              o transferencia ocurra, entonces haremos esfuerzos razonables y que estén a nuestro alcance para hacer que
              el adquirente utilice la información personal que usted nos proporcionó de manera consistente con esta
              Política de Privacidad.
            </li>
          </ul>
          <p className={classes.text}>
            5. Sus Derechos y Opciones
          </p>
          <p className={classes.text}>
            El titular de los datos personales tendrá los siguientes derechos:
          </p>
          <ul>
            <li>Conocer, actualizar, rectificar y suprimir sus datos personales en cualquier momento, previa
              verificación de la identidad de quien hace la solicitud.
            </li>


            <li>Solicitar prueba de la autorización otorgada al Responsable del Tratamiento salvo cuando expresamente se
              exceptúe como requisito para el tratamiento o el encargado del Tratamiento, de conformidad con lo previsto
              en el artículo 10 de la Ley 1581 de 2012.
            </li>


            <li>Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud,
              respecto del uso que le ha dado a sus datos personales.
            </li>


            <li>Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los
              principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando
              la Superintendencia de Industria y Comercio haya determinado que en el tratamiento el Responsable o
              Encargado han incurrido en conductas contrarias a la Ley y a la Constitución.
            </li>


            <li>Acceder de forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</li>
          </ul>
          <p className={classes.text}>
            Sin embargo, al abstenerse de proporcionar datos personales cuando le sean solicitados, usted no podrá
            beneficiarse de la plataforma Houshares, ni podremos proporcionarle los servicios de Houshares.
          </p>
          <p className={classes.text}>
            6. Protección de los Datos Personales
          </p>
          <ul>
            <li>Mantenemos salvaguardas administrativas, técnicas y físicas para proteger datos personales contra
              cualquier: destrucción accidental o ilícita, pérdida accidental, alteración no autorizada, revelación o
              acceso no autorizada, uso indebido y otras formas ilícitas de uso/tratamiento/procesamiento de datos
              personales en posesión nuestra. Limitamos el acceso a sus datos personales solamente a aquellos empleados
              que necesiten usarlos para brindarle nuestros productos o servicios.
            </li>

            <li>Almacenaremos su información personal únicamente durante el tiempo que sea necesario a fin de cumplir
              con la(s) finalidad(es) para las que la información personal hubiese sido recolectada, salvo que la ley
              aplicable disponga algo contrario. Tomamos medidas necesarias para la destrucción, disociación o
              anonimización permanente de información personal cuando así lo requiera la ley o si la información
              personal deja de ser requerida para la(s) finalidad(es) para la(s) que haya sido recabada.
            </li>
          </ul>
          <p className={classes.text}>
            7. Transmisiones de Datos Internacionales
          </p>
          <p className={classes.text}>
            Podremos transferir la información personal a otros países además del país en el que se recopiló
            originalmente la información. Esos países podrán no tener las mismas leyes de protección de datos que las
            del país en el cual usted proporcionó la información inicialmente. Al transferir su información personal a
            otros países, protegeremos dicha información según se describe en esta Política de Privacidad.
          </p>
          <p className={classes.text}>
            8. Actualizaciones de nuestra Política de Privacidad
          </p>
          <p className={classes.text}>
            Houshares. se reserva el derecho de realizar cambios en esta Política de Privacidad de información en
            cualquier momento y sin previo aviso. Los cambios efectuados serán notificados mediante un aviso a los
            Titulares por cualquiera de los medios utilizados por Houshares para mantenerse en contacto con sus clientes
            actuales o potenciales, especialistas, proveedores, contratista, subcontratistas y trabajadores.
          </p>
          <p className={classes.text}>
            Esta Política de Privacidad estará siempre publicada en esta página
          </p>
          <p className={classes.text}>
            9. Ejercicio de los derechos de los Titulares
          </p>
          <p className={classes.text}>
            Para el ejercicio de los derechos a conocer, actualizar, rectificar y suprimir información y para revocar la
            autorización, los titulares de la información pueden hacer sus solicitudes dirigidas a través de los
            siguientes canales:
          </p>
          <p className={classes.text}>
            info@houshares.com
          </p>
          <p className={classes.text}>
            Los derechos de los Titulares podrán ejercerse por las siguientes personas, acreditando la calidad
            correspondiente:
          </p>
          <ul>
            <li>1. Titular de los Datos Personales.</li>

            <li>2. Sus causahabientes.</li>

            <li>3. Su Representante y/o apoderado.</li>
          </ul>
          <p className={classes.text}>
            Para ejercer cualquier derecho de protección de datos requerirá que la misma cumpla con los requisitos
            establecidos por la ley colombiana aplicable. En todos los casos, el solicitante acreditará su condición de
            titular de los datos personales o alternativamente, su condición de representante con respecto al titular de
            los datos, según corresponda.
          </p>
          <p className={classes.text}>
            Podrá recibir notificación sobre nuestra decisión dentro de un plazo de 10 (diez) días hábiles contados a
            partir de la fecha de recepción de su solicitud por Houshares.
          </p>
        </div>
      </div>
    </PageWeb>
  );
});
