import {Button} from "react-bootstrap";
import React from "react";
import useStyles from "./styles";

type InputProps = {
    className?: string
    startFunction: () => void,
    bold: boolean
};

export const ButtonDark: React.FC<InputProps> = React.forwardRef((props,ref) => {

    let classes = useStyles();

    return (
            <Button
                className={[props.className?props.className:"", props.bold ? classes.btnDarkBold : classes.btnDark].join(" ")}
                onClick={props.startFunction}
            >
                {props.children}
            </Button>
    )
});

export default ButtonDark;