import React from 'react';
import {observer} from "mobx-react-lite";
import {Helmet} from 'react-helmet';
import {useRouteMatch} from "react-router-dom";
import {getTitlePag} from "../../helpers";

type CustomTitileProps = {
  title?: string
};

export const TituloCustom: React.FC<CustomTitileProps> = observer((props,ref) => {
  return <Helmet>
            <title>{props.title ? ("Houshares | " + props.title) : getTitlePag(useRouteMatch)}</title>
        </Helmet>
});

export default TituloCustom;
