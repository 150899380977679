import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import DatePicker, {registerLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es';
import esPhone from 'react-phone-input-2/lang/es.json'
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import InputMask from "react-input-mask";
import {fecha_fmt} from "../../helpers";
import useStyles from "./styles";
import {COLORS} from "../../constants/constants";
import { BsCalendar4 } from "react-icons/bs";

registerLocale('es', es)
const moment = require('moment-timezone')
const numeral = require("numeral")
type AdminItemFieldProps = {
  titulo: string
  tipo: string
  campo: string
  valor: any
  error?: any
  set_dato: any
  restriccion?: boolean | null
  deshabilitado?: boolean | null
  horizontal?: boolean | null
  textAreaRows?: number
  help_text?: string
  opciones_select?: any
  select_placeholder?: string
  select_no_options_text?: string
  select_multiple?: boolean
  select_onchange?: any
  numero_minimo?: number
  extra_css?: string
  className?: string
  set_dato_blur?: any
  campo_blur?: boolean
  verificar_firebase_habilitado?: boolean
  verificar_firebase_func?: any
  verificar_firebase_error?: string
  verificar_firebase_mostrar?: boolean
  verificar_firebase_reset?: any
  verificar_boton_clase?: string
  set_dato_registro_store?: boolean
};

// Tipos de formatos disponibles - solo algunos están disponibles en formato horizontal
// tipo: texto - sin formato especifico
// tipo: numero - le agrega separador de miles
// tipo: decimales - le agrega separador de decimales
// tipo: fecha - agrega un datepicker
// tipo: telefono - agrega un phoneinput
// tipo: boolean - agrega un checkbox
// tipo: textarea - agrega un text area y se le puede pasar una cantidad de rows con la propiedad textAreaRows
// tipo: boolean - agrega un checkbox
// tipo: email - formato email
// tipo: password - formato pasword
// tipo: ckeditor-mini - ckeditor con 3 propiedades
// tipo: ckeditor-full - ckeditor con todas las propiedades por defecto

//numero_minimo: Es el valor mínimo aceptado por el input

// campo_blur: Campos que disparan el evento onBlur
// set_dato_blur: Función que se dispara al hacer onBlur sobre el campo marcado

export const AdminItemField: React.FC<AdminItemFieldProps> = observer((props) => {
  const [startDate, setStartDate] = useState(new Date());
  const classes = useStyles();
  const textAreaRows = props.textAreaRows || 2

  if (props.restriccion === false) {
    return null
  }

  let deshabilitado = false
  if (props.deshabilitado !== undefined && props.deshabilitado !== null && !props.deshabilitado) {
    deshabilitado = true
  }

  const setDato = (campo: string, valor: any, extra_data?: any) => {
    props.set_dato(campo, valor, extra_data)
  }

  const setDatoBlur = (campo: string) => {
    props.set_dato_blur(campo)
  }

  const selectOnChange = (val: any) => {
    if (val) {
      setDato(props.campo, val.value)
    }else{
      setDato(props.campo, null)
    }
    if(props.select_onchange)
      props.select_onchange(val)
  }
  if (props.horizontal !== undefined && props.horizontal === true) {
    return (
      <div className={props.className}>
          <label className={classes.label} style={{alignSelf: "center", paddingRight: "20px"}}>{props.titulo}</label>
        <div>
          {props.tipo === 'numero' &&
            <input
              autoComplete={'off'}
              disabled={deshabilitado}
              type={'text'}
              id={props.campo}
              value={numeral(props.valor).format("0,0")}
              className={['form-control input-gray-style', classes.inputModal].join(" ")}
              onBlur={
                evt => {
                  let numero = numeral(evt.target.value).value()
                  if (props.numero_minimo !== undefined && props.numero_minimo > numero) {
                    numero = props.numero_minimo
                    setDato(props.campo, numero)
                  }
                }
              }
              onChange={evt => {
                let numero = numeral(evt.target.value).value()
                setDato(props.campo, numero)
              }}
            />
          }
          {props.tipo === 'texto' &&
            <input
              autoComplete={'off'}
              disabled={deshabilitado}
              type={props.tipo}
              className={'form-control'}
              id={props.campo}
              value={(props.valor !== undefined && props.valor !== null) ? props.valor + '' : ''}
              onChange={evt => setDato(props.campo, evt.target.value)}
            />
          }
          {props.tipo === 'fecha' &&
            <div className={'position-relative'}>
            <DatePicker
              selected={props.valor ? moment(props.valor, 'YYYY-MM-DD').toDate() : new Date()}
              dateFormat="dd/MM/yyyy"
              onChange={date => setDato(props.campo, date)}
              className={'form-control input-gray-style mt-2'}
            />
            <div
            className={'position-absolute'}
            style={{right: 10, top: 12, border: 0, backgroundColor: 'transparent', pointerEvents: 'none'}}
            >
            <BsCalendar4 style={{fontSize: 20, color: COLORS.white}}/>
            </div>
            </div>
          }
          {props.tipo === 'telefono' &&
            <PhoneInput
              disabled={deshabilitado}
              country={'py'}
              localization={esPhone}
              value={props.valor ? props.valor.toString() : ''}
              placeholder={'595 981 123 456'}
              onChange={phone => setDato(props.campo, phone)}
            />
          }
          {props.tipo === 'boolean' &&
            <div>
              <input
                autoComplete={'off'}
                disabled={deshabilitado}
                type={'checkbox'}
                id={props.campo}
                checked={props.valor === true}
                onClick={() => {
                  setDato(props.campo, !props.valor)
                }}
              />
              <label id={props.campo} onClick={() => {
                setDato(props.campo, !props.valor)
              }}>Aprobado</label>
            </div>
          }
        </div>
        {props.help_text !== undefined && <small>{props.help_text}</small>}
        {props.error && <div className={'text-danger'}>{props.error}</div>}
      </div>
    )
  } else {
    return (
      <div className={props.className}>
        <div>
          <label className={classes.label}>{props.titulo}</label>
        </div>
        <div style={{marginTop: "10px"}}>
          {props.tipo === 'numero' &&
            <input
              autoComplete={'off'}
              disabled={deshabilitado}
              type={'text'}
              className={'form-control input-gray-style'}
              id={props.campo}
              value={props.valor !== '' ? numeral(props.valor).format("0,0") : ''}
              onBlur={
                evt => {
                  let numero = numeral(evt.target.value).value()
                  if (props.numero_minimo !== undefined && props.numero_minimo > numero) {
                    numero = props.numero_minimo
                    setDato(props.campo, numero)
                  }
                }
              }
              onChange={evt => {
                let numero = numeral(evt.target.value).value()
                setDato(props.campo, numero)
              }}
            />
          }
          {props.tipo === 'decimales' &&
            <input
              autoComplete={'off'}
              disabled={deshabilitado}
              type={'text'}
              className={'form-control'}
              id={props.campo}
              value={props.valor}
              onBlur={evt => {
                setDato(props.campo, numeral(evt.target.value.replace('.',',')).format("0.00"))
              }}
              onChange={evt => {
                setDato(props.campo, evt.target.value)
              }}
            />
          }
          {(props.tipo === 'texto' || props.tipo === 'email' || props.tipo === 'password') &&
            <input
              autoComplete={'off'}
              disabled={deshabilitado}
              type={props.tipo}
              className={'form-control'}
              id={props.campo}
              value={(props.valor !== undefined && props.valor !== null) ? props.valor + '' : ''}
              onChange={evt => setDato(props.campo, evt.target.value)}
              onBlur={() => {
                if (props.campo_blur !== undefined && props.campo_blur) {
                  setDatoBlur(props.campo)
                }
              }}
            />
          }
          {props.tipo === 'textarea' &&
            <textarea
              disabled={deshabilitado}
              className={'form-control'}
              rows={textAreaRows}
              id={props.campo}
              onChange={evt => setDato(props.campo, evt.target.value)}
              value={props.valor !== undefined ? props.valor + '' : ''}
            />
          }
          {props.tipo === 'fecha' && <div className={'position-relative'}>
            <DatePicker
              selected={props.valor ? moment(props.valor, 'YYYY-MM-DD').toDate() : new Date()}
              dateFormat="dd/MM/yyyy"
              onChange={date => setDato(props.campo, date)}
              className={'form-control input-gray-style'}
            />
            <div
              className={'position-absolute'}
              style={{right: 10, top: 5, border: 0, backgroundColor: 'transparent', pointerEvents: 'none'}}
            >
              <BsCalendar4 style={{fontSize: 20, color: COLORS.white}}/>
            </div>
          </div>
          }
          {props.tipo === 'telefono' &&
            <div className={'row'}>
              <div className={'col-8'}>
                <InputMask
                  disabled={deshabilitado}
                  className={'form-control'}
                  mask="0\999-999-999"
                  onChange={(date: any) => {
                    setDato(props.campo, date.target.value)
                  }}
                  value={props.valor}
                />
              </div>
            </div>
          }
          {props.tipo === 'select2' && props.opciones_select !== undefined &&
            <div>
              <Select
                value={props.opciones_select.filter( (opc: any) => opc.value == props.valor)[0]}
                onChange={selectOnChange}
                options={props.opciones_select}
                placeholder={props.select_placeholder !== undefined ? props.select_placeholder : ('Seleccionar ' + props.titulo.toLocaleLowerCase())}
                isClearable
                isMulti={props.select_multiple}
                noOptionsMessage={props.select_no_options_text ? (obj) => props.select_no_options_text || null : undefined}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#303AB2',
                    neutral50: 'black',
                  },
                })}
              />
            </div>
          }
          {props.tipo === 'select2async' && props.opciones_select !== undefined &&
            <div>
              <AsyncSelect
                value={props.valor}
                onChange={selectOnChange}
                loadOptions={props.opciones_select}
                defaultOptions={props.opciones_select(null)}
                placeholder={props.select_placeholder !== undefined ? props.select_placeholder : ('Seleccionar ' + props.titulo.toLocaleLowerCase())}
                isClearable
                noOptionsMessage={props.select_no_options_text ? (obj) => props.select_no_options_text || null : undefined}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#303AB2',
                    neutral50: 'black',
                  },
                })}
                onBlur={() => {
                  if (props.campo_blur !== undefined && props.campo_blur) {
                    setDatoBlur(props.campo)
                  }
                }}
              />
            </div>
          }
          {props.tipo === 'boolean' &&
            <div>
              <input
                autoComplete={'off'}
                disabled={deshabilitado}
                type={'checkbox'}
                id={props.campo}
                checked={props.valor === true}
                onClick={() => {
                  setDato(props.campo, !props.valor)
                }}
              />
              <label id={props.campo} onClick={() => {
                setDato(props.campo, !props.valor)
              }}>{props.titulo}</label>
            </div>
          }
        </div>
        {props.help_text !== undefined && <small>{props.help_text}</small>}
        {props.error && <div className={'text-danger'}>{props.error}</div>}
      </div>
    )
  }


});

export default AdminItemField;
