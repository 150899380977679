import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../constants/constants";

export default makeStyles({

    labelForm: {
        color: COLORS.white,
        marginBottom: "8px",
    },

    labelAdminFormModal: {
        alignSelf: "center",
        color: COLORS.white,
        marginBottom: "8px",
        marginRight: "20px"
    },

});