import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import styles from "./AccountInfo.module.css"
import PageWebHalf from "../../../components/PageWeb/PageWebHalf";
import {Button, CloseButton, Col, Modal, ModalBody, ModalHeader, Row, Spinner} from "react-bootstrap";
import {BsCamera, BsCheck, BsFiles,} from "react-icons/bs";
import {IoIosArrowBack} from "react-icons/io";
import {COLOMBIA_ID, COLORS, optionsArray, ROUTES} from "../../../constants/constants";
import {useStores} from "../../../models/root-store/root-store-context";
import * as Yup from "yup";
import {Field, Form, Formik, FormikProps} from "formik";
import InputForm from "../../../components/Inputs/InputForm";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {DetailViewProps, OptionPlaceType} from "../../../constants/types";
import ReactSelect from "../../../components/Select/ReactSelect";
import InputPhone from "../../../components/Inputs/InputPhone";

import 'react-phone-input-2/lib/style.css'
import ReactSelectString from "../../../components/Select/ReactSelectString";
import {inHash, outHash, useClearStore} from "../../../helpers";
import Logo from "../../../components/Logo/Logo";
import AdminItemField from "../../../components/Inputs/AdminItemField";
import moment from "moment-timezone";

interface IAccountForm {
  firstName: string,
  secondName: string,
  firstSurname: string,
  secondSurname: string,
  number: string,
  idDate: Date,
  // phone: string,
  email: string
}

interface IProfile {
  id: number,
  firstName: string,
  secondName: string,
  firstSurname: string,
  secondSurname: string,
  number: string,
  idDate: Date,
  idPlace: number,
  idType: string,
  phone: string,
  email: string,
  profilePicture: string
}

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required'),
  firstSurname: Yup.string()
    .required('Required'),
  number: Yup.string().matches(/^(?=.*\d)(?=.*[1-9]).{1,12}$/, "Only numbers are allowed for this field")
    .required('Required'),
  idDate: Yup.date().default(undefined)
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .typeError("Invalid Date")
    .required('Required'),
  email: Yup.string().email().required('Required'),
})

//this is Application part 1
export const AccountInfo: React.FC<DetailViewProps> = observer(({history, match}) => {
  const rootStore = useStores()
  const clearStore = useClearStore()
  const {loginStore} = rootStore

  let idProperty = "";

  if (Object.keys(match.params).length > 0) {
    idProperty = outHash(match.params.id);
  }
  const [Loading, setLoading] = useState<boolean>(false)
  const [showSelectFile, setShowSelectFile] = useState<boolean>(false)
  const [idType, setIdType] = useState<string>("CC")
  const [phone, setPhone] = useState<string>("")
  const [idPlace, setIdPlace] = useState<number>(COLOMBIA_ID);
  const [showVerifiedEmailWarning, setShowVerifiedEmailWarning] = useState(!loginStore.verified_email);
  const [isVerified] = useState<boolean | null>(loginStore.verified_email)
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");

  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [preview, setPreview] = useState<any>()
  const [delay, setDelay] = useState(0);

  const [data, setData] = useState<IProfile>({
    id: -1,
    email: "",
    firstName: "",
    firstSurname: "",
    idDate: new Date(),
    number: "0",
    idPlace: 0,
    idType: "",
    phone: "",
    secondName: "",
    secondSurname: "",
    profilePicture: "",
  });
  const [countries, setCountries] = useState<Array<OptionPlaceType>>([]);

  const getProfileData = () => {

    rootStore.environment.api.getProfile().then((result: any) => {
      let dataTmp = data;
      dataTmp.id = 0;
      if (result.kind === "ok") {
        dataTmp.id = result.data.id;
        dataTmp.firstName = result.data.first_name;
        dataTmp.secondName = result.data.second_name;
        dataTmp.firstSurname = result.data.last_name;
        dataTmp.secondSurname = result.data.second_last_name;
        dataTmp.number = result.data.id_number;
        dataTmp.idDate = result.data.id_date_of_issue;
        dataTmp.idPlace = result.data.id_place_of_issue;
        dataTmp.idType = result.data.id_type;
        dataTmp.phone = result.data.phone_number;
        dataTmp.email = result.data.email;
        dataTmp.profilePicture = result.data.profile_picture
        setIdType(result.data.id_type)
        setPhone(result.data.phone_number)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else if (result.kind === "unauthorized") {
          setShow(true)
          setMessage("Session expired")
          setVariantMessage("danger")
          setTimeout(() => {
            clearStore();
            history.push(ROUTES.LOGIN)
          }, 1000)
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
      setData(dataTmp)
    })
  }

  const getCountries = () => {
    rootStore.environment.api.getCountries().then((result: any) => {
      let arrayTmp: { value: any; label: any; }[] = []
      if (result.kind === "ok") {
        result.data.map((item: any, idx: number) => {
          if (item.id === COLOMBIA_ID) {
            arrayTmp.push({value: item.id, label: item.name})
          }
        })
        setCountries(arrayTmp)

        // setShow(true)
        // setMessage("result:" + result)
        // setVariantMessage("success")
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const saveProfileData = (email: string, firstName: string, secondName: string, lastName: string, secondLastName: string,
                           idType: string, number: string, idDate: Date, idPlace: number, phone: string,
                           selectedFile: File, setSubmitting: (arg0: boolean) => void) => {
    const dataStored = {...data}
    const cleanData = () => {
      let data: any = {}
      let keys: string[] = []
      //data
      if (secondName !== "") {
        data["second_name"] = secondName
      }
      if (secondLastName !== "") {
        data["second_last_name"] = secondLastName
      }
      if (selectedFile !== null) {
        data["profile_picture"] = selectedFile
      }
      if (idPlace !== 0) {
        data["id_place_of_issue"] = idPlace
      }
      data["first_name"] = firstName
      data["last_name"] = lastName
      data["id_type"] = idType
      data["id_number"] = number
      data["id_date_of_issue"] = moment(dataStored['idDate']).format('YYYY-MM-DD')
      data["phone_number"] = "+" + phone
      data["email"] = email

      //keys
      if (secondName !== "") {
        keys.push("second_name")
      }
      if (secondLastName !== "") {
        keys.push("second_last_name")
      }
      if (idPlace !== 0) {
        keys.push("id_place_of_issue")
      }
      if (selectedFile !== null) {
        keys.push("profile_picture")
      }
      keys.push("first_name")
      keys.push("last_name")
      keys.push("id_type")
      keys.push("id_number")
      keys.push("id_date_of_issue")
      keys.push("phone_number")
      keys.push("email")
      return {data: data, keys: keys}
    }

    const cleanedData = cleanData()
    rootStore.environment.api.updateProfile(cleanedData.data, cleanedData.keys).then((result: any) => {
      setSubmitting(false)
      setLoading(false)
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Data successfully saved")
        setVariantMessage("success")

        loginStore.setProfile(result.response)

        if (idProperty !== null && idProperty !== "") {
          history.push(ROUTES.APPLICATION_PROPERTY(inHash((idProperty).toString())))
        } else {
          history.push(ROUTES.HOME);
        }

      } else {
        if (!isVerified) {
          setShowVerifiedEmailWarning(true)
        }
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else if (result.errors.phone_number) {
            setShow(true)
            setMessage(result.errors.phone_number)
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    // if (isVerified) {
    getProfileData()
    // }
    getCountries()
  }, []);


  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const closeMessage = () => {
    setShow(false)
  }

  const closeMessageVerifiedEmail = () => {
    setShowVerifiedEmailWarning(false)
  }

  const onSelectFile = (e: any) => {
    setShowSelectFile(false)
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    setSelectedFile(e.target.files[0])
  }

  const handleOpenCameraModal = () => {
    setShowSelectFile(true);
  };

  const handleClose = () => setShowSelectFile(false);

  const saveAccountInfo = (values: IAccountForm, setSubmitting: any) => {
    setLoading(true)
    saveProfileData(values.email, values.firstName, values.secondName, values.firstSurname, values.secondSurname,
      idType, values.number, values.idDate, idPlace, phone, selectedFile, setSubmitting);
  }

  const setDato = (campo: string, valor: string) => {
    let datos = {...data}
    // @ts-ignore
    datos[campo] = valor
    setData(datos)
  }

  const setDatoInput = (campo: string, data: any) => {
    setDato(campo, data)
  }

  return (
    <PageWebHalf login_required={true}>
      <div className={'content-container row mx-0'} style={{color: COLORS.white}}>
        <div style={{paddingTop: "40px"}}><Logo short={false} light={true}/></div>
        <div className={"container row"} style={{position: "relative"}}>
          <div style={{textAlign: "center"}}>
            {(selectedFile && preview !== undefined) &&
              <img src={preview} alt="Profile image"
                   style={{
                     width: "300px",
                     height: "300px",
                     borderRadius: "150px",
                     marginTop: "25px",
                     marginLeft: "auto",
                     marginRight: "auto",
                     objectFit: "contain",
                     backgroundColor: "black"
                   }}
              />
            }
            {(!selectedFile && preview === undefined && (data.profilePicture === "" || data.profilePicture === null)) &&
              <div style={{
                width: "300px",
                height: "300px",
                borderRadius: "150px",
                backgroundColor: "black",
                marginTop: "25px",
                marginLeft: "auto",
                marginRight: "auto"
              }}></div>
            }

            {data.profilePicture !== "" && data.profilePicture !== null &&
              !selectedFile && preview === undefined &&
              <img src={data.profilePicture} alt="Profile image"
                   style={{
                     width: "300px",
                     height: "300px",
                     borderRadius: "150px",
                     marginTop: "25px",
                     marginLeft: "auto",
                     marginRight: "auto",
                     objectFit: "contain",
                     backgroundColor: "black"
                   }}
              />}
            <div className={styles.divBtn}>
              <Button
                className={styles['button-container'] + ' d-flex justify-content-center align-items-center'}
                onClick={handleOpenCameraModal}>
                <label style={{cursor: "pointer"}}><BsCamera style={{color: COLORS.black}}/>
                </label>
              </Button>
            </div>
          </div>

        </div>
        {(data.id != -1 || !isVerified) &&
          <Formik
            initialValues={{
              firstName: data.firstName || '',
              secondName: data.secondName || '',
              firstSurname: data.firstSurname || '',
              secondSurname: data.secondSurname || '',
              number: data.number || "0",
              idDate: data.idDate || new Date(),
              idPlace: data.idPlace || 0,
              email: data.email || ''
            }}
            onSubmit={
              (values: IAccountForm, {setSubmitting}: any) => {
                saveAccountInfo(values, setSubmitting)
              }
            }
            validationSchema={schema}
          >
            {(props: FormikProps<IAccountForm>) => {
              const {
                // values,
                // touched,
                // errors,
                // handleBlur,
                // handleChange,
                isSubmitting,
              } = props
              return (
                <Form>
                  <div className={"row"}>
                    <Field name={"firstName"} label={"First name"} type={""}
                           placeholder={""} className={"col-lg-3 col-md-6 col-6 mt-2"}
                           component={InputForm}/>
                    <Field name={"secondName"} label={"Second name"} type={""}
                           placeholder={""} className={"col-lg-3 col-md-6 col-6 mt-2"}
                           component={InputForm}/>
                    <Field name={"firstSurname"} label={"First surname"} type={""}
                           placeholder={""} className={"col-lg-3 col-md-6 col-6 mt-2"}
                           component={InputForm}/>
                    <Field name={"secondSurname"} label={"Second surname"} type={""}
                           placeholder={""} className={"col-lg-3 col-md-6 col-6 mt-2"}
                           component={InputForm}/>
                  </div>
                  <Row>
                    <ReactSelectString label={"ID type"} option={idType}
                                       options={optionsArray}
                                       setValue={setIdType}
                                       placeholder={"ID type"}
                                       className={"col-lg-3 col-md-6 col-6 mt-2"}/>
                    <Field name={"number"} label={"Number"} type={""}
                           placeholder={""} className={"col-lg-3 col-md-6 col-6 mt-2"}
                           component={InputForm}/>
                    <AdminItemField
                      titulo={'Date of issue'}
                      tipo={'fecha'}
                      campo={'idDate'}
                      valor={data['idDate']}
                      set_dato={setDatoInput}
                      className={"col-lg-3 col-md-6 col-6 mt-2"}
                      horizontal={false}
                    />
                    <ReactSelect label={"Place of issue"} option={idPlace}
                                 options={countries}
                                 setValue={setIdPlace}
                                 placeholder={"Country of birth"}
                                 className={"col-lg-3 col-md-6 col-6 mt-2"}/>
                  </Row>
                  <Row>
                    <InputPhone label="Phone number" setValue={setPhone} value={phone}
                                required={true} className={"col-lg-6 col-sm-12 mt-2"}/>
                    <Field name={"email"} label={"email"} type={"email"}
                           placeholder={""} className={"col-lg-6 col-sm-12 mt-2"}
                           disabled={true}
                           component={InputForm}/>
                  </Row>
                  {/*TODO: resend verification mail with same link*/}
                  {/*<Row>*/}
                  {/*    <div style={{marginTop: "20px"}}>*/}
                  {/*        <span>Verified email:</span>*/}
                  {/*        {isVerified ?*/}
                  {/*            <BsFillCheckCircleFill style={{color: COLORS.green, marginLeft: "10px"}}/> :*/}
                  {/*            <IoMdCloseCircle style={{color: "red", marginLeft: "10px"}}/>*/}
                  {/*        }*/}
                  {/*        {!isVerified &&*/}
                  {/*            <div style={{marginLeft: "10px", display: "inline"}}>*/}
                  {/*                <ButtonSquare label={"Verify email"} bold={false}*/}
                  {/*                              startFunction={sendVerificationLink}*/}
                  {/*                />*/}
                  {/*            </div>*/}
                  {/*        }*/}
                  {/*    </div>*/}
                  {/*</Row>*/}
                  <Row className="mb-3" style={{marginTop: "20px", textAlign: "center"}}>
                      <Col>
                          <Col>
                              <Button style={{
                                  backgroundColor: COLORS.green, borderColor: COLORS.green,
                                  borderRadius: 35, width: 70, height: 70
                              }} onClick={history.goBack}>
                                  <IoIosArrowBack
                                    style={{fontSize: 50, marginLeft: "-4px"}}/>
                              </Button>
                          </Col>
                          <Col><label style={{color: COLORS.white}}>Back</label></Col>
                      </Col>
                    <Col>
                      <Col>
                        <Button style={{
                          backgroundColor: COLORS.green, borderColor: COLORS.green,
                          borderRadius: 35, width: 70, height: 70
                        }}
                                disabled={isSubmitting || Loading}
                                type="submit"
                        >
                          {Loading &&
                            <Spinner animation="border" variant="light" size={'sm'}/>}
                          {!Loading && <BsCheck
                            style={{fontSize: 50, marginLeft: "-2px"}}/>}
                        </Button>
                      </Col>

                      <Col><label style={{color: COLORS.white}}>Save</label></Col>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>}
      </div>

      <Modal show={showSelectFile}>
        <ModalHeader
          style={{backgroundColor: COLORS.gray, color: COLORS.white, border: COLORS.gray}}>
          <div style={{textAlign: "center", fontWeight: "bold"}}>Upload photo</div>
          <CloseButton variant="white" onClick={handleClose}/>
        </ModalHeader>
        <ModalBody style={{backgroundColor: COLORS.gray, color: COLORS.white}}>
          <Row>
            <Col style={{textAlign: "center"}}>
              <Col>
                <Button style={{
                  backgroundColor: COLORS.green,
                  borderColor: COLORS.green,
                  borderRadius: "50px",
                  width: "50px",
                  height: "50px",
                  fontSize: "X-large",
                  marginBottom: "6px"
                }}>
                  <label><BsCamera style={{color: COLORS.white}}/>
                    <input
                      id="file"
                      name="archivos"
                      type="file"
                      hidden
                      onChange={onSelectFile}
                      accept="image/*"
                      capture={"user"}
                    />
                  </label>
                </Button>
              </Col>
              <Col>
                <label>Take photo</label>
              </Col>
            </Col>
            <Col style={{textAlign: "center"}}>
              <Col>
                <Button style={{
                  backgroundColor: COLORS.green,
                  borderColor: COLORS.green,
                  borderRadius: "50px",
                  width: "50px",
                  height: "50px",
                  fontSize: "X-large",
                  marginBottom: "6px"
                }}>
                  <label><BsFiles style={{color: COLORS.white}}/>
                    <input
                      id="file"
                      name="archivos"
                      type="file"
                      hidden
                      onChange={onSelectFile}
                      accept="image/*"
                    />
                  </label>
                </Button>
              </Col>
              <Col>
                <label> Upload image</label>
              </Col>
            </Col>
          </Row>
          <div style={{textAlign: "center", marginTop: "30px"}}>
            <Button style={{
              backgroundColor: COLORS.green, borderColor: COLORS.green
            }} onClick={handleClose}>Cancel</Button>
          </div>

        </ModalBody>
      </Modal>

      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={delay}/>
      {!loginStore.verified_email &&
        <ToastComponent message={`To continue, you need to verify your email, please go to your mail inbox. 
                    When you signed up, we sent you a verification link`}
                        close={closeMessageVerifiedEmail} show={showVerifiedEmailWarning} variant={"warning"}
                        delay={8000}/>
      }
    </PageWebHalf>
  );
});

export default AccountInfo;
