import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../../constants/constants";

export default makeStyles((theme) => ({
    logoShort: {
        textAlign: "center",
        marginTop: "25px"
    },
    title: {
        color: COLORS.white,
        fontWeight: "bold",
        marginTop: "10%"
    },
    btnOption: {
        backgroundColor: COLORS.green,
        borderRadius: "25px",
        borderColor: "transparent",
        width: "50px",
        height: "50px",
        marginRight: "10px",
        "&:hover, &:focus": {
            backgroundColor: COLORS.darkgreen,
            borderColor: "transparent",
        }
    },

    btnMenu: {
        backgroundColor: COLORS.green,
        borderRadius: "25px",
        borderColor: "transparent",
        marginTop: "20px",
        fontWeight: "bold",
        width: "300px",
        textAlign: "start",
        "&:hover, &:focus": {
            backgroundColor: COLORS.darkgreen,
            borderColor: "transparent",
        }
    },
    modalHeader: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        border: COLORS.gray,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        justifyContent: "center"
    },
    modalBody: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        textAlign: "center"
    },


}));
