import React from "react";
import {COLORS} from "../../constants/constants";
import useStyles from "./styles";
import Select from "react-select";
import {OptionNumber} from "../../constants/types";

type InputProps = {
    label: string,
    option: number,
    options: Array<OptionNumber>,
    setValue: (arg: number) => void,
    placeholder: string,
    className: string,
    style?:string
};

const colourStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
        ...base,
        background: COLORS.gray,
        borderRadius: "12px",
        borderColor: "white",
        boxShadow: state.isFocused ? null : null,
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: COLORS.white
    }),
    option: (styles: any, { isFocused}: any) => {
        return {
            ...styles,
            backgroundColor: isFocused ? COLORS.lightGray : null,
            color: COLORS.black,
            zIndex: 10
        };
    },
    menu: (provided: any) => ({
        ...provided,
        zIndex: 3,
    }),
    input: (styles: any) => ({
        ...styles,
        width: "174px",
    }),
};

export const ReactSelectAdminFormModal: React.FC<InputProps> = React.forwardRef((props,ref) => {

    let classes = useStyles();

    return (
        <div className={[props.className, "d-flex"].join(" ")}>
            <label className={classes.labelAdminFormModal}>{props.label}</label>
            <Select options={props.options} onChange={(e)=>props.setValue(e?e.value:0)}
                    value={props.options.filter(obj => obj.value === props.option)}
                    placeholder={props.placeholder}
                    maxMenuHeight={180}
                    styles={colourStyles}
            />
        </div>
    )
});

export default ReactSelectAdminFormModal;