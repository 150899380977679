import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import useStyles from "../styles"
import {COLORS, ROUTES_ADMIN, space} from "../../../constants/constants";
import AdminSearch from "../../../components/AdminSearch/AdminSearch";
import {useStores} from "../../../models/root-store/root-store-context";
import AdminPagination from "../../../components/AdminPagination/AdminPagination";
import defaultImage from "../../../assets/images/default_Image.png"
import {BiMessageError} from "react-icons/bi";
import {inHash, plata_fmt} from "../../../helpers";
import {BsFileEarmarkCheck} from "react-icons/bs";
import ButtonRound from "../../../components/Buttons/ButtonRound";
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";


export const PropertiesUnderContract: React.FC<RouteComponentProps> = observer(({history}) => {

  let classes = useStyles();

  const rootStore = useStores()
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [valuesToSearch, setValuesToSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [previousPage, setPreviousPage] = useState<string | null>("");
  const [nextPage, setNextPage] = useState<string | null>("");
  const [pathLastCalled, setPathLastCalled] = useState<string>("/admin/contracts/");
  const [offsetLastCalled, setOffsetLastCalled] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");
  let datosFake = [
    {
      id: 1,
      idHousner: 1,
      fullName: "Penélope Aguilera Sahagún",
      idProperty: 9,
      propertyName: "Nombre Casa 1",
      currentRent: 100000,
      amountPaidACTIS: 100000
      // lastPayment: formatDate(2022, 5, 15),
      // status: "regular"
    }, {
      id: 2,
      idHousner: 2,
      fullName: "Juan Perez",
      idProperty: 9,
      propertyName: "Nombre Casa 2",
      currentRent: 100000,
      amountPaidACTIS: 100000
      // lastPayment: formatDate(2021, 5, 15),
      // status: "delayed"
    },
    {
      id: 3,
      idHousner: 3,
      fullName: "Juan Perez",
      idProperty: 9,
      propertyName: "Nombre Casa 3",
      currentRent: 100000,
      amountPaidACTIS: 100000
      // lastPayment: formatDate(2022, 5, 15),
      // status: "regular"
    },
    {
      id: 5,
      idHousner: 4,
      fullName: "Penélope Aguilera Sahagún",
      idProperty: 9,
      propertyName: "Nombre Casa 1",
      currentRent: 100000,
      amountPaidACTIS: 100000
      // lastPayment: formatDate(2021, 5, 15),
      // status: "delayed"
    }, {
      id: 8,
      idHousner: 5,
      fullName: "Juan Perez",
      idProperty: 9,
      propertyName: "Nombre Casa 2",
      currentRent: 100000,
      amountPaidACTIS: 100000
      // lastPayment: formatDate(2021, 5, 15),
      // status: "delayed"
    }, {
      id: 9,
      idHousner: 6,
      fullName: "Juan Perez",
      idProperty: 9,
      propertyName: "Nombre Casa 1",
      currentRent: 100000,
      amountPaidACTIS: 100000
      // lastPayment: formatDate(2022, 5, 15),
      // status: "regular"
    }, {
      id: 10,
      idHousner: 7,
      fullName: "Penélope Aguilera Sahagún",
      idProperty: 9,
      propertyName: "Nombre Casa 1",
      currentRent: 100000,
      amountPaidACTIS: 100000
      // lastPayment: formatDate(2021, 5, 15),
      // status: "delayed"
    }, {
      id: 11,
      idHousner: 8,
      fullName: "Juan Perez",
      idProperty: 9,
      propertyName: "Nombre Casa 1",
      currentRent: 100000,
      amountPaidACTIS: 100000
      // lastPayment: formatDate(2022, 5, 15),
      // status: "regular"
    }, {
      id: 13,
      idHousner: 9,
      fullName: "Penélope Aguilera Sahagún",
      idProperty: 9,
      propertyName: "Nombre Casa 2",
      currentRent: 100000,
      amountPaidACTIS: 100000
      // lastPayment: formatDate(2021, 5, 15),
      // status: "delayed"
    }
  ]

  const setResultListToData = (result: any) => {
    let datos: any = [];
    result.data.results.map((resultItem: any) => {
      datos.push({
        id: resultItem.id,
        idHousner: resultItem.user_id,
        fullName: resultItem.full_name,
        propertyName: resultItem.property_name,
        propertyImage: resultItem.property_image,
        currentRent: resultItem.current_rent,
        amountPaidACTIS: resultItem.amount_paid_actis,
      })
    })
    setData(datos)
  }

  const getPropertiesUnderContract = (rows: number) => {
    setLoading(true)
    rootStore.environment.api.getContractList(rows).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    getPropertiesUnderContract(rowsPerPage)
  }, []);


  const search = (rows: number) => {
    rootStore.environment.api.getContractssSearch(valuesToSearch, rows).then((result: any) => {
      if (result.kind === "ok") {
        setResultListToData(result)
        setCurrentPage(1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
        setTotalPages(Math.ceil(result.data.count / rows))
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const getContractsPrevNextPage = (path: string, offset: number) => {
    setLoading(true)
    setPathLastCalled(path)
    setOffsetLastCalled(offset)
    rootStore.environment.api.getContractListPrevNextPage(path).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setCurrentPage(Math.floor(offset / rowsPerPage) + 1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const handlePrevPageClicked = () => {
    let from = previousPage?.substring(previousPage?.indexOf("offset") + 7);
    let path = previousPage?.substring(previousPage?.indexOf("v1") + 2);
    if (path !== undefined) {
      if (from !== undefined && !isNaN(parseInt(from))) {
        getContractsPrevNextPage(path, parseInt(from))
      } else {
        getContractsPrevNextPage(path, 1)
      }
    }
  }

  const handleNextPageClicked = () => {
    let from = nextPage?.substring(nextPage?.indexOf("offset") + 7);
    let path = nextPage?.substring(nextPage?.indexOf("v1") + 2);

    if (path !== undefined) {
      if (from !== undefined && !isNaN(parseInt(from))) {
        getContractsPrevNextPage(path, parseInt(from))
      } else {
        getContractsPrevNextPage(path, 1)
      }
    }
  }

  const handleRowsPerPageClicked = (rows: number) => {
    setRowsPerPage(rows)
    if (valuesToSearch !== "") {
      search(rows)
    } else {
      getPropertiesUnderContract(rows)
    }
  }

  const ContractItems = (currentItems: any) => {
    console.log('current item ', currentItems)
    return (
      <>
        {currentItems && currentItems.currentItems?.length > 0 &&
          <div style={{marginTop: "35px"}}>
            {currentItems.currentItems.map((item: any, idx: number) => {
              return (
                <div key={idx} className={["row", classes.list].join(" ")}>
                  <div className={"col-1"}>{item.id}</div>
                  <div className={"col-2"}>
                    <img
                      key={idx}
                      src={item.propertyImage ? item.propertyImage : defaultImage}
                      alt="Property"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        height: "80px",
                        width: "130px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div className={"col-1"}>{item.propertyName}</div>
                  <div className={["col-2 text-wrap ", classes.btnUser].join(" ")}
                       onClick={() => history.push(ROUTES_ADMIN.USER_INFO(inHash(item.idHousner)))}>{item.fullName}</div>
                  <div className={"col-2"}>{plata_fmt(item.currentRent)}</div>
                  <div className={"col-2"}>{plata_fmt(item.amountPaidACTIS)}</div>
                  <div className={"col-2"}>
                    <ButtonRound label={"Payment history"}
                                 startFunction={() => history.push(ROUTES_ADMIN.PROPERTIES_UNDER_CONTRACT_INFO(inHash(item.id)))}
                                 bold={false}/></div>
                </div>
              );
            })}

          </div>
        }
      </>
    );
  }

  return (
    <PageWebAdminDark>
      <div className={"row"}>
        <div className={"col-9"}
             style={{color: COLORS.white, fontSize: "xx-large"}}>
          <BiMessageError/> Approved accounts
        </div>
        <div className={"col-3"}
             style={{display: "flex", justifyContent: "center"}}>
          <AdminSearch valuesToSearch={valuesToSearch} setValuesToSearch={setValuesToSearch}
                       search={search} rowsPerPage={rowsPerPage}/>
        </div>
      </div>
      <div className={"row"} style={{marginTop: "20px"}}>
        <div className={"col-12"}
             style={{color: COLORS.white, fontSize: "x-large"}}>
          <BsFileEarmarkCheck/> Contracts
        </div>
      </div>
      {!Loading && data?.length === 0 &&
        <NoDataAdmin/>}
      {Loading &&
        <SpinnerLight/>
      }
      {!Loading && data && data?.length > 0 &&
        <div>
          <div className={["row", classes.listTittles].join(" ")}>
            <div className={"col-1"}>Key ID</div>
            <div className={"col-2"}>Image</div>
            <div className={"col-1"}>Name</div>
            <div className={"col-2"}>Assigned Housner</div>
            <div className={"col-2"}>Current rent</div>
            <div className={"col-2"}>Amount paid for ACTIS</div>
            <div className={"col-2"}>Action</div>
          </div>
          <ContractItems currentItems={data}/>
          <AdminPagination
            rowsPerPage={rowsPerPage}
            handleRowsPerPageClicked={handleRowsPerPageClicked}
            currentPage={currentPage}
            handleNextPageClicked={handleNextPageClicked}
            handlePrevPageClicked={handlePrevPageClicked}
            nextPage={nextPage}
            previousPage={previousPage}
            totalPages={totalPages}
          />
        </div>
      }
    </PageWebAdminDark>
  )
});

export default PropertiesUnderContract;
