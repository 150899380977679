import {Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from ".."
import {DEFAULT_API_CONFIG} from "../../services/api/api-config";
import {LoginStoreModel} from "../login-store/login-store";
import {State} from "../types/State";
import {ServiceOffered} from "../types/ServiceOffered";
import {ServiceProviderModel} from "../service-provider-store/service-provider-store";

/**
 * A RootStore model.
 */
// @ts-ignore
export const RootStoreModel = types.model("RootStore")
  .extend(withEnvironment)
  .props({
    esMovil: types.optional(types.boolean, false),
    tieneInternet: types.optional(types.boolean, true),
    terminos: types.optional(types.string, ''),
    moderadoPendiente: types.optional(types.string, ''),
    loginStore: types.optional(LoginStoreModel, {}),
    serviceProviderStore: types.optional(ServiceProviderModel, {}),
    states: types.array(State),
    services_offered: types.array(ServiceOffered),
    //
    // adminStore: types.optional(AdminStoreModel, {}),

  })
  .views(self => ({
    get isDebug() {
      return DEFAULT_API_CONFIG.isDebug
    },
  }))
  .actions(self => ({
    setStates(states: any) {
      self.states = states || []
    },
    setServicesOffered(services_offered: any) {
      self.services_offered = services_offered || []
    },
    reset() {
      self.loginStore.reset()
    }
  }))

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {
}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {
}
