import {Button} from "react-bootstrap";
import React from "react";
import useStyles from "./styles";

type InputProps = {
    label: string,
    startFunction: () => void,
    bold: boolean
};

export const ButtonSquare: React.FC<InputProps> = React.forwardRef((props,ref) => {

     let classes = useStyles();

    return (
        <Button
            className={props.bold?classes.btnStyleBold:classes.btnStyle}
            onClick={props.startFunction}
        >
            {props.label}
            {props.children}
        </Button>
    )
});

export default ButtonSquare;
