import {cast, Instance, SnapshotOut, types} from "mobx-state-tree"
import {Specialty} from "../types/Specialty";
import {ServiceOffered} from "../types/ServiceOffered";
import {GreenCertification} from "../types/GreenCertification";
import {QuickFact} from "../types/QuickFact";
import {ServceProviderProject} from "../types/ServceProviderProject";
import {Insurance} from "../types/Insurance";

/**
 * Model description here for TypeScript hints.
 */
export const ServiceProviderModel = types
  .model("ServiceProvider")
  .props({
    id: types.maybeNull(types.number),
    contract_preference: types.maybeNull(types.number),
    specialty: types.maybeNull(Specialty),
    services_offered: types.array(ServiceOffered),
    business_description: types.maybeNull(types.string),
    business_logo: types.maybeNull(types.string),
    area_of_expertice: types.maybeNull(types.string),
    product_brands: types.maybeNull(types.string),
    my_awards: types.maybeNull(types.string),
    community_involvement: types.maybeNull(types.string),
    web: types.maybeNull(types.string),
    facebook: types.maybeNull(types.string),
    twitter: types.maybeNull(types.string),
    instagram: types.maybeNull(types.string),
    linkedin: types.maybeNull(types.string),
    youtube: types.maybeNull(types.string),
    pinterest: types.maybeNull(types.string),
    about_me_chose_work_industry: types.maybeNull(types.string),
    about_me_started_in: types.maybeNull(types.number),
    about_me_company_type: types.maybeNull(types.number),
    about_me_question: types.maybeNull(types.string),
    about_me_answer: types.maybeNull(types.string),
    green_certification: types.array(GreenCertification),
    quick_facts: types.maybeNull(QuickFact),
    service_provider_projects: types.array(ServceProviderProject),
    insurances: types.array(Insurance),
    licences: types.array(ServceProviderProject),
  })
  .views(self => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions(self => ({
    setServiceProvider(serviceProvider: any) {
      self.id = serviceProvider.id
      self.services_offered = serviceProvider.services_offered
      self.contract_preference = serviceProvider.contract_preference
      self.specialty = serviceProvider.specialty
      self.business_description = serviceProvider.business_description
      self.business_logo = serviceProvider.business_logo
      self.area_of_expertice = serviceProvider.area_of_expertice
      self.product_brands = serviceProvider.product_brands
      self.my_awards = serviceProvider.my_awards
      self.community_involvement = serviceProvider.community_involvement
      self.web = serviceProvider.web
      self.facebook = serviceProvider.facebook
      self.twitter = serviceProvider.twitter
      self.instagram = serviceProvider.instagram
      self.linkedin = serviceProvider.linkedin
      self.youtube = serviceProvider.youtube
      self.pinterest = serviceProvider.pinterest
      self.about_me_chose_work_industry = serviceProvider.about_me_chose_work_industry
      self.about_me_started_in = serviceProvider.about_me_started_in
      self.about_me_company_type = serviceProvider.about_me_company_type
      self.about_me_question = serviceProvider.about_me_question
      self.about_me_answer = serviceProvider.about_me_answer
      self.green_certification = serviceProvider.green_certification
      self.quick_facts = serviceProvider.quick_facts
      self.service_provider_projects = serviceProvider.service_provider_projects
      self.insurances = serviceProvider.insurances
      self.licences = serviceProvider.licences
    },
    reset(){
      self.id = null
      self.services_offered = cast([])
      self.contract_preference = null
      self.specialty = null
      self.business_description = null
      self.business_logo = null
      self.area_of_expertice = null
      self.product_brands = null
      self.my_awards = null
      self.community_involvement = null
      self.web = null
      self.facebook = null
      self.twitter = null
      self.instagram = null
      self.linkedin = null
      self.youtube = null
      self.pinterest = null
      self.about_me_chose_work_industry = null
      self.about_me_started_in = null
      self.about_me_company_type = null
      self.about_me_question = null
      self.about_me_answer = null
      self.green_certification = cast([])
      self.quick_facts = null
      self.service_provider_projects = cast([])
      self.insurances = cast([])
      self.licences = cast([])
    }
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

  /**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ServiceProviderType = Instance<typeof ServiceProviderModel>
export interface ServiceProviderStore extends ServiceProviderType {}
type ServiceProviderSnapshotType = SnapshotOut<typeof ServiceProviderModel>
export interface ServiceProviderSnapshot extends ServiceProviderSnapshotType {}
