import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
// @ts-ignore
import RenderSmoothImage from 'render-smooth-image-react';
import 'render-smooth-image-react/build/style.css';
import {useStores} from "../../../models/root-store/root-store-context";
import './Home.module.css';
import {RouteComponentProps} from "react-router";
import Slider from "react-slick";
import Select from 'react-select';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {COLOMBIA_ID, COLORS, ROUTES} from "../../../constants/constants"
import styles from "../Home/Home.module.css";
import {Button, CloseButton, Col, Form, InputGroup, Modal, ModalBody, ModalHeader, Row} from "react-bootstrap";
import {BsSearch, BsSliders} from "react-icons/bs"
import InputSlideBar from "../../../components/Inputs/InputSlideBar";
import {inHash, useClearStore, useWindowSize} from "../../../helpers";
import Logo from "../../../components/Logo/Logo";
import ButtonSquare from "../../../components/Buttons/ButtonSquare";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import useStyles from "./styles";
import PageWebWithHeader from "../../../components/PageWeb/PageWebWithHeader";


function SampleNextArrow(props: any) {
  const {className, style, onClick} = props;
  let classes = useStyles();
  return (
    <div
      className={[className, classes.slickArrow].join(" ")}
      style={{...style, display: "block"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const {className, style, onClick} = props;
  let classes = useStyles();
  return (
    <div
      className={[className, classes.slickArrow].join(" ")}
      style={{...style, display: "block"}}
      onClick={onClick}
    />
  );
}

interface OptionCityType {
  value: number,
  label: string
}

export const Home: React.FC<RouteComponentProps> = observer(({history}) => {
  let classes = useStyles();
  const clearStore = useClearStore()
  const rootStore = useStores()
  const isMobile = useWindowSize()
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [valueRentPrice, setValueRentPrice] = useState(0);
  const [valueTotalPrice, setValueTotalPrice] = useState(0);
  const [valueArea, setValueArea] = useState(0);
  const [valueIncome, setValueIncome] = useState(0);
  const [valueACTIS, setValueACTIS] = useState(0);
  const [valuesToSearch, setValuesToSearch] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");
  const [cities, setCities] = useState<Array<OptionCityType>>([]);
  const [selectedCity, setSelectedCity] = useState(0);
  const [data, setData] = useState<any>([]);
  const [filterBy, setFilterBy] = useState<string>("rent_price");
  const [minRentPrice] = useState<number>(300000);
  const [maxRentPrice] = useState<number>(20000000);
  const [minTotalPrice] = useState<number>(50000000);
  const [maxTotalPrice] = useState<number>(700000000);
  const [minMonthlyIncome] = useState<number>(1000000);
  const [maxMonthlyIncome] = useState<number>(10000000);
  const [maxACTIsFirst] = useState<number>(700000000);
  const [delay, setDelay] = useState(0);

  const changeFilter = (filter: string) => {
    setFilterBy(filter)
  }

  const setResultListToData = (result: any) => {
    let datos: any = [];
    result.properties.map((resultItem: any) => {
      datos.push({
        id: resultItem.id,
        image: resultItem.property_image
      })
    })
    setData(datos)
  }

  const getInitialData = () => {
    rootStore.environment.api.getPropertiesHome().then((result: any) => {
      if (result.kind === "ok") {
        setResultListToData(result.data)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else if (result.kind === "unauthorized") {
          setShow(true)
          setMessage("Session expired")
          setVariantMessage("danger")
          setTimeout(() => {
            clearStore();
            history.push(ROUTES.LOGIN)
          }, 1000)
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    getInitialData()
  }, [])

  useEffect(() => {
    if (valueIncome > 0) {
      setValueRentPrice(0)
      setValueTotalPrice(0)
    }
    if (valueRentPrice > 0) {
      setValueIncome(0)
      setValueTotalPrice(0)
    }
    if (valueTotalPrice > 0) {
      setValueRentPrice(0)
      setValueIncome(0)
    }
  }, [valueIncome, valueRentPrice, valueTotalPrice])

  const getCitiesByCountry = () => {
    rootStore.environment.api.getCitiesByCountry(COLOMBIA_ID).then((result: any) => {
      let arrayTmp: { value: any; label: any; }[] = []
      let bogotaData
      if (result.kind === "ok") {
        result.data.map((item: any) => {
          if (item.name !== "Bogotá") {
            arrayTmp.push({value: item.id, label: item.name})
          } else {
            bogotaData = {value: item.id, label: item.name}
          }
        })
        // @ts-ignore
        arrayTmp.unshift(bogotaData)
        setCities(arrayTmp)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    getCitiesByCountry()
  }, []);

  const handleChangeCity = (e: any) => {

    setSelectedCity(e.value)
  }

  const updateRentPrice = (value: number): void => {
    setValueRentPrice(value)
  }

  const updateTotalPrice = (value: number): void => {
    setValueTotalPrice(value)
  }

  const updateArea = (value: number): void => {
    setValueArea(value)
  }

  const updateIncome = (value: number): void => {
    setValueIncome(value)
  }

  const updateValueACTIS = (value: number): void => {
    setValueACTIS(value)
  }

  const closeMessage = () => {
    setShow(false)
  }

  const searchProperties = () => {
    rootStore.environment.api.getPropertiesSearch(valuesToSearch).then((result: any) => {
      if (result.kind === "ok") {
        history.push({
          pathname: "/properties",
          state: {
            searchResults: result
          }
        })
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const filterProperties = () => {
    let query = "?"

    if (valueRentPrice !== 0) {
      query += `total_rent_payout=${valueRentPrice}`
      query += `&filter_by_rent_price=1`
    }
    if (valueTotalPrice) {
      query += `&property_price=${valueTotalPrice}`
      query += `&filter_by_property_price=1`
    }
    if (valueArea) {
      query += `&size=${valueArea}`
    }
    if (selectedCity !== 0) {
      query += `&city=${selectedCity}`
    }
    if (valueIncome !== 0) {
      query += `&monthly_income=${valueIncome}`
      query += `&filter_by_monthly_income=1`
    }
    if (valueACTIS !== 0) {
      query += `&initial_acti=${valueACTIS}`
    }

    rootStore.environment.api.getPropertiesFilter(query).then((result: any) => {
      if (result.kind === "ok") {
        history.push({
          pathname: "/properties",
          state: {
            searchResults: result
          }
        })
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })


  }

  const settings = {
    arrows: true,
    autoplay:true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: data.length <= 3 ? 2 : 3 ,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleOpenFilters = () => {
    setShowFilters(true);
  };

  const handleCloseFilters = () => {
    setShowFilters(false);
  };

  const progImage = (imgItem: any) => {
    if (imgItem.image) {
      return (
        <div style={{ width: 250, height: 220, cursor: 'pointer' }}
             className={' mx-auto'}
             onClick={() => history.push(ROUTES.PROPERTY_SPECIFIC(inHash((imgItem.id).toString())))}
        >
          <RenderSmoothImage src={imgItem.image} alt="Property image" />
        </div>
      )
    } else {
      return (
        <div style={{ width: 250, height: 220, cursor: 'pointer' }} className={'d-flex justify-content-center align-items-center'}>
        <div style={{ width: 100, height: 100 }}
             className={' mx-auto'}
             onClick={() => history.push(ROUTES.PROPERTY_SPECIFIC(inHash((imgItem.id).toString())))}
        >
          <RenderSmoothImage src={'https://houshares-33309.botics.co/static/img/logo-small.png'} alt="Property image" />
        </div>
        </div>
      )
    }

  }

  return (
    <PageWebWithHeader login_required={false}>
      <div className="content-container row">
        {!isMobile && <Logo short={false} light={true}/>}
        {isMobile && <Logo short={true} light={true}/>}
        <Row style={{marginTop: "20px"}} className={'mx-0'}>
          <div className={styles.colSearch + ' col-12 '}>
            <div style={{fontWeight: "bold", textAlign: "center", fontSize: "X-large"}}>Housner area</div>
            <Form>
              <Form.Group as={Col} className={styles.formSearch + " d-flex mx-auto"}>
                <InputGroup>
                  <Button style={{
                    backgroundColor: COLORS.lightGray, color: COLORS.gray,
                    borderColor: COLORS.lightGray,
                  }}
                          onClick={searchProperties}>
                    <BsSearch size={"2rem"}/>
                  </Button>
                  <Form.Control
                    type="text"
                    value={valuesToSearch}
                    onChange={event => {
                      setValuesToSearch(event.target.value)
                    }}
                    onKeyPress={evt => {
                      if (evt.key === 'Enter') {
                        searchProperties()
                      }
                    }}
                    placeholder=" Search..."
                    style={{backgroundColor: COLORS.lightGray}}
                  />

                </InputGroup>
                <Button style={{
                  backgroundColor: COLORS.white, color: COLORS.gray,
                  borderColor: COLORS.white, width: "auto", fontSize: "X-large"
                }} title="Filters" data-toggle="tooltip" onClick={handleOpenFilters}>
                  <BsSliders style={{transform: "rotate(-90deg)"}}/>
                </Button>
              </Form.Group>

            </Form>
          </div>
        </Row>
        <Row style={{justifyContent: "center", width: "75%", marginTop: "60px"}}>
          <Slider {...settings} className={styles.slickSlider}>
            {data && data.map((imgItem: any, idx: any) => {
              return (
                <div key={idx} className={styles.itemCarousel}>
                  {progImage(imgItem)}
                </div>
              );
            })}
          </Slider>
        </Row>
        <Row>
          <span style={{textAlign: "center", marginTop: "10px"}}>Properties available</span>
        </Row>
      </div>
      <Modal show={showFilters} size={"lg"}>

        <ModalHeader style={{
          backgroundColor: COLORS.gray,
          color: COLORS.white,
          border: COLORS.gray,
          justifyContent: "center"
        }}>

          <CloseButton variant="white" onClick={handleCloseFilters}/>
        </ModalHeader>

        <ModalBody style={{backgroundColor: COLORS.gray, color: COLORS.white}} >
          <Form>

            <InputSlideBar moneyFormat label="Rent price ($)" value={valueRentPrice} updateValue={updateRentPrice}
                           min={minRentPrice} max={maxRentPrice}/>



            <InputSlideBar moneyFormat label="Total price ($)" value={valueTotalPrice}
                           updateValue={updateTotalPrice}
                           min={minTotalPrice} max={maxTotalPrice}/>


            <InputSlideBar moneyFormat label="My monthly income ($)" value={valueIncome}
                           updateValue={updateIncome}
                           min={minMonthlyIncome} max={maxMonthlyIncome}/>

            <InputSlideBar label="Area (m2)" value={valueArea} updateValue={updateArea} min={0} max={3500}/>
            <InputSlideBar moneyFormat label="How much can you invest in ACTIs at first ($)" value={valueACTIS}
                           updateValue={updateValueACTIS} min={0} max={maxACTIsFirst}/>
          </Form>
          <div style={{width: "50%", margin: "auto"}}>
            <Select options={cities} onChange={handleChangeCity}
                    value={cities.filter(obj => obj?.value === selectedCity)}
                    className={classes.select} placeholder={"City"}/>
          </div>
          <div style={{textAlign: "center", marginTop: "20px"}}>
            <ButtonSquare label={"Apply"} bold={false}
                          startFunction={filterProperties}/>
          </div>
        </ModalBody>
      </Modal>
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={delay}/>
    </PageWebWithHeader>
  );
});

export default Home;
