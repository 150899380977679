import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import useStyles from "../styles"
import {COLORS, ROUTES_ADMIN, space} from "../../../constants/constants";
import AdminSearch from "../../../components/AdminSearch/AdminSearch";
import {RiUserStarLine} from "react-icons/ri";
import {useStores} from "../../../models/root-store/root-store-context";
import AdminPagination from "../../../components/AdminPagination/AdminPagination";
import {BiMessageError} from "react-icons/bi";
import {inHash} from "../../../helpers"
// import {CloseButton, Modal, ModalBody, ModalHeader} from "react-bootstrap";
// import {Field, Form, Formik, FormikProps} from "formik";
// import InputTextAreaFormModal from "../../../components/Inputs/InputTextAreaFormModal";
// import ButtonRoundForm from "../../../components/Buttons/ButtonRoundForm";
// import {FiSend} from "react-icons/fi";
// import * as Yup from "yup";
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";
import ToastComponent from "../../../components/Toasts/ToastCustom";

// interface IEmail {
//     message: string,
// }
//
// const schemaEmail = Yup.object().shape({
//     message: Yup.string().required('Required'),
// })

export const HousnerList: React.FC<RouteComponentProps> = observer(({history}) => {

  let classes = useStyles();
  const rootStore = useStores()
  // const [LoadingSendEmail, setLoadingSendEmail] = useState<boolean>(false);
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [valuesToSearch, setValuesToSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [previousPage, setPreviousPage] = useState<string | null>("");
  const [nextPage, setNextPage] = useState<string | null>("");
  const [, setPathLastCalled] = useState<string>("/admin/housners/");
  const [, setOffsetLastCalled] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");
  // const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  // const [emailMessage, setEmailMessage] = useState<string>("");
  // const [idUserToSendEmail, setIdUserToSendEmail] = useState<number>(-1);

  const setResultListToData = (result: any) => {
    let datos: any = [];
    result.data.results.map((resultItem: any) => {
      datos.push({
        id: resultItem.id,
        // idUser: resultItem.id_user,
        fullName: resultItem.full_name,
        email: resultItem.email,
        phone: resultItem.phone,
        statusCheckDate: resultItem.status_check_date,
        dateCheck: resultItem.date_check,
        expireDate: resultItem.expire_date
      })
    })
    setData(datos)
  }

  const getHousners = (rows: number) => {
    setLoading(true)
    rootStore.environment.api.getHousnerList(rows).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setTotalPages(Math.ceil(result.data.count / rows))
        setCurrentPage(1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    getHousners(rowsPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const search = (rows: number) => {
    setLoading(true)
    rootStore.environment.api.getHousnersSearch(valuesToSearch, rows).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setCurrentPage(1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
        setTotalPages(Math.ceil(result.data.count / rows))
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const getHousnersPrevNextPage = (path: string, offset: number) => {
    setPathLastCalled(path)
    setOffsetLastCalled(offset)
    setLoading(true)
    rootStore.environment.api.getHousnerListPrevNextPage(path).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setCurrentPage(Math.floor(offset / rowsPerPage) + 1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const handlePrevPageClicked = () => {
    let from = previousPage?.substring(previousPage?.indexOf("offset") + 7);
    let path = previousPage?.substring(previousPage?.indexOf("v1") + 2);
    if (path !== undefined) {
      if (from !== undefined && !isNaN(parseInt(from))) {
        getHousnersPrevNextPage(path, parseInt(from))
      } else {
        getHousnersPrevNextPage(path, 1)
      }
    }
  }

  const handleNextPageClicked = () => {
    let from = nextPage?.substring(nextPage?.indexOf("offset") + 7);
    let path = nextPage?.substring(nextPage?.indexOf("v1") + 2);

    if (path !== undefined) {
      if (from !== undefined && !isNaN(parseInt(from))) {
        getHousnersPrevNextPage(path, parseInt(from))
      } else {
        getHousnersPrevNextPage(path, 1)
      }
    }
  }

  const handleRowsPerPageClicked = (rows: number) => {
    setRowsPerPage(rows)
    if (valuesToSearch !== "") {
      search(rows)
    } else {
      getHousners(rows)
    }
  }

  // const openShowEmail = (idUser: number) => {
  //     setIdUserToSendEmail(idUser)
  //     setShowEmailModal(true)
  // }

  // const sendEmail = (item: any) => {
  //     console.log("item: ", item)
  //     setIdUserToSendEmail(-1)
  //     setShowEmailModal(false)
  // }

  const closeMessage = () => {
    setShow(false)
  }

  const HousnerItems = (currentItems: any) => {
    return (
      <>
        {currentItems && currentItems.currentItems?.length > 0 &&
          <div style={{marginTop: "35px"}}>
            {currentItems.currentItems.map((item: any, idx: number) => {
              return (
                <div key={idx} className={["row", classes.list].join(" ")}>
                  <div className={"col-1"}>{item.id}</div>
                  <div className={["col-2 text-wrap ", classes.btnUser].join(" ")}
                       onClick={() => history.push(ROUTES_ADMIN.USER_INFO(inHash(item.id)))}>
                    {item.fullName}
                  </div>
                  <div className={"col-3"}>{item.email}</div>
                  <div className={"col-2"}>{item.phone}</div>
                  <div className={"col-3 text-center"} style={{display: "inline-flex"}}>
                    <p style={{
                      backgroundColor: COLORS.white,
                      color: COLORS.gray
                    }}>{item.dateCheck}</p>
                    {`${space}>${space}`}
                    <p style={{
                      backgroundColor: COLORS.white,
                      color: COLORS.gray
                    }}>{item.expireDate}</p>
                  </div>
                  <div className={"col-1 text-center"}
                       style={{
                         color: item.statusCheckDate == "regular" ? COLORS.green : COLORS.orange,
                         alignItems: "center"
                       }}>
                    {item.statusCheckDate == "regular" ? "Regular" : "Timed out"}
                    {/*{item.statusCheckDate == "timed_out" &&*/}
                    {/*    <div style={{marginLeft: "10px"}}>*/}
                    {/*        <ButtonDark startFunction={() => openShowEmail(item.idUser)}*/}
                    {/*                    bold={false}>*/}
                    {/*            <IoArrowUndo/>*/}
                    {/*        </ButtonDark>*/}
                    {/*    </div>*/}
                    {/*}*/}
                  </div>
                </div>
              );
            })}

          </div>
        }
      </>
    );
  }

  return (
    <PageWebAdminDark>
      <div className={"row"}>
        <div className={"col-9"}
             style={{color: COLORS.white, fontSize: "xx-large"}}>
          <BiMessageError/> Approved accounts
        </div>
        <div className={"col-3"}
             style={{display: "flex", justifyContent: "center"}}>
          <AdminSearch valuesToSearch={valuesToSearch} setValuesToSearch={setValuesToSearch}
                       search={search} rowsPerPage={rowsPerPage}/>
        </div>
      </div>
      <div className={"row"} style={{marginTop: "20px"}}>
        <div className={"col-12"}
             style={{color: COLORS.white, fontSize: "x-large"}}>
          <RiUserStarLine/> Housners
        </div>
      </div>
      {!Loading && data?.length == 0 &&
        <NoDataAdmin/>}
      {Loading &&
        <SpinnerLight/>
      }
      {!Loading && data && data?.length > 0 &&
        <div>
          <div className={["row", classes.listTittles].join(" ")}>
            <div className={"col-1"}>Housner ID</div>
            <div className={"col-2"}>Housner</div>
            <div className={"col-3"}>Email</div>
            <div className={"col-2"}>Phone</div>
            <div className={"col-3"}>Data validation</div>
            <div className={"col-1 text-center"}>Validation status</div>
          </div>
          <HousnerItems currentItems={data}/>
          <AdminPagination rowsPerPage={rowsPerPage} handleRowsPerPageClicked={handleRowsPerPageClicked}
                           currentPage={currentPage} handleNextPageClicked={handleNextPageClicked}
                           handlePrevPageClicked={handlePrevPageClicked} nextPage={nextPage}
                           previousPage={previousPage} totalPages={totalPages}/>
        </div>
      }
      {/*<Modal show={showEmailModal}>*/}
      {/*    <ModalHeader className={classes.modal}>*/}
      {/*        <div className={classes.divModalHeader}>*/}
      {/*            Write email*/}
      {/*        </div>*/}
      {/*        <CloseButton variant="white" onClick={() => setShowEmailModal(false)}/>*/}
      {/*    </ModalHeader>*/}
      {/*    <ModalBody className={classes.modal}>*/}
      {/*        <Formik*/}
      {/*            initialValues={{*/}
      {/*                message: ""*/}
      {/*            }}*/}
      {/*            onSubmit={*/}
      {/*                (values: IEmail) => {*/}
      {/*                    sendEmail(values.message)*/}
      {/*                }*/}
      {/*            }*/}
      {/*            validationSchema={schemaEmail}*/}
      {/*        >*/}
      {/*            {(props: FormikProps<IEmail>) => {*/}
      {/*                const {*/}
      {/*                    // values,*/}
      {/*                    // touched,*/}
      {/*                    // errors,*/}
      {/*                    // handleBlur,*/}
      {/*                    // handleChange,*/}
      {/*                    isSubmitting,*/}
      {/*                } = props*/}
      {/*                return (*/}
      {/*                    <Form>*/}
      {/*                        <Field name={"message"} label={""} bold={false}*/}
      {/*                               className={"text-center"}*/}
      {/*                               placeholder={"Write message"} component={InputTextAreaFormModal}/>*/}
      {/*                        <div style={{textAlign: "center", marginTop: "20px"}}>*/}
      {/*                            <ButtonRoundForm label={"Send"} bold={true}*/}
      {/*                                             isSubmitting={isSubmitting}*/}
      {/*                                             Loading={LoadingSendEmail}><FiSend*/}
      {/*                                style={{marginLeft: "5px", marginBottom: "5px"}}/>*/}
      {/*                            </ButtonRoundForm>*/}
      {/*                        </div>*/}
      {/*                    </Form>*/}
      {/*                )*/}
      {/*            }}*/}
      {/*        </Formik>*/}
      {/*    </ModalBody>*/}
      {/*</Modal>*/}
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
    </PageWebAdminDark>
  )
});

export default HousnerList;
