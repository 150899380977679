import React from "react";
import SwitchSelector from "react-switch-selector";
import {COLORS} from "../../constants/constants";
import useStyles from "./style";

type ToggleSwitchProps = {
    name: string,
    label: string,
    setValue: (arg: any) => void,
    className: string,
};

export const ToggleSwitch: React.FC<ToggleSwitchProps> = React.forwardRef((props,ref) => {
    let classes = useStyles();

    const options = [
        {
            label: "Yes",
            value: true,
            selectedBackgroundColor: COLORS.green,
        },
        {
            label: "No",
            value: false,
            selectedBackgroundColor: COLORS.orange,
        }
    ];

    return (
        <div className={[props.className, classes.switchDiv].join(' ')}>
            <span style={{
                color: COLORS.white, float: "left",
                padding: "6px 0px 12px 0px"
            }}>{props.label}</span>
            <SwitchSelector
                name={props.name}
                onChange={(value) => {
                    props.setValue(value);
                }}
                options={options}
                initialSelectedIndex={1}
                backgroundColor={COLORS.gray}
                fontColor={COLORS.white}
                border={COLORS.white}
            />
        </div>
    )
});

export default ToggleSwitch;






