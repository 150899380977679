import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
//todo: ocultar esto
const firebaseConfig = {
    apiKey: "AIzaSyD3dz9lzH8UBMErVe1r8nAOfrGJ_LIjaRc",
    authDomain: "houshares.firebaseapp.com",
    projectId: "houshares",
    storageBucket: "houshares.appspot.com",
    messagingSenderId: "1055275853578",
    appId: "1:1055275853578:web:791c162f1aaf7c8c717cf3",
    measurementId: "G-J7TDY9XZBF"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt: 'select_account'});

export const signInWithGoogle = () => auth.signInWithPopup(provider);
export default firebase;
