import {Button} from "react-bootstrap";
import React from "react";
import useStyles from "./styles";

type InputProps = {
    label: string,
    startFunction: () => void,
    bold: boolean
};

export const ButtonRound: React.FC<InputProps> = React.forwardRef((props,ref) => {

    let classes = useStyles();

    return (
        <Button
            className={props.bold?classes.btnStyleBoldRound:classes.btnStyleRound}
            onClick={props.startFunction}
        >
            {props.label}
            {props.children}
        </Button>
    )
});

export default ButtonRound;