import React from 'react';
import {Redirect} from "react-router";
import {ROUTES, ROUTES_ADMIN} from "../../constants/constants";
import {useStores} from "../../models/root-store/root-store-context";
import CustomTitle from "../CustomTitle/CustomTitle";
import HeaderUser from "../HeaderWeb/HeaderUser";

type PageProps = {
    login_required?: boolean
    title?: string
    housner_required?: boolean
};

export const PageWebWithHeader: React.FC<PageProps> = React.forwardRef((props,ref) => {
    const rootStore = useStores()
    const {loginStore} = rootStore

    if (props.login_required && !loginStore.isLoggedIn) {
        return <Redirect to={{pathname: ROUTES.LOGIN}}/>
    }
    if (props.housner_required && loginStore.group !== 2) {
        return <Redirect to={{ pathname: ROUTES.HOME }} />
    }

    if (loginStore.group === 1) {
        return <Redirect to={{ pathname: ROUTES_ADMIN.HOME_ADMIN }} />
    }

    return (
        <div className={'d-flex flex-column min-vh-100 white-body'}>
            <HeaderUser/>
            <CustomTitle title={props.title}/>
            <div className={'container py-5'}>
                {props.children}
            </div>
            {/* <FooterWeb/> */}
        </div>
    )
});

export default PageWebWithHeader;
