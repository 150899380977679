// ROUTES
import {OptionString} from "./types";

export const ROUTES = {
    ROOT: "/",
    HOME: "/home",
    ONBOARD: "/start",
    SIGNUP: "/signup",
    CHANGE_PASSWORD: "/change_password",
    EMAIL_VERIFICATION: "/email_verification",
    LOGIN: "/login",
    LOGOUT: "/logout",
    FORGOT_PASSWORD: "/forgot-password",
    TERMS: "/terms_conditions",
    PRIVACY: "/privacy_policy",
    ACCOUNT_INFO: `/profile`,
    APPLICATION_PROFILE: (id: string) => `/profile/property_application/${id}`,
    APPLICATION_PROPERTY: (id: string) => `/applications/${id}`,
    PROPERTY_LIST: `/properties`,
    PROPERTY_SPECIFIC: (id: string) => `/properties/${id}`,
    MY_PROPERTY: `/properties/me`,
    MAINTENANCE: (id: string) => `/maintenances/${id}`,
    SETTINGS: `/settings`,
    INVITE_FRIENDS:`/invite_friends`,
    FEEDBACK: `/feedback`,
}

export const ROUTES_ADMIN = {
    HOME_ADMIN: "/admin_panel/users",
    USER_LIST: "/admin_panel/users",
    USER_INFO: (id: string) => `/admin_panel/users/${id}`,
    BACKGROUND_CHECKS: "/admin_panel/background_checks",
    DENIED_USERS: "/admin_panel/denied_users",
    PROPERTY_MANAGEMENT: "/admin_panel/properties",
    HOUSNERS: "/admin_panel/housners",
    PROPERTY_REQUESTS: "/admin_panel/property_requests",
    PROPERTIES_UNDER_CONTRACT: "/admin_panel/properties_under_contract",
    PROPERTIES_UNDER_CONTRACT_INFO: (id: string) => `/admin_panel/properties_under_contract/${id}`,
    MAINTENANCE_REQUESTS: "/admin_panel/maintenance_requests",
    FEEDBACKS: "/admin_panel/feedbacks",
}

export const FACEBOOK_APP_ID = "733143384612376"
export const FACEBOOK_VERSION = "v13.0"
export const FACEBOOK_SRC = "https://connect.facebook.net/en_US/sdk.js"


export const COLOMBIA_ID = parseInt(process.env.REACT_APP_COLOMBIA_ID!)
export const BOGOTA_CITY = parseInt(process.env.REACT_APP_BOGOTA_CITY!)
export const BOGOTA_DC = parseInt(process.env.REACT_APP_BOGOTA_DC!)

export const space = "\u00A0\u00A0\u00A0"

export const COLORS = {
    realblack: "#000000",
    black: "#353939",
    white: "#FFFFFF",
    gray: "#748190",
    lightGray: "#C4C4C4",
    lightgreen: "#C2E9E8",
    green: "#36B8B4",
    greenApple: "#00FF38",
    darkgreen: "#2e9e9a",
    red: "#fc6f6f",
    redError: "#FF0000",
    orange: "#FFA800",
    yellow: "#F3E13F"
}

export const optionsArray: OptionString[] = [];
optionsArray.push({value: "CC", label: "C.C."})
optionsArray.push({value: "CE", label: "C.E."})
optionsArray.push({value: "PA", label: "Passport"})
optionsArray.push({value: "DI", label: "Diplomatic card"})
optionsArray.push({value: "OT", label: "Other"})

export const optionsStudies: OptionString[] = [];
optionsStudies.push({value: "NI", label: "None"})
optionsStudies.push({value: "PR", label: "Elementary"})
optionsStudies.push({value: "BA", label: "High school"})
optionsStudies.push({value: "TE", label: "Technical"})
optionsStudies.push({value: "UN", label: "Bachelor"})
optionsStudies.push({value: "PO", label: "Postgraduate"})

export const optionsOcupation: OptionString[] = [];
optionsOcupation.push({value: "ASA", label: "Salaried"})
optionsOcupation.push({value: "EST", label: "Student"})
optionsOcupation.push({value: "HOG", label: "Housewife / Househusband"})
optionsOcupation.push({value: "PEN", label: "Pensionary"})
optionsOcupation.push({value: "SOC", label: "Associate"})
optionsOcupation.push({value: "EMP", label: "Public employee"})
optionsOcupation.push({value: "PRO", label: "Independent freelancer"})
optionsOcupation.push({value: "REN", label: "Rentier"})
optionsOcupation.push({value: "OTR", label: "Other"})
