import React from "react";
import {COLORS} from "../../constants/constants";

export const NoDataAdmin = React.forwardRef((props,ref) => {

    return (
        <div
            style={{color:COLORS.white, textAlign:"center",marginTop:"100px"}}>
            No data found
        </div>
    )
});