import React from 'react';
import {Redirect} from "react-router";
import {ROUTES} from "../../constants/constants";
import {useStores} from "../../models/root-store/root-store-context";
import styles from "./PageWebHalf.module.css";

type PageProps = {
    login_required?: boolean
    title?: string
};

export const PageWebHalf: React.FC<PageProps> = React.forwardRef((props,ref) => {
    const rootStore = useStores()
    const {loginStore} = rootStore

    if (props.login_required && !loginStore.isLoggedIn) {
        return <Redirect to={{ pathname: ROUTES.LOGIN }} />
    }
    return (
        <div className={["d-flex flex-column min-vh-100", styles.backgroundPage].join(' ')}>
            {/* <HeaderWeb/> */}
            {/* <CustomTitle title={props.title}/> */}
            <div className={'container py-5'}>
                {props.children}
            </div>
            {/* <FooterWeb/> */}
        </div>
    )
});

export default PageWebHalf;
