import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import styles from "./Splash.module.css";
import "./Splash.css"
import {useStores} from "../../../models/root-store/root-store-context";
import frame_1 from "../../../assets/images/splash/frame-1.png";
import frame_2 from "../../../assets/images/splash/frame-2.png";
import frame_3 from "../../../assets/images/splash/frame-3.png";
import logo from "../../../assets/images/logo-big.png";
import {useWindowSize} from "../../../helpers";
import {ROUTES} from "../../../constants/constants";
import {Redirect, RouteComponentProps} from "react-router";

export const Splash: React.FC<RouteComponentProps> = observer(({history}) => {

  const rootStore = useStores()
  const {loginStore} = rootStore

  if (loginStore.isLoggedIn) {
    return <Redirect to={{ pathname: ROUTES.HOME }} />
  }

  const isMobile = useWindowSize()

  const [display, setDisplay] = React.useState('none');
  const [opacity, setOpacity] = React.useState(1);

  const showBanner = () => {
      setDisplay('');
      setOpacity(1)
        setTimeout(() => {
            history.push(ROUTES.ONBOARD)
        }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      showBanner()
    }, 1000)
  }, [])

  return (
    <div className={'d-flex flex-column min-vh-100 m-0' + (isMobile ? " overflow-hidden": " ")} style={{backgroundColor: '#353939'}}>

      <div className={'content-container mx-0 min-vh-100 row'} style={{display, opacity}}>
        <div className={'d-flex justify-content-center align-items-center box'}>
          <img src={frame_1} className={styles.image_side} alt=""/>
          <img src={frame_3} className={'mx-3 ' + styles.image_middle} alt=""/>
          <img src={frame_2} className={styles.image_side} alt=""/>
        </div>
        {!isMobile && <img src={logo} style={{width: 373, height: 86}} alt=""/>}
      </div>
    </div>
  );
});

export default Splash;
