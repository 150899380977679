import {Button, Spinner} from "react-bootstrap";
import React from "react";
import useStyles from "./styles";

type InputProps = {
    label: string,
    bold: boolean,
    isSubmitting: boolean,
    Loading: boolean
};

export const ButtonSquareForm: React.FC<InputProps> = React.forwardRef((props,ref) => {

    let classes = useStyles();

    return (
        <Button
            className={props.bold ? classes.btnStyleBold : classes.btnStyle}
            type="submit"
            disabled={props.isSubmitting || props.Loading}
        >
            {props.Loading &&
                <Spinner animation="border" variant="light" size={'sm'}/>}
            {!props.Loading &&
                <span style={{fontSize: 18, fontWeight: "bold"}}>
                    {props.label}
                </span>}
            {props.children}
        </Button>
    )
});

export default ButtonSquareForm;