import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import PageWebDarkWithHeader from "../../../components/PageWeb/PageWebDarkWithHeader";
import {space} from "../../../constants/constants";
import {outHash} from "../../../helpers";
import useStyles from "./styles";
import {useStores} from "../../../models/root-store/root-store-context";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {Field, Form, Formik, FormikProps, FormikState} from "formik";
import ButtonSquare from "../../../components/Buttons/ButtonSquare";
import * as Yup from "yup";
import ButtonSquareForm from "../../../components/Buttons/ButtonSquareForm";
import Logo from "../../../components/Logo/Logo";
import {DetailViewProps} from "../../../constants/types";
import InputTextAreaFormModal from "../../../components/Inputs/InputTextAreaFormModal";

interface IMaintenance {
  message: string,
}

const schemaMaintenance = Yup.object().shape({
  message: Yup.string().required('Required'),
})

export const MaintenanceRequest: React.FC<DetailViewProps> = observer(({history, match}) => {

  let classes = useStyles();
  const rootStore = useStores()

  let idProperty = "";
  if (Object.keys(match.params).length > 0) {
    idProperty = outHash(match.params.id);
  }

  const [Loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");


  const setResultToData = (result: { id: any; name: any; }) => {
    let datos = ({
      id: result.id,
      name: result.name,
    })
    setData(datos)
  }

  const getProperty = () => {
    rootStore.environment.api.getPropertySpecific(parseInt(idProperty)).then((result: any) => {
      if (result.kind === "ok") {
        // console.log(result);
        // setShow(true)
        // setMessage("result:" + result)
        // setVariantMessage("success")

        setResultToData(result.data)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    getProperty()
  }, []);

  const sendMaintenaceRequest = (values: IMaintenance, setSubmitting: (arg0: boolean) => void,
                                 resetForm: {
                                   (nextState?: Partial<FormikState<IMaintenance>> | undefined):
                                     void; (arg0: { values: { title: string; message: string; }; }): void;
                                 }) => {
    setLoading(true)
    rootStore.environment.api.postMaintenanceRequest(idProperty, values.message).then((result: any) => {
      resetForm({
        values: {
          title: "",
          message: ""
        },
      });
      setSubmitting(false)
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Maintenance successfully sent")
        setVariantMessage("success")
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
    setLoading(false)
  }

  const closeMessage = () => {
    setShow(false)
  }

  return (
    <PageWebDarkWithHeader login_required={true} housner_required={true}>
      <Logo short={true} light={true}/>
      <div>
        {data &&
          <div className={classes.titleMaintenance}>Maintenance Request of Property:{space}{data.name}</div>}
        <div style={{textAlign: "center"}}>
          <Formik
            initialValues={{
              title: "",
              message: ""
            }}
            onSubmit={
              (values: IMaintenance, {setSubmitting, resetForm}) => {
                sendMaintenaceRequest(values, setSubmitting, resetForm)
              }
            }
            validationSchema={schemaMaintenance}
          >
            {(props: FormikProps<IMaintenance>) => {
              const {
                // values,
                // touched,
                // errors,
                // handleBlur,
                // handleChange,
                isSubmitting,
              } = props
              return (
                <Form style={{marginTop: "15px"}}>
                  <Field name={"message"} label={"Message"} bold={true}
                         className={"text-start"} type={"textarea"}
                         placeholder={"Write message"} component={InputTextAreaFormModal}/>
                  <div style={{textAlign: "center", marginTop: "20px", marginBottom: "20px"}}>
                    <ButtonSquareForm label={"Submit the request"} bold={true}
                                      isSubmitting={isSubmitting}
                                      Loading={Loading}/>
                  </div>
                  <div>
                    <ButtonSquare label={"Back"} startFunction={() => history.goBack()}
                                  bold={true}/>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
    </PageWebDarkWithHeader>
  );
});
export default MaintenanceRequest;
