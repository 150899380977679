import React from 'react';
import {Redirect} from "react-router";
import {ROUTES} from "../../constants/constants";
import {useStores} from "../../models/root-store/root-store-context";
import CustomTitle from "../CustomTitle/CustomTitle";

type PageProps = {
  login_required?: boolean
  title?: string
};

export const PageWeb: React.FC<PageProps> = React.forwardRef((props,ref) => {
  const rootStore = useStores()
  const {loginStore} = rootStore

  if (props.login_required && !loginStore.isLoggedIn) {
    return <Redirect to={{ pathname: ROUTES.LOGIN }} />
  }
  return (
    <div className={'d-flex flex-column white-body'}>
      <CustomTitle title={props.title}/>
      <div className={'container py-5'}>
        {props.children}
      </div>
    </div>
  )
});

export default PageWeb;
