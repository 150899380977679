import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import './i18n';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://48b9c3a339d14f9aa754e3d852352a9f@sentry.innovatica.com.py//11",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
