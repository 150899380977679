import {types} from "mobx-state-tree"
import {QuickFactItem} from "./QuickFactItem";
import {QuickFactSchedule} from "./QuickFactSchedule";

export const QuickFact = types
  .model("QuickFact")
  .props({
    id: types.identifierNumber,
    all_days: types.boolean,
    display_profile_page: types.boolean,
    items: types.array(QuickFactItem),
    quick_facts_schedules: types.array(QuickFactSchedule),
  })
