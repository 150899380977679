import React from "react";
import ButtonDark from "../Buttons/ButtonDark";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {sort} from "../../helpers";

type InputProps = {
    className: string,
    asc: any,
    setAsc: (arg: any) => void
    label: string,
    attribute: string,
    data: any,
    setData: (arg: any) => void
};


export const SortArrows: React.FC<InputProps> = React.forwardRef((props, ref) => {

    const sortData = (orderBy: string) => {
        let sortDatos = sort(orderBy,  props.asc[orderBy],  props.data)
        if (sortDatos) {
            props.setData(sortDatos)
        }
        let temp = props.asc;
        temp[orderBy] = !temp[orderBy]
        props.setAsc(temp)
    }

    return (
        <React.Fragment>
            {props.label}
            {!props.asc[props.attribute] &&
                <ButtonDark className={"mb-2"} startFunction={() => (sortData(props.attribute))}
                            bold={true}><IoIosArrowUp/></ButtonDark>}
            {props.asc[props.attribute] &&
                <ButtonDark className={"mb-2"} startFunction={() => sortData(props.attribute)}
                            bold={true}><IoIosArrowDown/></ButtonDark>}
        </React.Fragment>
    )
});

export default SortArrows;
