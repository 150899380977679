import {Button} from "react-bootstrap";
import React from "react";
import useStyles from "./styles";

type InputProps = {
    // text: string,
    startFunction: () => void,
};

export const ButtonModal: React.FC<InputProps> = React.forwardRef((props,ref) => {

    let classes = useStyles();

    return (
        <div>
            <Button
                className={classes.btnModal}
                onClick={props.startFunction}
            >
                {props.children}
            </Button>
        </div>
    )
});

export default ButtonModal;