import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../constants/constants";

export default makeStyles((theme) => ({


    adminContainer: {
        width: "100%",
        padding: "20px"
    },

    modalCenter: {
        display: "flex",
        alignItems: "center",
    },

    modalHeader: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        border: COLORS.gray,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },
    modalBody: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        textAlign: "center",
    },
    btnOption: {
        backgroundColor: COLORS.green,
        borderRadius: "25px",
        borderColor: "transparent",
        width: "50px",
        height: "50px",
        marginLeft: "30px",
        marginRight: "30px",
        "&:hover, &:focus": {
            backgroundColor: COLORS.darkgreen,
            borderColor: "transparent",
        }
    },

}));
