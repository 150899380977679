import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../../constants/constants";

export default makeStyles((theme) => ({

    cardStyle: {
        color: COLORS.white,
        backgroundColor: COLORS.black,
        borderColor: COLORS.black,
        marginTop: "-160px"
    },

    bannercontainer: {
        width: "400px",
        height: "480px",
    },

    hoverGreenBtn: {
        color: COLORS.white,
        textDecoration: "none",
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green
        },
    },

    "@media (max-width: 768px)": {
        cardStyle: {
            marginTop: "-25px"
        },
    },

}));
