import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../../constants/constants";

export default makeStyles({
    logoShort: {
        textAlign: "center",
        marginTop: "25px"
    },
    title: {
        color: COLORS.white,
        fontWeight: "bold",
        marginTop: "10%",
        textAlign: "center",
        fontSize: "x-large"
    },
});
