import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../constants/constants";

export default makeStyles((theme) => ({
    inputSearch: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        height: "48px",
        width: "50%",
        borderRadius: "12px 0 0 12px",
        borderRightColor: "transparent",
        borderLeft: "thin solid",
        borderTop: "thin solid",
        borderBottom: "thin solid",
        "&::placeholder": {
            color: COLORS.white,
        },
    },
    searchButton: {
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        borderRadius: "0  12px 12px 0",
        borderLeftColor: "transparent",
        borderRightColor: COLORS.white,
        borderTopColor: COLORS.white,
        borderBottomColor: COLORS.white,
        height: "48px",
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.lightgreen,
            backgroundColor: COLORS.gray,
            borderColor: COLORS.lightgreen
        },
    }
}));