import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {useStores} from "../../../models/root-store/root-store-context";
import {RouteComponentProps} from "react-router";
import {runInAction} from "mobx";
import PageWebDark from "../../../components/PageWeb/PageWebDark";
import {Button, Modal, ModalBody, Spinner,} from "react-bootstrap";
import {
  COLORS,
  FACEBOOK_APP_ID,
  FACEBOOK_SRC,
  FACEBOOK_VERSION,
  ROUTES,
  ROUTES_ADMIN
} from "../../../constants/constants";
import {Link} from "react-router-dom";
import styles from "../Login/Login.module.css";
import useStyles from "./styles";
import {BsLock} from "react-icons/bs";
import {IoShareOutline} from "react-icons/io5";
import {MdLogin} from "react-icons/md";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {Field, Form, Formik, FormikProps} from "formik";
import InputPasswordForm from "../../../components/Inputs/InputPasswordForm";
import * as Yup from "yup";
import firebase, {signInWithGoogle} from "../../../services/firebase";
import logoShort from "../../../assets/images/logo-short.png";
import lock from "../../../assets/images/lock.png";
import logoBig from "../../../assets/images/logo-big.png";
import {FaRegEye, FaRegEyeSlash} from "react-icons/fa";

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

interface IResetForm {
  otp: string
  password: string
  confirmPassword: string
}

const schemaForgotPassword = Yup.object({
  email: Yup.string().email().required('Enter a valid email'),
})

const schemaResetPassword = Yup.object().shape({
  otp: Yup.string().required("Required"),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{7,20}\S$/,
      'Please valid password: At least 8 characters, one uppercase, one lowercase, one special character (like !@#$%^&*()) and no spaces'
    )
    .required(
      'Please valid password: At least 8 characters, one uppercase, one lowercase, one special character (like !@#$%^&*()) and no spaces'
    ),
  confirmPassword: Yup.string()
    .required('Required')
    .test(
      'Password-match',
      'Password must match',
      function (value) {
        return this.parent.password === value
      }
    ),
})


export const Login: React.FC<RouteComponentProps> = observer(({history}) => {

  let classes = useStyles();

  const rootStore = useStores()
  const {loginStore, serviceProviderStore} = rootStore

  const [Loading, setLoading] = useState(false)
  const [LoadingForgot, setLoadingForgot] = useState(false)
  const [LoadingReset, setLoadingReset] = useState(false)
  const [showOtpPassword, setShowOtpPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [showForgotPass, setShowForgotPass] = useState<boolean>(false)
  const [showResetPass, setShowResetPass] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");

  const [Email, setEmail] = useState<string>("");
  const [EmailReset, setEmailReset] = useState<string>("");
  const [EmailResetErrors, setEmailResetErrors] = useState<[]>([]);
  const [Password, setPassword] = useState<string>("");
  const [ShowPassword, setShowPassword] = useState<boolean>(false);


  const handleCloseForgotPassword = () => {
    setShowForgotPass(false);
  }

  const close = () => {
    setShow(false)
  }

  const startSession = () => {
    if (Email === '' || Email === null) {
      setShow(true)
      setMessage('Email is required')
      setVariantMessage("warning")
      return
    }
    if (Password === '' || Password === null) {
      setShow(true)
      setMessage('Password is required')
      setVariantMessage("warning")
      return
    }

    setLoading(true)
    rootStore.environment.api.login(Email, Password).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Session started successfully")
        setVariantMessage("success")
        runInAction(() => {
          loginStore.setUser(result.response.user)
          loginStore.setApiToken(result.response.access_token)
        })
        if (result.response.service_provider) {
          runInAction(() => {
            serviceProviderStore.setServiceProvider(result.response.service_provider)
          })
        }
        if (loginStore.group === 1) {
          history.push(ROUTES_ADMIN.HOME_ADMIN)
        } else {
          history.push(ROUTES.HOME)
        }
      } else {
        if (result.kind === "bad-data") {
          console.log(result)
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            if (result.errors.email) {
              setShow(true);
              setMessage(result.errors.email)
              setVariantMessage("warning")
            } else {
              setShow(true);
              setMessage("Validation errors found")
              setVariantMessage("warning")
            }
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const handleForgot = () => {
    setShowForgotPass(true);
  }

  const forgotPassword = async () => {
    let result
    let error: any
    try {
      result = await schemaForgotPassword.validate({email: EmailReset})
    } catch ({errors}) {
      error = errors
    }
    setEmailResetErrors(error)
    if (result === undefined) {
      return
    }
    setLoadingForgot(true)
    setShowForgotPass(false)
    rootStore.environment.api.forgotPassword(result.email).then((result: any) => {
      setLoadingForgot(false)
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Reset password email sent successfully")
        setVariantMessage("success")
        setShowResetPass(true)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const resetPassword = (values: IResetForm) => {
    setLoadingReset(true)
    rootStore.environment.api.resetPassword(values.otp, values.password, values.confirmPassword).then((result: any) => {
      setLoadingReset(false)
      if (result.kind === "ok") {
        setShowResetPass(false)
        setShow(true)
        setMessage("Your password has been successfully updated")
        setVariantMessage("success")
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const loginWithFacebook = (accessToken: any) => {
    rootStore.environment.api.loginFacebook(accessToken).then((result: any) => {
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Log in whit Facebook successfully")
        setVariantMessage("success")

        runInAction(() => {
          loginStore.setUser(result.response)
          loginStore.setApiToken(result.response.token.access)
        })
        if (result.response.service_provider) {
          runInAction(() => {
            serviceProviderStore.setServiceProvider(result.response.service_provider)
          })
        }
        if (loginStore.group === 1) {
          history.push(ROUTES_ADMIN.HOME_ADMIN)
        } else {
          history.push(ROUTES.HOME)
        }
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const checkLoginState = () => {
    window.FB.login(function (response: any) {
      if (response.authResponse) {
        let accessToken = response.authResponse.accessToken
        loginWithFacebook(accessToken)
      } else {
        // console.log('User cancelled login or did not fully authorize.');
      }
    }, {scope: 'email'});
  }

  const loginWithGoogle = (accessToken: any, user: any) => {
    rootStore.environment.api.loginGoogle(accessToken, user).then((result: any) => {
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Log in whit Google successfully")
        setVariantMessage("success")
        runInAction(() => {
          loginStore.setUser(result.response)
          loginStore.setApiToken(result.response.token.access)
        })
        if (result.response.service_provider) {
          runInAction(() => {
            serviceProviderStore.setServiceProvider(result.response.service_provider)
          })
        }
        if (loginStore.group === 1) {
          history.push(ROUTES_ADMIN.HOME_ADMIN)
        } else {
          history.push(ROUTES.HOME)
        }
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const googleClicked = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // console.log("firebase.auth().currentUser :::::: ", firebase.auth().currentUser)
        // console.log("firebase.auth().currentUser token:::::: ",
        firebase.auth().currentUser?.getIdTokenResult()
          .then((r) => {
            // console.log("getIdTokenResult token: ", r.token)
            loginWithGoogle(r.token, user)
          })
      }
    })
    signInWithGoogle()
    // .then(r => console.log("signInWithGoogle : ", r))
  }

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: FACEBOOK_APP_ID,
        cookie: true,  // enable cookies to allow the server to access
        // the session
        xfbml: true,  // parse social plugins on this page
        version: FACEBOOK_VERSION
      });
    };

    // Load the SDK asynchronously
    (function (d: any, s: any, id: any) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = FACEBOOK_SRC;
      fjs.parentNode.insertBefore(js, fjs);
    }(document, "script", "facebook-jssdk"));
  }, []);

  return (
    <PageWebDark login_required={false}>
      <div className={'d-flex flex-column vh-100'}>
        <div className={'col-12 mt-3'}>
          <div className={'row'}>
            <div className={"col"}>
              <Link to="/signup" className={classes.hoverGreenBtn + ' d-flex align-items-center'}>
                <span>Sign up</span>
                <IoShareOutline style={{width: 30, height: 30}}/>
              </Link>

            </div>
            <div className={["col", styles.logIn].join(' ')}>
              <Link to="/login" className={classes.hoverGreenBtn}>
                <span>Log in</span>
                <MdLogin style={{width: 30, height: 30}}/>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <img src={logoShort} alt="" className={styles.logoHoushares}/>
          <img src={logoBig} alt="" className={styles.logoBigHouseShares}/>
        </div>
        <div className={'card my-auto'}
             style={{
               color: COLORS.white,
               backgroundColor: COLORS.black,
               borderColor: COLORS.black,
             }}>
          <div className={'card-body'}>
            <p style={{textAlign: "center", fontWeight: "bold", fontSize: "xx-large"}}>Log in</p>
            <div>
              <div className={'form-group col-md-6 col-sm-12 mx-auto'}>
                <div>
                  <div>
                    <label className={''}>Email</label>
                  </div>
                  <div style={{marginTop: "10px"}}>
                    <input
                      className={'form-control white-text-input'}
                      value={Email}
                      onChange={(evt) => setEmail(evt.target.value)}
                      type={'email'}
                      placeholder={''}
                      style={{
                        backgroundColor: COLORS.gray,
                        color: COLORS.white,
                        borderRadius: "12px",
                        height: 37,
                        opacity: 1
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={'form-group col-md-6 mx-auto'}>
                <div style={{marginTop: "15px", width: "100%"}}>
                  <div>
                    <label className={''}>Password</label>
                  </div>
                  <div style={{display: "flex", marginTop: "10px"}}>
                    <input
                      type={ShowPassword ? "string" : "password"}
                      className={'form-control'}
                      value={Password}
                      onChange={(evt) => setPassword(evt.target.value)}
                      onKeyPress={evt => {
                        if (evt.key === 'Enter') {
                          startSession()
                        }
                      }}
                      style={{
                        backgroundColor: COLORS.gray,
                        color: COLORS.white,
                        borderColor: COLORS.white,
                        borderRightStyle: "none",
                        borderRadius: "12px 0 0 12px",
                      }}
                    />

                    <Button variant="outline-secondary" onClick={() => {
                      setShowPassword(!ShowPassword);
                    }} style={{
                      backgroundColor: COLORS.gray,
                      borderLeftStyle: "none",
                      borderRightColor: COLORS.white,
                      borderTopColor: COLORS.white,
                      borderBottomColor: COLORS.white,
                      borderRadius: "0  12px 12px 0",
                    }}>
                      {ShowPassword ? <FaRegEye style={{color: COLORS.white}}/> :
                        <FaRegEyeSlash style={{color: COLORS.white}}/>}

                    </Button>
                  </div>
                </div>
              </div>

            </div>

            <div className={'row'}>
              <div className={'form-group mt-3 container row'}>
                <div className={'form-group col-md-6 mx-auto'}>
                  <div className={"row"}>
                    <div className={"col-6"}>
                      <Button className={'btn btn-secondary btn-block ms-3'}
                              disabled={Loading}
                              style={{
                                minHeight: 42,
                                backgroundColor: COLORS.black,
                                borderColor: COLORS.black
                              }}
                              onClick={handleForgot}
                      >
                                <span
                                  style={{color: COLORS.green}}>Forgot password
                                </span>
                      </Button>
                    </div>
                    <div className={"col-6"} style={{textAlign: "end"}}>
                      <Button className={'btn btn-primary btn-block'}
                              disabled={Loading}
                              onClick={() => startSession()}
                              style={{
                                minHeight: 42,
                                backgroundColor: COLORS.black,
                                borderColor: COLORS.black
                              }}>
                        {Loading && <Spinner animation="border" variant="light"
                                             size={'sm'}/>}
                        {!Loading &&
                          <span style={{color: COLORS.green}}>Log in</span>}
                      </Button>
                    </div>
                  </div>
                  <div style={{textAlign: "center", marginTop: "50px"}}>
                    <p>Or continue using</p>
                    <div className={classes.hoverGreenBtn}
                         onClick={googleClicked}
                    >
                      Google
                    </div>
                    <div className={classes.hoverGreenBtn} onClick={checkLoginState}>
                      Facebook
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showForgotPass} size="sm" onHide={handleCloseForgotPassword} centered>
        <ModalBody style={{backgroundColor: COLORS.gray, color: COLORS.white}}>
          <div className={"row mx-auto"}>
            <div className={"col-12"} style={{textAlign: "center", fontWeight: "bold"}}>Forgot password</div>
            <div className={"col-12"} style={{textAlign: "center", marginTop: 10}}>
              <img src={lock} alt="" style={{width: 30, height: 40}}/>
            </div>
          </div>
          <div className={'d-flex flex-column justify-content-center align-items-center'}>
            <div className={'w-100'}>
              <label className={'mb-1'}>Email</label>
              <input
                className={'form-control white-text-input'}
                value={EmailReset}
                onChange={(evt) => setEmailReset(evt.target.value)}
                type={'email'}
                placeholder={''}
                style={{
                  backgroundColor: COLORS.lightGray,
                  color: COLORS.white,
                  borderRadius: 12,
                  height: 37,
                  opacity: 1
                }}
              />
            </div>
            {EmailResetErrors && EmailResetErrors.length > 0 && EmailResetErrors.map((error: string) =>
              <div style={{
                color: COLORS.red,
                marginTop: "5px",
                textAlign: "center"
              }}>{error}</div>)
            }
            <Button
              disabled={LoadingForgot}
              onClick={() => forgotPassword()}
              className={'btn btn-secondary btn-block mx-auto mt-2'}
              style={{backgroundColor: COLORS.gray, borderColor: COLORS.gray}}
            >
              {LoadingForgot &&
                <Spinner animation="border" variant="light" size={'sm'}/>}
              {!LoadingForgot &&
                <span style={{color: COLORS.white}}>
                  Reset password
                </span>
              }
            </Button>
            <div style={{textAlign: "center", marginTop: "10px"}}>
              <span style={{fontSize: 12}}>Send email with new temporary password</span>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal show={showResetPass} size="lg" centered>
        <ModalBody style={{backgroundColor: COLORS.gray, color: COLORS.white}}>
          <div className={"col-12"} style={{textAlign: "center", fontWeight: "bold"}}>Forgot password</div>
          <div className={"col-12"} style={{textAlign: "center", marginTop: 10}}>
            <img src={lock} alt="" style={{width: 30, height: 40}}/>
          </div>
          <div style={{textAlign: "center"}}>
                        <span style={{color: COLORS.white}}>You have received an email with the new temporal password
                            to reset your password here</span>
          </div>
          <Formik
            initialValues={{
              otp: '',
              password: '',
              confirmPassword: '',
            }}
            onSubmit={
              (values: IResetForm) => {
                resetPassword(values)
              }
            }
            validationSchema={schemaResetPassword}

          >
            {(props: FormikProps<IResetForm>) => {
              const {
                // values,
                // touched,
                // errors,
                // handleBlur,
                // handleChange,
                isSubmitting,
              } = props
              return (
                <Form style={{textAlign: "left"}}>
                  <Field name="otp" label={"Temporary password"}
                         setShowPassword={setShowOtpPassword}
                         showPassword={showOtpPassword} startFunction={startSession}
                         bold={false} backColor={"modal"}
                         onKeyPressFunction={false} component={InputPasswordForm}/>
                  <Field name="password" label={"New password"}
                         setShowPassword={setShowNewPassword}
                         showPassword={showNewPassword} startFunction={startSession}
                         bold={false} backColor={"modal"}
                         onKeyPressFunction={false} component={InputPasswordForm}/>
                  <div style={{marginTop: "15px"}}>
                    <Field name="confirmPassword" label={"Confirm password"}
                           setShowPassword={setShowConfirmPassword}
                           showPassword={showConfirmPassword} startFunction={startSession}
                           bold={false} backColor={"modal"}
                           onKeyPressFunction={false} component={InputPasswordForm}/>
                  </div>

                  <div style={{textAlign: "center", marginTop: "20px"}}>
                    <Button disabled={LoadingReset}
                            type={"submit"}
                            style={{
                              backgroundColor: COLORS.gray,
                              color: COLORS.white,
                              borderColor: COLORS.white
                            }}>
                      {LoadingForgot &&
                        <Spinner animation="border" variant="light" size={'sm'}/>}
                      {!LoadingForgot &&
                        <span style={{fontSize: 15}}>
                                                           Reset password
                                                       </span>}
                    </Button>
                  </div>

                </Form>
              )
            }}
          </Formik>
        </ModalBody>
      </Modal>


      <ToastComponent message={message} close={close} show={show} variant={variantMessage} delay={0}/>
    </PageWebDark>
  );
});
