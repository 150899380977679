import {Button} from "react-bootstrap";
import React from "react";
import {COLORS} from "../../constants/constants";
import {FaRegEye, FaRegEyeSlash} from "react-icons/fa";
import {FieldInputProps, FormikProps} from "formik";
import useStyles from "./styles";

type InputPasswordProps = {
  field: FieldInputProps<any>,
  form: FormikProps<any>,
  label: string,
  onKeyPressFunction: boolean,
  showPassword: boolean,
  setShowPassword: (arg: boolean) => void,
  startFunction: (arg: void) => void
  bold: boolean,
  backColor: string /*modal, dark or light*/
};

export const InputPasswordForm: React.FC<InputPasswordProps> = React.forwardRef((props, ref) => {

  let classes = useStyles();

  return (
    <div style={{marginTop: "15px", width: "100%"}}>
      <div>
        <label className={props.bold ? classes.labelPasswordBold : classes.labelPassword}>{props.label}</label>
      </div>
      <div style={{display: "flex", marginTop: "10px"}}>
        {props.onKeyPressFunction &&
          <input
            type={props.showPassword ? "string" : "password"}
            className={'form-control'}
            onKeyPress={evt => {
              if (evt.key === 'Enter') {
                props.startFunction()
              }
            }}

            {...props.field}

            style={{
              backgroundColor: COLORS.gray,
              color: COLORS.white,
              borderColor: COLORS.white,
              borderRightStyle: "none",
              borderRadius: "12px 0 0 12px",
            }}
          />
        }
        {!props.onKeyPressFunction &&
          <input
            type={props.showPassword ? "string" : "password"}
            className={'form-control'}
            {...props.field}
            style={{
              backgroundColor: COLORS.gray,
              color: COLORS.white,
              borderRightStyle: "none",
              borderRadius: "12px 0 0 12px",
              borderColor: COLORS.white
            }}
          />
        }

        <Button variant="outline-secondary" onClick={() => {
          props.setShowPassword(!props.showPassword);
        }} style={{
          backgroundColor: COLORS.gray,
          borderLeftStyle: "none",
          borderRightColor: COLORS.white,
          borderTopColor: COLORS.white,
          borderBottomColor: COLORS.white,
          borderRadius: "0  12px 12px 0",
          borderColor: COLORS.white
        }}>
          {props.showPassword ? <FaRegEye style={{color: COLORS.white}}/> :
            <FaRegEyeSlash style={{color: COLORS.white}}/>}

        </Button>
      </div>
      {props.form.errors[props.field.name] && props.form.touched[props.field.name] ? (
        <div
          className={props.backColor === "modal" ? classes.errorModal : classes.error}>{props.form.errors[props.field.name]}</div>
      ) : null}
    </div>
  )
});

export default InputPasswordForm;
