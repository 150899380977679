import {Toast, ToastContainer} from "react-bootstrap";
import React from "react";
import {BsCheckCircle, BsXCircle} from "react-icons/bs";
import {COLORS} from "../../constants/constants";

type InputProps = {
    message: string,
    close: () => void,
    show: boolean,
    variant: string,
    delay: number,
};

export const ToastComponent: React.FC<InputProps> = React.forwardRef((props,ref) => {

    let background;
    let icon;
    if (props.variant === 'success'){
        background = COLORS.white
        icon = <BsCheckCircle size={"2.5rem"} color={COLORS.green}/>
    }
    else if (props.variant === 'warning' || props.variant === 'danger'){
        background = COLORS.black
        icon = <BsXCircle size={"2.5rem"} color={COLORS.orange}/>
    }
    else {
        background = COLORS.black
        icon = <BsXCircle size={"2.5rem"} color={COLORS.orange}/>
    }
    return (
        <ToastContainer className="p-3" position={'middle-center'} style={{zIndex: "10000"}}>
            <Toast onClose={props.close} show={props.show}
                   delay={props.delay>0?props.delay:4000} autohide
                   style={{backgroundColor: background}}>
                <div className={"toast-header"} style={{backgroundColor: background}}>
                    <button type="button" className="ms-auto btn-close" data-bs-dismiss="toast"
                            aria-label="Close" onClick={props.close}></button>
                </div>
                <Toast.Body className={background !== COLORS.white ? 'text-white' : ""} style={{minHeight: "10rem",
                            textAlign: "center"}}>
                    <div style={{textAlign: "center"}}>
                        <p>{icon}</p>
                    </div>
                    <strong className="me-auto">{props.message}</strong>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
});

export default ToastComponent;
