import React from 'react';
import {Col, Form, Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';

import "./InputSliderBar.css"
import {cantidad_fmt, plata_fmt} from "../../helpers";

type InputSlideProps = {
  label: string,
  value: number,
  updateValue: (arg: number) => void
  min: number,
  max: number,
  moneyFormat?: boolean
};

export const InputSlideBar: React.FC<InputSlideProps> = React.forwardRef((props, ref) => {

  const moneyFormat = props.moneyFormat || false


  return (
    <div>
      <Form.Group>
        <div>
          <Form.Label style={{fontWeight: "bold", width:120}}>
            {props.label}
          </Form.Label>
        </div>
        <Form.Group as={Row}>
          <Col>Min: {moneyFormat ? plata_fmt(props.min) : cantidad_fmt(props.min)}</Col>
          <Col lg={7} md={6} sm={4}>
            <div className={'slidecontainer mt-3'}>
              <input
                type="range"
                min={props.min}
                max={props.max}
                value={props.value}
                onChange={e => props.updateValue(parseInt(e.target.value, 10))}
                className="slider"
              />
              <p className={'mt-2'}>Value: {moneyFormat ? plata_fmt(props.value) : cantidad_fmt(props.value)}</p>
            </div>
          </Col>
          <Col>Max: {moneyFormat ? plata_fmt(props.max) : cantidad_fmt(props.max)}</Col>
        </Form.Group>
      </Form.Group>
    </div>
  )
});

export default InputSlideBar;
