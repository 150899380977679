import {makeStyles} from "@material-ui/styles";

export default makeStyles({

    "reac":
        {
            // backgroundColor:"violet",
            "&:checked":
                {
                    // backgroundColor:"#0d6efd",
                    borderColor: "red !important",
                    backgroundColor: "red !important",
                },
        },

    "switchDiv": {
        height: "38px",
    },

    "@media (max-width: 993px)": {
        "switchDiv": {
            height: "38px",
            marginBottom: "45px",
        },
    },

    "@media (max-width: 768px)": {
        "switchDiv": {
            height: "38px",
            marginBottom: "0px",
        },
    },

});