import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../constants/constants";

export default makeStyles((theme) => ({

    dropDown: {
        color: COLORS.white,
        marginTop: "20px",
    },
    dropdownLabel: {
        backgroundColor: COLORS.black,
        borderColor: COLORS.black,
        fontSize: "12px"
    },
    dropdownToggle: {
        backgroundColor: COLORS.black,
        borderColor: COLORS.black,
        fontSize:"12px",
        "&:hover, &:focus, &:active": {
            cursor: "pointer",
            color: COLORS.green,
            backgroundColor: "transparent",
            borderColor: "transparent"
        },
    },
    dropdownMenu: {
        backgroundColor: COLORS.black,
        borderColor: COLORS.white
    },
    dropdownItem: {
        color: COLORS.white,
        fontSize:"12px",
    },
    btnNextPrev: {
        backgroundColor: "transparent",
        color: COLORS.white,
        borderColor: "transparent",
        "&:hover, &:focus, &:active": {
            cursor: "pointer",
            color: COLORS.green,
            backgroundColor: "transparent",
            borderColor: "transparent"
        },
        "&:disabled": {
            backgroundColor: "transparent",
            borderColor: "transparent"
        },
    }
}));