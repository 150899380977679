import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import useStyles from "../styles"
import {BOGOTA_CITY, COLOMBIA_ID, COLORS} from "../../../constants/constants";
import AdminSearch from "../../../components/AdminSearch/AdminSearch";
import {BsHouse} from "react-icons/bs";
import ButtonSquare from "../../../components/Buttons/ButtonSquare";
import {Button, CloseButton, Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {Field, Form, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import InputTextFormModal from "../../../components/Inputs/InputTextFormModal";
import InputNumberFormModal from "../../../components/Inputs/InputNumberFormModal";
import ButtonSquareForm from "../../../components/Buttons/ButtonSquareForm";
import {useStores} from "../../../models/root-store/root-store-context";
import ButtonDark from "../../../components/Buttons/ButtonDark";
import {RiDeleteBin5Line} from "react-icons/ri";
import AdminPagination from "../../../components/AdminPagination/AdminPagination";
import ButtonModal from "../../../components/Buttons/ButtonModal";
import {MdOutlineAddPhotoAlternate} from "react-icons/md";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import defaultImage from "../../../assets/images/default_Image.png"
import {OptionPlaceType} from "../../../constants/types";
import ReactSelectAdminFormModal from "../../../components/Select/ReactSelectAdminFormModal";
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";
import {plata_fmt} from "../../../helpers";
import AdminItemField from "../../../components/Inputs/AdminItemField";

interface IProperty {
  name: string,
  rentPrice: string,
  totalPrice: string,
  address: string,
  floor: number,
  size: number,
  rooms: number,
  bathrooms: number,
  parkingSpaces: number,
  constructionYear: number,
  googleMapLink: string,
  rentValueIndex: number,
  initialValueEachACTI: number,
  appraisalIndex: number
}

const schemaProperty = Yup.object().shape({
  name: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  floor: Yup.number().required('Required').test(
    'Is positive?',
    'No negative numbers allowed',
    (value) => value !== undefined && value >= 0
  ),
  size: Yup.number().required('Required').test(
    'Is positive?',
    'The size must be greater than 0',
    (value) => value !== undefined && value > 0
  ),
  rooms: Yup.number().required('Required').test(
    'Is positive?',
    'No negative numbers allowed',
    (value) => value !== undefined && value >= 0
  ),
  bathrooms: Yup.number().required('Required').test(
    'Is positive?',
    'No negative numbers allowed',
    (value) => value !== undefined && value >= 0
  ),
  parkingSpaces: Yup.number().required('Required').test(
    'Is positive?',
    'No negative numbers allowed',
    (value) => value !== undefined && value >= 0
  ),
  constructionYear: Yup.number().required('Required').test(
    'Is positive?',
    'The year must be greater than 1900',
    (value) => value !== undefined && value > 1900
  ),
  googleMapLink: Yup.string().required('Required'),
  rentValueIndex: Yup.number().required('Required').test(
    'Is positive?',
    'No negative numbers or zero allowed',
    (value) => value !== undefined && value > 0
  ),
  appraisalIndex: Yup.number().required('Required').test(
    'Is positive?',
    'No negative numbers or zero allowed',
    (value) => value !== undefined && value > 0
  ),
})

export const PropertyManagement: React.FC<RouteComponentProps> = observer(({history}) => {

  const rootStore = useStores()
  let classes = useStyles();
  const [data, setData] = useState<any>([]);
  const [Loading, setLoading] = useState<boolean>(false);
  const [valuesToSearch, setValuesToSearch] = useState<string>("");
  const [showConfirmDeleteMessage, setShowConfirmDeleteMessage] = useState<boolean>(false);
  const [propertyToDelete, setPropertyToDelete] = useState<any>({});
  const [propertyToViewEdit, setPropertyToViewEdit] = useState<any>({});
  const [viewEditPropertyMode, setViewEditPropertyMode] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [previousPage, setPreviousPage] = useState<string | null>("");
  const [nextPage, setNextPage] = useState<string | null>("");
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");
  const [showAddProperty, setShowAddProperty] = useState<boolean>(false);
  const [LoadingAddProperty, setLoadingAddProperty] = useState<boolean>(false);
  const [LoadingEditProperty, setLoadingEditProperty] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<(Blob | MediaSource | undefined)[]>()
  const [filesToSave, setFilesToSave] = useState<(Blob | MediaSource | undefined)[]>()
  const [preview, setPreview] = useState<any>([])
  const [cities, setCities] = useState<any>();
  const [idCity, setIdCity] = useState(BOGOTA_CITY);
  const [filesToDelete, setFilesToDelete] = useState<any>([]);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [imageToShow, setImageToShow] = useState<any>({});

  const setResultListToData = (result: any) => {
    let datos: any = [];
    result.data.results.map((resultItem: any) => {
      datos.push({
        id: resultItem.id,
        name: resultItem.name,
        rentPrice: resultItem.rent_price,
        totalPrice: resultItem.total_price,
        address: resultItem.address,
        floor: resultItem.floor,
        size: resultItem.size,
        rooms: resultItem.rooms,
        bathrooms: resultItem.bathrooms,
        parkingSpaces: resultItem.parking_spaces,
        constructionYear: resultItem.construction_year,
        googleMapLink: resultItem.google_map_link,
        image: resultItem.images?.length !== 0 ? resultItem.images[0].image : defaultImage,
        city: resultItem.city,
        rentValueIndex: resultItem.rent_value_index,
        initialValueEachACTI: resultItem.initial_value_each_acti,
        appraisalIndex: resultItem.apraisal_proxy_index
      })
    })
    setData(datos)
  }

  const setResultToData = (result: any) => {
    let property = {
      id: result.id,
      name: result.name,
      rentPrice: result.rent_price,
      totalPrice: result.total_price,
      address: result.address,
      floor: result.floor,
      size: result.size,
      rooms: result.rooms,
      bathrooms: result.bathrooms,
      parkingSpaces: result.parking_spaces,
      constructionYear: result.construction_year,
      googleMapLink: result.google_map_link,
      city: result.city,
      hasContract: result.has_contract,
      rentValueIndex: result.rent_value_index,
      initialValueEachACTI: result.initial_value_each_acti,
      appraisalIndex: result.apraisal_proxy_index
    }
    setPropertyToViewEdit(property);
    setIdCity(result.city)

    if (result.images?.length !== 0) {
      let previewTmp: any[] = []
      result.images.map((imgItem: { id: any; image: any; }) => {
        previewTmp.push({id: imgItem.id, image: imgItem.image})
      })
      setPreview(previewTmp)
      // console.log("previewTmp: ", previewTmp)
    }
  }

  const getProperties = (rows: number) => {
    setLoading(true)
    rootStore.environment.api.getPropertyList(rows).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setTotalPages(Math.ceil(result.data.count / rows))
        setCurrentPage(1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const getCitiesByCountry = (idCountry: number): Array<OptionPlaceType> => {
    let arrayTmp: { value: number; label: string; }[] = []
    rootStore.environment.api.getCitiesByCountry(idCountry).then((result: any) => {
      if (result.kind === "ok") {
        result.data.map((item: any) => {
          arrayTmp.push({value: item.id, label: item.name})
        })
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
    return arrayTmp
  }

  useEffect(() => {
    getProperties(rowsPerPage)
    let citiesTmp = getCitiesByCountry(COLOMBIA_ID)
    setCities(citiesTmp)
  }, []);

  const getPropertySpecific = (idProperty: number) => {
    rootStore.environment.api.getProperty(idProperty).then((result: any) => {
      if (result.kind === "ok") {
        setResultToData(result.data)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const search = (rows: number) => {
    setLoading(true)
    rootStore.environment.api.getPropertiesAdminSearch(valuesToSearch, rows).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setCurrentPage(1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
        setTotalPages(Math.ceil(result.data.count / rows))
        // setData(result.data.results)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const getPropertiesPrevNextPage = (path: string, offset: number) => {
    setLoading(true)
    rootStore.environment.api.getPropertyListPrevNextPage(path).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setCurrentPage(Math.floor(offset / rowsPerPage) + 1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFiles(undefined)
      return
    }
    setSelectedFiles(e.target.files)
  }

  const deleteFilePreview = (e: React.Key | null | undefined) => {
    let filesToDeleteTmp = [...filesToDelete]
    if (e !== null && e !== undefined && e >= 0) {
      filesToDeleteTmp.push({id: preview[e].id, url: preview[e].image, deleted: true})
    }
    setFilesToDelete(filesToDeleteTmp)
    const s = preview.filter((item: any, idx: any) => idx !== e);
    setPreview(s);
  }

  useEffect(() => {
    if (!selectedFiles) {
      setPreview(undefined)
      return
    }

    if (filesToSave !== undefined) {
      const joined = Array.from(filesToSave).concat(Array.from(selectedFiles));
      setFilesToSave(joined)
    } else {
      setFilesToSave(selectedFiles)
    }

    let filesArrayTmp = preview === undefined ? [] : [...preview]

    Array.from(selectedFiles).forEach(selectedItem => {
      if (selectedItem !== undefined) {
        filesArrayTmp.push({id: 0, image: URL.createObjectURL(selectedItem)})
      }
    });
    setPreview(filesArrayTmp)

    // free memory when ever this component is unmounted
    return () => {
      if (filesArrayTmp) {
        filesArrayTmp.map((itemFile) => {
          if (typeof itemFile === "string") {
            URL.revokeObjectURL(itemFile)
          }
        })
      }
    }

  }, [selectedFiles])

  const addProperty = (values: any) => {
    const dataStored = {...propertyToViewEdit}
    const cleanData = () => {
      let data: any = {}
      let keys: string[] = []
      //data
      data["name"] = values.name
      data["rent_price"] = dataStored.rentPrice
      data["total_price"] = dataStored.totalPrice
      data["address"] = values.address
      data["floor"] = values.floor
      data["size"] = values.size
      data["rooms"] = values.rooms
      data["bathrooms"] = values.bathrooms
      data["parking_spaces"] = values.parkingSpaces
      data["construction_year"] = values.constructionYear
      data["google_map_link"] = values.googleMapLink
      data["rent_value_index"] = values.rentValueIndex
      data["initial_value_each_acti"] = dataStored.initialValueEachACTI
      data["apraisal_proxy_index"] = values.appraisalIndex
      data["city"] = idCity
      if (filesToSave !== null) {
        data["images"] = filesToSave
      }

      //keys
      keys.push("name")
      keys.push("rent_price")
      keys.push("total_price")
      keys.push("address")
      keys.push("floor")
      keys.push("size")
      keys.push("rooms")
      keys.push("bathrooms")
      keys.push("parking_spaces")
      keys.push("construction_year")
      keys.push("google_map_link")
      keys.push("city")
      keys.push("rent_value_index")
      keys.push("initial_value_each_acti")
      keys.push("apraisal_proxy_index")
      if (filesToSave !== undefined) {
        keys.push("images")
      }
      return {data: data, keys: keys}
    }

    setLoadingAddProperty(true)
    const cleanedData = cleanData()
    rootStore.environment.api.addProperty(cleanedData.data, cleanedData.keys).then((result: any) => {
      setLoadingAddProperty(false)
      setLoadingAddProperty(false)
      setShowAddProperty(false)
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Data successfully saved")
        setVariantMessage("success")
        window.location.reload()
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else if (result.errors.phone_number) {
            setShow(true)
            setMessage(result.errors.phone_number)
            setVariantMessage("warning")
          } else if (result.errors.rent_value_index) {
            setShow(true)
            setMessage("Rent value index: " + result.errors.rent_value_index)
            setVariantMessage("warning")
          } else if (result.errors.initial_value_each_acti) {
            setShow(true)
            setMessage("Initial value each ACTI: " + result.errors.initial_value_each_acti)
            setVariantMessage("warning")
          } else if (result.errors.apraisal_proxy_index) {
            setShow(true)
            setMessage("Appraisal proxy index: " + result.errors.apraisal_proxy_index)
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
    setSelectedFiles(undefined)
    setFilesToSave(undefined)
    setPreview([])
    setIdCity(0)
  }

  const editProperty = (values: any) => {

    let objectFilesToSave: any = []
    if (filesToSave !== undefined) {
      filesToSave.forEach((fileItem) => {
        objectFilesToSave.push({file: fileItem})
      })
    }

    if (preview !== undefined) {
      preview.forEach((previewItem: { id: number; image: any; }) => {
        if (previewItem.id !== 0) {
          objectFilesToSave.push({id: previewItem.id, url: previewItem.image, deleted: false})
        }
      })
    }

    if (filesToDelete?.length > 0) {
      objectFilesToSave = objectFilesToSave.concat(filesToDelete)
    }
    const dataStored = {...propertyToViewEdit}
    const cleanData = () => {
      let data: any = {}
      let keys: string[] = []
      //data
      data["name"] = values.name
      data["rent_price"] = dataStored.rentPrice
      data["total_price"] = dataStored.totalPrice
      data["address"] = values.address
      data["floor"] = values.floor
      data["size"] = values.size
      data["rooms"] = values.rooms
      data["bathrooms"] = values.bathrooms
      data["parking_spaces"] = values.parkingSpaces
      data["construction_year"] = values.constructionYear
      data["google_map_link"] = values.googleMapLink
      data["rent_value_index"] = values.rentValueIndex
      data["initial_value_each_acti"] = dataStored.initialValueEachACTI
      data["apraisal_proxy_index"] = values.appraisalIndex
      data["city"] = idCity
      if (filesToSave !== undefined || preview != undefined) {
        data["images"] = objectFilesToSave
      }

      //keys
      keys.push("name")
      keys.push("rent_price")
      keys.push("total_price")
      keys.push("address")
      keys.push("floor")
      keys.push("size")
      keys.push("rooms")
      keys.push("bathrooms")
      keys.push("parking_spaces")
      keys.push("construction_year")
      keys.push("google_map_link")
      keys.push("city")
      keys.push("rent_value_index")
      keys.push("initial_value_each_acti")
      keys.push("apraisal_proxy_index")
      if (filesToSave != null) {
        keys.push("images")
      }
      return {data: data, keys: keys}
    }

    setLoadingEditProperty(true)
    const cleanedData = cleanData()
    rootStore.environment.api.updateProperty(propertyToViewEdit.id, cleanedData.data, cleanedData.keys).then((result: any) => {
      setLoadingEditProperty(false)
      setViewEditPropertyMode(false)
      setShowAddProperty(false)
      setPropertyToViewEdit({})
      if (result.kind === "ok") {
        // window.location.reload()
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
    setSelectedFiles(undefined)
    setFilesToSave(undefined)
    setPreview([])
    setIdCity(0)
  }

  const startEditViewMode = (idProperty: number) => {
    setViewEditPropertyMode(true)
    getPropertySpecific(idProperty)
  }

  const deletePropertyClick = (userItem: any) => {
    setPropertyToDelete(userItem)
    setShowConfirmDeleteMessage(true)
  }

  const deleteProperty = () => {
    rootStore.environment.api.deleteProperty(propertyToDelete.id).then((result: any) => {
      if (result.kind === "ok") {
        setShow(true)
        setMessage(`Property "${propertyToDelete.name}" has been successfully deleted`)
        setVariantMessage("success")
        window.location.reload();
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
    setPropertyToDelete({})
    setShowConfirmDeleteMessage(false)
  }

  const closePropertyModal = () => {
    setPropertyToViewEdit({})
    setViewEditPropertyMode(false)
    setShowAddProperty(false)
    setSelectedFiles(undefined)
    setFilesToSave(undefined)
    setPreview([])
  }

  const closeConfirmDeleteMessage = () => {
    setShowConfirmDeleteMessage(false)
  }

  const handlePrevPageClicked = () => {
    let from = previousPage?.substring(previousPage?.indexOf("offset") + 7);
    let path = previousPage?.substring(previousPage?.indexOf("v1") + 2);
    if (path != undefined) {
      if (from != undefined && !isNaN(parseInt(from))) {
        getPropertiesPrevNextPage(path, parseInt(from))
      } else {
        getPropertiesPrevNextPage(path, 1)
      }
    }
  }

  const handleNextPageClicked = () => {
    let from = nextPage?.substring(nextPage?.indexOf("offset") + 7);
    let path = nextPage?.substring(nextPage?.indexOf("v1") + 2);

    if (path != undefined) {
      if (from != undefined && !isNaN(parseInt(from))) {
        getPropertiesPrevNextPage(path, parseInt(from))
      } else {
        getPropertiesPrevNextPage(path, 1)
      }
    }
  }

  const handleRowsPerPageClicked = (rows: number) => {
    setRowsPerPage(rows)
    if (valuesToSearch != "") {
      search(rows)
    } else {
      getProperties(rows)
    }
  }

  const closeMessage = () => {
    setShow(false)
  }

  const openImage = (idx: React.Key | null | undefined) => {
    if (idx != null && idx >= 0) {
      setImageToShow(preview[idx].image)
    }
    setShowImage(true)
  }

  const closeImage = () => {
    setShowImage(false)
  }

  const setDato = (campo: string, valor: string) => {
    let datos = {...propertyToViewEdit}
    // @ts-ignore
    datos[campo] = valor
    setPropertyToViewEdit(datos)
  }

  const setDatoInput = (campo: string, data: any) => {
    setDato(campo, data)
  }

  const PropertyItems = (currentItems: any) => {
    return (
      <>
        {currentItems.currentItems?.length != 0 &&
          <div style={{marginTop: "20px"}}>
            {currentItems.currentItems.map((item: any, idx: number) => {
              return (
                <div key={idx} className={["row", classes.list].join(" ")}>
                  <div className={"col-1 portrait"}>
                    <img
                      key={idx}
                      src={item.image}
                      alt="Property image"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        height: "80px",
                        width: "130px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div className={"col-3 text-left"}>{item.name}</div>
                  <div className={"col-3 text-center"}>{plata_fmt(item.rentPrice)}</div>
                  <div className={"col-3 text-center"}>{plata_fmt(item.totalPrice)}</div>
                  <div className={"col-2 text-center"}>
                    <ButtonDark startFunction={() => deletePropertyClick(item)}
                                bold={false}>
                      <RiDeleteBin5Line/>
                    </ButtonDark>
                    <ButtonDark startFunction={() => startEditViewMode(item.id)}
                                bold={false}>
                      View/Edit
                    </ButtonDark>
                  </div>
                </div>
              );
            })}

          </div>
        }
      </>
    );
  }

  return (
    <PageWebAdminDark>

      <div className={"row"}
      >
        <div className={"col-7"}
             style={{color: COLORS.white, fontSize: "xx-large"}}>
          <BsHouse/> Property Management
        </div>
        <div className={"col-2"}
             style={{marginTop: "8px", textAlign: "center"}}
        >
          <ButtonSquare label={"Add Property"} startFunction={() => setShowAddProperty(true)} bold={true}/>
        </div>
        <div className={"col-3"}
             style={{display: "flex", justifyContent: "center"}}
        >
          <AdminSearch valuesToSearch={valuesToSearch} setValuesToSearch={setValuesToSearch}
                       search={search} rowsPerPage={rowsPerPage}/>
        </div>
      </div>
      {!Loading && data?.length == 0 &&
        <NoDataAdmin/>}
      {Loading &&
        <SpinnerLight/>
      }
      {!Loading && data && data?.length > 0 &&
        <div>
          <div className={["row", classes.listTittles].join(" ")}>
            <div className={"col-1 text-center"}>Image</div>
            <div className={"col-3 text-center"}>Property</div>
            <div className={"col-3 text-center"}>Rent Price</div>
            <div className={"col-3 text-center"}>Total Price</div>
            <div className={"col-2 text-center"}>Edit</div>
          </div>
          <PropertyItems currentItems={data}/>
          <AdminPagination rowsPerPage={rowsPerPage} handleRowsPerPageClicked={handleRowsPerPageClicked}
                           currentPage={currentPage} handleNextPageClicked={handleNextPageClicked}
                           handlePrevPageClicked={handlePrevPageClicked} nextPage={nextPage}
                           previousPage={previousPage} totalPages={totalPages}/>
        </div>
      }
      <Modal centered show={showAddProperty || viewEditPropertyMode}>
        <ModalHeader className={classes.modalPropBorderTop}>
          <CloseButton variant="white" onClick={closePropertyModal}/>
        </ModalHeader>
        <ModalBody className={classes.modalPropBorderBottom}>
          <div className={'col-12'}>
            <div style={{margin: "auto", width: "90%"}}>
              {(viewEditPropertyMode && Object.keys(propertyToViewEdit).length != 0 || showAddProperty) &&
                <Formik
                  initialValues={{
                    name: viewEditPropertyMode ? propertyToViewEdit.name : "",
                    rentPrice: viewEditPropertyMode ? propertyToViewEdit.rentPrice : "",
                    totalPrice: viewEditPropertyMode ? propertyToViewEdit.totalPrice : "",
                    address: viewEditPropertyMode ? propertyToViewEdit.address : "",
                    floor: viewEditPropertyMode ? propertyToViewEdit.floor : 0,
                    size: viewEditPropertyMode ? propertyToViewEdit.size : 0,
                    rooms: viewEditPropertyMode ? propertyToViewEdit.rooms : 0,
                    bathrooms: viewEditPropertyMode ? propertyToViewEdit.bathrooms : 0,
                    parkingSpaces: viewEditPropertyMode ? propertyToViewEdit.parkingSpaces : 0,
                    constructionYear: viewEditPropertyMode ? propertyToViewEdit.constructionYear : 0,
                    googleMapLink: viewEditPropertyMode ? propertyToViewEdit.googleMapLink : "",
                    rentValueIndex: viewEditPropertyMode ? propertyToViewEdit.rentValueIndex : 0,
                    initialValueEachACTI: viewEditPropertyMode ? propertyToViewEdit.initialValueEachACTI : 0,
                    appraisalIndex: viewEditPropertyMode ? propertyToViewEdit.appraisalIndex : 0,
                  }}
                  onSubmit={
                    (values: IProperty) => {
                      if (viewEditPropertyMode) {
                        editProperty(values)
                      } else {
                        addProperty(values)
                      }
                    }
                  }
                  validationSchema={schemaProperty}
                >
                  {(props: FormikProps<IProperty>) => {
                    const {
                      isSubmitting,
                    } = props
                    return (
                      <Form>
                        <div style={{textAlign: "right"}}>
                          <Field name={"name"} label={"Property name"} bold={false}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 placeholder={"Property name"} disabled={propertyToViewEdit.hasContract}
                                 component={InputTextFormModal}/>
                          <AdminItemField
                            numero_minimo={0}
                            deshabilitado={!propertyToViewEdit.hasContract}
                            titulo={'Rent price ($)'}
                            tipo={'numero'}
                            campo={'rentPrice'}
                            valor={propertyToViewEdit['rentPrice']}
                            set_dato={setDatoInput}
                            className={"d-flex flex-row justify-content-end mb-3"}
                            horizontal
                          />
                          <AdminItemField
                            numero_minimo={0}
                            deshabilitado={!propertyToViewEdit.hasContract}
                            titulo={'Total price ($)'}
                            tipo={'numero'}
                            campo={'totalPrice'}
                            valor={propertyToViewEdit['totalPrice']}
                            set_dato={setDatoInput}
                            className={"d-flex flex-row justify-content-end mb-3"}
                            horizontal
                          />
                          <Field name={"address"} label={"Address"} bold={false}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 disabled={propertyToViewEdit.hasContract}
                                 placeholder={"Address"} component={InputTextFormModal}/>
                          <Field name={"floor"} label={"Floor"} bold={false}
                                 disabled={propertyToViewEdit.hasContract}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 placeholder={"Floor"} component={InputNumberFormModal}/>
                          <Field name={"size"} label={"Size (m2)"} bold={false}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 disabled={propertyToViewEdit.hasContract}
                                 placeholder={"size (m2)"} component={InputNumberFormModal}/>
                          <Field name={"rooms"} label={"Rooms"} bold={false}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 placeholder={"Number of rooms"} disabled={propertyToViewEdit.hasContract}
                                 component={InputNumberFormModal}/>
                          <Field name={"bathrooms"} label={"Bathrooms"} bold={false}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 placeholder={"Number of bathrooms"} disabled={propertyToViewEdit.hasContract}
                                 component={InputNumberFormModal}/>
                          <Field name={"parkingSpaces"} label={"Parking spaces"} bold={false}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 placeholder={"Number of parking spaces"}
                                 disabled={propertyToViewEdit.hasContract}
                                 component={InputNumberFormModal}/>

                          <Field name={"constructionYear"} label={"Year of construction"}
                                 bold={false} disabled={propertyToViewEdit.hasContract}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 placeholder={"Year of construction"}
                                 component={InputNumberFormModal}/>
                          <Field name={"googleMapLink"} label={"Google Maps link"} bold={false}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 disabled={propertyToViewEdit.hasContract}
                                 placeholder={"Google Maps link"} component={InputTextFormModal}/>

                          <Field name={"rentValueIndex"} label={"Rent-Value Index % (monthly)"} bold={false}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 placeholder={"Rent-Value Index %"} disabled={propertyToViewEdit.hasContract}
                                 component={InputNumberFormModal}/>
                          <AdminItemField
                            numero_minimo={0}
                            deshabilitado={!propertyToViewEdit.hasContract}
                            titulo={'Initial value for each ACTI (unit share) $'}
                            tipo={'numero'}
                            campo={'initialValueEachACTI'}
                            valor={propertyToViewEdit['initialValueEachACTI']}
                            set_dato={setDatoInput}
                            className={"d-flex flex-row justify-content-end mb-3"}
                            horizontal
                          />
                          <Field name={"appraisalIndex"} label={"Appraisal proxy index"} bold={false}
                                 className={"d-flex flex-row justify-content-end mb-3"}
                                 placeholder={"Appraisal proxy index"} disabled={propertyToViewEdit.hasContract}
                                 component={InputNumberFormModal}/></div>
                        <div className={"d-flex flex-row justify-content-end mb-3"} style={{marginTop: "28px"}}>
                          <ReactSelectAdminFormModal label={"City"} option={idCity}
                                                     options={cities} setValue={setIdCity}
                                                     placeholder={"City"}
                                                     className={""}
                          />
                        </div>
                        <div className={classes.divModalHeader} style={{
                          display: "inline-flex", justifyContent: "center", alignItems: "center"
                        }}>
                          <div>
                            <Button
                              className={classes.btnUploadPhotos}>
                              <label>
                                <MdOutlineAddPhotoAlternate className={classes.iconUploadPhotos}/>
                                <input
                                  id="file"
                                  name="archivos"
                                  type="file"
                                  hidden
                                  onChange={onSelectFile}
                                  accept="image/*"
                                  multiple
                                />
                              </label>
                            </Button>
                          </div>
                          <div>
                            Upload photos
                          </div>
                        </div>
                        <div className={"row"}>
                          {preview != undefined && preview?.length != 0 &&
                            preview.map((previewItem: { image: string | undefined; }, idx: React.Key | null | undefined) => {
                              return (
                                <div key={idx} className={["col-4 ", "containerPropertyImg"].join(" ")}>
                                  <img
                                    src={previewItem.image}
                                    alt="Property image"
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      height: "80px",
                                      width: "130px",
                                      objectFit: "contain",
                                    }}
                                    onClick={() => openImage(idx)}
                                  />
                                  <CloseButton onClick={() => deleteFilePreview(idx)}
                                               style={{
                                                 position: "relative",
                                                 left: "86px",
                                                 top: "-74px",
                                                 backgroundColor: COLORS.white
                                               }}/>
                                </div>
                              )
                            })}
                        </div>
                        <div style={{textAlign: "center"}}>
                          <ButtonSquareForm label={"Save"} bold={false}
                                            isSubmitting={isSubmitting}
                                            Loading={viewEditPropertyMode ?
                                              LoadingEditProperty : LoadingAddProperty}/>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>}
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal show={showConfirmDeleteMessage} size={"sm"}>
        <ModalHeader className={classes.modal}>
          <div>
            Are you sure you want to delete the property {propertyToDelete.name}?
          </div>
        </ModalHeader>
        <Modal.Footer className={classes.modal}>
          <ButtonModal startFunction={deleteProperty}>Yes</ButtonModal>
          <ButtonModal startFunction={closeConfirmDeleteMessage}>No</ButtonModal>
        </Modal.Footer>
      </Modal>
      <Modal show={showImage} size={"lg"} onHide={closeImage}>
        <ModalHeader className={classes.modal}>
          <CloseButton variant="white" onClick={closeImage}/>
        </ModalHeader>
        <Modal.Footer className={classes.modal}>
          <img src={imageToShow} style={{width: "100%", height: "100%"}}/>
        </Modal.Footer>
      </Modal>
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
    </PageWebAdminDark>
  )
});

export default PropertyManagement;
