import {types} from "mobx-state-tree"
import {WorkImage} from "./WorkImage";

export const ServceProviderProject = types
  .model("ServceProviderProject")
  .props({
    id: types.identifierNumber,
    name: types.string,
    description: types.string,
    zip_code: types.number,
    work_images: types.array(WorkImage),
  })
