import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {useStores} from "../../../models/root-store/root-store-context";
import {RouteComponentProps} from "react-router";
import {
  COLORS,
  FACEBOOK_APP_ID,
  FACEBOOK_SRC,
  FACEBOOK_VERSION,
  ROUTES,
  ROUTES_ADMIN
} from "../../../constants/constants";
import {Button, Spinner} from "react-bootstrap";
import PageWebDark from "../../../components/PageWeb/PageWebDark";
import {runInAction} from "mobx";

import {Link} from 'react-router-dom';
import logoShort from "../../../assets/images/logo-short.png";
import logoBig from "../../../assets/images/logo-big.png";
import bannerimage from "../../../assets/images/Sign-Up-Big.png"

import styles from "./Signup.module.css";

import useStyles from "./styles";
import {MdLogin} from "react-icons/md";
import {IoShareOutline} from "react-icons/io5";

import {Field, Form, Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import InputPasswordForm from "../../../components/Inputs/InputPasswordForm";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import InputForm from "../../../components/Inputs/InputForm";
import firebase, {signInWithGoogle} from "../../../services/firebase";

interface ISignUpForm {
  password: string
  confirmPassword: string
  email: string
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Enter a valid email'),

  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{7,20}\S$/,
      'Please valid password: At least 8 characters, one uppercase, one lowercase, one special character (like !@#$%^&*()) and no spaces'
    )
    .required(
      'Please valid password: At least 8 characters, one uppercase, one lowercase, one special character (like !@#$%^&*()) and no spaces'
    ),
  confirmPassword: Yup.string()
    .required('Required')
    .test(
      'Password-match',
      'Password must match',
      function (value) {
        return this.parent.password === value
      }
    ),
})

export const Signup: React.FC<RouteComponentProps> = observer(({history}) => {

    let classes = useStyles();

    const rootStore = useStores()
    const {loginStore, serviceProviderStore} = rootStore
    const [Loading, setLoading] = useState(false)
    const [policyChecked, setPolicyChecked] = useState<boolean>(false)
    const [rememberChecked, setRememberChecked] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [variantMessage, setVariantMessage] = useState<string>("");
    const [delay, setDelay] = useState(0);

    const close = () => {
      setShow(false)
    }

    useEffect(() => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: FACEBOOK_APP_ID,
          cookie: true,  // enable cookies to allow the server to access
          // the session
          xfbml: true,  // parse social plugins on this page
          version: FACEBOOK_VERSION
        });
      };

      // Load the SDK asynchronously
      (function (d: any, s: any, id: any) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = FACEBOOK_SRC;
        fjs.parentNode.insertBefore(js, fjs);
      }(document, "script", "facebook-jssdk"));
    }, []);

    const signUp = (email: string, password: string, passwordConfirm: string) => {
      if (!policyChecked) {
        setShow(true);
        setMessage("Please read and agree Privacy policy first")
        setVariantMessage("warning")
      } else {
        setLoading(true)
        rootStore.environment.api.signUp(email, password, passwordConfirm).then((result: any) => {
            setLoading(false)
            if (result.kind === "ok") {
              setShow(true);
              setMessage("User registered successfully, shortly you will " +
                "receive an email with the link to verify your account")
              setVariantMessage("success")
              setDelay(9000)
              runInAction(() => {
                loginStore.setUser(result.user)
                loginStore.setApiToken(result.access_token)
                if (rememberChecked) {
                  localStorage.setItem("user", result.user);
                }
              })
              setTimeout(() => {
                history.replace(ROUTES.LOGIN)
              }, 8000);
            } else {
              if (result.kind === "bad-data") {
                if (result.errors.non_field_errors) {
                  setShow(true);
                  setMessage(result.errors.non_field_errors[0])
                  setVariantMessage("warning")
                } else if (result.errors.email) {
                  setShow(true)
                  setMessage(result.errors.email)
                  setVariantMessage("warning")
                } else {
                  setShow(true);
                  setMessage("Validation errors found")
                  setVariantMessage("warning")
                }
              } else {
                setShow(true)
                setMessage("An error occurred while signing in, please try again in a few moments")
                setVariantMessage("danger")
              }
            }
          }
        )
      }

    }

    const handleChangeCheckPolicy = () => {
      setPolicyChecked(!policyChecked);
    }

    const handleChangeCheckRemember = () => {
      setRememberChecked(!rememberChecked);
    }

    const loginWithFacebook = (accessToken: any) => {
      rootStore.environment.api.loginFacebook(accessToken).then((result: any) => {
        if (result.kind === "ok") {
          setShow(true)
          setMessage("Log in whit Facebook successfully")
          setVariantMessage("success")

          runInAction(() => {
            loginStore.setUser(result.response)
            loginStore.setApiToken(result.response.token.access)
          })
          if (result.response.service_provider) {
            runInAction(() => {
              serviceProviderStore.setServiceProvider(result.response.service_provider)
            })
          }
          if (loginStore.group === 1) {
            history.push(ROUTES_ADMIN.HOME_ADMIN)
          } else {
            history.push(ROUTES.HOME)
          }
        } else {
          if (result.kind === "bad-data") {
            if (result.errors.non_field_errors) {
              setShow(true)
              setMessage(result.errors.non_field_errors[0])
              setVariantMessage("warning")
            } else {
              setShow(true);
              setMessage("Validation errors found")
              setVariantMessage("warning")
            }
          } else {
            setShow(true)
            setMessage("An error occurred, please try again in a few moments")
            setVariantMessage("danger")
          }
        }
      })
    }

    const checkLoginState = () => {
      if (!policyChecked) {
        setShow(true);
        setMessage("Please read and agree Privacy policy first")
        setVariantMessage("warning")
      } else {
        window.FB.login(function (response: any) {
          if (response.authResponse) {
            let accessToken = response.authResponse.accessToken
            loginWithFacebook(accessToken)
          } else {
            // console.log('User cancelled login or did not fully authorize.');
          }
        }, {scope: 'email'});
      }
    }

    const loginWithGoogle = (accessToken: any, user: any) => {
      rootStore.environment.api.loginGoogle(accessToken, user).then((result: any) => {
        if (result.kind === "ok") {
          setShow(true)
          setMessage("Log in whit Google successfully")
          setVariantMessage("success")
          runInAction(() => {
            loginStore.setUser(result.response)
            loginStore.setApiToken(result.response.token.access)
          })
          if (result.response.service_provider) {
            runInAction(() => {
              serviceProviderStore.setServiceProvider(result.response.service_provider)
            })
          }
          if (loginStore.group === 1) {
            history.push(ROUTES_ADMIN.HOME_ADMIN)
          } else {
            history.push(ROUTES.HOME)
          }
        } else {
          if (result.kind === "bad-data") {
            if (result.errors.non_field_errors) {
              setShow(true)
              setMessage(result.errors.non_field_errors[0])
              setVariantMessage("warning")
            } else {
              setShow(true);
              setMessage("Validation errors found")
              setVariantMessage("warning")
            }
          } else {
            setShow(true)
            setMessage("An error occurred, please try again in a few moments")
            setVariantMessage("danger")
          }
        }
      })
    }

    const googleClicked = () => {
      if (!policyChecked) {
        setShow(true);
        setMessage("Please read and agree Privacy policy first")
        setVariantMessage("warning")
      } else {
        firebase.auth().onAuthStateChanged(user => {
          if (user) {
            firebase.auth().currentUser?.getIdTokenResult()
              .then((r) => {
                loginWithGoogle(r.token, user)
              })
          }
        })
        signInWithGoogle()
      }
    }

    return (
      <PageWebDark login_required={false}>
        <div className={'d-flex flex-column vh-100'} style={{color: COLORS.white}}>
          <div className={'col-12 mt-3'}>
            <div className={'row'}>
              <div className={"col"}>
                <Link to="/signup" className={classes.hoverGreenBtn + ' d-flex align-items-center'}>
                  <span>Sign up</span>
                  <IoShareOutline style={{width: 30, height: 30}}/>
                </Link>
              </div>
              <div className={["col", styles.logIn].join(' ')}
              >
                <Link to="/login" className={classes.hoverGreenBtn}>
                  <span>Log in</span>
                  <MdLogin style={{width: 30, height: 30}}/>
                </Link>
              </div>
            </div>

          </div>
          <div>
            <img src={logoShort} alt="" className={styles.logoHoushares}/>
            <img src={logoBig} alt="" className={styles.logoBigHouseShares}/>
          </div>
          <div className={''}>
            <div className={styles.imagesSignUp + ' mx-auto'}>
              <img src={bannerimage} alt="" className={styles.imageBanner1 + ' img-fluid'}/>
            </div>

            <div className={['card', classes.cardStyle].join(" ")}>
              <div className={'card-body rm-pad'}>
                <p style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "xx-large",
                  marginBottom: "auto"
                }}>Sign up</p>

                <Formik
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                    email: '',
                  }}
                  onSubmit={
                    (values: ISignUpForm, actions) => {
                      signUp(values.email, values.password, values.confirmPassword);
                    }
                  }
                  validationSchema={schema}

                >
                  {(props: FormikProps<ISignUpForm>) => {
                    const {
                      // values,
                      // touched,
                      // errors,
                      // handleBlur,
                      // handleChange,
                      // isSubmitting,
                    } = props
                    return (
                      <Form>
                        <div className={"container row mx-0"}>
                          <div className={'col-lg-4 col-md-12 col-12'}>
                            <Field name="email" label={"email"} bold={true}
                                   className={"mt-3"}
                                   placeholder={""}
                                   component={InputForm}/>
                          </div>
                          <div className={'col-lg-4 col-md-12 col-12'}>
                            <Field name="password" label={"Password"}
                                   setShowPassword={setShowPassword}
                                   showPassword={showPassword} startFunction={signUp} bold={true}
                                   onKeyPressFunction={false} component={InputPasswordForm}/>
                          </div>
                          <div className={'col-lg-4 col-md-12 col-12'}>
                            <Field name="confirmPassword" label={"Confirm Password"}
                                   setShowPassword={setShowPasswordConfirm}
                                   showPassword={showPasswordConfirm} startFunction={signUp}
                                   bold={true}
                                   onKeyPressFunction={true} component={InputPasswordForm}/>
                          </div>

                        </div>


                        <div style={{textAlign: "center"}} className={'mt-3'}>
                          <Button
                            type="submit"
                            disabled={Loading}
                            style={{
                              backgroundColor: "transparent",
                              borderColor: "transparent",
                              color: COLORS.green,
                              fontWeight: "bold",
                              fontSize: 30
                            }}
                          >
                            {Loading && <Spinner animation="border" variant="light" size={'sm'}/>}
                            {!Loading && <span>Create account</span>}

                          </Button>
                        </div>

                        <div className={"container row justify-content-lg-center mx-0"}
                             style={{textAlign: "center", marginTop: "1%"}}>
                          <div className={"col-lg-2 col-md-12"}>Or continue using</div>
                          <div className={["col-lg-2 col-md-12 mt-md-0 mt-2", classes.hoverGreenBtn].join(' ')}
                               onClick={googleClicked}
                          >
                            Google
                          </div>
                          <div className={["col-lg-2 col-md-12 mt-md-0 mt-2", classes.hoverGreenBtn].join(' ')}
                               onClick={checkLoginState}
                          >
                            Facebook
                          </div>
                        </div>

                        <div className={"container row justify-content-lg-center mx-0"}
                             style={{textAlign: "center", marginTop: "2%"}}>
                          <div className={'col-lg-4 col-md-12 col-12'}
                               style={{display: "-webkit-inline-box"}}>
                            <input type="checkbox" className="custom-control-input"
                                   id="policyChecked"
                                   defaultChecked={policyChecked}
                                   onChange={handleChangeCheckPolicy}/>
                            <p style={{marginLeft: "5px"}}> I read and agree with the
                              <Link to={"/privacy_policy"}
                                    style={{color: COLORS.green, textDecoration: "none"}}> Privacy
                                Policy</Link>.
                            </p>
                          </div>
                          <div className={'col-lg-3 col-md-12 col-12'}
                               style={{display: "-webkit-inline-box"}}>
                            <input type="checkbox" className="custom-control-input"
                                   id="rememberChecked"
                                   defaultChecked={rememberChecked}
                                   onChange={handleChangeCheckRemember}/>
                            <p style={{marginLeft: "5px"}}> Remember me.</p>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>

        </div>

        <ToastComponent message={message} close={close} show={show} variant={variantMessage} delay={delay}/>

      </PageWebDark>
    );
  })
;

export default Signup;
