import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../constants/constants";

export default makeStyles((theme) => ({

    navBrand: {
        "&:hover, &:focus": {
            cursor: "pointer"
        }
    },
    container: {
        display: "flex",
        width: "100%",
        padding: "20px"
    },
    fixed: {
        width: "200px",
    },
    flexItem: {
        flexGrow: 1,
        alignSelf: "center",
    },
    logoLongHoushares: {
        height: "50px",
    },
    adminLogo: {
        color: COLORS.lightGray,
        fontSize: "large",
        fontWeight: "bold",
        marginRight: "20px",
        justifyContent: "right",
        textAlign: "right",
        flexFlow: "wrap",
    },
    settingIcon: {
        marginLeft: "15px",
        fontSize: "xx-large",
        marginTop: "-4px",
    },
    settingIconBehind: {
        fontSize: "xx-large",
        marginLeft: "-44px",
        marginTop: "-12px",
    },
    navLink: {
        textAlign: "center",
        marginRight: "10px",
        marginLeft: "10px"
    },
    iconLink: {
        fontSize: "x-large",
        marginBottom: "5px"
    }
}));
