import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../../constants/constants";

export default makeStyles({

    select: {
        color: COLORS.gray,
    },
    dropdownToggle: {
        backgroundColor: COLORS.gray,
        borderColor: COLORS.gray,
        "&:hover, &:focus, &:active": {
            cursor: "pointer",
            color: COLORS.green,
            backgroundColor: COLORS.gray,
            borderColor: COLORS.gray
        },
    },
    dropdownMenu: {
        backgroundColor: COLORS.gray,
        borderColor: COLORS.green
    },
    dropdownItem: {
        color: COLORS.white,
        "&:active": {
            backgroundColor: COLORS.gray
        }
    },
    applyButton: {
        paddingLeft: "4rem",
        paddingRight: "4rem"
    },
    slickArrow: {
        color: COLORS.gray,
        "&:before": {
            color: COLORS.gray
        }
    }
});
