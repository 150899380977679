import React from 'react';
import {observer} from "mobx-react-lite";
import {RouteComponentProps} from "react-router";
import PageWeb from "../../../components/PageWeb/PageWeb";
import Logo from "../../../components/Logo/Logo";
import {IoIosArrowDropleft} from "react-icons/io";
import {COLORS} from "../../../constants/constants";
import useStyles from "./styles";

export const TermsConditions: React.FC<RouteComponentProps> = observer(({history}) => {
    let classes = useStyles();

    return (
        <PageWeb login_required={false}>
            <div className={'content-container row mx-0'} style={{padding: "0 10% 0 10%"}}>
                <div>
                    <IoIosArrowDropleft color={COLORS.gray} fontSize={"xx-large"} onClick={history.goBack}
                                        className={classes.arrowBack}/>
                </div>
                <Logo short={false} light={true}/>
                <div className={classes.title}>Terms and Conditions
                </div>
                <div className={"col-lg-8 col-md-10 col-sm-12 term-conditions"}>
                    <p className={classes.text}>
                        La información aquí suministrada corresponde a la realidad y puede ser verificada por cualquier otro medio.
                    </p>
                    <p className={classes.text}>
                        Autorizo expresa e irrevocablemente a Houshares SAS para acceder, consultar, comparar, monitorear y
                        evaluar toda la información se encuentre almacenada en las bases de datos de cualquier central de
                        antecedentes judiciales o de seguridad, de naturaleza estatal o privada, nacional o extranjera, o cualquier
                        base de datos financiera, comercial o de servicios legítima y reporte a cualquier banco de datos, la
                        información y referencias que requiera.
                    </p>
                </div>
            </div>
        </PageWeb>
    );
});
