import {types} from "mobx-state-tree"

export const QuickFactSchedule = types
  .model("QuickFactSchedule")
  .props({
    id: types.identifierNumber,
    init_hour: types.string,
    end_hour: types.string,
    day: types.number,
  })
