import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import useStyles from "../styles"
import {COLORS, ROUTES} from "../../../constants/constants";
import {FaUsers} from "react-icons/fa";
import {CloseButton, Modal, ModalBody, ModalHeader,} from "react-bootstrap";
import ButtonSquare from "../../../components/Buttons/ButtonSquare";
import {BsFlagFill} from "react-icons/bs";
import {RiDeleteBin5Line} from "react-icons/ri"
import ButtonDark from "../../../components/Buttons/ButtonDark";
import {Field, FieldInputProps, Form, Formik, FormikProps} from "formik";
import {useStores} from "../../../models/root-store/root-store-context";
import ButtonModal from "../../../components/Buttons/ButtonModal";
import * as Yup from "yup";
import AdminPagination from "../../../components/AdminPagination/AdminPagination";
import AdminSearch from "../../../components/AdminSearch/AdminSearch";
import ButtonSquareForm from "../../../components/Buttons/ButtonSquareForm";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {sort, useClearStore} from "../../../helpers";
import SortArrows from "../../../components/SortArrows/SortArrows";
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";
import InputForm from "../../../components/Inputs/InputForm";
import InputPasswordForm from "../../../components/Inputs/InputPasswordForm";

interface IAddUser {
  password: string
  confirmPassword: string
  email: string
}

type InputPasswordProps = {
  field: FieldInputProps<any>,
  form: FormikProps<any>,
  label: string,
  showPassword: boolean,
  setShowPassword: (arg: boolean) => void,
  bold: boolean,
  backColor: string /*modal, dark or light*/
};

const schemaAddUser = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Enter a valid email'),

  password: Yup.string(),
  confirmPassword: Yup.string()
    .required('Required')
    .test(
      'Password-match',
      'Password must match',
      function (value) {
        return this.parent.password === value
      }
    ),
})

export const UserList: React.FC<RouteComponentProps> = observer(({history}) => {

  let classes = useStyles();
  const rootStore = useStores()
  const clearStore = useClearStore()
  const [data, setData] = useState<any>([]);
  const [Loading, setLoading] = useState<boolean>(false);
  const [valuesToSearch, setValuesToSearch] = useState<string>("");
  const [showConfirmDeleteMessage, setShowConfirmDeleteMessage] = useState<boolean>(false);
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<any>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");
  const [LoadingAddUser, setLoadingAddUser] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [previousPage, setPreviousPage] = useState<string | null>("");
  const [nextPage, setNextPage] = useState<string | null>("");
  const [pathLastCalled, setPathLastCalled] = useState<string>("/admin/users/");
  const [offsetLastCalled, setOffsetLastCalled] = useState<number>(0);
  const [asc, setAsc] = useState<any>({id: false, flag: false});

  const setResultToData = (result: any) => {
    let datos: any = [];
    result.data.results.map((resultItem: any) => {
      datos.push({id: resultItem.id, email: resultItem.email, flag: resultItem.flag})
    })
    setData(datos)
  }

  const getUsers = (rows: number) => {
    setLoading(true)
    rootStore.environment.api.getUserList(rows).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultToData(result)
        setTotalPages(Math.ceil(result.data.count / rows))
        setCurrentPage(1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else if (result.kind === "unauthorized") {
          setShow(true)
          setMessage("Session expired")
          setVariantMessage("danger")
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")

        }
      }
    })
  }

  const getUsersPrevNextPage = (path: string, offset: number) => {
    setPathLastCalled(path)
    setOffsetLastCalled(offset)
    setLoading(true)
    rootStore.environment.api.getUserListPrevNextPage(path).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultToData(result)
        setCurrentPage(Math.floor(offset / rowsPerPage) + 1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else if (result.kind === "unauthorized") {
          setShow(true)
          setMessage("Session expired")
          setVariantMessage("danger")
          setTimeout(() => {
            clearStore();
            history.push(ROUTES.LOGIN)
          }, 1000)
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    getUsers(rowsPerPage)
  }, []);


  const addUser = (values: IAddUser) => {
    setLoadingAddUser(true)
    rootStore.environment.api.addUser(values.email, values.password, values.confirmPassword).then((result: any) => {
      if (result.kind === "ok") {
        window.location.reload()
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else if (result.kind === "unauthorized") {
          setShow(true)
          setMessage("Session expired")
          setVariantMessage("danger")
          setTimeout(() => {
            clearStore();
            history.push(ROUTES.LOGIN)
          }, 1000)
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
    setLoadingAddUser(false)
    setShowAddUser(false)
  }

  const searchUser = (rows: number) => {
    setLoading(true)
    rootStore.environment.api.getUsersSearch(valuesToSearch, rows).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultToData(result)
        setCurrentPage(1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
        setTotalPages(Math.ceil(result.data.count / rows))
        // setData(result.data.results)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else if (result.kind === "unauthorized") {
          setShow(true)
          setMessage("Session expired")
          setVariantMessage("danger")
          setTimeout(() => {
            clearStore();
            history.push(ROUTES.LOGIN)
          }, 1000)
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const deleteUserClick = (userItem: any) => {
    setUserToDelete(userItem)
    setShowConfirmDeleteMessage(true)
  }

  const deleteUser = () => {
    rootStore.environment.api.deleteUser(userToDelete.id).then((result: any) => {
      if (result.kind === "ok") {
        setShow(true)
        setMessage(`User "${userToDelete.id}, ${userToDelete.email}" has been successfully deleted`)
        setVariantMessage("success")
        window.location.reload();
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else if (result.kind === "unauthorized") {
          setShow(true)
          setMessage("Session expired")
          setVariantMessage("danger")
          setTimeout(() => {
            clearStore();
            history.push(ROUTES.LOGIN)
          }, 1000)
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
    setUserToDelete({})
    setShowConfirmDeleteMessage(false)
  }

  const flagUser = (idUser: any) => {
    rootStore.environment.api.flagUser(idUser).then((result: any) => {
      if (result.kind === "ok") {
        getUsersPrevNextPage(pathLastCalled, offsetLastCalled)
        // window.location.reload()
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else if (result.kind === "unauthorized") {
          setShow(true)
          setMessage("Session expired")
          setVariantMessage("danger")
          setTimeout(() => {
            clearStore();
            history.push(ROUTES.LOGIN)
          }, 1000)
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const closeConfirmDeleteMessage = () => {
    setShowConfirmDeleteMessage(false)
  }
  const closeAddUser = () => {
    setShowAddUser(false)
  }

  const handlePrevPageClicked = () => {
    let from = previousPage?.substring(previousPage?.indexOf("offset") + 7);
    let path = previousPage?.substring(previousPage?.indexOf("v1") + 2);
    if (path !== undefined) {
      if (from !== undefined && !isNaN(parseInt(from))) {
        getUsersPrevNextPage(path, parseInt(from))
      } else {
        getUsersPrevNextPage(path, 1)
      }
    }
  }

  const handleNextPageClicked = () => {
    let from = nextPage?.substring(nextPage?.indexOf("offset") + 7);
    let path = nextPage?.substring(nextPage?.indexOf("v1") + 2);

    if (path !== undefined) {
      if (from !== undefined && !isNaN(parseInt(from))) {
        getUsersPrevNextPage(path, parseInt(from))
      } else {
        getUsersPrevNextPage(path, 1)
      }
    }
  }

  const handleRowsPerPageClicked = (rows: number) => {
    setRowsPerPage(rows)
    if (valuesToSearch !== "") {
      searchUser(rows)
    } else {
      getUsers(rows)
    }
  }

  const closeMessage = () => {
    setShow(false)
  }

  const sortData = (orderBy: string) => {
    let sortDatos = sort(orderBy, asc[orderBy], data)

    if (sortDatos) {
      setData(sortDatos)
    }
    let temp = asc;
    temp[orderBy] = !temp[orderBy]
    setAsc(temp)
  }

  const UserItems = (currentItems: any) => {
    return (
      <>
        {currentItems.currentItems?.length !== 0 &&
          <div style={{marginTop: "35px"}}>
            {currentItems.currentItems.map((userItem: any, idx: number) => {
              return (
                <div key={idx} className={["row", classes.list].join(" ")}>
                  <div className={"col-1"}>{userItem.id}</div>
                  <div className={"col-9"}>{userItem.email}</div>
                  <div className={"col-2"}>
                    <ButtonDark startFunction={() => deleteUserClick(userItem)}
                                bold={false}>
                      <RiDeleteBin5Line/>
                    </ButtonDark>
                    <ButtonDark startFunction={() => flagUser(userItem.id)}
                                bold={false}>
                      <BsFlagFill style={{color: userItem.flag ? COLORS.red : COLORS.white}}/>
                    </ButtonDark>
                  </div>
                </div>
              );
            })}

          </div>
        }
      </>
    );
  }

  return (
    <PageWebAdminDark>
      <div className={"row"}>
        <div className={"col-7"}
             style={{color: COLORS.white, fontSize: "xx-large"}}>
          <FaUsers/> Registered Users
        </div>
        <div className={"col-2"}
             style={{marginTop: "8px", textAlign: "center"}}>
          <ButtonSquare label={"Add user"} startFunction={() => setShowAddUser(true)} bold={true}/>
        </div>
        <div className={"col-3"}
             style={{display: "flex", justifyContent: "center"}}>
          <AdminSearch valuesToSearch={valuesToSearch} setValuesToSearch={setValuesToSearch}
                       search={searchUser} rowsPerPage={rowsPerPage}/>
        </div>
      </div>
      {!Loading && data?.length == 0 &&
        <NoDataAdmin/>}
      {Loading &&
        <SpinnerLight/>
      }
      {!Loading && data && data?.length > 0 &&
        <React.Fragment>
          <div className={["row", classes.listTittles].join(" ")}>
            <div className={"col-1"}>
              <SortArrows className={""} asc={asc} setAsc={setAsc} label={"ID"} attribute={"id"}
                          data={data}
                          setData={setData}/>
            </div>
            <div className={"col-9"}>Email</div>
            <div className={"col-2"}>
              <SortArrows className={""} asc={asc} setAsc={setAsc} label={"Flag/Unflag"}
                          attribute={"flag"}
                          data={data} setData={setData}/>
            </div>
          </div>
          <div>
            <UserItems currentItems={data}/>
            <AdminPagination rowsPerPage={rowsPerPage} handleRowsPerPageClicked={handleRowsPerPageClicked}
                             currentPage={currentPage} handleNextPageClicked={handleNextPageClicked}
                             handlePrevPageClicked={handlePrevPageClicked} nextPage={nextPage}
                             previousPage={previousPage} totalPages={totalPages}/>
          </div>
        </React.Fragment>
      }
      <Modal centered show={showConfirmDeleteMessage} size={"sm"}>
        <ModalHeader className={classes.modalBorderTop}>
          <div>
            Are you sure you want to delete user {userToDelete.id} {userToDelete.email}?
          </div>
        </ModalHeader>
        <Modal.Footer className={classes.modalBorderBottom}>
          <ButtonModal startFunction={deleteUser}>Yes</ButtonModal>
          <ButtonModal startFunction={closeConfirmDeleteMessage}>No</ButtonModal>
        </Modal.Footer>
      </Modal>
      <Modal centered show={showAddUser}>
        <ModalHeader className={classes.modalBorderTop}>
          <div className={classes.divModalHeader}>
            Add User
          </div>
          <CloseButton variant="white" onClick={closeAddUser}/>
        </ModalHeader>
        <ModalBody className={classes.modalBorderBottom}>
          <div className={'col-12'}>
            <div>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  confirmPassword: "",
                }}
                onSubmit={(values) => {
                  addUser(values);
                }}
                validationSchema={schemaAddUser}
              >
                {(props: FormikProps<IAddUser>) => {
                  const {
                    isSubmitting,
                  } = props
                  return (
                    <Form style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <Field name="email" label={"email"} bold={true}
                             placeholder={""}
                             className={classes.emailInput}
                             component={InputForm}/>
                      <Field name="password" label={"Password"}
                             setShowPassword={setShowPassword}
                             showPassword={showPassword}
                             bold={true}
                             onKeyPressFunction={true} component={InputPasswordForm}/>
                      <Field name="confirmPassword" label={"Confirm Password"}
                             setShowPassword={setShowConfirmPassword}
                             showPassword={showConfirmPassword}
                             bold={true}
                             onKeyPressFunction={true} component={InputPasswordForm}/>
                      <div style={{textAlign: "center", marginTop: "15px"}}>
                        <ButtonSquareForm label={"Save"} bold={false}
                                          isSubmitting={isSubmitting} Loading={LoadingAddUser}/>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
    </PageWebAdminDark>
  )
});

export default UserList;
