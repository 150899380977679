import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import {COLORS, space} from "../../../constants/constants";
import {useStores} from "../../../models/root-store/root-store-context";
import useStyles from "../styles"
import {cantidad_fmt, openInNewTab, outHash, plata_fmt} from "../../../helpers";
import {DetailViewProps} from "../../../constants/types";
import defaultImage from "../../../assets/images/default_Image.png"
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";
import ToastComponent from "../../../components/Toasts/ToastCustom"
import ButtonDark from "../../../components/Buttons/ButtonDark";
import {RiExchangeDollarLine} from "react-icons/ri";
import {BsFileEarmarkCheck} from "react-icons/bs";
import {BiArrowBack, BiMap} from "react-icons/bi";

export const PropertyUnderContractInfo: React.FC<DetailViewProps> = observer(({history, match}) => {

    let classes = useStyles();
    const rootStore = useStores()

    let idContract = "";
    if (Object.keys(match.params).length > 0) {
      idContract = outHash(match.params.id);
    }

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    const todayShow = mm + '/' + dd + '/' + yyyy;

    const [Loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [dataPaymentRentList, setDataPaymentRentList] = useState<any>(null);
    const [dataPaymentACTIList, setDataPaymentACTIList] = useState<any>(null);
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [variantMessage, setVariantMessage] = useState<string>("");

    const setResultToData = (result: any) => {
      let datos = ({
        id: result.id,
        propertyName: result.property_name,
        propertyAddress: result.property_address,
        propertyGoogleMapLink: result.property_google_map_link,
        propertyImage: result.property_image ? result.property_image : defaultImage,
        initialPropertyValue: result.property_value,
        currentPropertyValue: result.current_property_value,
        initialRent: result.property_initial_rent,
        currentRent: result.current_rent,
        valueEachACTI: result.value_acti,
        totalACTIS: result.total_number_actis,
        opCost: result.operational_costs
      })
      // console.log("datos: ", datos)
      setData(datos)
    }

    const setResultToDataPaymentRentList = (result: any) => {
      let datos: any = [];

      result.map((resultItem: any) => {
        datos.push({
          // id: result.id,
          date: resultItem.date,
          amount: resultItem.amount,
          paymentType: "Rent"
        })
      })
      setDataPaymentRentList(datos)
    }

    const setResultToDataPaymentACTIList = (result: any) => {
      let datos: any = [];

      result.map((resultItem: any) => {
        datos.push({
          // id: result.id,
          date: resultItem.date,
          amount: resultItem.amount,
          paymentType: "ACTIS"
        })
      })
      setDataPaymentACTIList(datos)
    }


    const getPropertyUnderContract = () => {
      setLoading(true)
      rootStore.environment.api.getContractById(parseInt(idContract)).then((result: any) => {
        setLoading(false)
        if (result.kind === "ok") {
          setResultToData(result.data)
          setResultToDataPaymentRentList(result.data.rent_payment_history)
          setResultToDataPaymentACTIList(result.data.acti_purchase_history)
        } else {
          if (result.kind === "bad-data") {
            if (result.errors.non_field_errors) {
              setShow(true)
              setMessage(result.errors.non_field_errors[0])
              setVariantMessage("warning")
            } else {
              setShow(true);
              setMessage("Validation errors found")
              setVariantMessage("warning")
            }
          } else {
            setShow(true)
            setMessage("An error occurred, please try again in a few moments")
            setVariantMessage("danger")
          }
        }
      })
    }

    useEffect(() => {
      getPropertyUnderContract()
    }, []);


    const closeMessage = () => {
      setShow(false)
    }


    return (
      <PageWebAdminDark>
        <div className={"container row"}>
          <div className={"col-6"}
               style={{color: COLORS.white, fontSize: "xx-large"}}>
            <BsFileEarmarkCheck/> Properties under contract
          </div>
        </div>
        {!Loading && Object.keys(data)?.length === 0 &&
          <NoDataAdmin/>}
        {Loading &&
          <SpinnerLight/>
        }
        {!Loading && data &&
          <div className={"container row"} style={{color: COLORS.white}}>
            <div>
              <ButtonDark startFunction={history.goBack} bold={true}
                          className="text-start"><BiArrowBack/>{space}Back</ButtonDark>
            </div>
            <div className={"row my-4"}>
              <div style={{fontWeight: "bold", fontSize: "larger"}}>Property Finance table in real-time</div>
              <div className="row" style={{borderBottom: "solid", marginTop: "20px", paddingBottom: "20px"}}>
                <div className={"col-4"} style={{textAlign: "center"}}>
                  <div>
                    <img
                      src={data.propertyImage}
                      alt="Property"
                      style={{
                        width: "250px",
                        height: "200px",
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                  <div style={{marginTop: "10px"}}>
                    <ButtonDark startFunction={() => openInNewTab(data.propertyGoogleMapLink)}
                                bold={true} className={""}>
                      <BiMap size={"40"}/>
                    </ButtonDark>
                    Address:{space}{data.propertyAddress}
                  </div>
                </div>
                <div className={"col-6"} style={{lineHeight: "12px"}}>
                  <p style={{fontWeight: "bold", fontSize: "larger"}}>{data.propertyName}</p>
                  <div>
                    <p style={{textAlign: "left"}}>
                      Real estate value
                      <span style={{float: "right"}}>
                                               {plata_fmt(data.initialPropertyValue)}
                                            </span>
                    </p>
                  </div>
                  <div>
                    <p style={{textAlign: "left"}}>
                      Property value at {todayShow}
                      <span style={{float: "right"}}>
                                               {plata_fmt(data.currentPropertyValue)}
                                            </span>
                    </p>
                  </div>
                  <div>
                    <p style={{textAlign: "left"}}>
                      Initial monthly Rent
                      <span style={{float: "right"}}>
                                                {plata_fmt(data.initialRent)}
                                            </span>
                    </p>
                  </div>
                  <div>
                    <p style={{textAlign: "left"}}>
                      Value for each ACTI (share) at {todayShow}
                      <span style={{float: "right"}}>
                                                {plata_fmt(data.valueEachACTI)}
                                            </span>
                    </p>
                  </div>
                  <div>
                    <p style={{textAlign: "left"}}>
                      Monthly Rent at {todayShow}
                      <span style={{float: "right"}}>
                                                {plata_fmt(data.currentRent)}
                                            </span>
                    </p>
                  </div>
                  <div>
                    <p style={{textAlign: "left"}}>
                      Operational Costs
                      <span style={{float: "right"}}>
                                                {plata_fmt(data.opCost)}
                                            </span>
                    </p>
                  </div>
                  <div>
                    <p style={{textAlign: "left"}}>
                      Total number of ACTI's
                      <span style={{float: "right"}}>
                                                {cantidad_fmt(data.totalACTIS)}
                                            </span>
                    </p>
                  </div>
                </div>
              </div>
              <div style={{marginTop: "20px"}}>
                <div style={{fontSize: "larger", fontWeight: "bold"}}>
                  <RiExchangeDollarLine/>{space}Payment history
                </div>
                <div style={{height: "auto", maxHeight: "35vh", display: "flex"}}>
                  <div className={"row"} style={{overflowY: "scroll", flex: 1}}>
                    {dataPaymentRentList &&
                      <React.Fragment>
                        {dataPaymentRentList.map((item: any, idx: number) => {
                          return (
                            <div key={idx} className={"col-3"}
                                 style={{
                                   lineHeight: "12px",
                                   marginTop: "15px",
                                   padding: "10px 20px 10px 20px"
                                 }}>
                              <div>
                                <p style={{textAlign: "left"}}>
                                  Date
                                  <span style={{float: "right"}}>
                                                           {item.date}
                                                        </span>
                                </p>
                              </div>
                              <div>
                                <p style={{textAlign: "left"}}>
                                  Amount
                                  <span style={{float: "right"}}>
                                                           {item.amount}{space}($)
                                                        </span>
                                </p>
                              </div>
                              <div>
                                <p style={{textAlign: "left"}}>
                                  Payment type
                                  <span style={{
                                    float: "right",
                                    color: item.paymentType === "Rent" ? COLORS.orange : COLORS.green
                                  }}>
                                                           {item.paymentType}
                                                        </span>
                                </p>
                              </div>
                            </div>
                          )
                        })}
                      </React.Fragment>
                    }
                    {dataPaymentACTIList &&
                      <React.Fragment>
                        {dataPaymentACTIList.map((item: any, idx: number) => {
                          return (
                            <div key={idx} className={"col-3"}
                                 style={{
                                   lineHeight: "12px",
                                   marginTop: "15px",
                                   padding: "10px 20px 10px 20px"
                                 }}>
                              <div>
                                <p style={{textAlign: "left"}}>
                                  Date
                                  <span style={{float: "right"}}>
                                                           {item.date}
                                                        </span>
                                </p>
                              </div>
                              <div>
                                <p style={{textAlign: "left"}}>
                                  Amount
                                  <span style={{float: "right"}}>
                                                           {item.amount}
                                                        </span>
                                </p>
                              </div>
                              <div>
                                <p style={{textAlign: "left"}}>
                                  Payment type
                                  <span style={{
                                    float: "right",
                                    color: item.paymentType === "Rent" ? COLORS.orange : COLORS.green
                                  }}>
                                                           {item.paymentType}
                                                        </span>
                                </p>
                              </div>
                            </div>
                          )
                        })}
                      </React.Fragment>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
      </PageWebAdminDark>
    )
  })
;

export default PropertyUnderContractInfo;
