import {makeStyles} from "@material-ui/styles";

export default makeStyles({
    title: {
        fontWeight: "bold",
        fontSize: "x-large",
        textAlign: "center",
        marginTop: "60px!important",
        marginBottom: "40px",
    },
    arrowBack:{
        "&:hover,&:focus": {
            cursor:"pointer"
        }
    },
    text: {
        textAlign: "justify",
        fontSize: "18px"
    }
});
