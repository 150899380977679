import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import useStyles from "../styles"
import {COLORS, space} from "../../../constants/constants";
import AdminSearch from "../../../components/AdminSearch/AdminSearch";
import {HiOutlineChatAlt2} from "react-icons/hi";
import AdminPagination from "../../../components/AdminPagination/AdminPagination";
import {CloseButton, Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {IoArrowUndo} from "react-icons/io5";
import ButtonDark from "../../../components/Buttons/ButtonDark";
import {useStores} from "../../../models/root-store/root-store-context";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {Field, Form, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import {FiSend} from "react-icons/fi";
import InputTextAreaFormModal from "../../../components/Inputs/InputTextAreaFormModal";
import ButtonRoundForm from "../../../components/Buttons/ButtonRoundForm";
import defaultImage from "../../../assets/images/default_Image.png"
import {FaRegEye} from "react-icons/fa";
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";

interface IFeedback {
    message: string,
}

const schemaFeedback = Yup.object().shape({
    message: Yup.string().required('Required'),
})

export const FeedbackList: React.FC<RouteComponentProps> = observer(() => {

    let classes = useStyles();
    const rootStore = useStores()

    const [Loading, setLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [feedbackItem, setFeedbackItem] = useState<any>({});
    const [showReplyModal, setShowReplyModal] = useState<boolean>(false);
    const [valuesToSearch, setValuesToSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [previousPage, setPreviousPage] = useState<string | null>("");
    const [nextPage, setNextPage] = useState<string | null>("");
    const [pathLastCalled, setPathLastCalled] = useState<string>("/admin/feedback/");
    const [offsetLastCalled, setOffsetLastCalled] = useState<number>(0);
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [variantMessage, setVariantMessage] = useState<string>("");
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const setResultListToData = (result: any) => {
        let datos: any = [];
        result.data.results.map((resultItem: any) => {
            datos.push({
                id: resultItem.id,
                title: resultItem.title,
                text: resultItem.text,
                responded: resultItem.responded,
                full_name: resultItem.full_name,
                image: resultItem.profile_picture ? resultItem.profile_picture : defaultImage,
            })
        })
        setData(datos)
    }

    const getFeedbacks = (rows: number) => {
        setLoading(true)
        rootStore.environment.api.getFeedbackList(rows).then((result: any) => {
            setLoading(false)
            if (result.kind === "ok") {
                setResultListToData(result)
                setPreviousPage(result.data.previous)
                setNextPage(result.data.next)
            } else {
                if (result.kind === "bad-data") {
                    if (result.errors.non_field_errors) {
                        setShow(true)
                        setMessage(result.errors.non_field_errors[0])
                        setVariantMessage("warning")
                    } else {
                        setShow(true);
                        setMessage("Validation errors found")
                        setVariantMessage("warning")
                    }
                } else {
                    setShow(true)
                    setMessage("An error occurred, please try again in a few moments")
                    setVariantMessage("danger")
                }
            }
        })
    }

    useEffect(() => {
        getFeedbacks(rowsPerPage)
    }, []);

    const getFeedbacksPrevNextPage = (path: string, offset: number) => {
        setLoading(true)
        setPathLastCalled(path)
        setOffsetLastCalled(offset)
        rootStore.environment.api.getFeedbackListPrevNextPage(path).then((result: any) => {
            setLoading(false)
            if (result.kind === "ok") {
                setResultListToData(result)
                setCurrentPage(Math.floor(offset / rowsPerPage) + 1)
                setPreviousPage(result.data.previous)
                setNextPage(result.data.next)
            } else {
                if (result.kind === "bad-data") {
                    if (result.errors.non_field_errors) {
                        setShow(true)
                        setMessage(result.errors.non_field_errors[0])
                        setVariantMessage("warning")
                    } else {
                        setShow(true);
                        setMessage("Validation errors found")
                        setVariantMessage("warning")
                    }
                } else {
                    setShow(true)
                    setMessage("An error occurred, please try again in a few moments")
                    setVariantMessage("danger")
                }
            }
        })
    }


    const search = (rows: number) => {
        setLoading(true)
        rootStore.environment.api.getFeedbacksSearch(valuesToSearch, rows).then((result: any) => {
            setLoading(false)
            if (result.kind === "ok") {
                setResultListToData(result)
                setCurrentPage(1)
                setPreviousPage(result.data.previous)
                setNextPage(result.data.next)
                setTotalPages(Math.ceil(result.data.count / rows))
            } else {
                if (result.kind === "bad-data") {
                    if (result.errors.non_field_errors) {
                        setShow(true)
                        setMessage(result.errors.non_field_errors[0])
                        setVariantMessage("warning")
                    } else {
                        setShow(true)
                        setMessage('Validation errors found')
                        setVariantMessage("warning")
                    }
                } else {
                    setShow(true)
                    setMessage('An error occurred, please try again in a few moments')
                    setVariantMessage("danger")
                }
            }
        })
    }

    const openShowReply = (feedback: any) => {
        setFeedbackItem(feedback)
        setShowReplyModal(true)
    }

    const closeShowReply = () => {
        setFeedbackItem({})
        setShowReplyModal(false)
    }

    const replyFeedback = (message: string) => {
        rootStore.environment.api.addReplyFeedback(feedbackItem.id, message).then((result: any) => {
            if (result.kind === "ok") {
                getFeedbacksPrevNextPage(pathLastCalled, offsetLastCalled)
                setShow(true)
                setMessage("Reply successfully sent")
                setVariantMessage("success")
            } else {
                if (result.kind === "bad-data") {
                    if (result.errors.non_field_errors) {
                        setShow(true)
                        setMessage(result.errors.non_field_errors[0])
                        setVariantMessage("warning")
                    } else {
                        setShow(true);
                        setMessage("Validation errors found")
                        setVariantMessage("warning")
                    }
                } else {
                    setShow(true)
                    setMessage("An error occurred, please try again in a few moments")
                    setVariantMessage("danger")
                }
            }
        })
        setFeedbackItem({})
        setLoading(false)
        setShowReplyModal(false)
    }

    const handlePrevPageClicked = () => {
        let from = previousPage?.substring(previousPage?.indexOf("offset") + 7);
        let path = previousPage?.substring(previousPage?.indexOf("v1") + 2);
        if (path !== undefined) {
            if (from !== undefined && !isNaN(parseInt(from))) {
                getFeedbacksPrevNextPage(path, parseInt(from))
            } else {
                getFeedbacksPrevNextPage(path, 1)
            }
        }
    }

    const handleNextPageClicked = () => {
        let from = nextPage?.substring(nextPage?.indexOf("offset") + 7);
        let path = nextPage?.substring(nextPage?.indexOf("v1") + 2);

        if (path !== undefined) {
            if (from !== undefined && !isNaN(parseInt(from))) {
                getFeedbacksPrevNextPage(path, parseInt(from))
            } else {
                getFeedbacksPrevNextPage(path, 1)
            }
        }
    }

    const handleRowsPerPageClicked = (rows: number) => {
        setRowsPerPage(rows)
        if (valuesToSearch !== "") {
            search(rows)
        } else {
            getFeedbacks(rows)
        }
    }

    const closeMessage = () => {
        setShow(false)
    }

    const openShowDetails = (feedback: any) => {
        setFeedbackItem(feedback)
        setShowDetails(true)
    }

    const closeShowDetails = () => {
        setFeedbackItem({})
        setShowDetails(false)
    }

    const FeedbackItems = (currentItems: any) => {
        return (
            <>
                {currentItems && currentItems.currentItems?.length > 0 &&
                    <div style={{marginTop: "35px"}}>
                        {currentItems.currentItems.map((item: any, idx: number) => {
                            return (
                                <div key={idx} className={["row", classes.list].join(" ")}>
                                    <div className={"col-1"}>
                                        <img
                                            src={item.image}
                                            alt="User image"
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                height: "80px",
                                                width: "130px",
                                                objectFit: "contain",
                                            }}
                                        />
                                    </div>
                                    <div className={"col-3"}>{item.full_name}</div>
                                    <div className={"col-3 text-truncate"}>
                                        <p style={{display: "inline"}}>
                                            {item.title}
                                        </p>
                                    </div>
                                    <div className={"col-4 text-truncate"}>
                                        <p style={{display: "inline"}}>
                                            {item.text}</p>
                                    </div>
                                    <div className={"col-1"}>
                                        <ButtonDark startFunction={() => openShowDetails(item)} bold={false}>
                                            <FaRegEye size={"25px"}/>
                                        </ButtonDark>
                                    </div>
                                    {!item.responded &&
                                        <div className={"col-2"} style={{display: "inline-flex", marginLeft: "10px"}}>
                                            <ButtonDark startFunction={() => openShowReply(item)}
                                                        bold={false}>
                                                <IoArrowUndo/> Reply
                                            </ButtonDark>
                                        </div>}
                                </div>
                            );
                        })}

                    </div>
                }
            </>
        );
    }

    return (
        <PageWebAdminDark>
            <div className={"row"}>
                <div className={"col-9"}
                     style={{color: COLORS.white, fontSize: "xx-large"}}>
                    <HiOutlineChatAlt2/> Feedbacks
                </div>
                <div className={"col-3"}
                     style={{display: "flex", justifyContent: "center"}}>
                    <AdminSearch valuesToSearch={valuesToSearch} setValuesToSearch={setValuesToSearch}
                                 search={search} rowsPerPage={rowsPerPage}/>
                </div>
            </div>
            {!Loading && data?.length == 0 &&
                <NoDataAdmin/>}
            {Loading &&
                <SpinnerLight/>
            }
            {!Loading && data && data?.length > 0 &&
                <div>
                    <div className={["row", classes.listTittles].join(" ")}>
                        <div className={"col-1"}></div>
                        <div className={"col-3"}>Name</div>
                        <div className={"col-3 text-truncate"}>Title</div>
                        <div className={"col-5 text-truncate"}>Message</div>
                    </div>
                    <FeedbackItems currentItems={data}/>
                    <AdminPagination rowsPerPage={rowsPerPage} handleRowsPerPageClicked={handleRowsPerPageClicked}
                                     currentPage={currentPage} handleNextPageClicked={handleNextPageClicked}
                                     handlePrevPageClicked={handlePrevPageClicked} nextPage={nextPage}
                                     previousPage={previousPage} totalPages={totalPages}/>
                </div>
            }
            <Modal show={showReplyModal} size={"lg"}>
                <ModalHeader className={classes.modal}>
                    <div style={{textAlign: "center", width: "100%"}}>{feedbackItem.title}</div>
                    <CloseButton variant="white" onClick={closeShowReply}/>
                </ModalHeader>
                <ModalBody className={classes.modal}>
                    <div style={{
                        backgroundColor: COLORS.lightGray,
                        color: COLORS.realblack,
                        padding: "10px",
                        borderRadius: "12px"
                    }}>{feedbackItem.text}</div>
                    <Formik
                        initialValues={{
                            message: ""
                        }}
                        onSubmit={
                            (values: IFeedback) => {
                                replyFeedback(values.message)
                            }
                        }
                        validationSchema={schemaFeedback}
                    >
                        {(props: FormikProps<IFeedback>) => {
                            const {
                                // values,
                                // touched,
                                // errors,
                                // handleBlur,
                                // handleChange,
                                isSubmitting,
                            } = props
                            return (
                                <Form style={{marginTop: "15px"}}>
                                    <Field name={"message"} label={"Write message"} bold={true}
                                           className={"text-center"}
                                           placeholder={"Write message"} component={InputTextAreaFormModal}/>
                                    <div style={{textAlign: "center", marginTop: "20px"}}>
                                        <ButtonRoundForm label={"Send"} bold={true}
                                                         isSubmitting={isSubmitting}
                                                         Loading={Loading}><FiSend
                                            style={{marginLeft: "5px", marginBottom: "5px"}}/>
                                        </ButtonRoundForm>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </ModalBody>
            </Modal>

            <Modal show={showDetails} onHide={closeShowDetails}>
                <ModalHeader className={classes.modalHeader}>
                    <div style={{fontWeight: "bold", fontSize: "x-large"}}>
                        Feedback
                    </div>
                    <CloseButton variant="white" onClick={closeShowDetails}/>
                </ModalHeader>
                <ModalBody className={classes.modalBody} style={{textAlign:"start",padding:"30px"}}>
                    <div style={{marginBottom: "15px"}}><p
                        style={{fontWeight: "bold"}}>Title:</p>{space}{feedbackItem.title}</div>
                    <div style={{marginBottom: "15px"}}><p
                        style={{fontWeight: "bold"}}>Message:</p>{space}{feedbackItem.text}</div>
                </ModalBody>
            </Modal>
            <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
        </PageWebAdminDark>
    )
});

export default FeedbackList;
