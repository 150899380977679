import {Spinner} from "react-bootstrap";
import React from "react";

export const SpinnerDark = React.forwardRef((props,ref) => {

    return (
        <div style={{textAlign: "center", marginTop:"100px"}}>
            <Spinner animation={"border"} variant={"dark"}/>
        </div>
    )
});
