import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../../constants/constants";

export default makeStyles((theme) => ({

    hoverGreenBtn: {
        color: COLORS.white,
        textDecoration: "none",
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green
        },
    },

    emailLabelForgot:{
        marginLeft:"65px"
    },

    emailInputForgot:{
    },

    "@media (max-width: 990px)": {
        emailLabelForgot:{
            marginLeft:"0px"
        },
    },

    "@media (max-width: 375px)": {
        emailInputForgot:{
            width:"175px"
        },
    },

}));
