import React from 'react';
import {observer} from "mobx-react-lite";

import './HeaderWeb.css';
import {Nav, Navbar} from "react-bootstrap";
import {useHistory} from "react-router";
// import {useClearStore} from "../../helpers";
// import {useStores} from "../../models/root-store/root-store-context";
import {COLORS, ROUTES_ADMIN} from "../../constants/constants";
import useStyles from "./styles";
import logoLong from "../../assets/images/logo-big.png";
import {AiFillSetting, AiOutlineSetting} from "react-icons/ai";

type HeaderWebProps = {
    titulo?: string
    boton_derecha?: any
};

export const HeaderWebAdmin: React.FC<HeaderWebProps> = observer(() => {
    const history = useHistory()
    // const clearStore = useClearStore()
    // const rootStore = useStores()
    // const {loginStore} = rootStore
    let classes = useStyles();
    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" style={{backgroundColor: COLORS.realblack}}>
            <div className={classes.container}>
                <div className={classes.fixed}>
                    <Navbar.Brand onClick={() => history.push(ROUTES_ADMIN.USER_LIST)}>
                        <img
                            src={logoLong}
                            alt="Logo Houshares"
                            className={classes.logoLongHoushares}
                        />
                    </Navbar.Brand>
                </div>
                {/*<Navbar.Toggle className={classes.toggle} aria-controls="responsive-navbar-nav"/>*/}
                <div className={classes.flexItem}>
                    {/*<Navbar.Collapse id="responsive-navbar-nav">*/}
                        <Nav className={['ms-auto', classes.adminLogo].join(" ")}>
                            Admin
                            <AiFillSetting className={classes.settingIcon}/>
                            <AiOutlineSetting className={classes.settingIconBehind}/>
                        </Nav>
                        {/*<Nav className={'ms-auto'}>*/}
                        {/*    {!loginStore.isLoggedIn &&*/}
                        {/*        <NavDropdown title="Login" id="collasible-nav-dropdown">*/}
                        {/*            <NavDropdown.Item*/}
                        {/*                onClick={() => history.push(ROUTES.LOGIN)}>Login</NavDropdown.Item>*/}
                        {/*            <NavDropdown.Item onClick={() => history.push(ROUTES.FORGOT_PASSWORD)}>Forgot*/}
                        {/*                Password</NavDropdown.Item>*/}
                        {/*            <NavDropdown.Divider/>*/}
                        {/*            <NavDropdown.Item onClick={() => history.push(ROUTES.SIGNUP)}>Sign*/}
                        {/*                up</NavDropdown.Item>*/}
                        {/*        </NavDropdown>*/}
                        {/*    }*/}
                        {/*    {loginStore.isLoggedIn &&*/}
                        {/*        <NavDropdown title={loginStore.username} id="collasible-nav-dropdown">*/}
                        {/*            {loginStore.group === GROUP.BUILDING_OWNER &&*/}
                        {/*                <NavDropdown.Item*/}
                        {/*                    onClick={() => history.push(ROUTES_BUILDING_OWNER.ROOT)}>Dashboard</NavDropdown.Item>}*/}
                        {/*            {loginStore.group === GROUP.SERVICE_PROVIDER && <NavDropdown.Item*/}
                        {/*                onClick={() => history.push(ROUTES_SERVICE_PROVIDER.ROOT)}>Dashboard</NavDropdown.Item>*/}
                        {/*            }*/}
                        {/*            <NavDropdown.Item onClick={() => clearStore()}>Logout</NavDropdown.Item>*/}
                        {/*        </NavDropdown>*/}
                        {/*    }*/}
                        {/*</Nav>*/}
                    {/*</Navbar.Collapse>*/}
                </div>
            </div>
        </Navbar>
    )

});

export default HeaderWebAdmin;
