import React from "react";
import logoLongDark from "../../assets/images/logo-big.png";
import logoLongLight from "../../assets/images/logo-long-light.png";
import logoShort from "../../assets/images/logo-short.png";
import useStyles from "./styles";
import {ROUTES} from "../../constants/constants";
import {useHistory} from "react-router";

type LogoProps = {
  short: boolean,
  light: boolean
};

export const Logo: React.FC<LogoProps> = React.forwardRef((props, ref) => {
  let history = useHistory();
  let classes = useStyles();

  return (
    <div style={{textAlign: "center"}}>
      <img
        src={props.short ? logoShort : props.light ? logoLongLight : logoLongDark}
        alt="Logo Houshares"
        className={props.short ? classes.logoShortHoushares : classes.logoLongHoushares}
        onClick={() => history.push(ROUTES.HOME)}
        style={{cursor: "pointer"}}
      />
    </div>
  )
});

export default Logo;
