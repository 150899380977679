import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {RouteComponentProps} from "react-router";
import PageWebDarkWithHeader from "../../../components/PageWeb/PageWebDarkWithHeader";
import {ROUTES, space} from "../../../constants/constants";
import {useClearStore} from "../../../helpers";
import {Button, Modal, ModalBody, ModalHeader} from "react-bootstrap";
import useStyles from "./styles";
import {BiMessageError, BiUser} from "react-icons/bi"
import {BsCardChecklist, BsShieldCheck} from "react-icons/bs"
import {FiUserPlus} from "react-icons/fi";
import {IoLogOutOutline} from "react-icons/io5";
import {MdPublishedWithChanges} from "react-icons/md";
import Logo from "../../../components/Logo/Logo";
import firebase from "../../../services/firebase";
import {useStores} from "../../../models/root-store/root-store-context";


export const Settings: React.FC<RouteComponentProps> = observer(({history}) => {

  let classes = useStyles();
  const rootStore = useStores()
  const clearStore = useClearStore()
  const {loginStore} = rootStore
  const [showSignOutMessage, setShowSignOutMessage] = useState<boolean>(false)


  const signoutClicked = () => {
    setShowSignOutMessage(true)
  }

  const signOut = () => {
    clearStore();
    if (loginStore.google) {
      firebase.auth().signOut()
      // .then((r) => console.log("log out google and r: ", r))
    }
    history.push(ROUTES.LOGIN)
  }

  const closeSignOutMessage = () => {
    setShowSignOutMessage(false)
  }

  return (
    <PageWebDarkWithHeader login_required={true}>
      <div className={'d-flex flex-column vh-100'}>
        <div className={'my-auto'}>
          <Logo short={true} light={false}/>
          <div style={{textAlign: "center"}}>
            <div>
              <Button className={classes.btnMenu} onClick={() => history.push(ROUTES.ACCOUNT_INFO)}>
                <BiUser/>{space}View my profile</Button>
            </div>
            <div>
              <Button className={classes.btnMenu} onClick={() => history.push(ROUTES.PRIVACY)}>
                <BsShieldCheck/>{space}Privacy Policy
              </Button>
            </div>
            <div>
              <Button className={classes.btnMenu} onClick={() => history.push(ROUTES.TERMS)}>
                <BsCardChecklist/>{space}Terms and Conditions
              </Button>
            </div>
            <div>
              <Button className={classes.btnMenu} onClick={() => history.push(ROUTES.FEEDBACK)}>
                <BiMessageError/>{space}Send Feedback
              </Button>
            </div>
            <div>
              <Button className={classes.btnMenu} onClick={() => history.push(ROUTES.INVITE_FRIENDS)}>
                <FiUserPlus/>{space}Invite Friends
              </Button>
            </div>
            <div>
              <Button className={classes.btnMenu} onClick={() => history.push(ROUTES.CHANGE_PASSWORD)}>
                <MdPublishedWithChanges/>{space}Change Password
              </Button>
            </div>
            <div>
              <Button className={classes.btnMenu} onClick={signoutClicked}>
                <IoLogOutOutline/>{space}Sign out
              </Button>
            </div>
          </div>
        </div>
        </div>


      <Modal centered show={showSignOutMessage} size={'sm'}>
        <ModalBody className={classes.modalBody}>
          <div style={{fontWeight: "bold", fontSize: "x-large"}} className={'mb-4'}>Are you sure?</div>
          <div>
            <Button
              className={classes.btnOption +  ' me-5'}
              onClick={signOut}
            >
              Yes
            </Button>
            <Button
              className={classes.btnOption}
              onClick={closeSignOutMessage}
            >
              No
            </Button>
          </div>

        </ModalBody>
      </Modal>
    </PageWebDarkWithHeader>
  );
});
export default Settings;
