import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import useStyles from "../styles"
import {COLORS, ROUTES_ADMIN, space} from "../../../constants/constants";
import AdminSearch from "../../../components/AdminSearch/AdminSearch";
import {MdOutlineRequestQuote} from "react-icons/md";
import {useStores} from "../../../models/root-store/root-store-context";
import AdminPagination from "../../../components/AdminPagination/AdminPagination";
import ButtonDark from "../../../components/Buttons/ButtonDark";
import defaultImage from "../../../assets/images/default_Image.png"
import {FaRegEye} from "react-icons/fa";
import {AiOutlineCheckCircle} from "react-icons/ai";
import {BiMap, BiMessageError} from "react-icons/bi";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {CloseButton, Modal, ModalBody, ModalHeader, Spinner} from "react-bootstrap";
import {inHash, openInNewTab} from "../../../helpers";
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";


export const PropertyRequestList: React.FC<RouteComponentProps> = observer(({history}) => {

  let classes = useStyles();

  const rootStore = useStores()
  const [Loading, setLoading] = useState<boolean>(false);
  const [LoadingItem, setLoadingItem] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [dataItem, setDataItem] = useState<any>({});
  const [idPropertyRequest, setIdPropertyRequest] = useState<number>(0);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showApproveMessage, setShowApproveMessage] = useState<boolean>(false);
  const [valuesToSearch, setValuesToSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [previousPage, setPreviousPage] = useState<string | null>("");
  const [nextPage, setNextPage] = useState<string | null>("");
  const [pathLastCalled, setPathLastCalled] = useState<string>("/admin/property-request/");
  const [offsetLastCalled, setOffsetLastCalled] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");

  const setResultToData = (result: any) => {
    let datos = {
      id: result.data.id,
      idHousner: result.data.user_id,
      fullName: result.data.full_name,
      idType: result.data.id_type,
      idNumber: result.data.id_number,
      idDateIssue: result.data.id_date_of_issue,
      idPlaceIssue: result.data.id_place_of_issue,
      phoneNumber: result.data.phone_number,
      cellNumber: result.data.cell_phone_number,
      email: result.data.email,
      profilePicture: result.data.profile_picture ? result.data.profile_picture : defaultImage,
      propertyId: result.data.property_id,
      propertyName: result.data.property_name,
      propertyAddress: result.data.property_address,
      propertyImage: result.data.property_image ? result.data.property_image : defaultImage,
      googleMapLink: result.data.google_map_link
    }
    setDataItem(datos)
  }

  const setResultListToData = (result: any) => {
    let datos: any = [];
    result.data.results.map((resultItem: any) => {
      datos.push({
        id: resultItem.id,
        idHousner: resultItem.user_id,
        fullName: resultItem.full_name,
        idProperty: resultItem.property_id,
        propertyName: resultItem.property_name,
        propertyImage: resultItem.property_image ? resultItem.property_image : defaultImage,
      })
    })
    setData(datos)
  }

  const getPropertyRequests = (rows: number) => {
    setLoading(true)
    rootStore.environment.api.getPropertyRequestList(rows).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  useEffect(() => {
    getPropertyRequests(rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getPropertyRequestItem = (idPropertyRequest: number) => {
    setLoadingItem(true)
    rootStore.environment.api.getPropertyRequest(idPropertyRequest).then((result: any) => {
      setLoadingItem(false)
      if (result.kind === "ok") {
        setResultToData(result)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const search = (rows: number) => {
    rootStore.environment.api.getPropertyRequestsSearch(valuesToSearch, rows).then((result: any) => {
      if (result.kind === "ok") {
        setResultListToData(result)
        setCurrentPage(1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
        setTotalPages(Math.ceil(result.data.count / rows))
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const getPropertyRequestsPrevNextPage = (path: string, offset: number) => {
    setLoading(true)
    setPathLastCalled(path)
    setOffsetLastCalled(offset)
    rootStore.environment.api.getPropertyRequestListPrevNextPage(path).then((result: any) => {
      setLoading(false)
      if (result.kind === "ok") {
        setResultListToData(result)
        setCurrentPage(Math.floor(offset / rowsPerPage) + 1)
        setPreviousPage(result.data.previous)
        setNextPage(result.data.next)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const handlePrevPageClicked = () => {
    let from = previousPage?.substring(previousPage?.indexOf("offset") + 7);
    let path = previousPage?.substring(previousPage?.indexOf("v1") + 2);
    if (path !== undefined) {
      if (from !== undefined && !isNaN(parseInt(from))) {
        getPropertyRequestsPrevNextPage(path, parseInt(from))
      } else {
        getPropertyRequestsPrevNextPage(path, 1)
      }
    }
  }

  const handleNextPageClicked = () => {
    let from = nextPage?.substring(nextPage?.indexOf("offset") + 7);
    let path = nextPage?.substring(nextPage?.indexOf("v1") + 2);

    if (path !== undefined) {
      if (from !== undefined && !isNaN(parseInt(from))) {
        getPropertyRequestsPrevNextPage(path, parseInt(from))
      } else {
        getPropertyRequestsPrevNextPage(path, 1)
      }
    }
  }

  const handleRowsPerPageClicked = (rows: number) => {
    setRowsPerPage(rows)
    if (valuesToSearch !== "") {
      search(rows)
    } else {
      getPropertyRequests(rows)
    }
  }

  const openShowDetails = (idPropertyRequest: number) => {
    setShowDetails(true)
    getPropertyRequestItem(idPropertyRequest)
  }


  const openApproveMessage = (id: number) => {
    setShowApproveMessage(true)
    setIdPropertyRequest(id)
    getPropertyRequestItem(id)
  }

  const approvePropertyRequest = () => {
    setShowApproveMessage(false)
    setLoading(true)
    rootStore.environment.api.approvePropertyRequest(idPropertyRequest).then((result: any) => {
      console.log('result ', result)
      setLoading(false)
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Property request successfully approved")
        setVariantMessage("success")
        getPropertyRequestsPrevNextPage(pathLastCalled, offsetLastCalled)
        setIdPropertyRequest(0)
        window.location.reload()
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true);
            setMessage("Validation errors found")
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage("An error occurred, please try again in a few moments")
          setVariantMessage("danger")
        }
      }
    })
  }

  const closeMessage = () => {
    setShow(false)
  }

  const closeShowDetails = () => {
    setShowDetails(false)
    setDataItem({})
  }

  const closeShowApproveMessage = () => {
    setShowApproveMessage(false)
    setDataItem({})
  }

  const PropertyRequestItems = (currentItems: any) => {
    return (
      <>
        {currentItems && currentItems.currentItems?.length > 0 &&
          <div style={{marginTop: "35px"}}>
            {currentItems.currentItems.map((item: any, idx: number) => {
              return (
                <div key={idx} className={["row", classes.list].join(" ")}>
                  <div className={"col-1"}>{item.idHousner}</div>
                  <div className={["col-4", classes.btnUser].join(" ")}
                       onClick={() => history.push(ROUTES_ADMIN.USER_INFO(inHash(item.idHousner)))}>
                    {item.fullName}
                  </div>
                  <div className={"col-5"}>
                    <img
                      key={idx}
                      src={item.propertyImage}
                      alt="Property image"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        height: "80px",
                        width: "130px",
                        objectFit: "contain",
                      }}
                    />
                    {`${space}${item.propertyName}`}</div>

                  <div className={"col-2"} style={{display: "inline-flex"}}>
                    <div style={{marginLeft: "10px"}}>
                      <ButtonDark startFunction={() => openApproveMessage(item.id)}
                                  bold={false}>
                        <AiOutlineCheckCircle size={"25px"}/>
                      </ButtonDark>
                    </div>
                    <div style={{marginLeft: "10px"}}>
                      <ButtonDark startFunction={() => openShowDetails(item.id)} bold={false}>
                        <FaRegEye size={"25px"}/>
                      </ButtonDark>
                    </div>
                  </div>
                </div>
              );
            })}

          </div>
        }
      </>
    );
  }

  return (
    <PageWebAdminDark>
      <div className={"row"}>
        <div className={"col-9"}
             style={{color: COLORS.white, fontSize: "xx-large"}}>
          <BiMessageError/> Approved accounts
        </div>
        <div className={"col-3"}
             style={{display: "flex", justifyContent: "center"}}>
          <AdminSearch valuesToSearch={valuesToSearch} setValuesToSearch={setValuesToSearch}
                       search={search} rowsPerPage={rowsPerPage}/>
        </div>
      </div>
      <div className={"row"} style={{marginTop: "20px"}}>
        <div className={"col-12"}
             style={{color: COLORS.white, fontSize: "x-large"}}>
          <MdOutlineRequestQuote/> Property requests
        </div>
      </div>
      {!Loading && data?.length == 0 &&
        <NoDataAdmin/>}
      {Loading &&
        <SpinnerLight/>
      }
      {!Loading && data && data?.length > 0 &&
        <div>
          <div className={["row", classes.listTittles].join(" ")}>
            <div className={"col-1"}>Housner ID</div>
            <div className={"col-4"}>Name</div>
            <div className={"col-5"}>Property Name</div>
          </div>
          <PropertyRequestItems currentItems={data}/>
          <AdminPagination rowsPerPage={rowsPerPage} handleRowsPerPageClicked={handleRowsPerPageClicked}
                           currentPage={currentPage} handleNextPageClicked={handleNextPageClicked}
                           handlePrevPageClicked={handlePrevPageClicked} nextPage={nextPage}
                           previousPage={previousPage} totalPages={totalPages}/>
        </div>
      }
      <Modal show={showDetails} onHide={closeShowDetails}>
        <ModalHeader className={classes.modal}>
          <div className={classes.modalHeaderUserDetail}>
            Housner
          </div>
          <CloseButton variant="white" onClick={closeShowDetails}/>
        </ModalHeader>
        {LoadingItem && <Spinner animation="border" variant="light" size={'sm'}/>}
        {!LoadingItem && dataItem.id &&
          <ModalBody className={classes.modal}>
            <div className={"row my-3"}>
              <div className={"col-4"} style={{marginBottom: "15px", textAlign: "center"}}>
                <div>
                  {/*{dataItem.profile_picture !== "" && dataItem.profile_picture !== null &&*/}
                  <img src={dataItem.profilePicture} alt="Profile image"
                       style={{
                         width: "100px",
                         height: "100px",
                         borderRadius: "50px",
                         marginLeft: "auto",
                         marginRight: "auto",
                         marginBottom: "10px",
                         objectFit: 'cover'
                       }}
                  />
                  {/*}*/}
                </div>
              </div>
              <div className={"col-8"} style={{marginBottom: "15px"}}>
                <div style={{fontWeight: "bold"}}>{dataItem.fullName}</div>
                <div>{dataItem.idType}{space}{dataItem.idNumber}</div>
                <div>{dataItem.idDateIssue}{space}{dataItem.idPlaceIssue}</div>
                <div>Tel:{space}{dataItem.phoneNumber}{space}</div>
                <div>Cel:{space}{dataItem.cellNumber}</div>
                <div>{dataItem.email}</div>
              </div>
            </div>
            <div className={"row my-3"}>
              <div style={{fontWeight: "bold"}}>Property</div>
              <div style={{fontWeight: "bold"}}>{dataItem.propertyName}</div>
              <div className={"col-4"} style={{textAlign: "center", marginTop: "20px"}}>
                <img
                  src={dataItem.propertyImage}
                  alt="Property image"
                  style={{
                    width: "110px",
                    height: "90px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    objectFit: 'contain'
                  }}
                />
              </div>
              <div className={"col-8"} style={{marginTop: "20px"}}>
                <div style={{overflowWrap: "break-word"}}>
                  {dataItem.googleMapLink !== "" && dataItem.googleMapLink !== undefined &&
                    <BiMap size={40} className={classes.btnMaps}
                           onClick={() => openInNewTab(dataItem.googleMapLink)}/>}
                  Address:{space}{dataItem.propertyAddress}
                </div>
              </div>
            </div>
          </ModalBody>}
      </Modal>
      <Modal show={showApproveMessage}>
        <ModalHeader className={classes.modalHeader}>
          <div style={{fontWeight: "bold", fontSize: "x-large"}}>
            Approve the property request of {dataItem.fullName} to {dataItem.propertyName}?
          </div>
        </ModalHeader>
        <ModalBody className={classes.modalBody}>
          <ButtonDark startFunction={approvePropertyRequest} bold={true}>Yes</ButtonDark>
          <ButtonDark startFunction={closeShowApproveMessage} bold={true}>No</ButtonDark>
        </ModalBody>
      </Modal>
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
    </PageWebAdminDark>
  )
});

export default PropertyRequestList;
