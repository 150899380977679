import React from 'react';
import {Redirect} from "react-router";
import {ROUTES} from "../../constants/constants";
import {useStores} from "../../models/root-store/root-store-context";
import HeaderUser from "../HeaderWeb/HeaderUser";

type PageProps = {
    login_required?: boolean
    title?: string
    housner_required?:boolean
};

export const PageWebDarkWithHeader: React.FC<PageProps> = React.forwardRef((props,ref) => {
    const rootStore = useStores()
    const {loginStore} = rootStore

    if (props.login_required && !loginStore.isLoggedIn) {
        return <Redirect to={{ pathname: ROUTES.LOGIN }} />
    }
    if (props.housner_required && loginStore.group !== 2) {
        return <Redirect to={{ pathname: ROUTES.HOME }} />
    }

    return (
        <div className={'d-flex flex-column min-vh-100 '} style={{backgroundColor: '#353939'}}>
             <HeaderUser/>
            <div className={'container py-1'}>
                {props.children}
            </div>
        </div>
    )
});

export default PageWebDarkWithHeader;
