import {Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment, withRootStore} from ".."
// import {State} from "../types/State";
// import {Specialty} from "../types/Specialty";
// import {ServiceOffered} from "../types/ServiceOffered";


/**
 * Model description here for TypeScript hints.
 */
// @ts-ignore
export const LoginStoreModel = types
    .model("LoginStore")
    .extend(withRootStore)
    .extend(withEnvironment)
    .props({
        id: types.maybeNull(types.number),
        username: types.maybeNull(types.string),
        first_name: types.maybeNull(types.string),
        second_name: types.maybeNull(types.string),
        last_name: types.maybeNull(types.string),
        second_last_name: types.maybeNull(types.string),
        token: types.maybeNull(types.string),
        access_token: types.maybeNull(types.string),
        refresh_token: types.maybeNull(types.string),
        group: types.maybeNull(types.number),
        verified_email: types.maybeNull(types.boolean),
        profile_image: types.maybeNull(types.string),
        phone_number: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        google: types.maybeNull(types.boolean),
        backgroundStatus:types.maybeNull(types.string),
    })
    .views(self => ({
        get isLoggedIn() {
            return self.token !== null && self.token !== undefined
        },
    })) // eslint-disable-line @typescript-eslint/no-unused-vars
    .actions(self => ({
        setUser(user: any) {
            self.id = user.id
            self.username = user.username
            self.first_name = user.first_name
            self.second_name = user.second_name
            self.last_name = user.last_name
            self.second_last_name = user.second_last_name
            self.group = user.group
            self.verified_email = user.verified_email
            self.profile_image = user.profile_picture
            self.google = !!user.google_id
            self.backgroundStatus = user.background_status
            // self.city = user.city
            // self.access_token = user.token.access
            // self.refresh_token = user.token.refresh
        },
        setProfile(profile: any) {
            self.id = profile.id
            self.first_name = profile.first_name
            self.second_name = profile.second_name
            self.last_name = profile.last_name
            self.second_last_name = profile.second_last_name
            self.profile_image = profile.profile_picture
        },
        //////////////////////
        setVerifiedEmail(verified_email: boolean) {
            self.verified_email = verified_email
        },
        //////////////////////
        setApiToken(token: string | null) {
            const api = self.environment.api.apisauce
            self.token = token
            if (token) {
                api?.setHeader('Authorization', 'Bearer ' + token)
            } else {
                api?.deleteHeader('Authorization')
            }
        },
        reset() {
            self.id = null
            self.username = null
            self.first_name = null
            self.last_name = null
            self.token = null
            self.group = null
            self.verified_email = null
            self.profile_image = null
            self.phone_number = null
            self.city = null
            const api = self.environment.api.apisauce
            api?.deleteHeader('Authorization')
        },
    })) // eslint-disable-line @typescript-eslint/no-unused-vars

/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type LoginStoreType = Instance<typeof LoginStoreModel>

export interface LoginStore extends LoginStoreType {
}

type LoginStoreSnapshotType = SnapshotOut<typeof LoginStoreModel>

export interface LoginStoreSnapshot extends LoginStoreSnapshotType {
}
