import {ApiConfig, DEFAULT_API_CONFIG} from "./api-config"
import * as Types from "./api.types"
import {HomeResult} from "./api.types"
import {ApiBase} from "./api-base";


/**
 * Manages all requests to the API.
 */
export class Api extends ApiBase {

    constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
        super(config)
    }

    async logout(device_id: string): Promise<Types.LogoutResult> {
        return this.noresult_post(`/logout/`, {device_id})
    }

    async login(email: string, password: string): Promise<Types.SimplePostResult> {
        return this.simple_post(`/dj-rest-auth/login/`, {
            email: email,
            password: password,
        })
    }

    async signUp(email: string, password: string, confirm_password: string): Promise<Types.LoginResult> {
        let response = await this.simple_post(`/dj-rest-auth/registration/`, {
            email: email,
            password: password,
            password_confirm: confirm_password,
        })

        if (response.kind === 'ok') {
            try {
                const resultUser: Types.User = {
                    id: response.response.id,
                    username: response.response.username,
                    first_name: response.response.first_name,
                    second_name: response.response.second_name,
                    last_name: response.response.last_name,
                    second_last_name: response.response.second_last_name,
                    token: response.response.token,
                    group: response.response.group,
                    verified_email: response.response.verified_email,
                    profile_image: response.response.profile_image,
                    phone_number: response.response.phone_number,
                    city: response.response.city,
                }
                return {kind: "ok", user: resultUser}
            } catch {
                return {kind: "bad-data"}
            }
        }
        return response
    }

    async stateList(): Promise<Types.ListResult> {
        return this.single_list_view(`/state/`)
    }

    async forgotPassword(email: string): Promise<Types.GenericResponse> {
        return this.simple_post(`/users/forgot-password/`, {email: email})
    }

    async resetPassword(otp: string, password: string, confirmPassword: string): Promise<Types.GenericResponse> {
        return this.simple_post(`/users/reset-password/`, {
            otp: otp,
            password: password,
            confirmPassword: confirmPassword
        })
    }

    async changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Promise<Types.GenericResponse> {
        return this.simple_patch(`/users/change-password/`, {
            old_password: oldPassword,
            new_password: newPassword,
            new_password_confirm: confirmPassword
        })
    }

    async faq(): Promise<Types.ListResult> {
        return this.single_list_view(`/faq/`)
    }

    async initialData(): Promise<HomeResult> {
        return this.single_get_view(`/home/`)
    }

    async reviewList(): Promise<Types.ListResult> {
        return this.single_list_view(`/review/`)
    }

    async getServiceOfferedList(): Promise<Types.ListResult> {
        return this.single_list_view(`/service_offered/`)
    }

    async serviceProviderUpdateServiceOffered(service_offered_id: number, remove: boolean): Promise<Types.GenericResponse> {
        return this.simple_post(`/update-service-provider/`, {service_offered: service_offered_id, remove})
    }

    async serviceProviderUpdate(data: any): Promise<Types.GenericResponse> {
        return this.simple_post(`/update-service-provider/`, data)
    }

    async serviceProviderImageUpdate(data: any): Promise<Types.GenericResponse> {
        return this.save_single_image(`/update-service-provider/`, data, 'business_logo')
    }

    //change to real api url
    // async sendVerificationLink(): Promise<Types.GenericResponse> {
    //     return this.simple_post(`/`)
    // }

    async resendEmailVerificationCode(): Promise<Types.GenericResponse> {
        return this.simple_post(`/users/registration/send-verification-code/`)
    }

    async loginFacebook(accessToken: any): Promise<Types.GenericResponse> {
        return this.simple_post(`/users/continue-with-facebook/`, {accessToken: accessToken})
    }

    async loginGoogle(accessToken: any, user: any): Promise<Types.GenericResponse> {
        return this.simple_post(`/users/continue-with-google/`, {token: accessToken, data: user})
    }

    async postInvitation(friendEmail: string): Promise<Types.GenericResponse> {
        return this.simple_post(`/users/invite-friend/`, {friend_email:friendEmail})
    }

    async getCities(): Promise<Types.ListResult> {
        return this.single_list_view(`/users/cities/`)
    }

    async getRegions(): Promise<Types.ListResult> {
        return this.single_list_view(`/users/regions/`)
    }

    async getCountries(): Promise<Types.ListResult> {
        return this.single_list_view(`/users/countries/`)
    }

    async getCitiesByCountry(idCountry: any): Promise<Types.ListResult> {
        return this.single_list_view(`/users/citiesbycountry/`, {country: idCountry})
    }

    async getRegionsByCountry(idCountry: any): Promise<Types.ListResult> {
        return this.single_list_view(`/users/regionsbycountry/`, {country: idCountry})
    }

    async getCitiesByRegion(idRegion: any): Promise<Types.ListResult> {
        return this.single_list_view(`/users/citiesbyregion/`, {region: idRegion})
    }

    async getPropertiesSearch(keySearch: any): Promise<Types.ListResult> {
        return this.single_list_view(`/properties/`, {search: keySearch})
    }

    async getPropertiesFilter(keyFilter: any): Promise<Types.ListResult> {
        return this.single_list_view(`/properties/${keyFilter}`)
    }

    async getProfile(): Promise<Types.ListResult> {
        return this.simple_get(`/users/account-profile/`)
    }

    async updateProfile(formData: any, keys: string[]): Promise<Types.GenericResponse> {
        return this.post_multipart_form_data(`/users/account-profile/`, formData, keys)
    }

    async getPropertiesHome(): Promise<Types.ListResult> {
        return this.simple_get(`/home/`)
    }

    async getPropertySpecific(idProperty: number): Promise<Types.ListResult> {
        return this.simple_get(`/properties/${idProperty}/`)
    }

    async createPropertyRequest(idProperty: number): Promise<Types.GenericResponse> {
        return this.simple_post(`/users/housner-property-request/`, {property_id: idProperty})
    }

    async postMaintenanceRequest(idProperty: string, message: string): Promise<Types.GenericResponse> {
        return this.simple_post(`/properties/maintenance/`, {property: idProperty, explanation: message})
    }

    async sendFeedback(title: string, message: string): Promise<Types.GenericResponse> {
        return this.simple_post(`/feedback/`, {title: title, text: message})
    }

    async getMyProperty(): Promise<Types.ListResult> {
        return this.simple_get(`/users/my-property/`)
    }

    //////ADMIN
    //User
    async getUserList(rows: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/users/?limit=${rows}`)
    }

    async getUserListPrevNextPage(path: string): Promise<Types.ListResult> {
        return this.simple_get(path)
    }

    async getUsersSearch(keySearch: any, rowsPerPage: number): Promise<Types.ListResult> {
        return this.single_list_view(`/admin/users/`, {search: keySearch, limit: rowsPerPage})
    }

    async addUser(email: string, password: string, confirmPassword: string): Promise<Types.GenericResponse> {
        return this.simple_post(`/admin/users/`, {
            email: email,
            password: password,
            password_confirm: confirmPassword,
        })
    }

    async flagUser(idUser: number): Promise<Types.GenericResponse> {
        return this.simple_post(`/admin/users/${idUser}/toggle_flag/`)
    }

    async deleteUser(idUser: number): Promise<Types.GenericResponse> {
        return this.simple_delete(`/admin/users/${idUser}/`)
    }

    //User Profile
    async getUserProfileById(idUser: number): Promise<Types.SimpleGetResult> {
        return this.simple_get(`/admin/users-profile-data/${idUser}/`)
    }

    //PDF Truora
    async getPDF(path: string): Promise<Types.GenericResponse> {
        return this.get_PDF_file(path)
    }

    //Properties
    async getPropertyList(rows: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/properties/?limit=${rows}`)
    }

    async getProperty(idProperty: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/properties/${idProperty}/`)
    }

    async getPropertiesAdminSearch(keySearch: any, rowsPerPage: number): Promise<Types.ListResult> {
        return this.single_list_view(`/admin/properties/`, {search: keySearch, limit: rowsPerPage})
    }

    async getPropertyListPrevNextPage(path: string): Promise<Types.ListResult> {
        return this.simple_get(path)
    }

    async addProperty(formData: any, keys: string[]): Promise<Types.GenericResponse> {
        return this.post_multipart_form_data(`/admin/properties/`, formData, keys)
    }

    async updateProperty(idProperty: number, formData: any, keys: string[]): Promise<Types.GenericResponse> {
        return this.patch_multipart_form_data(`/admin/properties/${idProperty}/`, formData, keys)
    }


    async deleteProperty(idProperty: number): Promise<Types.GenericResponse> {
        return this.simple_delete(`/admin/properties/${idProperty}/`)
    }

    //BackgroundChecks
    async getBackgroundCheckList(rows: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/background-checks/?limit=${rows}`)
    }

    async getChecksSearch(keySearch: any, rowsPerPage: number): Promise<Types.ListResult> {
        return this.single_list_view(`/admin/background-checks/`, {search: keySearch, limit: rowsPerPage})
    }

    async getCheckListPrevNextPage(path: string): Promise<Types.ListResult> {
        return this.simple_get(path)
    }

    //Denied users
    async getDeniedUserList(rows: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/denied-users/?limit=${rows}`)
    }

    async getDeniedUsersSearch(keySearch: any, rowsPerPage: number): Promise<Types.ListResult> {
        return this.single_list_view(`/admin/denied-users/`, {search: keySearch, limit: rowsPerPage})
    }

    async getDeniedUserListPrevNextPage(path: string): Promise<Types.ListResult> {
        return this.simple_get(path)
    }

    async approveByAdminUser(idUser: number): Promise<Types.GenericResponse> {
        return this.simple_post(`/admin/denied-users/${idUser}/approve/`)
    }

    //Housners
    async getHousnerList(rows: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/housners/?limit=${rows}`)
    }

    async getHousnersSearch(keySearch: any, rowsPerPage: number): Promise<Types.ListResult> {
        return this.single_list_view(`/admin/housners/`, {search: keySearch, limit: rowsPerPage})
    }

    async getHousnerListPrevNextPage(path: string): Promise<Types.ListResult> {
        return this.simple_get(path)
    }

    //Property Requests
    async getPropertyRequestList(rows: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/property-request/?limit=${rows}`)
    }

    async getPropertyRequestsSearch(keySearch: any, rowsPerPage: number): Promise<Types.ListResult> {
        return this.single_list_view(`/admin/property-request/`, {search: keySearch, limit: rowsPerPage})
    }

    async getPropertyRequestListPrevNextPage(path: string): Promise<Types.ListResult> {
        return this.simple_get(path)
    }

    async approvePropertyRequest(idPropertyRequest: number): Promise<Types.GenericResponse> {
        return this.simple_post(`/admin/property-request/${idPropertyRequest}/approve/`)
    }

    async getPropertyRequest(idPropertyRequest: number): Promise<Types.SimpleGetResult> {
        return this.simple_get(`/admin/property-request/${idPropertyRequest}/`)
    }

    //Contracts
    async getContractList(rows: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/contracts/?limit=${rows}`)
    }
    async getContractssSearch(keySearch: any, rowsPerPage: number): Promise<Types.ListResult> {
        return this.single_list_view(`/admin/contracts/`, {search: keySearch, limit: rowsPerPage})
    }
    async getContractListPrevNextPage(path: string): Promise<Types.ListResult> {
        return this.simple_get(path)
    }

    async getContractById(idContract: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/contracts/${idContract}/`)
    }

    //Maintenances
    async getMaintenanceList(rows: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/maintenance-requests/?limit=${rows}`)
    }

    async getMaintenancesSearch(keySearch: any, rowsPerPage: number): Promise<Types.ListResult> {
        return this.single_list_view(`/admin/maintenance-requests/`, {search: keySearch, limit: rowsPerPage})
    }

    async getMaintenanceListPrevNextPage(path: string): Promise<Types.ListResult> {
        return this.simple_get(path)
    }

    async getMaintenance(idMaintenanceRequest: number): Promise<Types.SimpleGetResult> {
        return this.simple_get(`/admin/maintenance-requests/${idMaintenanceRequest}/`)
    }

    async changeStatusMaintenance(idMaintenanceRequest: number): Promise<Types.GenericResponse> {
        return this.simple_post(`/admin/maintenance-requests/${idMaintenanceRequest}/change_status/`)
    }

    async deleteMaintenance(idMaintenanceRequest: number): Promise<Types.GenericResponse> {
        return this.simple_delete(`/admin/maintenance-requests/${idMaintenanceRequest}/`)
    }

    //Feedback
    async getFeedbackList(rows: number): Promise<Types.ListResult> {
        return this.simple_get(`/admin/feedback/?limit=${rows}`)
    }

    async getFeedbackListPrevNextPage(path: string): Promise<Types.ListResult> {
        return this.simple_get(path)
    }

    async getFeedbacksSearch(keySearch: any, rowsPerPage: number): Promise<Types.ListResult> {
        return this.single_list_view(`/admin/feedback/`, {search: keySearch, limit: rowsPerPage})
    }

    async addReplyFeedback(idFeedback: number, message: string): Promise<Types.GenericResponse> {
        return this.simple_post(`/admin/feedback/${idFeedback}/reply_feedback/`, {message: message})
    }

}
