import {Api} from "../services/api"
import {DEFAULT_API_CONFIG} from "../services/api/api-config"


/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    // create each service
    // in dev, we attach Reactotron, in prod we attach a interface-compatible mock.
    if (DEFAULT_API_CONFIG.isDebug) {
      // dev-only services
      // @ts-ignore
      this.reactotron = new ReactotronDev()
    }
    this.api = new Api()
  }

  setRootStore(rootStore: any) {
    this.api.setRootStore(rootStore)
  }

  async setup() {
    await this.api.setup()
    // allow each service to setup
    // in dev, we attach Reactotron, in prod we attach a interface-compatible mock.
  }


  /**
   * Our api.
   */
  api: Api
}
