import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {RouteComponentProps, useLocation} from "react-router";
import {Button, Card, Col, Container, Image, Row} from "react-bootstrap";
import {FaMapMarkedAlt} from "react-icons/fa";
import defaultImage from "../../../assets/images/default_Image.png";
import {ROUTES} from "../../../constants/constants";
import {inHash, openInNewTab, plata_fmt} from "../../../helpers"
import useStyles from "./styles"
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {useStores} from "../../../models/root-store/root-store-context";
import PageWebDarkWithHeader from "../../../components/PageWeb/PageWebDarkWithHeader";

interface PropertyType {
  id: number,
  address: string,
  city: string,
  total_price: number,
  total_price_currency: string,
  rent_price: number,
  rent_price_currency: string,
  maps: string,
  image: any
}

export const PropertyList: React.FC<RouteComponentProps> = observer(({history}) => {

    let classes = useStyles();
    const rootStore = useStores()
    const location = useLocation<{
      state: {
        searchResults: unknown;
      }
    }>();

    const [data, setData] = useState<Array<PropertyType>>([]);
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [variantMessage, setVariantMessage] = useState<string>("");

    const setResultListToData = (result: any) => {
      let property_data: any = [];
      result.searchResults.data.map((resultItem: any) => {
        property_data.push({
          id: resultItem.id,
          rent_price: resultItem.rent_price,
          total_price: resultItem.total_price,
          address: resultItem.address,
          maps: resultItem.google_map_link,
          image: resultItem.property_image !== null ? resultItem.property_image : defaultImage,
          city: resultItem.city !== null ? resultItem.city.name : ""
        })
      })
      setData(property_data)
    }

    useEffect(() => {
      setResultListToData(location.state)
    }, []);

    const closeMessage = () => {
      setShow(false)
    }

    return (
      <PageWebDarkWithHeader login_required={false}>
        <Container>
          <Row className={classes.rowList}>Properties Available</Row>
          <Row xs={1} sm={1} md={1} lg={2} className="g-4">
            {data.map((propertyItem, idx) => (
              <Col key={idx}>
                <Card
                  className={classes.card}
                  style={{cursor: 'pointer'}}
                  onClick={() =>
                    history.push(ROUTES.PROPERTY_SPECIFIC(inHash((propertyItem.id).toString())))
                  }>
                  <div className={classes.cardImg}>
                    <Image src={propertyItem.image} className={classes.propertyImage} />
                  </div>
                  <Card.ImgOverlay>
                    <Card.Text>
                      {propertyItem.maps && propertyItem.maps !== '' && <Button className={classes.cardBtn}
                              onClick={() => openInNewTab(propertyItem.maps)}>
                        <FaMapMarkedAlt className={classes.iconMap}/>
                      </Button>}
                    </Card.Text>
                  </Card.ImgOverlay>
                  <Card.Body>
                    <Row style={{paddingBottom: "10px"}}>
                      <Col>
                        <Card.Text> Address: {propertyItem.address}</Card.Text>
                      </Col>
                      <Col>
                        <Card.Text> City: {propertyItem.city}
                        </Card.Text>
                      </Col>
                    </Row>
                    <Row style={{paddingBottom: "10px"}}>
                      <Col>
                        <Card.Text> Sale: {plata_fmt(propertyItem.total_price)} </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text> Rent: {plata_fmt(propertyItem.rent_price)}  </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}

          </Row>
        </Container>
        <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
      </PageWebDarkWithHeader>
    );
  })
;

export default PropertyList;
