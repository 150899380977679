import React from "react";
import {COLORS} from "../../constants/constants";
import useStyles from "./styles";
import Select from "react-select";
import {OptionString} from "../../constants/types";

type InputProps = {
    label: string,
    option: string,
    options: Array<OptionString>,
    setValue: (arg: string) => void,
    placeholder: string,
    className: string,
};

const colourStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
        ...base,
        background: COLORS.gray,
        borderRadius: "12px",
        // borderColor: state.isFocused ? COLORS.green : COLORS.white,
        boxShadow: state.isFocused ? null : null,
        // "&:hover": {
        //     borderColor: state.isFocused ? COLORS.green : COLORS.white
        // }
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: COLORS.white
    }),
    option: (styles: any, {isFocused}: any) => {
        return {
            ...styles,
            backgroundColor: isFocused ? COLORS.lightGray : null,
            color: COLORS.black
        };
    }
};

export const ReactSelectString: React.FC<InputProps> = React.forwardRef((props,ref) => {

    let classes = useStyles();

    return (
        <div className={props.className}>
            <label className={classes.labelForm}>{props.label}</label>
            <Select options={props.options}
                    onChange={(e) => props.setValue(e ? e.value : "")}
                    value={props.options.filter(obj => obj.value === props.option)}
                    placeholder={props.placeholder}
                    maxMenuHeight={180}
                    styles={{...colourStyles,

                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
            />
        </div>
    )
});

export default ReactSelectString;
