import {makeStyles} from "@material-ui/styles";

export default makeStyles((theme) => ({
    logoShortHoushares: {
        // marginLeft: "45%",
    },

    logoLongHoushares: {
        width: "28%",
        height: "28%",
        marginTop: "-40px"
    },

    "@media (max-width: 1200px)": {
        logoLongHoushares: {
            width: "33%",
            height: "33%",
            marginTop: "-40px"
        },
    },

    "@media (max-width: 992px)": {
        logoLongHoushares: {
            width: "40%",
            height: "40%",
            marginTop: "-40px"
        },
    },
    "@media (max-width: 768px)": {
        logoLongHoushares: {
            width: "50%",
            height: "50%",
            marginTop: "-40px"
        },
    },
    "@media (max-width: 720px)": {
        logoLongHoushares: {
            width: "65%",
            height: "65%",
            marginTop: "-60px"
        },
    },
}));
