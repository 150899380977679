import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Button, Col, Modal, ModalBody, Row, Spinner} from "react-bootstrap";
import {AiOutlineCheckCircle, AiOutlineWarning} from "react-icons/ai";
import {BsCheck} from "react-icons/bs";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {
  BOGOTA_CITY,
  BOGOTA_DC,
  COLOMBIA_ID,
  COLORS,
  optionsArray,
  optionsOcupation,
  optionsStudies,
  ROUTES
} from "../../../constants/constants";
import PageWebDark from "../../../components/PageWeb/PageWebDark";
import ToggleSwitch from "../../../components/Switch/ToggleSwitch";
import * as Yup from "yup";
import {Field, Form, Formik, FormikProps} from "formik";
import InputForm from "../../../components/Inputs/InputForm";
import useStyles from "../Application/styles";
import ButtonSquare from "../../../components/Buttons/ButtonSquare";
import {useStores} from "../../../models/root-store/root-store-context";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import ReactSelect from "../../../components/Select/ReactSelect";
import {DetailViewProps, OptionPlaceType} from "../../../constants/types";
import {outHash, useClearStore, useWindowSize} from "../../../helpers";
import ReactSelectString from "../../../components/Select/ReactSelectString";
import InputPhone from "../../../components/Inputs/InputPhone";
import AdminItemField from "../../../components/Inputs/AdminItemField";
import moment from "moment-timezone";

interface IProfileApplication {
  id: number,
  firstName: string,
  secondName: string,
  firstSurname: string,
  secondSurname: string,
  idNumber: string,
  idDate: Date,
  idPlace: number,
  idType: string,
  phone: string,
  email: string,
  profilePicture: string
  cellPhone: string,
  birthDate: Date,
  birthCity: number,
  birthCountry: number,
  studyLevel: string,
  profession: "",
  residenceAddress: string,
  residenceCountry: number,
  residenceRegion: number,
  residenceCity: number,
  occupation: string,
  ciiuCode: string,
  incomeTaxFiler: boolean,
  civilServant: boolean,
  managePublicResources: boolean,
  companyName: string,
  companyPosition: string,
  companyAddress: string,
  companyTel: string,
  companyCountry: number,
  companyRegion: number,
  companyCity: number,
  monthlyIncome: any,
  monthlyIncomeDescription: string,
  otherMonthlyIncome: number,
  otherMonthlyIncomeDescription: string,
  totalMonthlyExpenses: number,
  totalAssets: number,
  totalLiabilities: number
}

interface IApplicationForm {
  firstName: string,
  secondName: string,
  firstSurname: string,
  secondSurname: string,
  idNumber: string,
  idDate: Date,
  //idPlace: number,
  //phone: string,
  //cel: string,
  email: string,
  birthDate: Date,
  profession: string,
  residenceAddress: string,
  ciiuCode: string,
  companyName: string,
  companyPosition: string,
  companyAddress: string,
  //companyTel: string,
  monthlyIncome: number,
  monthlyIncomeDescription: string,
  otherMonthlyIncome: number,
  otherMonthlyIncomeDescription: string,
  totalMonthlyExpenses: number, //total de egresos mensuales in spanish
  totalAssets: number, //total de activos in spanish
  totalLiabilities: number, //total de pasivos in spanish
}

interface IApplicationFormMobile1 {
  firstName: string,
  secondName: string,
  firstSurname: string,
  secondSurname: string,
  idNumber: string,
  idDate: Date,
  email: string,
  birthDate: Date,
  birthCity: number,
  birthCountry: number,
  studyLevel: string
}

interface IApplicationFormMobile2 {
  companyCountry: number;
  companyDepartment: number;
  companyCity: number;
  country: number;
  occupation: string;
  city: number;
  department: number;
  profession: string,
  residenceAddress: string,
  ciiuCode: string,
  companyName: string,
  companyPosition: string,
  companyAddress: string,
  // companyTel: string,
}

interface IApplicationFormMobile3 {
  monthlyIncome: number,
  monthlyIncomeDescription: string,
  otherMonthlyIncome: number,
  otherMonthlyIncomeDescription: string,
  totalMonthlyExpenses: number, //total de egresos mensuales in spanish
  totalAssets: number, //total de activos in spanish
  totalLiabilities: number, //total de pasivos in spanish
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  firstSurname: Yup.string().required('Required'),
  idNumber: Yup.string().matches(/^(?=.*\d)(?=.*[1-9]).{1,12}$/, "Only numbers are allowed for this field")
    .required('Required'),
  // idDate: Yup.date().default(undefined)
  //   .nullable()
  //   .transform((curr, orig) => orig === '' ? null : curr)
  //   .typeError("Invalid Date")
  //   .required('Required'),
  //idPlace: Yup.string().required('Required'),
  // phone: Yup.string().required('Required'),
  //cel: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  // birthDate: Yup.date().default(undefined)
  //   .nullable()
  //   .transform((curr, orig) => orig === '' ? null : curr)
  //   .typeError("Invalid Date")
  //   .required('Required'),
  residenceAddress: Yup.string().required('Required'),
  profession: Yup.string().required('Required'),
  ciiuCode: Yup.string().matches(/^(?=.*\d)(?=.*[1-9]).{1,5}$/, "Only numbers are allowed for this field")
    .required("Required"),
  companyName: Yup.string().required('Required'),
  companyPosition: Yup.string().required('Required'),
  companyAddress: Yup.string().required('Required'),
  //companyTel: Yup.string().required('Required'),
  monthlyIncomeDescription: Yup.string().required('Required'),
  // otherMonthlyIncome: Yup.number().moreThan(0, "other monthly income must be greater than 0").required("Required"),
  otherMonthlyIncomeDescription: Yup.string().required('Required'),
  // totalMonthlyExpenses: Yup.number().moreThan(0, "total monthly expenses must be greater than 0").required("Required"),
  // totalAssets: Yup.number().moreThan(0, "total assets must be greater than 0").required("Required"),
  // totalLiabilities: Yup.number().moreThan(0, "total liabilities must be greater than 0").required("Required"),
})

const schemaMobile1 = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  firstSurname: Yup.string().required('Required'),
  idNumber: Yup.string().matches(/^(?=.*\d)(?=.*[1-9]).{1,12}$/, "Only numbers are allowed for this field")
    .required('Required'),
  idDate: Yup.date().default(undefined)
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .typeError("Invalid Date")
    .required('Required'),
  email: Yup.string().email().required('Required'),
  birthDate: Yup.date().default(undefined)
    .nullable()
    .transform((curr, orig) => orig === '' ? null : curr)
    .typeError("Invalid Date")
    .required('Required'),
})

const schemaMobile2 = Yup.object().shape({
  residenceAddress: Yup.string().required('Required'),
  profession: Yup.string().required('Required'),
  ciiuCode: Yup.string().matches(/^(?=.*\d)(?=.*[1-9]).{1,5}$/, "Only numbers are allowed for this field").required("Required"),
  companyName: Yup.string().required('Required'),
  companyPosition: Yup.string().required('Required'),
  companyAddress: Yup.string().required('Required'),
})

const schemaMobile3 = Yup.object().shape({
  // monthlyIncome: Yup.number().moreThan(0, "monthly income must be greater than 0").required("Required"),
  monthlyIncomeDescription: Yup.string().required('Required'),
  // otherMonthlyIncome: Yup.number().moreThan(0, "other monthly income must be greater than 0").required("Required"),
  otherMonthlyIncomeDescription: Yup.string().required('Required'),
  // totalMonthlyExpenses: Yup.number().moreThan(0, "total monthly expenses must be greater than 0").required("Required"),
  // totalAssets: Yup.number().moreThan(0, "total assets must be greater than 0").required("Required"),
  // totalLiabilities: Yup.number().moreThan(0, "total liabilities must be greater than 0").required("Required"),
})

const useInspectHook = (hook: any, hookName: any) => {
  const [a,setA] = hook;

  return [a, (x: any) => {
    console.log(`set ${hookName} with `, x)
    setA(x)
  }]
}

//this is Application part 2
export const Application: React.FC<DetailViewProps> = observer(({history, match}) => {
  let classes = useStyles();
  const rootStore = useStores()
  const clearStore = useClearStore()
  const idProperty = outHash(match.params.id);

  const [Loading, setLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1);
  const [phone, setPhone] = useState<string>("");
  const [cellphone, setCellphone] = useState<string>("");
  const [idType, setIdType] = useState<string>("CC");
  const [idPlace, setIdPlace] = useState<number>(COLOMBIA_ID);
  const [birthCity, setBirthCity] = useState<number>(BOGOTA_CITY);
  const [birthCountry, setBirthCountry] = useState<number>(COLOMBIA_ID);
  const [studyLevel, setStudyLevel] = useState<string>("NI");
  const [residenceCity, setResidenceCity] = useState<number>(BOGOTA_CITY);
  const [residenceDepartment, setResidenceDepartment] = useInspectHook(useState<number>(BOGOTA_DC), 'department');
  const [residenceCountry, setResidenceCountry] = useState<number>(COLOMBIA_ID);
  const [occupation, setOccupation] = useState<string>("ASA");
  const [incomeTaxFiler, setIncomeTaxFiler] = useState<boolean>(false); //declarante de renta in spanish
  const [civilServant, setCivilServant] = useState<boolean>(false); //funcionario publico in spanish
  const [managePublicResources, setManagePublicResources] = useState<boolean>(false); //maneja recursos publicos in spanish
  const [companyTel, setCompanyTel] = useState<string>("");
  const [companyCity, setCompanyCity] = useState<number>(BOGOTA_CITY);
  const [companyDepartment, setCompanyDepartment] = useState<number>(BOGOTA_DC);
  const [companyCountry, setCompanyCountry] = useState<number>(COLOMBIA_ID);
  const [formValues, setFormValues] = useState<any>();
  const [showSubmitted, setShowSubmitted] = useState(false);
  const isMobile = useWindowSize();
  const [countriesIdPlace, setCountriesIdPlace] = useState<Array<OptionPlaceType>>([]);
  const [citiesBirth, setCitiesBirth] = useState<Array<OptionPlaceType>>([]);
  const [countriesBirth, setCountriesBirth] = useState<Array<OptionPlaceType>>([]);
  const [citiesResidence, setCitiesResidence] = useState<Array<OptionPlaceType>>([]);
  const [regionsResidence, setRegionsResidence] = useState<Array<OptionPlaceType>>([]);
  const [countriesResidence, setCountriesResidence] = useState<Array<OptionPlaceType>>([]);
  const [citiesCompany, setCitiesCompany] = useState<Array<OptionPlaceType>>([]);
  const [regionsCompany, setRegionsCompany] = useState<Array<OptionPlaceType>>([]);
  const [countriesCompany, setCountriesCompany] = useState<Array<OptionPlaceType>>([]);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");
  const [rentPrice, setRentPrice] = useState<number>(0);
  const [showWarningRental, setShowWarningRental] = useState<boolean>(false);

  const [data, setData] = useState<IProfileApplication>({
    id: -1,
    firstName: "",
    secondName: "",
    firstSurname: "",
    secondSurname: "",
    idType: "",
    idNumber: "",
    idDate: new Date(),
    idPlace: COLOMBIA_ID,
    phone: "",
    cellPhone: "",
    email: "",
    profilePicture: "",
    birthDate: new Date(),
    birthCity: BOGOTA_CITY,
    birthCountry: COLOMBIA_ID,
    studyLevel: "",
    profession: "",
    residenceAddress: "",
    residenceCountry: COLOMBIA_ID,
    residenceRegion: BOGOTA_DC,
    residenceCity: BOGOTA_CITY,
    occupation: "",
    ciiuCode: "0",
    incomeTaxFiler: false,
    civilServant: false,
    managePublicResources: false,
    companyName: "",
    companyPosition: "",
    companyAddress: "",
    companyTel: "",
    companyCountry: COLOMBIA_ID,
    companyRegion: BOGOTA_DC,
    companyCity: BOGOTA_CITY,
    monthlyIncome: 0,
    monthlyIncomeDescription: "",
    otherMonthlyIncome: 0,
    otherMonthlyIncomeDescription: "",
    totalMonthlyExpenses: 0,
    totalAssets: 0,
    totalLiabilities: 0
  });

  const setDato = (campo: string, valor: string) => {
    let datos = {...data}
    // @ts-ignore
    datos[campo] = valor
    setData(datos)
  }

  const setDatoInput = (campo: string, data: any) => {
    setDato(campo, data)
  }

  const getProfileData = () => {
    rootStore.environment.api.getProfile().then((result: any) => {
      let dataTmp = data;
      dataTmp.id = 0;
      if (result.kind === "ok") {
        console.log('data ', result.data)
        dataTmp.id = result.data.id;
        dataTmp.firstName = result.data.first_name;
        dataTmp.secondName = result.data.second_name;
        dataTmp.firstSurname = result.data.last_name;
        dataTmp.secondSurname = result.data.second_last_name;
        dataTmp.idNumber = result.data.id_number;
        dataTmp.idDate = result.data.id_date_of_issue;
        dataTmp.idPlace = result.data.id_place_of_issue;
        dataTmp.idType = result.data.id_type;
        dataTmp.phone = result.data.phone_number;
        dataTmp.cellPhone = result.data.cell_phone_number;
        dataTmp.email = result.data.email;
        dataTmp.profilePicture = result.data.profile_picture;
        dataTmp.birthDate = result.data.date_of_birth;
        dataTmp.birthCity = result.data.city_of_birth;
        dataTmp.birthCountry = result.data.country_of_birth;
        dataTmp.studyLevel = result.data.study_level;
        dataTmp.profession = result.data.profession;
        dataTmp.residenceAddress = result.data.residence_address;
        dataTmp.residenceCountry = result.data.residence_country;
        dataTmp.residenceRegion = result.data.residence_region;
        dataTmp.residenceCity = result.data.residence_city;
        dataTmp.occupation = result.data.occupation;
        dataTmp.ciiuCode = result.data.ciiu_code;
        dataTmp.incomeTaxFiler = result.data.income_tax_filer;
        dataTmp.civilServant = result.data.public_servant;
        dataTmp.managePublicResources = result.data.public_resources_management;
        dataTmp.companyName = result.data.company_name;
        dataTmp.companyPosition = result.data.company_position;
        dataTmp.companyAddress = result.data.company_address;
        dataTmp.companyTel = result.data.company_phone_number;
        dataTmp.companyCountry = result.data.company_country;
        dataTmp.companyRegion = result.data.company_region;
        dataTmp.companyCity = result.data.company_city;
        dataTmp.monthlyIncome = Math.trunc(result.data.monthly_income);
        dataTmp.monthlyIncomeDescription = result.data.monthly_income_description;
        dataTmp.otherMonthlyIncome = Math.trunc(result.data.other_monthly_income);
        dataTmp.otherMonthlyIncomeDescription = result.data.other_monthly_income_description;
        dataTmp.totalMonthlyExpenses = Math.trunc(result.data.monthly_total_expenses);
        dataTmp.totalAssets = Math.trunc(result.data.total_actives);
        dataTmp.totalLiabilities = Math.trunc(result.data.total_passives);

        setIdType(result.data.id_type ? result.data.id_type : idType);
        setIdPlace(result.data.id_place_of_issue ? result.data.id_place_of_issue : idPlace);
        setStudyLevel(result.data.study_level ? result.data.study_level : studyLevel);
        setOccupation(result.data.occupation ? result.data.occupation : occupation);
        setIncomeTaxFiler(result.data.income_tax_filer ? result.data.income_tax_filer : incomeTaxFiler);
        setCivilServant(result.data.public_servant ? result.data.public_servant : civilServant);
        setManagePublicResources(result.data.public_resources_management ? result.data.public_resources_management : managePublicResources);
        setPhone(result.data.phone_number ? result.data.phone_number : phone);
        setCellphone(result.data.cell_phone_number ? result.data.cell_phone_number : cellphone);
        setBirthCountry(result.data.country_of_birth ? result.data.country_of_birth : birthCountry);
        setBirthCity(result.data.city_of_birth ? result.data.city_of_birth : birthCity);
        setResidenceCountry(result.data.residence_country ? result.data.residence_country : residenceCountry);
        setResidenceDepartment(result.data.residence_region ? result.data.residence_region : residenceDepartment);
        setResidenceCity(result.data.residence_city ? result.data.residence_city : residenceCity);
        setCompanyTel(result.data.company_phone_number ? result.data.company_phone_number : companyTel)
        setCompanyCountry(result.data.company_country ? result.data.company_country : companyCountry);
        setCompanyDepartment(result.data.company_region ? result.data.company_region : companyDepartment);
        setCompanyCity(result.data.company_city ? result.data.company_city : companyCity);
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else if (result.kind === "unauthorized") {
          setShow(true)
          setMessage("Session expired")
          setVariantMessage("danger")
          setTimeout(() => {
            clearStore();
            history.push(ROUTES.LOGIN)
          }, 1000)
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
      setData(dataTmp)
    })
  }

  const getPropertyData = () => {
    rootStore.environment.api.getPropertySpecific(parseInt(idProperty)).then((result: any) => {
      if (result.kind === "ok") {
        setRentPrice(result.data.rent_price)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const getCountries = () => {
    rootStore.environment.api.getCountries().then((result: any) => {
      let arrayTmp: { value: any; label: any; }[] = []
      if (result.kind === "ok") {
        result.data.map((item: any, idx: number) => {
          if (item.id === COLOMBIA_ID) {
            arrayTmp.push({value: item.id, label: item.name})
          }
        })
        setCountriesIdPlace(arrayTmp)
        setCountriesBirth(arrayTmp)
        setCountriesResidence(arrayTmp)
        setCountriesCompany(arrayTmp)
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const getCitiesByCountry = (idCountry: number, setState: (cityCode: number) => void, onLoad: boolean): Array<OptionPlaceType> => {
    let arrayTmp: { value: number; label: string; }[] = []
    rootStore.environment.api.getCitiesByCountry(COLOMBIA_ID).then((result: any) => {
      if (result.kind === "ok") {
        result.data.map((item: any, idx: number) => {
          arrayTmp.push({value: item.id, label: item.name})
        })
        setCitiesBirth(arrayTmp);
        if (!onLoad && arrayTmp.length > 0) setState(arrayTmp[0].value);
        // else if(arrayTmp.length > 0) setState(null);
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
    return arrayTmp
  }

  const getRegionsByCountry = (idCountry: number, setRegions: (regions: any) => void,
                               setRegion: (regionCode: number) => void, onLoad: boolean): Array<OptionPlaceType> => {
    let arrayTmp: { value: number; label: string; }[] = []
    rootStore.environment.api.getRegionsByCountry(COLOMBIA_ID).then((result: any) => {
      if (result.kind === "ok") {
        result.data.map((item: any, idx: number) => {
          arrayTmp.push({value: item.id, label: item.name})
        })
        setRegions(arrayTmp)
        if (!onLoad && arrayTmp.length > 0) setRegion(arrayTmp[0].value);
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
    return arrayTmp
  }

  const getCitiesByRegion = (idRegion: number, setCities: (cities: any) => void,
                             setCity: (cityCode: number) => void, onLoad: boolean): Array<OptionPlaceType> => {
    let arrayTmp: { value: number; label: string; }[] = []
    rootStore.environment.api.getCitiesByCountry(COLOMBIA_ID).then((result: any) => {
      if (result.kind === "ok") {
        result.data.map((item: any, idx: number) => {
          arrayTmp.push({value: item.id, label: item.name})
        })
        setCities(arrayTmp)
        if (!onLoad && arrayTmp.length > 0) setCity(arrayTmp[0].value);
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
    return arrayTmp
  }

  const saveUserData = (values: IApplicationForm, idType: string, idPlace: number, phone: string, setSubmitting: (arg0: boolean) => void) => {
    console.log('values', values)
    setLoading(true)
    const dataStored = {...data}
    const cleanData = () => {
      let data: any = {}
      let keys: string[] = []
      //data
      if (values.secondName !== "") {
        data["second_name"] = values.secondName
      }
      if (values.secondSurname !== "") {
        data["second_last_name"] = values.secondSurname
      }
      if (idPlace !== 0) {
        data["id_place_of_issue"] = idPlace
      }
      data["first_name"] = values.firstName
      data["last_name"] = values.firstSurname
      data["id_type"] = idType
      data["id_number"] = values.idNumber
      data["id_date_of_issue"] = moment(dataStored['idDate']).format('YYYY-MM-DD')
      data["phone_number"] = "+" + phone
      data["cell_phone_number"] = "+" + cellphone
      data["email"] = values.email
      data["date_of_birth"] = moment(dataStored['birthDate']).format('YYYY-MM-DD')
      data["city_of_birth"] = birthCity
      data["country_of_birth"] = birthCountry
      data["study_level"] = studyLevel
      data["profession"] = values.profession
      data["residence_address"] = values.residenceAddress
      data["residence_country"] = residenceCountry
      data["residence_region"] = residenceDepartment
      data["residence_city"] = residenceCity
      data["occupation"] = occupation
      data["ciiu_code"] = values.ciiuCode
      data["income_tax_filer"] = incomeTaxFiler
      data["public_servant"] = civilServant
      data["public_resources_management"] = managePublicResources
      data["company_name"] = values.companyName
      data["company_position"] = values.companyPosition
      data["company_address"] = values.companyAddress
      data["company_phone_number"] = "+" + companyTel
      data["company_country"] = companyCountry
      data["company_region"] = companyDepartment
      data["company_city"] = companyCity
      data["monthly_income_description"] = values.monthlyIncomeDescription
      data["other_monthly_income_description"] = values.otherMonthlyIncomeDescription

      data["monthly_income"] = dataStored['monthlyIncome']
      data["other_monthly_income"] = dataStored['otherMonthlyIncome']
      data["monthly_total_expenses"] = dataStored['totalMonthlyExpenses']
      data["total_actives"] = dataStored['totalAssets']
      data["total_passives"] = dataStored['totalLiabilities']

      data["completed"] = true
      data["property_id"] = idProperty

      //keys
      if (values.secondName !== "") {
        keys.push("second_name")
      }
      if (values.secondSurname !== "") {
        keys.push("second_last_name")
      }
      if (idPlace !== 0) {
        keys.push("id_place_of_issue")
      }
      keys.push("first_name")
      keys.push("last_name")
      keys.push("id_type")
      keys.push("id_number")
      keys.push("id_date_of_issue")
      keys.push("phone_number")
      keys.push("cell_phone_number")
      keys.push("email")
      keys.push("date_of_birth")
      keys.push("city_of_birth")
      keys.push("country_of_birth")
      keys.push("study_level")
      keys.push("profession")
      keys.push("residence_address")
      keys.push("residence_country")
      keys.push("residence_region")
      keys.push("residence_city")
      keys.push("occupation")
      keys.push("ciiu_code")
      keys.push("income_tax_filer")
      keys.push("public_servant")
      keys.push("public_resources_management")
      keys.push("company_name")
      keys.push("company_position")
      keys.push("company_address")
      keys.push("company_phone_number")
      keys.push("company_country")
      keys.push("company_region")
      keys.push("company_city")
      keys.push("monthly_income")
      keys.push("monthly_income_description")
      keys.push("other_monthly_income")
      keys.push("other_monthly_income_description")
      keys.push("monthly_total_expenses")
      keys.push("total_actives")
      keys.push("total_passives")
      keys.push("completed")
      keys.push("property_id")
      return {data: data, keys: keys}
    }

    const cleanedData = cleanData()
      rootStore.environment.api.updateProfile(cleanedData.data, cleanedData.keys).then((result: any) => {
        setSubmitting(false)
        setLoading(false)
        if (result.kind === "ok") {


          if ((parseInt(String(data['monthlyIncome'])) + parseInt(String(data['otherMonthlyIncome']))) * 0.3 <= parseInt(String(rentPrice))) {
            setShowWarningRental(true)
          } else {
            setShowSubmitted(true)
          }
        } else {
          window.scrollTo(0, 0)
          if (result.kind === "bad-data") {
            if (result.errors.non_field_errors) {
              setShow(true)
              setMessage(result.errors.non_field_errors[0])
              setVariantMessage("warning")
            } else if (result.errors.phone_number) {
              setShow(true)
              setMessage(result.errors.phone_number)
              setVariantMessage("warning")
            } else if (result.errors.cell_phone_number) {
              setShow(true)
              setMessage("Cell phone number: " + result.errors.cell_phone_number)
              setVariantMessage("warning")
            } else if (result.errors.company_phone_number) {
              setShow(true)
              setMessage("Company phone number: " + result.errors.company_phone_number)
              setVariantMessage("warning")
            } else if (result.errors.total_actives) {
              setShow(true)
              setMessage("Total actives: " + result.errors.total_actives)
              setVariantMessage("warning")
            } else if (result.errors.total_passives) {
              setShow(true)
              setMessage("Total liabilities: " + result.errors.total_passives)
              setVariantMessage("warning")
            } else if (result.errors.other_monthly_income) {
              setShow(true)
              setMessage("Other monthly income: " + result.errors.other_monthly_income)
              setVariantMessage("warning")
            } else {
              setShow(true)
              setMessage('Validation errors found')
              setVariantMessage("warning")
            }
          } else {
            setShow(true)
            setMessage('An error occurred, please try again in a few moments')
            setVariantMessage("danger")
          }
        }
      })
  }

  useEffect(() => {
    getPropertyData();
    getProfileData();
    getCountries();
    let citiesTmp = getCitiesByCountry(birthCountry, setBirthCity, true)
    let regionsResidenceTmp = getRegionsByCountry(residenceCountry, setRegionsResidence, setResidenceDepartment, true)
    let regionsCompanyTmp = getRegionsByCountry(companyCountry, setRegionsCompany, setCompanyDepartment, true)
    let citiesResidenceTmp = getCitiesByRegion(residenceDepartment, setCitiesResidence, setResidenceCity, true)
    let citiesCompanyTmp = getCitiesByRegion(companyDepartment, setCitiesCompany, setCompanyCity, true)
  }, []);


  const handleNextPage = () => {
    if (page + 1 <= 3)
      setPage(page + 1)
  }

  const handlePreviewPage = () => {
    if (page - 1 >= 1)
      setPage(page - 1)
    else
      history.goBack()
  }

  const handleCloseShowSubmitted = () => {
    setShowSubmitted(false)
    history.push(ROUTES.HOME)
  }

  const handleCloseShowWarningRental = () => {
    setShowWarningRental(false)
    history.push(ROUTES.HOME)
  }

  const closeMessage = () => {
    setShow(false)
  }

  const saveAccountInfo = (values: any, setSubmitting: (arg0: boolean) => void) => {
    saveUserData(values, idType, idPlace, phone, setSubmitting);
  }

  const onCountryOfBirthChange = (countryOfBirthCode: number) => {
    // let citiesTmp = getCitiesByCountry(countryOfBirthCode, setBirthCity, false);
    setBirthCountry(countryOfBirthCode);
  }

  const onCountryOfResidenceChange = (countryOfResidenceCode: number) => {
    // let regionsTmp = getRegionsByCountry(countryOfResidenceCode, setRegionsResidence, setResidenceDepartment, false);
    setResidenceCountry(countryOfResidenceCode);
  }

  const onCountryOfCompanyChange = (countryOfCompanyCode: number) => {
    // let regionsTmp = getRegionsByCountry(countryOfCompanyCode, setRegionsCompany, setCompanyDepartment, false);
    setCompanyCountry(countryOfCompanyCode);
  }

  const onRegionOfResidenceChange = (regionOfResidenceCode: number) => {
    // let citiesTmp = getCitiesByRegion(regionOfResidenceCode, setCitiesResidence, setResidenceCity, false);
    setResidenceDepartment(regionOfResidenceCode);
  }

  const onRegionOfCompanyChange = (regionOfCompanyCode: number) => {
    // let citiesTmp = getCitiesByRegion(regionOfCompanyCode, setCitiesCompany, setCompanyCity, false);
    setCompanyDepartment(regionOfCompanyCode);
  }

  const Form1 = (props: any) => {
    return (
      <Formik
        key={1}
        initialValues={{
          firstName: formValues?.firstName ? formValues.firstName : data.firstName || '',
          secondName: formValues?.secondName ? formValues.secondName : data.secondName || '',
          firstSurname: formValues?.firstSurname ? formValues.firstSurname : data.firstSurname || '',
          secondSurname: formValues?.secondSurname ? formValues.secondSurname : data.secondSurname || '',
          idNumber: formValues?.idNumber ? formValues.idNumber : data.idNumber || '0',
          idDate: formValues?.idDate ? formValues.idDate : data.idDate || new Date(),
          email: formValues?.email ? formValues.email : data.email || '',
          birthDate: formValues?.birthDate ? formValues.birthDate : data.birthDate || new Date(),
          studyLevel: studyLevel,
          birthCountry: birthCountry,
          birthCity: birthCity
        }}
        onSubmit={
          (values: IApplicationFormMobile1, actions) => {

            let data1 = {
              firstName: values.firstName,
              secondName: values.secondName,
              firstSurname: values.firstSurname,
              secondSurname: values.secondSurname,
              idNumber: values.idNumber,
              idDate: values.idDate,
              email: values.email,
              birthDate: values.birthDate,
              profession: formValues ? formValues.profession : '',
              residenceAddress: formValues ? formValues.residenceAddress : '',
              ciiuCode: formValues ? formValues.ciiuCode : '0',
              companyName: formValues ? formValues.companyName : '',
              companyPosition: formValues ? formValues.companyPosition : '',
              companyAddress: formValues ? formValues.companyAddress : '',
              monthlyIncome: formValues ? formValues.monthlyIncome : 0,
              monthlyIncomeDescription: formValues ? formValues.monthlyIncomeDescription : '',
              otherMonthlyIncome: formValues ? formValues.otherMonthlyIncome : 0,
              otherMonthlyIncomeDescription: formValues ? formValues.otherMonthlyIncomeDescription : '',
              totalMonthlyExpenses: formValues ? formValues.totalMonthlyExpenses : 0,
              totalAssets: formValues ? formValues.totalAssets : 0,
              totalLiabilities: formValues ? formValues.totalLiabilities : 0,
            }
            setBirthCountry(values.birthCountry)
            setBirthCity(values.birthCity)
            setStudyLevel(values.studyLevel)
            setFormValues(data1)
            handleNextPage()
          }
        }
        validationSchema={schemaMobile1}
        // enableReinitialize
      >
        {(props: FormikProps<IApplicationFormMobile1>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
          } = props
          return (
            <Form key={"form-1"}>
              <Row className={classes.tittleMobile}>
                Profile data
              </Row>
              <Row>
                <Field name={"firstName"} label={"First name"} type={""}
                       placeholder={""}
                       className={"col-6 mt-2 text-start"}
                       component={InputForm}/>
                <Field name={"secondName"} label={"Second name"} type={""}
                       placeholder={""}
                       className={"col-6 mt-2 text-start"}
                       component={InputForm}/>
              </Row>
              <Row>
                <Field name={"firstSurname"} label={"First surname"} type={""}
                       placeholder={""}
                       className={"col-6 mt-2 text-start"}
                       component={InputForm}/>
                <Field name={"secondSurname"} label={"Second surname"} type={""}
                       placeholder={""}
                       className={"col-6 mt-2 text-start"}
                       component={InputForm}/>
              </Row>
              <Row>
                <ReactSelectString label={"ID type"} option={idType}
                                   options={optionsArray}
                                   setValue={setIdType}
                                   placeholder={"ID type"}
                                   className={"col-6 mt-2 text-start"}/>
                <Field name={"idNumber"} label={"Number"} type={""}
                       placeholder={""}
                       className={"col-6 mt-2 text-start"}
                       component={InputForm}/>
              </Row>
              <Row>
                <AdminItemField
                  titulo={'Date of issue'}
                  tipo={'fecha'}
                  campo={'idDate'}
                  valor={data['idDate']}
                  set_dato={setDatoInput}
                  className={"col-6 mt-2 text-start"}
                  horizontal
                />
                <ReactSelect label={"Place of issue"} option={idPlace}
                             options={countriesIdPlace}
                             setValue={setIdPlace}
                             placeholder={"Place of issue"}
                             className={"col-6 mt-2 text-start"}/>
              </Row>
              <Row>
                <InputPhone label="Phone number" setValue={setPhone} value={phone}
                            required={true} className={"col-12 mt-2 text-start"}/>
              </Row>
              <Row>
                <InputPhone label="Cel number" setValue={setCellphone} value={cellphone}
                            required={true} className={"col-12 mt-2 text-start"}/>
              </Row>
              <Row>
                <Field name={"email"} label={"email"} type={"email"} disabled={true}
                       placeholder={""} className={"col-12 mt-2 text-start"}
                       component={InputForm}/>
              </Row>
              <Row>
                <AdminItemField
                  titulo={'Date of birth'}
                  tipo={'fecha'}
                  campo={'birthDate'}
                  valor={data['birthDate']}
                  set_dato={setDatoInput}
                  className={"col-12 mt-2 text-start"}
                  horizontal
                />
              </Row>
              <Row>
                <ReactSelect
                  label={"Country of birth"}
                  option={values.birthCountry}
                  options={countriesBirth}
                  setValue={(birthCountry:number) => {setFieldValue('birthCountry', birthCountry)}}
                  placeholder={"Country of birth"}
                  className={"col-12 mt-2 text-start"}
                />
              </Row>
              <Row>
                <ReactSelect
                  label={"City of birth"}
                  option={values.birthCity}
                  options={citiesBirth}
                  setValue={(birthCity:number) => {setFieldValue('birthCity', birthCity)}}
                  placeholder={"City of birth"}
                  className={"col-12 mt-2 text-start"}
                />
              </Row>
              <Row>
                <ReactSelectString
                  label={"Educational level"}
                  option={values.studyLevel}
                  options={optionsStudies}
                  setValue={(studyLevel:string) => {setFieldValue('studyLevel', studyLevel)}}
                  placeholder={"Educational level"}
                  className={"col-12 mt-2 text-start"}
                />
              </Row>
              <Row className="mb-3" style={{marginTop: "20px", textAlign: "center"}}>
                <Col>
                  <Col>
                    <Button style={{
                      backgroundColor: COLORS.green, borderColor: COLORS.green,
                      borderRadius: 35, width: 70, height: 70
                    }} onClick={handlePreviewPage}>
                      <IoIosArrowBack
                        style={{fontSize: 50, marginLeft: "-4px"}}/>
                    </Button>
                  </Col>
                  <Col>
                    <label style={{color: COLORS.white}}>Back</label>
                  </Col>
                </Col>
                <Col>
                  <Col>
                    <Button key={"submit-1"} style={{
                      backgroundColor: COLORS.green, borderColor: COLORS.green,
                      borderRadius: 35, width: 70, height: 70
                    }} type="submit">
                      <IoIosArrowForward
                        style={{fontSize: 50}}/>
                    </Button>
                  </Col>
                  <Col>
                    <label style={{color: COLORS.white}}>Next</label>
                  </Col>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    )
  }

  const Form2 = (props: any) => {
    return (
      <Formik
        key={2}
        initialValues={{
          profession: formValues?.profession ? formValues.profession : data.profession || '',
          residenceAddress: formValues?.residenceAddress ? formValues.residenceAddress : data.residenceAddress || '',
          ciiuCode: data.ciiuCode || '0',
          companyName: formValues?.companyName ? formValues.companyName : data.companyName || '',
          companyPosition: formValues?.companyPosition ? formValues.companyPosition : data.companyPosition || '',
          companyAddress: formValues?.companyAddress ? formValues.companyAddress : data.companyAddress || '',
          department: residenceDepartment,
          country: residenceCountry,
          occupation: occupation,
          city: residenceCity,
          companyCity: companyCity,
          companyDepartment: companyDepartment,
          companyCountry: companyCountry
        }}
        onSubmit={
          (values: IApplicationFormMobile2, actions) => {
            let data2 = formValues
            if (data2) {
              data2.profession = values.profession
              data2.residenceAddress = values.residenceAddress
              data2.ciiuCode = values.ciiuCode
              data2.companyName = values.companyName
              data2.companyPosition = values.companyPosition
              data2.companyAddress = values.companyAddress
              setCompanyCountry(values.companyCountry)
              setCompanyDepartment(values.companyDepartment)
              setCompanyCity(values.companyCity)
              setOccupation(values.occupation)
              setResidenceCountry(values.country)
              setResidenceDepartment(values.department)
              setResidenceCity(values.city)
            }
            setFormValues(data2)
            handleNextPage()
          }
        }
        //validationSchema={schemaMobile2}
      >
        {(props: FormikProps<IApplicationFormMobile2>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldValue,
          } = props
          return (
            <Form key={"form-2"}>
              <Row className={classes.tittleMobile}>
                Work data
              </Row>
              <Row>
                <Field name={"profession"} label={"Profession"} type={""}
                       placeholder={""}
                       className={"col-12 mt-2 text-start"}
                       component={InputForm}
                />
              </Row>
              <Row>
                <Field name={"residenceAddress"} label={"Residence address"} type={""}
                       placeholder={""} className={"col-6 mt-2 text-start"}
                       component={InputForm}/>
                <ReactSelect
                  label={"Country"}
                  option={values.country}
                  options={countriesResidence}
                  setValue={(country:number) => {setFieldValue('country', country)}}
                  placeholder={"Country"}
                  className={"col-6 mt-2 text-start"}
                />
              </Row>
              <Row>
                <ReactSelect
                  label={"Department"}
                  option={values.department}
                  options={regionsResidence}
                  setValue={(department:number) => {setFieldValue('department', department)}}
                  placeholder={"Department"}
                  className={"col-6 mt-2 text-start"}
                />
                <ReactSelect
                  label={"City"}
                  option={values.city}
                  options={citiesResidence}
                  setValue={(city:number) => {setFieldValue('city', city)}}
                  placeholder={"City"}
                  className={"col-6 mt-2 text-start"}/>
              </Row>
              <Row>
                <ReactSelectString
                  label={"Occupation"}
                  option={values.occupation}
                  options={optionsOcupation}
                  setValue={(occupation: string) => {setFieldValue('occupation', occupation)}}
                  placeholder={"Occupation"}
                  className={"col-6 mt-2 text-start"}/>
                <Field name={"ciiuCode"} label={"CIIU Code"} type={""}
                       placeholder={""} className={"col-6 mt-2 text-start"}
                       component={InputForm}/>
              </Row>
              <Row style={{marginBottom: "45px", zIndex:"-999"}}>
                <ToggleSwitch name={"IncomeTaxFiler"} label={"Income Tax Filer"}
                              setValue={setIncomeTaxFiler}
                              className={"col-6"}/>

                <ToggleSwitch name={"CivilServant"} label={"Civil Servant"}
                              setValue={setCivilServant}
                              className={"col-6"}/>
              </Row>
              <Row style={{marginBottom: "45px"}}>
                <ToggleSwitch name={"ManagePublicResources"}
                              label={"Manage public resources"}
                              setValue={setManagePublicResources}
                              className={"col-12"}/>
              </Row>
              <Row>
                <Field name={"companyName"} label={"Name of company or business"} type={""}
                       placeholder={""} className={"col-12 mt-2 text-start"}
                       component={InputForm}/>
              </Row>
              <Row>
                <Field name={"companyPosition"} label={"Position"} type={""}
                       placeholder={""} className={"col-12 mt-2 text-start"}
                       component={InputForm}/>
              </Row>
              <Row>
                <Field name={"companyAddress"} label={"Company address"} type={""}
                       placeholder={""} className={"col-12 mt-2 text-start"}
                       component={InputForm}/>
              </Row>
              <Row>
                <InputPhone label="Telephone ext" setValue={setCompanyTel} value={companyTel}
                            required={true} className={"col-6 mt-2 text-start"}/>
                <ReactSelect
                  label={"Country"}
                  option={values.companyCountry}
                  options={countriesCompany}
                  setValue={(companyCountry: number) => {setFieldValue('companyCountry', companyCountry)}}
                  placeholder={"Country"}
                  className={"col-6 mt-2 text-start"}
                />
              </Row>
              <Row>
                <ReactSelect
                  label={"Department"}
                  option={values.companyDepartment}
                  options={regionsCompany}
                  setValue={(companyDepartment: number) => {setFieldValue('companyDepartment', companyDepartment)}}
                  placeholder={"Department"}
                  className={"col-6 mt-2 text-start"}
                />
                <ReactSelect
                  label={"City"}
                  option={values.companyCity}
                  options={citiesCompany}
                  setValue={(companyCity: number) => {setFieldValue('companyCity', companyCity)}}
                  placeholder={"City"}
                  className={"col-6 mt-2 text-start"}
                />
              </Row>
              <Row className="mb-3" style={{marginTop: "20px", textAlign: "center"}}>
                <Col>
                  <Col>
                    <Button style={{
                      backgroundColor: COLORS.green, borderColor: COLORS.green,
                      borderRadius: 35, width: 70, height: 70
                    }} onClick={handlePreviewPage}>
                      <IoIosArrowBack
                        style={{fontSize: 50, marginLeft: "-4px"}}/>
                    </Button>
                  </Col>
                  <Col>
                    <label style={{color: COLORS.white}}>Back</label>
                  </Col>
                </Col>
                <Col>
                  <Col>
                    <Button key={"submit-2"} style={{
                      backgroundColor: COLORS.green, borderColor: COLORS.green,
                      borderRadius: 35, width: 70, height: 70
                    }} type="submit"
                    >
                      <IoIosArrowForward
                        style={{fontSize: 50}}/>
                    </Button>
                  </Col>
                  <Col>
                    <label style={{color: COLORS.white}}>Next</label>
                  </Col>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    )
  }

  const Form3 = () => {
    return (
      <Formik
        key={3}
        initialValues={{
          monthlyIncome: formValues?.monthlyIncome ? formValues.monthlyIncome : data.monthlyIncome || 0,
          monthlyIncomeDescription: formValues?.monthlyIncomeDescription ? formValues.monthlyIncomeDescription : data.monthlyIncomeDescription || '',
          otherMonthlyIncome: formValues?.otherMonthlyIncome ? formValues.otherMonthlyIncome : data.otherMonthlyIncome || 0,
          otherMonthlyIncomeDescription: formValues?.otherMonthlyIncomeDescription ? formValues.otherMonthlyIncomeDescription : data.otherMonthlyIncomeDescription || '',
          totalMonthlyExpenses: formValues?.totalMonthlyExpenses ? formValues.totalMonthlyExpenses : data.totalMonthlyExpenses || 0,
          totalAssets: formValues?.totalAssets ? formValues.totalAssets : data.totalAssets || 0,
          totalLiabilities: formValues?.totalLiabilities ? formValues.totalLiabilities : data.totalLiabilities || 0,

        }}
        onSubmit={
          (values: IApplicationFormMobile3, {setSubmitting}: any) => {
            let data3 = formValues

            if (data3) {
              data3.monthlyIncome = values.monthlyIncome
              data3.monthlyIncomeDescription = values.monthlyIncomeDescription
              data3.otherMonthlyIncome = values.otherMonthlyIncome
              data3.otherMonthlyIncomeDescription = values.otherMonthlyIncomeDescription
              data3.totalMonthlyExpenses = values.totalMonthlyExpenses
              data3.totalAssets = values.totalAssets
              data3.totalLiabilities = values.totalLiabilities
            }
            setFormValues(data3)
            saveAccountInfo(formValues, setSubmitting)
          }
        }
        validationSchema={schemaMobile3}
        // enableReinitialize
      >
        {(props: FormikProps<IApplicationFormMobile3>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props
          return (
            <Form key={"form-3"}>
              <Row className={classes.tittleMobile}>
                Financial data
              </Row>
              <Row>
                <AdminItemField
                  numero_minimo={0}
                  titulo={'Monthly income ($)'}
                  tipo={'numero'}
                  campo={'monthlyIncome'}
                  valor={data['monthlyIncome']}
                  set_dato={setDatoInput}
                  extra_css={'text-gray'}
                  className={"col-12 mt-2 text-start"}
                />
              </Row>
              <Row>
                <Field name={"monthlyIncomeDescription"}
                       label={"Monthly income description"} type={""}
                       placeholder={""} className={"col-12 mt-2 text-start"}
                       component={InputForm}/>
              </Row>
              <Row>
                <AdminItemField
                  numero_minimo={0}
                  titulo={'Other monthly income ($)'}
                  tipo={'numero'}
                  campo={'otherMonthlyIncome'}
                  valor={data['otherMonthlyIncome']}
                  set_dato={setDatoInput}
                  extra_css={'text-gray'}
                  className={"col-12 mt-2 text-start"}
                />
              </Row>
              <Row>
                <Field name={"otherMonthlyIncomeDescription"}
                       label={"Other monthly income description"} type={""}
                       placeholder={""} className={"col-12 mt-2 text-start"}
                       component={InputForm}/>
              </Row>
              <Row>

                <AdminItemField
                  numero_minimo={0}
                  titulo={'Total monthly expenses ($)'}
                  tipo={'numero'}
                  campo={'totalMonthlyExpenses'}
                  valor={data['totalMonthlyExpenses']}
                  set_dato={setDatoInput}
                  extra_css={'text-gray'}
                  className={"col-12 mt-2 text-start"}
                />
              </Row>
              <Row>
                <AdminItemField
                  numero_minimo={0}
                  titulo={'Total assetss ($)'}
                  tipo={'numero'}
                  campo={'totalAssets'}
                  valor={data['totalAssets']}
                  set_dato={setDatoInput}
                  extra_css={'text-gray'}
                  className={"col-12 mt-2 text-start"}
                />
              </Row>
              <Row>
                <AdminItemField
                  numero_minimo={0}
                  titulo={'Total liabilities ($)'}
                  tipo={'numero'}
                  campo={'totalLiabilities'}
                  valor={data['totalLiabilities']}
                  set_dato={setDatoInput}
                  extra_css={'text-gray'}
                  className={"col-12 mt-2 text-start"}
                />
              </Row>
              <Row className="mb-3" style={{marginTop: "20px", textAlign: "center"}}>
                <Col>
                  <Col>
                    <Button style={{
                      backgroundColor: COLORS.green, borderColor: COLORS.green,
                      borderRadius: 35, width: 70, height: 70
                    }} onClick={handlePreviewPage}>
                      <IoIosArrowBack
                        style={{fontSize: 50, marginLeft: "-4px"}}/>
                    </Button>
                  </Col>
                  <Col>
                    <label style={{color: COLORS.white}}>Back</label>
                  </Col>
                </Col>
                <Col>
                  <Col>
                    <Button key={"submit-3"}
                            style={{
                              backgroundColor: COLORS.green, borderColor: COLORS.green,
                              borderRadius: 35, width: 70, height: 70
                            }}
                            disabled={isSubmitting || Loading}
                            type="submit">
                      {Loading &&
                        <Spinner animation="border" variant="light"
                                 size={'sm'}/>}
                      {!Loading && <BsCheck
                        style={{fontSize: 50, marginLeft: "-2px"}}/>}
                    </Button>
                  </Col>
                  <Col>
                    <label style={{color: COLORS.white}}>Save</label>
                  </Col>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    )
  }

  return (
    <PageWebDark login_required={true}>
      <div className={"container mt-5"} style={{textAlign: "center", color: COLORS.white}}>
        <div className={"container row"} style={{
          color: COLORS.white,
          justifyContent: "center",
          fontWeight: "bold",
          fontSize: "x-large",
        }}>Complete Application
        </div>
        {data.id !== -1 &&
          <div
            // className={"col"}
          >
            {isMobile && page === 1 &&
              <Form1/>
            }
            {isMobile && page === 2 &&
              <Form2/>
            }
            {isMobile && page === 3 &&
              <Form3/>
            }
            {!isMobile && <div className={"container row"}>
              <Formik
                key={0}
                initialValues={{
                  firstName: data.firstName || '',
                  secondName: data.secondName || '',
                  firstSurname: data.firstSurname || '',
                  secondSurname: data.secondSurname || '',
                  idNumber: data.idNumber || '',
                  idDate: data.idDate || '',
                  email: data.email || '',
                  birthDate: data.birthDate || '',
                  profession: data.profession || '',
                  residenceAddress: data.residenceAddress || '',
                  ciiuCode: data.ciiuCode || '0',
                  companyName: data.companyName || '',
                  companyPosition: data.companyPosition || '',
                  companyAddress: data.companyAddress || '',
                  //companyTel: data.companyTel || '',
                  monthlyIncome: data.monthlyIncome || 0,
                  monthlyIncomeDescription: data.monthlyIncomeDescription || '',
                  otherMonthlyIncome: data.otherMonthlyIncome || 0,
                  otherMonthlyIncomeDescription: data.otherMonthlyIncomeDescription || '',
                  totalMonthlyExpenses: data.totalMonthlyExpenses || 0,
                  totalAssets: data.totalAssets || 0,
                  totalLiabilities: data.totalLiabilities || 0,
                }}
                onSubmit={
                  (values: IApplicationForm, {setSubmitting}: any) => {
                    // console.log("birthCountry: ", birthCountry)
                    // console.log("residenceCountry: ", residenceCountry)
                    // console.log("companyCountry: ", companyCountry)
                    // console.log("birthCity: ", birthCity)
                    // console.log("residenceCity: ", residenceCity)
                    // console.log("companyCity: ", companyCity)
                    // console.log("residenceDepartment: ", residenceDepartment)
                    // console.log("companyDepartment: ", companyDepartment)
                    saveAccountInfo(values, setSubmitting)
                  }
                }
                validationSchema={schema}
              >
                {(props: FormikProps<IApplicationForm>) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                    isSubmitting,
                  } = props
                  return (
                    <Form key={"form-0"}>
                      <Row>
                        <div className={"container row"} style={{
                          color: COLORS.white,
                          marginLeft: "0px",
                          fontWeight: "bold",
                          fontSize: "x-large",
                          marginTop: "20px",
                        }}>Profile data
                        </div>
                        <Field name={"firstName"} label={"First name"} type={""}
                               placeholder={""}
                               className={"col-lg-2 col-md-6 col-6 mt-2 text-start"}
                               component={InputForm}/>
                        <Field name={"secondName"} label={"Second name"} type={""}
                               placeholder={""}
                               className={"col-lg-2 col-md-6 col-6 mt-2 text-start"}
                               component={InputForm}/>
                        <Field name={"firstSurname"} label={"First surname"} type={""}
                               placeholder={""}
                               className={"col-lg-2 col-md-6 col-6 mt-2 text-start"}
                               component={InputForm}/>
                        <Field name={"secondSurname"} label={"Second surname"} type={""}
                               placeholder={""}
                               className={"col-lg-2 col-md-6 col-6 mt-2 text-start"}
                               component={InputForm}/>
                        <InputPhone label="Phone number" setValue={setPhone} value={phone}
                                    required={true}
                                    className={"col-lg-2 col-sm-12 mt-2 text-start"}/>
                        <InputPhone label="Cel number" setValue={setCellphone} value={cellphone}
                                    required={true}
                                    className={"col-lg-2 col-sm-12 mt-2 text-start"}/>
                      </Row>
                      <Row>
                        <Field name={"email"} label={"email"} type={"email"} disabled={true}
                               placeholder={""} className={"col-lg-2 col-sm-12 mt-2 text-start"}
                               component={InputForm}/>
                        <ReactSelectString label={"ID type"} option={idType}
                                           options={optionsArray}
                                           setValue={setIdType}
                                           placeholder={"ID type"}
                                           className={"col-lg-2 col-md-6 col-6 mt-2 text-start"}/>
                        <Field name={"idNumber"} label={"Number"} type={""}
                               placeholder={""}
                               className={"col-lg-2 col-md-6 col-6 mt-2 text-start"}
                               component={InputForm}/>
                        <AdminItemField
                          titulo={'Date of issue'}
                          tipo={'fecha'}
                          campo={'idDate'}
                          valor={data['idDate']}
                          set_dato={setDatoInput}
                          className={"col-lg-2 col-md-6 col-6 mt-2 text-start"}
                          horizontal={false}
                        />
                        <ReactSelect label={"Place of issue"} option={idPlace}
                                     options={countriesIdPlace}
                                     setValue={setIdPlace}
                                     placeholder={"Place of issue"}
                                     className={"col-lg-3 col-md-6 col-6 mt-2 text-start"}/>
                      </Row>
                      <Row>
                      </Row>
                      <Row>
                        <AdminItemField
                          titulo={'Date of birth'}
                          tipo={'fecha'}
                          campo={'birthDate'}
                          valor={data['birthDate']}
                          set_dato={setDatoInput}
                          className={"col-lg-3 col-sm-12 mt-2 text-start"}
                          horizontal={false}
                        />
                        <ReactSelect label={"Country of birth"} option={birthCountry}
                                     options={countriesBirth} setValue={onCountryOfBirthChange}
                                     placeholder={"Country of birth"}
                                     className={"col-lg-3 col-sm-12 mt-2 text-start"}/>
                        <ReactSelect label={"City of birth"} option={birthCity}
                                     options={citiesBirth} setValue={setBirthCity}
                                     placeholder={"City of birth"}
                                     className={"col-lg-3 col-sm-12 mt-2 text-start"}/>
                        <ReactSelectString label={"Educational level"} option={studyLevel}
                                           options={optionsStudies} setValue={setStudyLevel}
                                           placeholder={"Educational level"}
                                           className={"col-lg-3 col-sm-12 mt-2 text-start"}/>
                      </Row>
                      <Row>
                        <div
                          style={{
                            color: COLORS.white,
                            marginLeft: "0px",
                            fontWeight: "bold",
                            fontSize: "x-large",
                            marginTop: "25px",
                            textAlign: "start"
                          }}>Work data
                        </div>
                        <Field name={"profession"} label={"Profession"} type={""}
                               placeholder={""} className={"col-lg-2 col-sm-12 mt-2 text-start"}
                               component={InputForm}/>
                        <Field name={"residenceAddress"} label={"Residence address"} type={""}
                               placeholder={""} className={"col-lg-3 col-sm-12 mt-2 text-start"}
                               component={InputForm}/>
                        <ReactSelect label={"Country of residence"} option={residenceCountry}
                                     options={countriesResidence}
                                     setValue={onCountryOfResidenceChange}
                                     placeholder={"Country of residence"}
                                     className={"col-lg-3 col-sm-12 mt-2 text-start"}/>
                        <ReactSelect label={"Department"} option={residenceDepartment}
                                     options={regionsResidence}
                                     setValue={onRegionOfResidenceChange}
                                     placeholder={"Department of residence"}
                                     className={"col-lg-2 col-sm-12 mt-2 text-start"}/>
                        <ReactSelect label={"City"} option={residenceCity}
                                     options={citiesResidence} setValue={setResidenceCity}
                                     placeholder={"City of residence"}
                                     className={"col-lg-2 col-sm-12 mt-2 text-start"}/>
                      </Row>
                      <Row>
                        <ReactSelectString label={"Occupation"} option={occupation}
                                           options={optionsOcupation} setValue={setOccupation}
                                           placeholder={"Occupation"}
                                           className={"col-lg-2 col-sm-12 mt-2 text-start"}/>

                        <Field name={"ciiuCode"} label={"CIIU Code"} type={""}
                               placeholder={""} className={"col-lg-2 col-sm-12 mt-2 text-start"}
                               component={InputForm}/>

                        <ToggleSwitch name={"IncomeTaxFiler"} label={"Income Tax Filer"}
                                      setValue={setIncomeTaxFiler}
                                      className={"col-lg-2 col-sm-12"}/>

                        <ToggleSwitch name={"CivilServant"} label={"Civil Servant"}
                                      setValue={setCivilServant}
                                      className={"col-lg-2 col-sm-12"}/>
                        <ToggleSwitch name={"ManagePublicResources"}
                                      label={"Manage public resources"}
                                      setValue={setManagePublicResources}
                                      className={"col-lg-3 col-sm-12"}/>
                      </Row>
                      <Row>
                        <Field name={"companyName"} label={"Name of company or business"}
                               type={""}
                               placeholder={""} className={"col-lg-4 col-sm-12 mt-2 text-start"}
                               component={InputForm}/>
                        <Field name={"companyPosition"} label={"Position"} type={""}
                               placeholder={""} className={"col-lg-4 col-sm-12 mt-2 text-start"}
                               component={InputForm}/>
                        <Field name={"companyAddress"} label={"Company address"} type={""}
                               placeholder={""} className={"col-lg-4 col-sm-12 mt-2 text-start"}
                               component={InputForm}/>
                      </Row>
                      <Row>
                        <InputPhone label="Telephone ext" setValue={setCompanyTel}
                                    value={companyTel}
                                    required={true}
                                    className={"col-lg-3 col-sm-12 mt-2 text-start"}/>
                        <ReactSelect label={"Country"} option={companyCountry}
                                     options={countriesCompany}
                                     setValue={onCountryOfCompanyChange}
                                     placeholder={"Country of company"}
                                     className={"col-lg-3 col-sm-12 mt-2 text-start"}/>
                        <ReactSelect label={"Department"} option={companyDepartment}
                                     options={regionsCompany} setValue={onRegionOfCompanyChange}
                                     placeholder={"Department of company"}
                                     className={"col-lg-3 col-sm-12 mt-2 text-start"}/>
                        <ReactSelect label={"City"} option={companyCity}
                                     options={citiesCompany} setValue={setCompanyCity}
                                     placeholder={"City of company"}
                                     className={"col-lg-3 col-sm-12 mt-2 text-start"}/>
                      </Row>
                      <Row>
                        <div
                          style={{
                            color: COLORS.white,
                            marginLeft: "0px",
                            fontWeight: "bold",
                            fontSize: "x-large",
                            marginTop: "25px",
                            textAlign: "start"
                          }}>Financial data
                        </div>
                        <AdminItemField
                          numero_minimo={0}
                          titulo={'Monthly income ($)'}
                          tipo={'numero'}
                          campo={'monthlyIncome'}
                          valor={data['monthlyIncome']}
                          set_dato={setDatoInput}
                          extra_css={'text-gray'}
                          className={"col-lg-4 col-sm-12 mt-2 text-start"}
                        />
                        <Field name={"monthlyIncomeDescription"}
                               label={"Monthly income description"} type={""}
                               placeholder={""} className={"col-lg-8 col-sm-12 mt-2 text-start"}
                               component={InputForm}/>
                      </Row>
                      <Row>
                        <AdminItemField
                          numero_minimo={0}
                          titulo={'Other monthly income ($)'}
                          tipo={'numero'}
                          campo={'otherMonthlyIncome'}
                          valor={data['otherMonthlyIncome']}
                          set_dato={setDatoInput}
                          extra_css={'text-gray'}
                          className={"col-lg-4 col-sm-12 mt-2 text-start"}
                        />
                        <Field name={"otherMonthlyIncomeDescription"}
                               label={"Other monthly income description"} type={""}
                               placeholder={""} className={"col-lg-8 col-sm-12 mt-2 text-start"}
                               component={InputForm}/>
                      </Row>
                      <Row>
                        <AdminItemField
                          numero_minimo={0}
                          titulo={'Total monthly expenses ($)'}
                          tipo={'numero'}
                          campo={'totalMonthlyExpenses'}
                          valor={data['totalMonthlyExpenses']}
                          set_dato={setDatoInput}
                          extra_css={'text-gray'}
                          className={"col-lg-4 col-sm-12 mt-2 text-start"}
                        />
                        <AdminItemField
                          numero_minimo={0}
                          titulo={'Total assetss ($)'}
                          tipo={'numero'}
                          campo={'totalAssets'}
                          valor={data['totalAssets']}
                          set_dato={setDatoInput}
                          extra_css={'text-gray'}
                          className={"col-lg-4 col-sm-12 mt-2 text-start"}
                        />
                        <AdminItemField
                          numero_minimo={0}
                          titulo={'Total liabilities ($)'}
                          tipo={'numero'}
                          campo={'totalLiabilities'}
                          valor={data['totalLiabilities']}
                          set_dato={setDatoInput}
                          extra_css={'text-gray'}
                          className={"col-lg-4 col-sm-12 mt-2 text-start"}
                        />
                      </Row>
                      <Row className="mb-3" style={{marginTop: "20px", textAlign: "center"}}>
                          <Col>
                              <Col>
                                  <Button style={{
                                      backgroundColor: COLORS.green, borderColor: COLORS.green,
                                      borderRadius: 35, width: 70, height: 70
                                  }} onClick={history.goBack}>
                                      <IoIosArrowBack
                                        style={{fontSize: 50, marginLeft: "-4px"}}/>
                                  </Button>
                              </Col>
                              <Col><label style={{color: COLORS.white}}>Back</label></Col>
                          </Col>
                        <Col>
                          <Col>
                            <Button style={{
                              backgroundColor: COLORS.green, borderColor: COLORS.green,
                              borderRadius: 35, width: 70, height: 70
                            }}
                                    disabled={isSubmitting || Loading}
                                    type="submit"
                            >
                              {Loading &&
                                <Spinner animation="border" variant="light"
                                         size={'sm'}/>}
                              {!Loading && <BsCheck
                                style={{fontSize: 50, marginLeft: "-2px"}}/>}
                            </Button>
                          </Col>

                          <Col><label style={{color: COLORS.white}}>Save</label></Col>
                        </Col>
                      </Row>
                    </Form>
                  )
                }}
              </Formik>
            </div>}
          </div>}
      </div>
      <Modal show={showSubmitted} size="lg" centered>
        <ModalBody style={{backgroundColor: COLORS.white, textAlign: "center"}}>
          <div style={{marginTop: "10px"}}>
            <AiOutlineCheckCircle style={{color: COLORS.green, fontSize: "100px"}}/>
          </div>
          <div style={{
            fontWeight: "bold",
            fontSize: "X-large",
            marginTop: "10px"
          }}>
            You have successfully submitted a request
          </div>
          <div style={{marginTop: "10px"}}>
            <ButtonSquare label={"Back"} bold={true} startFunction={handleCloseShowSubmitted}/>
          </div>
        </ModalBody>
      </Modal>
      <Modal show={showWarningRental} size="lg" centered onHide={() => setShowWarningRental(false)}>
        <ModalBody style={{backgroundColor: COLORS.white, textAlign: "center"}}>
          <div style={{marginTop: "10px"}}>
            <AiOutlineWarning style={{color: COLORS.red, fontSize: "50px"}}/>
          </div>
          <div style={{
            marginTop: "10px"
          }}>
            The Housners are able to apply for the selected Property ONLY if the rent is a maximum of 30% of
            their monthly income.
          </div>
          <div style={{marginTop: "10px"}}>
            <ButtonSquare label={"Back to Home"} bold={true} startFunction={handleCloseShowWarningRental}/>
          </div>
        </ModalBody>
      </Modal>
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
    </PageWebDark>
  );
});

export default Application;
