import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {RouteComponentProps} from "react-router";
import PageWebDarkWithHeader from "../../../components/PageWeb/PageWebDarkWithHeader";
import useStyles from "./styles";
import {FiSend} from "react-icons/fi";
import {useStores} from "../../../models/root-store/root-store-context";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {Field, Form, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import InputForm from "../../../components/Inputs/InputForm";
import ButtonSquare from "../../../components/Buttons/ButtonSquare";
import ButtonSquareForm from "../../../components/Buttons/ButtonSquareForm";
import Logo from "../../../components/Logo/Logo";
import TextAreaForm from "../../../components/Inputs/TextAreaForm";


interface IFeedback {
  title: string,
  message: string,
}

const schemaFeedback = Yup.object().shape({
  title: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
})


export const Feedback: React.FC<RouteComponentProps> = observer(({history}) => {

  let classes = useStyles();
  const rootStore = useStores()

  const [Loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [variantMessage, setVariantMessage] = useState<string>("");


  const sendFeedback = (values: IFeedback, setSubmitting: { (isSubmitting: boolean): void; (arg0: boolean): void; }) => {
    setLoading(true)
    rootStore.environment.api.sendFeedback(values.title, values.message).then((result: any) => {
      setSubmitting(false)
      setLoading(false)
      if (result.kind === "ok") {
        setShow(true)
        setMessage("Feedback successfully sent")
        setVariantMessage("success")
      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            setShow(true)
            setMessage(result.errors.non_field_errors[0])
            setVariantMessage("warning")
          } else if (result.errors.title) {
            setShow(true)
            setMessage(result.errors.title)
            setVariantMessage("warning")
          } else {
            setShow(true)
            setMessage('Validation errors found')
            setVariantMessage("warning")
          }
        } else {
          setShow(true)
          setMessage('An error occurred, please try again in a few moments')
          setVariantMessage("danger")
        }
      }
    })
  }

  const closeMessage = () => {
    setShow(false)
  }

  return (
    <PageWebDarkWithHeader login_required={true}>
      <div className={'content-container row mx-0'} style={{padding: "0 10% 0 10%"}}>

        <Logo short={true} light={false}/>

        <div className={classes.title}>Feedback</div>
        <div style={{textAlign: "center"}}>
          <Formik
            initialValues={{
              title: "",
              message: ""
            }}
            onSubmit={
              (values: IFeedback, {setSubmitting}) => {
                sendFeedback(values, setSubmitting)
              }
            }
            validationSchema={schemaFeedback}
          >
            {(props: FormikProps<IFeedback>) => {
              const {
                // values,
                // touched,
                // errors,
                // handleBlur,
                // handleChange,
                isSubmitting,
              } = props
              return (
                <Form style={{marginTop: "15px"}}>
                  <div className={"my-2 text-start"}>
                    <Field name={"title"} label={"Title"} bold={true}
                           className={"col-12 col-md-4 mx-auto"} type={"text"}
                           placeholder={""} component={InputForm}/>
                  </div>
                  <div className={"my-2 mb-5 text-start"}>
                    <Field name={"message"} label={"Message"} bold={true}
                           className={"col-12 col-md-4 mx-auto"} type={"text"}
                           placeholder={""} component={TextAreaForm}/>
                  </div>
                  <div style={{textAlign: "center", marginTop: "20px", marginBottom: "20px"}}>
                    <ButtonSquareForm label={"Send"} bold={true}
                                      isSubmitting={isSubmitting}
                                      Loading={Loading}><FiSend
                      style={{marginLeft: "5px", marginBottom: "5px"}}/>
                    </ButtonSquareForm>
                  </div>
                  <div>
                    <ButtonSquare label={"Back"} startFunction={() => history.goBack()}
                                  bold={true}/>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
      <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>

    </PageWebDarkWithHeader>
  );
});
export default Feedback;
