import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react-lite";
import PageWebAdminDark from "../../../components/PageWeb/PageWebAdminDark";
import {COLORS, ROUTES_ADMIN} from "../../../constants/constants";
import AdminSearch from "../../../components/AdminSearch/AdminSearch";
import {FiUserCheck} from "react-icons/fi";
import {useStores} from "../../../models/root-store/root-store-context";
// import ButtonDark from "../../../components/Buttons/ButtonDark";
// import {IoArrowUndo} from "react-icons/io5";
import useStyles from "../styles"
import AdminPagination from "../../../components/AdminPagination/AdminPagination";
// import {CloseButton, Modal, ModalBody, ModalHeader, Spinner} from "react-bootstrap";
// import {Field, Form, Formik, FormikProps} from "formik";
// import InputTextAreaFormModal from "../../../components/Inputs/InputTextAreaFormModal";
// import * as Yup from "yup";
// import ButtonRoundForm from "../../../components/Buttons/ButtonRoundForm";
import {inHash} from "../../../helpers";
import ToastComponent from "../../../components/Toasts/ToastCustom";
import {NoDataAdmin} from "../../../components/NoData/NoDataAdmin";
import {SpinnerLight} from "../../../components/Spinner/SpinnerLight";

// interface IEmail {
//     message: string,
// }
//
// const schemaEmail = Yup.object().shape({
//     message: Yup.string().required('Required'),
// })

export const BackgroundCheckList: React.FC<RouteComponentProps> = observer(({history}) => {

    let classes = useStyles();

    const rootStore = useStores()
    // const [LoadingSendEmail, setLoadingSendEmail] = useState<boolean>(false);
    const [Loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [valuesToSearch, setValuesToSearch] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [previousPage, setPreviousPage] = useState<string | null>("");
    const [nextPage, setNextPage] = useState<string | null>("");
    // const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
    // const [emailMessage, setEmailMessage] = useState<string>("");
    // const [idUserToSendEmail, setIdUserToSendEmail] = useState<number>(-1);
    // const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [variantMessage, setVariantMessage] = useState<string>("");
    const [, setPathLastCalled] = useState<string>("/admin/background-checks/");
    const [, setOffsetLastCalled] = useState<number>(0);

    const setResultListToData = (result: any) => {
      let datos: any = [];
      result.data.results.map((resultItem: any) => {
        datos.push({
          id: resultItem.id,
          userId: resultItem.user_id,
          fullName: resultItem.full_name,
          email: resultItem.email,
          phone: resultItem.phone,
          statusCheck: resultItem.status_check,
          statusCheckCode: resultItem.status_check_code,
          globalScore: resultItem.global_score,
          creditScore: resultItem.credit_score,
          approvedCheck: resultItem.approved_check, //Approved or Denied
          approvedCheckCode: resultItem.approved_check_code //APPROVED or DENIED
        })
      })
      setData(datos)
    }

    const getBackgroundCheckList = (rows: number) => {
      setLoading(true)
      rootStore.environment.api.getBackgroundCheckList(rows).then((result: any) => {
        setLoading(false)
        if (result.kind === "ok") {
          setResultListToData(result)
          setPreviousPage(result.data.previous)
          setNextPage(result.data.next)
        } else {
          if (result.kind === "bad-data") {
            if (result.errors.non_field_errors) {
              setShow(true)
              setMessage(result.errors.non_field_errors[0])
              setVariantMessage("warning")
            } else {
              setShow(true);
              setMessage("Validation errors found")
              setVariantMessage("warning")
            }
          } else {
            setShow(true)
            setMessage("An error occurred, please try again in a few moments")
            setVariantMessage("danger")
          }
        }
      })
    }

    useEffect(() => {
      getBackgroundCheckList(rowsPerPage)
    }, []);


    const search = (rows: number) => {
      rootStore.environment.api.getChecksSearch(valuesToSearch, rows).then((result: any) => {
        if (result.kind === "ok") {
          setResultListToData(result)
          setCurrentPage(1)
          setPreviousPage(result.data.previous)
          setNextPage(result.data.next)
          setTotalPages(Math.ceil(result.data.count / rows))
        } else {
          if (result.kind === "bad-data") {
            if (result.errors.non_field_errors) {
              setShow(true)
              setMessage(result.errors.non_field_errors[0])
              setVariantMessage("warning")
            } else {
              setShow(true)
              setMessage('Validation errors found')
              setVariantMessage("warning")
            }
          } else {
            setShow(true)
            setMessage('An error occurred, please try again in a few moments')
            setVariantMessage("danger")
          }
        }
      })
    }

    const getChecksPrevNextPage = (path: string, offset: number) => {
      setPathLastCalled(path)
      setOffsetLastCalled(offset)
      rootStore.environment.api.getCheckListPrevNextPage(path).then((result: any) => {
        if (result.kind === "ok") {
          setResultListToData(result)
          setCurrentPage(Math.floor(offset / rowsPerPage) + 1)
          setPreviousPage(result.data.previous)
          setNextPage(result.data.next)
        } else {
          if (result.kind === "bad-data") {
            if (result.errors.non_field_errors) {
              setShow(true)
              setMessage(result.errors.non_field_errors[0])
              setVariantMessage("warning")
            } else {
              setShow(true);
              setMessage("Validation errors found")
              setVariantMessage("warning")
            }
          } else {
            setShow(true)
            setMessage("An error occurred, please try again in a few moments")
            setVariantMessage("danger")
          }
        }
      })
    }

    // const openShowEmail = (idUser: number) => {
    //     setIdUserToSendEmail(idUser)
    //     setShowEmailModal(true)
    // }

    // const sendEmail = (message: string) => {
    //     console.log("message: ", message)
    //     setShowEmailModal(false)
    //     setIdUserToSendEmail(0)
    //     // console.log("email message: ", emailMessage);
    //     // console.log("email message to id User: ", idUserToSendEmail);
    // }

    const handlePrevPageClicked = () => {
      let from = previousPage?.substring(previousPage?.indexOf("offset") + 7);
      let path = previousPage?.substring(previousPage?.indexOf("v1") + 2);
      if (path !== undefined) {
        if (from !== undefined && !isNaN(parseInt(from))) {
          getChecksPrevNextPage(path, parseInt(from))
        } else {
          getChecksPrevNextPage(path, 1)
        }
      }
    }

    const handleNextPageClicked = () => {
      let from = nextPage?.substring(nextPage?.indexOf("offset") + 7);
      let path = nextPage?.substring(nextPage?.indexOf("v1") + 2);

      if (path !== undefined) {
        if (from !== undefined && !isNaN(parseInt(from))) {
          getChecksPrevNextPage(path, parseInt(from))
        } else {
          getChecksPrevNextPage(path, 1)
        }
      }
    }

    const handleRowsPerPageClicked = (rows: number) => {
      setRowsPerPage(rows)
      if (valuesToSearch !== "") {
        search(rows)
      } else {
        getBackgroundCheckList(rows)
      }
    }

    // const openUserDetails = (id: number) => {
    //     getProfileDataFromUser(id)
    //     setShowUserDetails(true)
    // }

    const closeMessage = () => {
      setShow(false)
    }

    const BackgroundChecksItems = (currentItems: any) => {
      return (
        <>
          {currentItems.currentItems?.length > 0 &&
            <div style={{marginTop: "35px"}}>
              {currentItems.currentItems.map((item: any, idx: number) => {
                return (
                  <div key={idx} className={["row", classes.list].join(" ")}>
                    <div className={"col-1"}>{item.userId}
                    </div>
                    <div
                      className={["col-2 text-wrap ", classes.btnUser].join(" ")}
                      onClick={() => history.push(ROUTES_ADMIN.USER_INFO(inHash(item.userId)))}>
                      {item.fullName} </div>
                    <div className={"col-3 text-wrap"}>{item.email} </div>
                    <div className={"col-2 text-wrap"}>{item.phone} </div>
                    <div className={"col-1 text-center"}
                         style={{
                           color: item.statusCheckCode === "completed" ? COLORS.green : item.statusCheckCode === "error" ?
                             COLORS.redError : item.status_check_code === "not_started" ? COLORS.yellow :
                               item.statusCheckCode === "in_progress" ? COLORS.orange : item.statusCheckCode === "delayed" ?
                                 COLORS.greenApple : COLORS.white
                         }}
                    >{item.statusCheck} </div>

                    <div className={"col-1 text-center"}>
                      {item.statusCheckCode === "completed" ? item.globalScore : ""}
                    </div>
                    <div className={"col-1 text-center"}>
                      {item.statusCheckCode === "completed" ? item.creditScore : ""}
                    </div>
                    <div className={"col-1 text-center"} style={{
                      color: item.approvedCheckCode === "APPROVED" ? COLORS.green : COLORS.orange
                    }}>
                      {item.statusCheckCode === "completed" ? item.approvedCheck : ""}
                    </div>
                    {/*<div className={"col-2"}*/}
                    {/*     style={{display: "inline-flex", marginLeft: "12px", width: "110px"}}>*/}
                    {/*    <ButtonDark startFunction={() => openShowEmail(item.userId)}*/}
                    {/*                bold={false}>*/}
                    {/*        <IoArrowUndo/> email*/}
                    {/*    </ButtonDark>*/}
                    {/*</div>*/}
                  </div>
                );
              })}

            </div>
          }
        </>
      );
    }

    return (
      <PageWebAdminDark>
        <div className={"row"}>
          <div className={"col-9"}
               style={{color: COLORS.white, fontSize: "xx-large"}}>
            <FiUserCheck/> Background Check results
          </div>
          <div className={"col-3"}
               style={{display: "flex", justifyContent: "center"}}>
            <AdminSearch valuesToSearch={valuesToSearch} setValuesToSearch={setValuesToSearch}
                         search={search} rowsPerPage={rowsPerPage}/>
          </div>
        </div>
        {!Loading && data?.length === 0 &&
          <NoDataAdmin/>}
        {Loading &&
          <SpinnerLight/>
        }
        {!Loading && data && data?.length > 0 &&
          <div>
            <div className={["row", classes.listTittles].join(" ")}>
              <div className={"col-1"}>User ID</div>
              <div
                className={"col-2"}>Name
              </div>
              <div className={"col-3"}>Email</div>
              <div className={"col-2"}>Phone</div>
              <div className={"col-1 text-center"}>Check status</div>
              <div className={"col-1 text-center"}>Global score</div>
              <div className={"col-1 text-center"}>Credit score</div>
              <div className={"col-1 text-center"}>According to global score</div>
            </div>
            <BackgroundChecksItems currentItems={data}/>
            <AdminPagination rowsPerPage={rowsPerPage} handleRowsPerPageClicked={handleRowsPerPageClicked}
                             currentPage={currentPage} handleNextPageClicked={handleNextPageClicked}
                             handlePrevPageClicked={handlePrevPageClicked} nextPage={nextPage}
                             previousPage={previousPage} totalPages={totalPages}/>
          </div>}

        {/*<Modal show={showEmailModal}>*/}
        {/*    <ModalHeader className={classes.modal}>*/}
        {/*        <div className={classes.divModalHeader}>*/}
        {/*            Write email*/}
        {/*        </div>*/}
        {/*        <CloseButton variant="white" onClick={() => setShowEmailModal(false)}/>*/}
        {/*    </ModalHeader>*/}
        {/*    <ModalBody className={classes.modal}>*/}
        {/*        <Formik*/}
        {/*            initialValues={{*/}
        {/*                message: ""*/}
        {/*            }}*/}
        {/*            onSubmit={*/}
        {/*                (values: IEmail) => {*/}
        {/*                    sendEmail(values.message)*/}
        {/*                }*/}
        {/*            }*/}
        {/*            validationSchema={schemaEmail}*/}
        {/*        >*/}
        {/*            {(props: FormikProps<IEmail>) => {*/}
        {/*                const {*/}
        {/*                    // values,*/}
        {/*                    // touched,*/}
        {/*                    // errors,*/}
        {/*                    // handleBlur,*/}
        {/*                    // handleChange,*/}
        {/*                    isSubmitting,*/}
        {/*                } = props*/}
        {/*                return (*/}
        {/*                    <Form>*/}
        {/*                        <Field name={"message"} label={""} bold={false}*/}
        {/*                               className={"text-center"}*/}
        {/*                               placeholder={"Write message"} component={InputTextAreaFormModal}/>*/}
        {/*                        <div style={{textAlign: "center", marginTop: "20px"}}>*/}
        {/*                            <ButtonRoundForm label={"Send"} bold={true}*/}
        {/*                                             isSubmitting={isSubmitting}*/}
        {/*                                             Loading={LoadingSendEmail}><FiSend*/}
        {/*                                style={{marginLeft: "5px", marginBottom: "5px"}}/>*/}
        {/*                            </ButtonRoundForm>*/}
        {/*                        </div>*/}
        {/*                    </Form>*/}
        {/*                )*/}
        {/*            }}*/}
        {/*        </Formik>*/}
        {/*    </ModalBody>*/}
        {/*</Modal>*/}
        {/*<Modal show={showUserDetails}>*/}
        {/*    <ModalHeader className={classes.modal}>*/}
        {/*        <div className={classes.modalHeaderUserDetail}>*/}
        {/*            Profile data*/}
        {/*        </div>*/}
        {/*        <CloseButton variant="white" onClick={() => setShowUserDetails(false)}/>*/}
        {/*    </ModalHeader>*/}
        {/*    <ModalBody className={classes.modal}>*/}
        {/*        <div className={"row my-3"} style={{borderBottom: "solid"}}>*/}
        {/*            <div className={"col-4"} style={{marginBottom: "15px", textAlign: "center"}}>*/}
        {/*                <div>*/}
        {/*                    {data.profile_picture !== "" && data.profile_picture !== null &&*/}
        {/*                        <img src={data.profile_picture} alt="Profile image"*/}
        {/*                             style={{*/}
        {/*                                 width: "90px",*/}
        {/*                                 height: "90px",*/}
        {/*                                 borderRadius: "100px",*/}
        {/*                                 marginLeft: "auto",*/}
        {/*                                 marginRight: "auto",*/}
        {/*                                 marginBottom: "10px",*/}
        {/*                             }}*/}
        {/*                        />}*/}
        {/*                </div>*/}
        {/*                <div style={{fontWeight: "bold"}}>{data.first_name}{space}{data.second_name}</div>*/}
        {/*                <div style={{fontWeight: "bold"}}>{data.last_name}{space}{data.second_last_name}</div>*/}
        {/*                <div>Studies</div>*/}
        {/*                <div>{data.study_level}</div>*/}
        {/*                <div>Profession</div>*/}
        {/*                <div>{data.profession}</div>*/}
        {/*            </div>*/}
        {/*            <div className={"col-8"} style={{marginBottom: "15px"}}>*/}
        {/*                <div>{data.id_type}{space}{data.id_number}</div>*/}
        {/*                <div>Date of issue{space}{data.id_date_of_issue}</div>*/}
        {/*                <div>Country{space}{data.id_place_of_issue}</div>*/}
        {/*                <div>Tel:{space}{data.phone_number}</div>*/}
        {/*                <div>Cel:{space}{data.cell_phone_number}</div>*/}
        {/*                <div>{data.email}</div>*/}
        {/*                <div>Birth date:{space}{data.date_of_birth}</div>*/}
        {/*                <div>City and country:{space}{data.city_of_birth},{data.country_of_birth}</div>*/}
        {/*                <div>Address:{space}{data.address}</div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className={"row my-3"} style={{borderBottom: "solid"}}>*/}
        {/*            <div className={"col-5"} style={{marginBottom: "15px"}}>*/}
        {/*                <p style={{fontWeight: "bold"}}>Work data</p>*/}
        {/*                <div>Occupation:{space}{data.occupation}</div>*/}
        {/*                <div>CIIU:{space}{data.ciiu_code}</div>*/}
        {/*                <div>Company:{space}{data.company_name}</div>*/}
        {/*                <div>Address:{data.company_address}</div>*/}
        {/*                <div>Tel:{space}{data.company_phone_number}</div>*/}
        {/*            </div>*/}
        {/*            <div className={"col-7"} style={{marginBottom: "15px"}}>*/}
        {/*                <div>Income tax filer:{space}{data.income_tax_filer ? "YES" : "NO"}</div>*/}
        {/*                <div>Public servant:{space}{data.public_servant ? "YES" : "NO"}</div>*/}
        {/*                <div>Public resources management:{space}{data.id_place_of_issue ? "YES" : "NO"}</div>*/}
        {/*                <div>Company position:{space}{data.company_position}</div>*/}
        {/*                <div>{data.company_city}{space}{data.company_region}{space}{data.company_country}</div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className={"row my-3"} style={{borderBottom: "solid"}}>*/}
        {/*            <div className={"col-4"} style={{marginBottom: "15px"}}>*/}
        {/*                <p style={{fontWeight: "bold"}}>Finantial data</p>*/}
        {/*            </div>*/}
        {/*            <div className={"col-8"} style={{marginBottom: "15px"}}>*/}
        {/*                <div>Monthly income:{space}{data.monthly_income}</div>*/}
        {/*                <div>Monthly income description:{space}{data.monthly_income_description}</div>*/}
        {/*                <div>Other monthly income:{space}{data.other_monthly_income}</div>*/}
        {/*                <div>Other monthly income description:{data.other_monthly_income_description}</div>*/}
        {/*                <div>Montly total expenses:{space}{data.montly_total_expenses}</div>*/}
        {/*                <div>Total assets:{space}{data.total_actives}{space}{space}Total*/}
        {/*                    liabilities:{space}{data.total_passives}</div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </ModalBody>*/}
        {/*</Modal>*/}
        <ToastComponent message={message} close={closeMessage} show={show} variant={variantMessage} delay={0}/>
      </PageWebAdminDark>
    )
  })
;

export default BackgroundCheckList;
