import {makeStyles} from "@material-ui/styles";
import {COLORS} from "../../constants/constants";

export default makeStyles({

    btnStyle: {
        backgroundColor: COLORS.green,
        borderColor: "transparent",
        "&:hover, &:focus": {
            cursor: "pointer",
            backgroundColor: COLORS.darkgreen,
            borderColor:COLORS.darkgreen
        },
        "&:disabled": {
            backgroundColor: COLORS.darkgreen,
            borderColor:COLORS.darkgreen
        },
    },
    btnStyleBold: {
        backgroundColor: COLORS.green,
        borderColor: "transparent",
        fontWeight: "bold",
        "&:hover, &:focus": {
            cursor: "pointer",
            backgroundColor: COLORS.darkgreen,
            borderColor:COLORS.darkgreen
        },
        "&:disabled": {
            backgroundColor: COLORS.darkgreen,
            borderColor:COLORS.darkgreen
        },
    },

    btnStyleRound: {
        backgroundColor: COLORS.green,
        borderColor: COLORS.white,
        borderRadius:"15px",
        "&:hover, &:focus": {
            cursor: "pointer",
            backgroundColor: COLORS.darkgreen,
            borderColor:COLORS.darkgreen
        },
        "&:disabled": {
            backgroundColor: COLORS.darkgreen,
            borderColor:COLORS.darkgreen
        },
    },
    btnStyleBoldRound: {
        backgroundColor: COLORS.green,
        borderColor: COLORS.white,
        fontWeight: "bold",
        borderRadius:"15px",
        "&:hover, &:focus": {
            cursor: "pointer",
            backgroundColor: COLORS.darkgreen,
            borderColor:COLORS.darkgreen
        },
        "&:disabled": {
            backgroundColor: COLORS.darkgreen,
            borderColor:COLORS.darkgreen
        },
    },

    btnDark: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green,
            backgroundColor: "transparent",
            borderColor:"transparent"
        },
    },
    btnDarkBold: {
        backgroundColor: "transparent",
        borderColor: "transparent",
        fontWeight: "bold",
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green,
            backgroundColor: "transparent",
            borderColor:"transparent"
        },
    },
    btnModal:{
        backgroundColor: COLORS.gray,
        color: COLORS.white,
        border: COLORS.gray,
        "&:hover, &:focus": {
            cursor: "pointer",
            color: COLORS.green,
            backgroundColor: "transparent",
            borderColor:"transparent"
        },
    }

});