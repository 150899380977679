import React from "react";
import useStyles from "./styles";
import {FieldInputProps, FormikProps} from "formik";
import {COLORS} from "../../constants/constants";

type InputProps = {
  field: FieldInputProps<any>,
  form: FormikProps<any>,
  label: string,
  bold: boolean,
  type: string,
  className: string,
  placeholder: string,
  disabled?: boolean,
};

export const TextAreaForm: React.FC<InputProps> = React.forwardRef((props, ref) => {
  let classes = useStyles();

  return (
    <div className={props.className}>
      <div>
        <label className={props.bold ? classes.labelBold : classes.label}>{props.label}</label>
      </div>
      <div style={{marginTop: "10px"}}>
        <textarea
          rows={4}
          disabled={props.disabled ? props.disabled : false}
          className={'form-control white-text-input ' + (props.type === 'date' ? ' pt-1' : ' ')}

          {...props.field}

          placeholder={props.placeholder}
          style={{
            backgroundColor: COLORS.gray,
            color: COLORS.white,
            borderRadius: "12px",
            opacity: 1
          }}
        />
      </div>
      {props.form.errors[props.field.name] && props.form.touched[props.field.name] ? (
        <div className={classes.error}>{props.form.errors[props.field.name]}</div>
      ) : null}
    </div>
  )
});

export default TextAreaForm;
